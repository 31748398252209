import { getStorage } from "@/settings/zustand"
import { create } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"

export const useDataStore: any = create( devtools( persist( (set: any, get: any ) => ({
    
}),
{
    name: 'pe-data-storage', 
    storage: createJSONStorage( getStorage ), 
} 
)))