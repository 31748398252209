import { ID } from "@/libs/interfaces/layouts"
import { Loading } from "@/libs/useful"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useNavigate } from "react-router"
import { useFestival } from "../../hooks/festival"
import Message from "./Message"
import MessageFriends from "./MessageFriends"


/* Protopia Ecosystem component */
export interface IMessagesListProps {
    parentMode?: boolean
}
const MessagesList = (props: IMessagesListProps): JSX.Element => {
    const navigate = useNavigate()
    const landId: ID = useMainStore((state: IState) => state.landId) || "-1"   
    const isLoading = useFestival(!props.parentMode, landId)  
    const paging = () => { 
        return `  metas:[{key: "adressee", value:"list"}]  order_by_meta: "id" `
    }
    
    if(isLoading) {
        return <div className="layout-state">
            <Loading />
        </div>
    }

    const onSelect=(user: IUser) => {
        navigate( `user/${user.id}` )
    }
    return <div className=' short-container'> 
        <MessageFriends onSelect={onSelect}/>
        <Feed 
            data_type={ "PEMessage" }  
            offset={0}
            land_id={landId}
            component={Message}
            topPagingWidget={
                <div className="flex-grow-100 w-100">
                    <UserSearchEngine 
                        onSelect={onSelect} 
                        isMulti={false} 
                        landId={landId} 
                        onlyThisLands 
                        notClear
                        placeholder="Search adressee"
                        autoFocus
                    />
                </div>
            }
            isDateRaze
            dateRazeClassName="small px-3 py-1 border border-secondary mt-4 "
            class_name="short-container"
            paging={ paging() }
            // onLoading={() => setIsLoading(false)}
            params= {{
                col: 1
            } }
        />
    </div> 
}
export default MessagesList