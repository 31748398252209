import { ID } from '@/libs/interfaces/layouts'
import { getFeedRoute, getRoute } from '@/libs/layouts'
import { __ } from '@/libs/utilities'
import actions from '@/modules/pe-basic-module/data/actions'
import DiaryCard from '@/modules/pe-basic-module/views/diary/DiaryCard'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GET_FEED_ACTION } from '../../data/actionTypes'
import { IDiary, IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'

const LastDiary = ( ) :JSX.Element | null => {
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    return !!festival.isDiary
        ?
        <LastDiaryForm/ >
        :
        <></>
} 

export default LastDiary

const LastDiaryForm = ( ): JSX.Element => {
    
    const festId : ID           = useFestDataStore( (state: IFestDataStor) => state.festId )
    const [posts, setPosts]     = useState<IDiary[]>( [] as IDiary[] )
    useEffect( () => {
        actions(
            GET_FEED_ACTION, 
            {
                count:3, 
                land_id: festId,
                data_type: "PEDiary"
            }
        )
            .then((resp : any) => { 
                console.log( resp.feed )
                setPosts( resp.feed ) 
            })
    }, [ festId ] )
    
    return !!posts.length 
        ?
        <section className=" light-colored ">
            <div className="small-container">
                <div className="row justify-content-center">
                    <div className="col-md-12 d-flex justify-content-center pt-5 pb-3">
                        <div className="role_descr">
                            {__("Last Diaries from Projects")}
                        </div>
                    </div>
                    {
                        posts.map((post: IDiary) => {
                            return <DiaryCard
                                key={ post.id }
                                item={ post }
                                minimal
                                hideComments
                            />
                        })
                    }   
                    <div className='col-12 d-flex justify-content-center my-2'>
                        <Link 
                            to={ `/${getRoute(getFeedRoute( ("PEDiary")))}`}
                            className="btn btn-secondary"
                        >
                            {__("All diaries")}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        :
        <></>
} 