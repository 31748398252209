import { IAttachments } from "../interfaces";

export const ADD_ATTACHMENT = ( data: IAttachments) => {
    const site = data.landId 
        ?
        ` land_id: "${data.landId}" `
        :
        ``
    const q =`
    mutation addAttachment {
        changePEFestAttachment(
            ${site}
            input: {
                type:"${ data.type }",
                data: "${ data.data }",
                descr: "${ data.descr }",
                member_id:"${ data.member_id }",
                milestone_id:"${ data.milestone_id }",
            }
        ) {
            ID
            data
            type
            descr
            member_id
            milestone_id
            unixtime
        }
    }    
    ` 
    //console.log( q )
    return q
}