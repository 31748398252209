import Layouts, { description, iconBg, iconHeight, iconPadding, iconUrl, iconWidth, title } from "@/libs/layouts"
import { Widgets } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SINGLE_ACTIONS } from "@/modules/pe-basic-module/data/actionTypes"
import { ILayoutStore, useLayoutStore } from "@/settings/zustand"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"

const LayoutHeaderHeader = (props: any) :JSX.Element => {
  return <Widgets 
        areaName="layout-header-left" 
        data = {{ ...props }}
        defArea = { <LayoutHeaderTitle /> }
    />
}
export default LayoutHeaderHeader


/* Protopia Ecosystem component */
interface ILayoutHeaderTitleProps {

}
export const LayoutHeaderTitle = (props: ILayoutHeaderTitleProps): JSX.Element => {
    const _title:string         = useLayoutStore((state: ILayoutStore) => state.title)
    const _description:string   = useLayoutStore((state: ILayoutStore) => state.description) 
    const _icon:string          = useLayoutStore((state: ILayoutStore) => state.icon)  
    useEffect(() => { 
        if( !_title && !_description && !_icon ) {
            if( !!Layouts().app?.land_group_id ) {
                actions(GET_SINGLE_ACTIONS, {data_type: "PELandGroup", id: Layouts().app?.land_group_id })
                    .then( (resp) => { 
                        useLayoutStore.setState( {title: resp.title }) 
                        useLayoutStore.setState( {description: resp.post_content })
                        useLayoutStore.setState( {icon: resp.thumbnail })
                    }) 
                    .catch(() => {
                        useLayoutStore.setState( {title: title() })
                        useLayoutStore.setState( {description: description() })
                        useLayoutStore.setState( {icon: iconUrl() }) 
                    })
            }
            else {
                useLayoutStore.setState( {title: title() })
                useLayoutStore.setState( {description: description() })
                useLayoutStore.setState( {icon: iconUrl() }) 
            }
        }
    }, []) 
    return <NavLink to="/" style={{ display: "flex" }} className="mainLink pl-0 mr-3">
        <div
            className="layout-header-icon p-0 m-0"
            style={{
                backgroundImage: `url(${_icon})`,
                backgroundSize: `${ iconWidth() }px ${ iconHeight() }px `,
                height: iconHeight() + iconPadding(),
                width: iconWidth() + iconPadding(),
                minWidth: iconWidth(),
                backgroundColor: iconBg(),
            }}
        />
        <div className="layout-header-title ml-4"> 
            { _title } 
            <div className="layout-header-description d-sm-flex ">
                { _description }
            </div>
        </div>
    </NavLink>
}