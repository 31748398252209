import { DIALOG_SIZE, URL } from "@/libs/interfaces/layouts"
import { Button, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import ScalableDialog from "../ScalableDialog"
import useVKApi from "@/modules/pe-basic-module/hooks/useVKApi" 

/* Protopia Ecosystem component */
export interface IindexProps {
    src: URL 
    btnWidth?:  number | string
    btnHeight?: number | string
    padding?:   number | string
    intent?:    Intent | string
    minimal?:   boolean
    fill?:      boolean
    image?:     URL
    label:      string
    isModal?:   boolean

}
const VKVideoButton = (props: IindexProps): JSX.Element => {
    return props.isModal
        ?
        <ModalButton {...props} />
        :
        <InlineButton {...props} />
}
export default VKVideoButton

const ModalButton = (props: IindexProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [player, setPlayer] = useState<any>()
    const width  = !isNumeric( props.btnWidth ) ? props.btnWidth?.toString()  || "640px" : Number(props.btnWidth)  + "px"
    const height = !isNumeric(props.btnHeight)  ? props.btnHeight?.toString() || "320px" : Number(props.btnHeight) + "px"
    const iframe = (/<iframe/).test(props.src)
        ?
        doAutoplay( props.src, "&autoplay=1&js_api=1 id=" + getId(props.src)) 
        : 
        props.src
      
    const initPlayer = (player: any) => {
        setPlayer( player )
        player?.play()
    }   
    const onPaused = () => {
        //setIsOpen(false)
    } 
    return <div style={{padding: props.padding}}> 
        <VKButton {...props} width={width} height={height} setIsOpen={setIsOpen} />
        <ScalableDialog 
            dialogSize={DIALOG_SIZE.NORMAL}
            isOpen={isOpen}
            mayFullScreen
            hideClose={ false }
            onClose={ () => setIsOpen( false ) } 
        > 
            <div className=" h-100 min-height-350 d-flex flex-column"> 
                <VKVideoPlayer 
                    value={ iframe } 
                    width={"100%"} 
                    height={"100%"} 
                    player={player}
                    onPaused={ onPaused } 
                    initPlayer={initPlayer}
                />
            </div>
        </ScalableDialog>
    </div>
}

const InlineButton = (props: IindexProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [player, setPlayer] = useState<any>()
    const width: string  = !isNumeric( props.btnWidth ) ? props.btnWidth?.toString()  || "640px" : Number(props.btnWidth)  + "px"
    const height: string = !isNumeric(props.btnHeight)  ? props.btnHeight?.toString() || "320px" : Number(props.btnHeight) + "px"
    const iframe: string = (/<iframe/).test(props.src)
        ?
        doAutoplay( props.src, "&autoplay=0&js_api=1 id=" + getId(props.src)) 
        : 
        props.src  
        
    const initPlayer = (player: any) => {
        setPlayer( player )
    }
    const onPlay = ( isOpen: boolean ) => { 
        player?.play()
        setIsOpen(true)
    }
    const onPaused = () => {
        setIsOpen(false)
    } 

    return <div  
        style={{
            width,
            height,
            overflow: "hidden",
            padding: props.padding, 
            backgroundColor:"#00000033", 
            position: "relative"
        }}
    > 
        <div className={`position-absolute top left w-100 h-100 ${!isOpen ? "d-none" : ""}`} >
            <VKVideoPlayer 
                value={ iframe } 
                width={width} 
                height={height}  
                player={player}
                onPaused={ onPaused } 
                initPlayer={initPlayer}
            />
        </div>
        <div className={`position-absolute top left w-100 h-100 ${isOpen ? "d-none" : ""}`} >
            <VKButton {...props} width={width} height={height} setIsOpen={onPlay} />
        </div> 
        
    </div>
}

interface IVKButtonProps extends IindexProps {
    width: string
    height: string
    setIsOpen: (isOpen: boolean) => void

}

const VKButton = ({setIsOpen, width, height, ...props}: IVKButtonProps): JSX.Element => {
    return <Button 
        intent={props.intent as Intent  || Intent.NONE} 
        minimal={props.minimal}
        fill={props.fill}
        onClick={ () => setIsOpen(true) }
        className="p-0 stretch" 
    >
        <div 
            className="pe-video-btn"
            style={{
                width, 
                height,
                backgroundImage:    `url(${props.image})`,
            }}
        >   
            <div 
                className="anti-muar position-absolute top left"
                style={{
                    width, 
                    height,
                }}
            />                 
            <div 
                className="pe-video-btn position-absolute top left bg-transparent untouchble"
                style={{
                    width, 
                    height,
                }}
            >
                <span className="mt-3">
                    {props.label}
                </span>
            </div>
        </div>
    </Button>
}
const VKVideoPlayer = (props: any) => {
    const isDisabled: boolean   = useVKApi () 
    const videoWidth            = props.width  ? props.width  : 390
    const videoHeight           = props.height ? props.height : 260
    const initPlayer = () => {
        var iframe = document.getElementById(getId( props.value )); 
        if( iframe ){ 
            if(!window.VK?.VideoPlayer) {
                //console.error("No VK.VideoPlayer loaded. ", getId( props.value ) )
                return
            }
            const player = window.VK.VideoPlayer( iframe )
                //console.log( "initPlayer",  player )
            if(!!props.initPlayer) {
                props.initPlayer(player)
            } 
            player.on("inited", (state: any) => {  
                
            })
            player.on("paused", (state: any) => {
                //console.log( "paused",  player ) 
                if(!!props.onPaused) props.onPaused()
            })
        }
        else {
            console.error("No load container for ", getId( props.value ) )
        } 
    }
    useEffect(() => {
        if(!isDisabled) {  
            initPlayer() 
           
            /*
            const vData: IVKVideoData = getVKVideoData(props.value)        
            window.VK.Api.call(
                "video.get",
                {
                    videos: `${vData.ownerId}_${vData.id}`,
                    v:"5.199"
                },
                (r: any) => {
                    if(r.error) {
                        console.log( r )
                        AppToaster.show({
                            message: "VK API error:"+  r.error?.error_msg,
                            intent: Intent.DANGER,
                            timeout: 10000
                        }) 
                        return
                    }
                    console.log( r )
                }
            )
                */
        }
        else {

        }
    }, [isDisabled])
    const __html = props.value
        .replace(/width="\d*"/, "width='"+ videoWidth + "'")
        .replace(/height="\d*"/, "height='"+videoHeight+"'")
        .replace(/width='\d*'/, "width='"+ videoWidth + "'")
        .replace(/height='\d*'/, "height='"+videoHeight+"'")
        .replace(/width=d*/, "width='"+ videoWidth + "'")
        .replace(/height=d*/, "height='"+videoHeight+"'") 
        .replace("iframe", `iframe style="display: flex; flex-grow:100;" `)
        
    const value = <>
        <div className='w-100 h-100 flex-grow-100 d-flex flex-column ' dangerouslySetInnerHTML={{ __html }} />
    </>
    return value
}


function isNumeric(params:string | number | undefined) : boolean { 
    return !!params ? params.toString() === parseInt( params.toString() ).toString() : false
}

function getSrcData( iframe: string ) :RegExpExecArray | null {
    const match = (/<iframe[^>]+src=([^\s]+)[\s]/).exec(iframe)
    return match
}

function doAutoplay(iframe: string, replace: string) : string  { 
    const match: RegExpExecArray | null = getSrcData( iframe )
    return match 
        ? 
        iframe.replace( match[1],  match[1] + replace)
        : 
        iframe
     
}
function getId( iframe: string ): string {
    const match: RegExpExecArray | null = (/<iframe[^>]+&id=([^&hash]+)[&hash]/).exec(iframe)
    return  (match ? match[1] : Math.random().toString()).toString()
}