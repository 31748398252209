import React, { useId } from 'react' 
import { Icon, Intent } from '@blueprintjs/core'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const Boolean = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<BooleanEnabled {...props} />}
        desabledForm={<BooleanDesabled {...props} />}
    />
} 
export default Boolean 

export const BooleanEnabled = ( props: IScalarForm ): JSX.Element => {
    const id = useId()
    const onChange = (evt: any) => {
        const { checked } = evt.currentTarget 
        if( props.onChange )
        {
            props.onChange( checked,  props.field, "" )
        }   
    }

    return <div className=" d-flex my-4">
        <input 
            id = {id} 
            type = "checkbox" 
            className = "_checkbox m-0" 
            checked = {  !!props.value } 
            onChange={onChange}
        />
        <label htmlFor={id} className="ml-3 mr-0 my-0">
            { __(!!props.value 
                ? 
                props.enabledLabel ? props.enabledLabel : "Yes" 
                : 
                props.disabledLabel ? props.disabledLabel : "No"
            )}
        </label>  
    </div>
} 
 
const BooleanDesabled = ( props: IScalarForm ): JSX.Element => {
    return props.value
        ?
        <Icon size={22} icon="tick" intent={Intent.SUCCESS} className='my-auto' /> 
        :
        <Icon size={22} icon="cross" intent={Intent.DANGER} className='my-auto' /> 
} 
 