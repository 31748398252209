
import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { Loading } from "@/libs/useful"
import AttachmentCard from "@/modules/pe-basic-module/views/attachments/AttachmentCard"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { IFestFilters } from "../data/interfaces"
import { IFestStore, useFestStore } from "../data/store"
import { useFestival } from "../hooks/festival"
import PEFestProjectTerm from "./projectList/PEFestProjectTerm"
import PListUnderMenu from "./projectList/PListUnderMenu"

/* Protopia Ecosystem component */
export interface ITrackAttachmentListFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
}
const TrackAttachmentListForm = (props: ITrackAttachmentListFormProps): JSX.Element => {
    
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    // пагинация для фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters )
    
    const [paging, setPaging] = useState<string>( "" )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams() 

    const updateURLSearchParams = () => {
        let params: any = {}
        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
            setPaging( ` metas:[ {key: "parent_type", value: "PEFestTrack"} , {key: "parent_id", value: "${ filters.tracks[0] }" }  ]` )
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
        }
        SetURLSearchParams( params )
    }
    useEffect(() => {
        const tracks = URLSearchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
    }, [ URLSearchParams ])
    useEffect(() => {
        //setOffset(0) 
        updateURLSearchParams()
        
    }, [ filters ])

    const isLoading = useFestival(!props.parentMode, festId) 

    if(isLoading ) return <Loading /> 

    return <div className="mx-auto w-100">
        <PEFestProjectTerm />
        <div className='mx-auto container pt-3 p-0'>
            <PListUnderMenu /> 
            <div className="row">  
                <Feed 
                    offset={0}
                    data_type={"PEAttachment"} 
                    land_id={ festId }
                    paging={ paging } 
                    class_name=' '
                    containerClassName="w-100" 
                    component={ AttachmentCard }
                    params={{ 
                        col: 2
                    }}
                />
            </div>
        </div>
    </div>
}
export default TrackAttachmentListForm

 