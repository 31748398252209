import { DIALOG_SIZE, IPENotification } from "@/libs/interfaces/layouts"
import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import { ScalableDialog } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { Icon, Intent, Tag } from "@blueprintjs/core"
import { useState } from "react"
import Moment from "react-moment"

/* Protopia Ecosystem component */
export interface INotificationProps {
   item: IPENotification
   i:number
}
const Notification = ({item, ...props}: INotificationProps): JSX.Element => { 
    const [isOpen, setOpen] = useState<boolean>(false)
    const onOpen = (evt:any) => {
        evt.preventDefault()
        evt.stopPropagation()
        if( !! item.thumbnail && item.thumbnail?.indexOf("empty.png") === -1 ) {
            setOpen(!isOpen)
        } 
    }
    
    const getUrl = ( ) => {
        let url =""; 
        console.log( item.resource_type )
        switch(item.resource_type) {
            case "PEMessage":
                url = `fest/${item.land?.id || 1}/messages/user/${item.post_author?.id || 1}`
                break
            case "PEFestProject":
                url=`fest/${item.land?.id || 1}/project/${item.id || 1}/ratings?comments=1`
                break
            case "Comment": 
                url = `fest/${item.land?.id || 1}`
                break
            case "PEAttachment": 
                url = `fest/${item.land?.id || 1}/attachments/modal/${item.resource_id || 1}`
                break
            case "Ad":
            default:
                url = `fest/${item.land?.id || 1}/ads/modal/${item.resource_id || 1}`
        }  
        return window.location.origin + component_url("FestivalView") + url
    }

    return <>
        <div className=" w-100 d-flex align-items-center justify-content-start strob01 p-0" >
            <div className="w-100 d-flex flex-grow-100 h-100 p-0">
                <div className="d-flex h-100 flex-column justify-content-between py-4 pl-4">
                    <UserAvatar user={item.post_author} noPadding width={50} height={50} />
                <UserAvatar user={item.adressee} noPadding width={50} height={50} /> 
                </div>
                
                <div className="ml-2 h-100 d-flex flex-column py-4 pr-4 w-100">
                    <div className=" mb-2 d-flex ">
                        <span className="text-secondary mr-2">
                            {__("Author")}
                        </span>
                        <Tag className={``} intent={Intent.SUCCESS} minimal >
                            {item.post_author?.display_name}
                        </Tag>
                    </div>
                    <div className={`small ${ !item.is_viewed ? "title" : "" }`}>
                        {item.title}
                    </div>
                    {
                        item.resource_title && <div className="text-force-contrast  my-1  ">
                            {item.resource_title}
                        </div>
                    }
                    <div className="descr mt-2">
                        <span className="text-secondary mr-2">
                            {__("Land")}
                        </span>
                        <Tag className={``} minimal style={{maxWidth: 200}}>
                            {item.land?.name}
                        </Tag>
                    </div> 
                    <div className="descr mt-auto">
                        <span className="text-secondary mr-2">
                            {__("Adressee")}
                        </span>
                        <Tag className={``} intent={Intent.SUCCESS} minimal style={{maxWidth: 200}}>
                            {item.adressee?.display_name}
                        </Tag>
                    </div>
                    <div className="d-flex justify-content-end">
                        <a href={getUrl()} target="_blank" rel="noreferrer" className="btn btn-secondary btn-sm mt-1">
                            {__("Goto")}
                            <Icon icon="caret-right" size={17} />
                        </a>
                    </div> 
                </div>
            </div>
            <div 
                className={`float-right position-relative h-100 thumbnail ${ props.i % 2 ? `bg-secondary-super-light` : `bg-secondary-light`} ${"pointer"}`} 
                style={{
                    minWidth: 170, 
                    minHeight: 170,
                    backgroundImage: `url(${item.thumbnail})`
                }}
                onClick={onOpen}
            >
                <div className="position-absolute bottom right z-index-100">
                    <Tag className={``} intent={ !item.is_viewed ? Intent.DANGER : Intent.NONE } >
                        { __( item.is_viewed ?  "Viewed" : "Not viewed")}
                    </Tag>
                </div>
                <div className="position-absolute top left z-index-100 m-1">
                    <Tag className=" descr text-light">
                        <Moment locale="ru" format="D MMMM YYYY ">
                            { (Number(item.post_date) * 1000) }
                        </Moment> 
                    </Tag>
                </div>
            </div>
        </div>
        <ScalableDialog
            dialogSize={DIALOG_SIZE.LARGE}
            isOpen={!!isOpen}
            onClose={ () => setOpen(false) }
            hideClose={false}
            mayFullScreen={false}
            isCloseButtonShown
        >
            <div className="p-0 h-100 position-relative w-100 flex-centered">
                <img src={ item.thumbnail }  style={{  maxHeight: "100%" }} alt="" onClick={ () => setOpen(false) } />
            </div>                
        </ScalableDialog>
    </>
}
export default Notification