import { DIALOG_SIZE, HTML } from "@/libs/interfaces/layouts"
import { CommentInputForm, ScalableDialog } from "@/libs/useful"
import Comments from "@/libs/useful/Comments"
import CommentFeed from "@/modules/pe-basic-module/views/comment/CommentFeed"
import { useSearchParams } from "react-router-dom"
import { IProject } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IProjectCommentsProps {
    item: IProject
    onSend?: (comment: HTML) => void
    isForFeed?: boolean
    className?: string
}
const ProjectComments = (props: IProjectCommentsProps): JSX.Element => {

    const [URLSearchParams, SetURLSearchParams] = useSearchParams()
    const onSend = () => {
        let f: any = {comments:  URLSearchParams.get("comments") === "1" ? "0" : "1"}
        if( props.isForFeed) {
            f.project_id = props.item.id
        }
        SetURLSearchParams(f)
    }
    const is = !!URLSearchParams.get("project_id") 
        ?
        URLSearchParams.get("project_id")  === props.item.id && (URLSearchParams.get("comments") || "0") === "1"
        :
        (URLSearchParams.get("comments") || "0") === "1"
    return <>
        <Comments item={props.item} dataType='PEFestProject' onClick={onSend} className={props.className} />
        <ScalableDialog
            dialogSize={DIALOG_SIZE.MINIMAL}
            isOpen={ is }
            onClose={onSend}
            hideClose={false}
            mayFullScreen={false}
        >
            <div className="max-height overflow-y-auto">
                <CommentInputForm 
                    item={props.item} 
                    className="p-0 mt-1 " 
                    noTitle={ !!props.item.comments?.length }
                    discussion_type="post"
                    autoFocus
                    onSend={props.onSend}
                />
                <CommentFeed item={props.item} />
            </div> 
        </ScalableDialog>
    </>
}
export default ProjectComments