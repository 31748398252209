import { getFeedRoute, getRoute, schema } from "src/libs/layouts"
import { getURL } from "src/libs/scalars/scalars/URL"
import { __ } from "src/libs/utilities"
import { IFeedProps } from "src/modules/pe-basic-module/views/core/FeedComponent"
 
import { CompoundTag, Intent, Position, Tag } from "@blueprintjs/core"
import Moment from "react-moment"
import { ClipboardCopyBtn, CommentInputForm, Likes } from "src/libs/useful"
import Comments from "src/libs/useful/Comments"
import { IAds } from "../../data/interfaces"
import CommentFeed from "src/modules/pe-basic-module/views/comment/CommentFeed"

interface IProps extends IFeedProps { 
    item: IAds
    className?: string
    firstCommentAutofocus?: boolean
}
const AdCardMobile = ({item, className, ...props }:IProps) : JSX.Element => { 
    const typeName = __( item.parent_type ? schema()[item.parent_type]?.name || "" : "" )
    return <>
        <div className={ ` position-relative ${ className } mx-auto pl-1 pr-3 mb-3 max-width-400 ` }>
            <div className="d-flex border border-secondary w-100" style={{maxWidth:700, minHeight: 270}}>
                <div className="  position-relative m-1 d-flex flex-column flex-grow-100 pb-3"> 
                    <div className="bg-secondary-light p-3 h lead" style={{fontWeight: 900, lineHeight:0.9}}>
                        {item.title}
                    </div>
                    <div className="position-relative">
                        {
                            item.thumbnail &&
                                <div className="position-relative mb-3 min-height-40">
                                    <img src={item.thumbnail} alt="" className=" max-width-370 max_h_300 mb-2"/>
                                    
                                    {
                                        !!item.date &&
                                            <Moment  locale="ru" format="D.MM.YYYY" className={`px-3 py-1 bg-secondary text-white position-absolute top left m-1`}>
                                                { parseInt(item.date.toString()) * 1000 }    
                                            </Moment> 
                                    }  
                                </div> 
                        } 
                        <div className="px-3 " dangerouslySetInnerHTML={{__html: item.post_content}} /> 
                        <div className="d-flex py-0 px-3 w-100 align-items-center">
                            <ClipboardCopyBtn
                                data={`${window.location.origin}/${getRoute(getFeedRoute("Ad"))}/${item.id}`}
                                type="button"
                                label={__("Copy link")}
                                position={Position.TOP_RIGHT}
                            /> 
                            <Comments item={item } dataType="Ad" />
                            {/* <Likes item={item} dataType="Ad" className="" /> */}
                        </div>
                    </div> 
                    {
                        item.url &&
                            <a href={ getURL(item.url)[0]} target="_blank" rel="noreferrer" className="mt-auto ml-auto">
                                <Tag intent={Intent.SUCCESS}>
                                    <small>{ getURL(item.url)[1] || __("Ins")}</small>
                                </Tag>
                            </a> 
                    } 
                    {
                        !!item.parent?.id && 
                            <CompoundTag  
                                // round
                                interactive
                                active={false}
                                intent={Intent.PRIMARY}
                                className="position-absolute bottom left " 
                                leftContent={ <div className="small text-overflow">{typeName}</div> }
                                
                            > 
                                <div className="small text-overflow">
                                    { item.parent?.title}
                                </div>
                            </CompoundTag>
                    } 
                </div>
            </div>
            <CommentInputForm 
                item={item} 
                className="p-0 mt-1 " 
                noTitle 
                discussion_type="post"
                autoFocus={props.firstCommentAutofocus}
            />
            <CommentFeed item={item} />
        </div>
    </>
} 

export default AdCardMobile