import { useState } from "react"
import { IFestDataStor, useFestDataStore, useFestProjects } from "../../data/store"
import { ICritery, IFestival, IProject } from "../../data/interfaces"
import { useProjectLeader } from "../../hooks/project"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { Tag } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */ 
const RatingsReport = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)
    const [project, setProject] = useState<IProject>( useFestProjects.getState().currentProject )
    const criteries: ICritery[] = useDataStore((state: any) => state.PEFestCritery)
    const landId = useFestDataStore((state: IFestDataStor) => state.festId ) 
    const festival:IFestival = useFestDataStore((state: IFestDataStor) => state.festival ) 
    const isOwner: boolean = useProjectLeader(project, landId)

    return <div className="pe-project-team-container minimal-colored my-3 pb-5 pt-3">
        <div className={`short-container ${isLoading ? "untouchble opacity_5" : ""}`}> 
            <div className="lead-md text-center mb-4">
                {
                    __("Ratings report")
                }
            </div>
            {
                project.ratings
                .filter(rating => !!rating.rating)
                .map((rating, i) => {
                    return <div key={i} className="row bg-light-strobe">
                        <div className="col-md-1 display-6 flex-centered">
                            {
                                rating.rating
                            }
                        </div>
                        <div className="col-md-4 flex-centered">
                            {
                                rating.display_name
                            }
                        </div>
                        <div className="col-md-5 text-left small "> 
                            <div dangerouslySetInnerHTML={{__html: rating.description}} />
                        </div>
                        <div className="col-md-2 flex-centered">
                            <Tag className="small py-0 px-3" round>
                            {
                                criteries.filter(cr => cr.id === rating.criteryId)[0]?.title
                            }
                            </Tag>
                        </div>
                        {
                            // JSON.stringify( rating )
                        }
                    </div>
                })
                
            } 
        </div>
    </div>
}
export default RatingsReport