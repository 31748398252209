import { DIALOG_SIZE, IComment } from "@/libs/interfaces/layouts"
import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { getModeratorRoles } from "@/modules/pe-fest-module/data/utils/getModeratorRoles"
import { IState, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { Button, ButtonGroup, Callout, Icon, Intent, Popover, Position } from "@blueprintjs/core"
import Moment from "react-moment"
import actions from "../../data/actions"
import { UPDATE_COMMENT_ACTION, UPDATE_SINGLE_TYPE } from "../../data/actionTypes"
import { useEffect, useState } from "react"
import { ScalableDialog } from "@/libs/useful"

/* Protopia Ecosystem component */
export interface ICommentProps {
   item: IComment
}
const Comment = (props: ICommentProps): JSX.Element => {
    const [item, setItem] = useState<IComment>(props.item)
    const landId = useMainStore((state:IState) => state.landId)
    const user = useMainStore((state: IState) => state.user)
    const [isOpen, setOpen] = useState<boolean>(false)
    
    useEffect(() => {
        setItem(props.item)
    }, [props.item])
    const onRemove = () => {
        setItem({ ...item, is_approved: !item.is_approved })
        actions(UPDATE_COMMENT_ACTION, {
            item: {
                is_approved: !item.is_approved
            },
            landId,
            id: item.id
        }) 
    }
    const onOpen = (evt:any) => {
        evt.preventDefault()
        evt.stopPropagation()
        setOpen(!isOpen)
    }

    const onBlockUser = () => {
        actions(UPDATE_SINGLE_TYPE, {
            data_type:"User",
            land_id: landId ,
            item: {
                is_blocked: !item.author?.is_blocked
            },
            id: item.author?.id
        })
    }


    return  <div className='pe-comment-container d-flex flex-column w-100 py-4 pl-4 pr-3'>
            <div className='d-flex w-100'>
                <UserAvatar user={ item.author } width={ 33 } height={ 33 } style={{width: 33, height: 33}}/>
                <div className="d-flex flex-column flex-grow-100 " >
                    {
                        item.author?.is_blocked
                            ?
                                <Callout intent={Intent.DANGER} className="p-3 lead-md text-center ">
                                    {__("Comment by banned User")}
                                </Callout>
                            :
                            item.is_approved
                                ?
                                <>                            
                                    <div className="title small">{item.author.display_name}</div>
                                    <div className="" dangerouslySetInnerHTML={{__html: item.content }} />
                                </>
                                :
                                <Callout intent={Intent.DANGER} className="p-3 lead-md text-center ">
                                    {__("Comment not approved")}
                                </Callout>
                    } 
                    {
                        item.thumbnail?.indexOf("empty.png") === -1 &&
                        <>
                            <div 
                                className="thumbnail my-1 pointer" 
                                style={{
                                    height: 150, 
                                    width: "auto", 
                                    backgroundImage: `url(${item.thumbnail})`, 
                                    backgroundSize: "contain", 
                                    backgroundRepeat:"no-repeat", 
                                    backgroundPosition:"top left",  
                                }} 
                                onClick={onOpen}
                            />
                            <ScalableDialog
                                dialogSize={DIALOG_SIZE.FULL}
                                className="transparent"
                                isOpen={!!isOpen}
                                onClose={ () => setOpen(false) }
                                hideClose={false}
                                mayFullScreen={false}
                                isCloseButtonShown
                            >
                                <div className="p-0 h-100 w-100 flex-centered position-relative" onClick={ () => setOpen(false) } >
                                    <img src={ item.thumbnail }  style={{  maxHeight: "100%" }} alt=""/>
                                </div>                
                            </ScalableDialog>
                        </>
                    }
                    <div className="mt-1 opacity_75 small">
                    {
                        !!item.date && <Moment locale="ru" format="D MMMM YYYY HH:mm" >
                            { parseInt(item.date.toString()) * 1000 }   
                        </Moment>
                    }
                    </div>
                </div>
                <Popover
                    interactionKind="hover"
                    position={Position.LEFT_TOP}
                    content={<div className="p-0">
                        <ButtonGroup fill vertical>
                            {/* <Button icon="new-person" minimal alignText="left">
                                <span className="small">{__("subscribe to the author")}</span>
                            </Button>
                            <Button icon="add-to-artifact" minimal alignText="left">
                                <span className="small">{__("subscribe to feed")}</span>
                            </Button> */} 
                            {
                                ( isRole(getModeratorRoles()) || user.id === item.author?.id) && 
                                <>
                                    <ConfirmButton
                                        buttonLabel={
                                            <span className="small">
                                                {__(item.is_approved ? "Remove" : "Restore")}
                                            </span>
                                        }
                                        buttonIcon="cross" 
                                        buttonIntent={Intent.DANGER} 
                                        buttonAlignText="left" 
                                        dialogClasssName={DIALOG_SIZE.MINIMAL}
                                        onConfirm={onRemove}
                                    >
                                        <div className="p-4">
                                            {__("Really remove this Comment?")}
                                        </div>
                                    </ConfirmButton> 
                                </>
                            } 
                            {
                                isRole(getModeratorRoles()) && 
                                <> 
                                    <ConfirmButton
                                        buttonLabel={
                                            <span className="small">
                                                {__(item.author?.is_blocked ? "Debun User" : "Ban author")}
                                            </span>
                                        }
                                        buttonIcon="blocked-person" 
                                        buttonIntent={Intent.DANGER} 
                                        buttonAlignText="left" 
                                        dialogClasssName={DIALOG_SIZE.MINIMAL}
                                        onConfirm={onBlockUser}
                                    >
                                        <div className="p-4">
                                            {__(item.author?.is_blocked ? "Debun User?" : "Ban author?")}
                                        </div>
                                    </ConfirmButton>
                                </>
                            }
                        </ButtonGroup>
                    </div>}
                >
                    <Button minimal>
                        <Icon icon="more" size={12}/> 
                    </Button>
                </Popover>
            </div>
            
        </div>
}
export default Comment