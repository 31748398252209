import { getFeedRoute, getRoute, getRouteByComponent } from '@/libs/layouts'
import { __, __cases } from '@/libs/utilities'
import { Button, Collapse, Popover, Position } from '@blueprintjs/core'
import { useState } from 'react'
import { useNavigate, } from 'react-router-dom'
import { ITrack } from '../../data/interfaces' 
import TrackClosedLabel from '../utils/TrackClosedLabel'
import TrackVideo from '../utils/TrackVideo'
import TrackVKLink from '../utils/TrackVKLink'
import { trimContent } from '../utils/trimContent'
import TrackDomains from '../utils/TrackDomains'
import TrackBusinessLabel from '../utils/TrackBusinessLabel'
import ModeratorAvatar from '../utils/getModeratorAvatar'
import Bookmark from '@/libs/useful/Bookmark'
import { Likes } from '@/libs/useful'
import Shortcode from '@/libs/useful/shortcode'
import QRCodeButton from '@/modules/pe-basic-module/views/core/QRCodeButton'

interface IFestTrackCardProps {
  item: ITrack
}

const FestTrackCard = (props: IFestTrackCardProps) :JSX.Element => {  
    const navigate = useNavigate()
    const[isOpen, setOpen] = useState(false) 
    const[random,] = useState( Math.random() )
    const routeSingle = getFeedRoute("PEFestProject")  
    const _url = routeSingle
        ? 
        `/${ getRoute(routeSingle)}?track=${ props.item.id }` 
        : 
        "/"

    const onClick = () => {
      navigate( _url )
    }
    const onCancelClick = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault() 
    }
    const onOpenClick = (evt: any) => {
      onCancelClick(evt)
      setOpen(true)
    }
    const onCloseClick = (evt: any) => {
      onCancelClick(evt)
      setOpen(false)
    }
    const getAvatar = (moderator: "moderator" | "moderator2" = "moderator") => {  
      return <ModeratorAvatar item={props.item} isOpen={isOpen} random={random} moderator={moderator}  />
    }  
    const trimmedContent = trimContent( props.item.content, 20 )
    return  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
      <div 
        className={`card ${props.item.is_buisness ? "is_buisness" : "" } h-100 m-0 p-0 position-relative pointer hover`}
        onClick={onClick}
      >
          <div
            className="card-img overflow-hidden track-card-img"
            style={{
              backgroundImage: props.item.illustration  && props.item.illustration !== "false"  
                ?
                `url(${props.item.illustration})`
                :
                  props.item.video_id 
                  ? 
                  `url(https://img.youtube.com/vi/${ props.item.video_id }/0.jpg)` 
                  : 
                  `url('')`, 
            }} 
          >  
            <TrackBusinessLabel item={props.item} /> 
            <TrackVKLink item={props.item} />
            <TrackVideo  item={props.item} />
            <TrackClosedLabel is_closed={!!props.item?.is_closed} /> 
            <div className='under-track-icon'>

            </div>
          </div> 
          <div
            className="card-header d-flex flex-centered flex-column"
            title={ props.item.title }
            style={{
              height: 90,
              overflow: "hidden",
              padding: "0 1.25em",
              position: "relative",
              display: "table",
              paddingTop: 14
            }}
          >
              <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                { props.item.title }  
              </h5>
              <div>
                <span className='mr-2'>{ props.item.count || 0 }</span>
                <span>{__cases(props.item.count, "projects")}</span>
              </div>
              <div className="position-absolute bottom right" onClick={onCancelClick}>
                <Popover
                  interactionKind='hover'
                  position={Position.TOP_RIGHT}
                  content={<>
                    <QRCodeButton 
                        item={props.item} 
                        options={{
                            text:`${window.location.origin}/${getRouteByComponent("FestivalView")?.realRoute}/members?tack=${props.item.id}`, 
                            title: '',
                            width:  410,
                            height: 410, 
                        }} 
                    />
                    <Bookmark item={ props.item } />
                    <Likes item={ props.item } dataType='PEFestTrack' className='' /> 
                  </>}
                >
                  <Button  minimal >
                    <i className="fas fa-ellipsis-v opacity_5 hover" />
                  </Button>
                </Popover> 
              </div>
          </div>
          <div className='track-icon' style={{backgroundImage: `url(${props.item.thumbnail})`}} /> 
          <ul className="list-group list-group-flush flex-grow-100 ">
            <li className="list-group-item h-100 d-flex flex-column">              
              {
                trimmedContent.isTrim || props.item.moderator?.user_descr?.length > 0
                  ?
                  <>
                    <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                      <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                        <TrackDomains item={props.item} />
                        <Shortcode className="h-100 text-clip--transparent" content={trimmedContent.content } />
                        
                      </div>                                               
                    </Collapse>
                    <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                      <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                        <TrackDomains item={props.item} />
                        <Shortcode className="h-100" content={props.item.content } />
                      </div>
                    </Collapse>
                    <div className="py-md-4 py-2 mt-auto" >
                      { getAvatar() } 
                      { getAvatar( "moderator2" ) }
                    </div> 
                    <div className="pb-2"></div>  
                    <Button 
                      icon={ isOpen ? "chevron-up" : "chevron-down"}
                      className="position-absolute w-100 bottom"
                      minimal 
                      onClick={ isOpen ? onCloseClick : onOpenClick } 
                    />                    
                  </>
                  : 
                  <>
                    <Shortcode className="p-4" content={trimmedContent.content } /> 
                    <div className="py-md-4 p-2 mt-auto" >
                      { getAvatar() }   
                      { getAvatar( "moderator2" ) }                                             
                    </div>
                  </>
              } 
            </li>
          </ul>
      </div>
  </div> 
}

export default FestTrackCard

