import { Button, Dialog, DialogProps, Icon } from "@blueprintjs/core" 
import { DIALOG_SIZE } from "../interfaces/layouts"
import { SyntheticEvent, useState } from "react"

export interface IScalableDialogProps extends DialogProps {
    dialogSize?: DIALOG_SIZE
    mayFullScreen?: boolean
    hideClose?: boolean
    isScale?: boolean
    isControlOutside?: boolean
}
const ScalableDialog = ({
    children,
    onClose,
    onClosed,
    isOpen,
    icon,
    isCloseButtonShown,
    style,
    title,
    transitionName,
    containerRef, 
    mayFullScreen = true, 
    hideClose = true, 
    ...props
}: IScalableDialogProps) : JSX.Element => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    const handleClose = (evt: SyntheticEvent<HTMLElement, Event>) => {
        setIsFullScreen( false )
        if(onClose) onClose( evt )
    }
    return <Dialog
        isOpen={isOpen}
        onClose={onClose}
        onClosed={onClosed}
        title={ title }
        icon={icon}
        isCloseButtonShown={isCloseButtonShown}
        style={style}
        className={`${props.className} ${ !isFullScreen ? props.dialogSize || DIALOG_SIZE.MINIMAL : DIALOG_SIZE.FULL}`}
        containerRef={containerRef} 
    >
        <div className="position-relative h-100 w-100  flex-grow-100">
            {children}
        </div>
        {
            !hideClose && <div className="sdialog-control">
                <Button
                    className="bg-dark p-1"
                    onClick={handleClose}
                >
                    <i className= "fas fa-times" />
                </Button>
                {
                    mayFullScreen && <Button
                        className="bg-dark p-1"
                        onClick={() => setIsFullScreen( !isFullScreen ) }
                    > 
                        <i className={!isFullScreen ? "fas fa-expand" : "fas fa-compress" } />
                    </Button>
                } 
            </div>
        }
        
    </Dialog>
} 
export default ScalableDialog