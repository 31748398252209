import { SCALAR_TYPES } from "@/libs/scalars";

export default function attachmentTypes() : any {
    return {
        [SCALAR_TYPES.MEDIA] : {
            id: SCALAR_TYPES.MEDIA,
            title: "Image",
            actionName: "Open image",
            icon: "/assets/img/topos/media-type.svg",
        },
        [SCALAR_TYPES.VK_VIDEO_ID] : {
            id: SCALAR_TYPES.VK_VIDEO_ID,
            title: "VK video",
            actionName: "Open VK video",
            icon: "/assets/img/topos/vk_video_id-type.svg",

        },
        [SCALAR_TYPES.RUTUBE_ID] : {
            id: SCALAR_TYPES.RUTUBE_ID,
            title: "Rutube video",
            actionName: "Open rutube video",
            icon: "/assets/img/topos/rutube_id-type.svg",

        },
        [SCALAR_TYPES.STRING] : {
            id: SCALAR_TYPES.STRING,
            title: "Text",
            actionName: "See",
            icon: "/assets/img/topos/string-type.svg",

        },
        [SCALAR_TYPES.URL] : {
            id: SCALAR_TYPES.URL,
            title: "Link",
            actionName: "Goto",
            icon: "/assets/img/topos/url-type.svg",

        },
        [SCALAR_TYPES.UPLOAD] : {
            id: SCALAR_TYPES.UPLOAD,
            title: "Upload",
            actionName: "Upload",
            icon: "/assets/img/topos/url-type.svg",

        },
    }
}