import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { useUser } from "@/settings/zustand/user"
import { Button, Dialog, Intent, Popover } from "@blueprintjs/core"
import { Fragment, useEffect, useState } from "react"
import { ILike, IPost, LIKE_TYPE } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import AvatarLink from "./AvatarLink"
import { likes } from "./likes/likes"

interface IProps {
    item: IPost
    dataType: string
    className?: string
}
const Likes = (props: IProps): JSX.Element => {
    const user = useUser()
    const landId = useMainStore((state: IState) => state.landId)
    const [_likes, setLikes] = useState<ILike[]>([])
    const [isMarked,setIsMarked] = useState<boolean>(!!props.item.likes?.filter(b => b.user?.id === user.id).length) 
    useEffect(() => {
         setLikes(props.item.likes || []) 
    }, [])
    const onLike = (type:LIKE_TYPE = LIKE_TYPE.LIKE) => {
        actions(UPDATE_SINGLE_TYPE, {
            landId: landId, 
            id: props.item.id,
            data_type: props.item.__typename, 
            item: {
                likes: [ { type } ]
            }
        })
        .then((res: IPost) => {
            const is = !!res.likes?.filter(b => b.user?.id === user.id).length
            setIsMarked( is )  
            setLikes(res.likes || [])
            if( res.__typename) {
                useMainStore.setState({
                    [ res.__typename ]: [
                        ...(useMainStore.getState()[ res.__typename ] || []).map((r:IPost) => {
                            if(r.id === props.item.id) {
                                return res
                            }
                            return r;
                        })
                    ]
                })
            }
        })
    }
    
    return <Popover  
        hoverOpenDelay={400} 
        interactionKind="hover" 
        position="top-right"
        // content={ <LikesList likes={likes} /> }
        content={ 
            !!_likes?.length 
                ? 
                <LikesUsersList likes={ _likes } type={likes()[LIKE_TYPE.LIKE]}/> 
                : 
                <div className="py-1 px-2">
                    { __(  likes()[LIKE_TYPE.LIKE].title ) }
                </div> 
        }
        // modifiers={{
        //     arrow: { enabled: false },
        //     flip: { enabled: true },
        //     preventOverflow: { enabled: false },
        // }}
    >
        <Button minimal intent={Intent.NONE} large onClick={ () => onLike() } className={`px-2 ${props.className ? props.className : ""}`}  data-hint={__("Like")}>
            <span className={`flex-centered ${isMarked ? "text-danger" : ""}`}>
                <i className={`fa${isMarked ? "s" : "r"} fa-heart mr-1`}></i> 
                <span>{ _likes?.length }</span>
            </span>
        </Button>
    </Popover >
} 

export default Likes

interface ILikeList {
    likes: ILike[]
}
const LikesList = (props:ILikeList) :JSX.Element => {
    const _likes: any = likes()
    return <div className="d-flex flex-row ">
    {
        Object.keys(_likes).map(( like:string ) => {
            const likes = props.likes.filter(lk => lk.type === like) 
            const count : number = likes.length
            return <Popover  
                hoverOpenDelay={ 400 }  
                interactionKind="hover" 
                position="top"
                content={ !!count ? <LikesUsersList likes={likes} type={_likes[like]}/> : <div className="py-1 px-2">{ __( _likes[like].title ) }</div> } 
            > 
                <Button minimal className="" key={like}>
                    <div className="pe-like-elem position-relative">
                    {
                        _likes[like].icon
                    }
                    {
                        !!count && <div className=" pe-like-count ">{count}</div>
                    }  
                    </div> 
                </Button>
            </Popover >
        })
    }
    </div>
}
const LikesUsersList = (props:any) : JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean> (false)
    return <div>
        <div className="pt-1 text-center">{ __( props.type.title ) }</div>
        <div className="d-flex flex-row">
            {
                props.likes.map((like:ILike, i:number) => {
                    return i  <  4 
                    ?  
                    <AvatarLink user={like.user!}  key={like.user!.id || i} />               
                    // <div className="d-flex flex-column p-1 pe-w-100  " key={i}>
                    //     <div className="avatar-large" style={{backgroundImage: `url(${like.user?.avatar})`}} />
                    //     <small className="small text-overflow">{like.user?.display_name}</small>
                    // </div>
                    :
                    null
                })
            }
            {
                props.likes.length > 4 && <Fragment >
                    <div className="flex-centered p-1 pe-w-100 pointer" onClick={ () => setIsOpen(true) }>
                        +{ props.likes.length - 4 }
                    </div>
                    <Dialog
                        isOpen={isOpen}
                        onClose={()=> setIsOpen(false)}
                    >
                        <div className="p-4 flex-centered flex-wrap overflow-y-auto max-height">
                        {
                            props.likes.map((like:ILike, i:number) => {
                                return <AvatarLink user={like.user!}  key={like.user!.id || i} /> 
                                return  <div className="d-flex flex-column p-1 pe-w-100 " key={i}>
                                    <div className="avatar-large" style={{backgroundImage: `url(${like.user?.avatar})`}} />
                                    <small className="small text-overflow">{like.user?.display_name}</small>
                                </div> 
                            })
                        }
                        </div>
                    </Dialog>
                </Fragment> 
            }
        </div>
    </div>
}