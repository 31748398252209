import { useState } from 'react'
import FieldInput from '..'
import IScalarForm, { SCALAR_TYPES, VerticalType } from '../interface/IScalarForm'
import Scalar from './Scalar'

const _Array = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<ArrayEnabled {...props} />}
        desabledForm={<ArrayDisabled {...props} />}
    />
} 
export default _Array 

export const ArrayEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = ( images: string[]) => {
          if(props.onChange) {
            props.onChange(images, props.field, "")
          }
    }

    return <div className=" d-flex my-4  w-100"> 
        <Components {...props} onChange={onChange}/>
        {/* <div>
            { JSON.stringify(props) }
        </div> */}
    </div>
} 
 
const ArrayDisabled = ( props: IScalarForm ): JSX.Element => {
    return <div className=" d-flex my-4">
        ARRAY 
    </div>
} 
 

const Components = ( props: IScalarForm ): JSX.Element => { 
    const [value, setValue] = useState<string[]>( Array.isArray(props.value) ? props.value : [])

    const onMediaChange = (images: string[], images_names: string[]) => {
        setValue( images )
        if(props.onChange)
            props.onChange( images, null,  "" )
    }
    switch(props.component) {
        case SCALAR_TYPES.MEDIA:
            return <div className='pl-3 d-flex flex-wrap w-100'>
                <FieldInput
                    type={SCALAR_TYPES.GALLERY}
                    editable
                    vertical={VerticalType.VERTICAL} 
                    width={100}
                    height={100}
                    value={ value || []}
                    notClear
                    onChange={onMediaChange}
                />
            </div>
        case SCALAR_TYPES.NUMBER:
            return <>NUMBER ARRAY</>
        case SCALAR_TYPES.STRING:
        default:
            return <>{props.component} ARRAY</>
    }
}