import LayoutApp from '@/libs/layout-app';
import { initModules, layoutInit, moduleExists } from "@/libs/layouts";
import { Loading } from "@/libs/useful";
import { __, initDictionary, sprintf } from "@/libs/utilities";
import { Callout, Intent } from "@blueprintjs/core";
import React, { useEffect, useState } from 'react';
import './assets/css/bootstrap.scss';
import './assets/css/bp5.scss';
import './assets/css/index.scss';
import { client } from "./settings/cliets";
import { initConfig } from "./settings/config";
import { IState, useMainStore } from './settings/zustand';

const { ApolloProvider } = require('@apollo/client'); 

const ProtopiaEcosystem = (props: IProtopiaEcosystemProps) : React.ReactElement | null => { 
  const [ isLoad, changeLoad] = useState( false ) 
  const [ isReady, setIsReady] = useState( false )
  const { layoutConfig, dictionary } = props
  const config = useMainStore( ( state: IState ) => state.config )
  useEffect(() =>
  { 
    const promise1: Promise<boolean> = initConfig( props.config )
    const promise2: Promise<boolean> = layoutInit( layoutConfig )
    const promise3: Promise<boolean> = initDictionary( '', dictionary )
    const promise4: Promise<boolean> = initModules()
    
    Promise.all([ promise1, promise2, promise3, promise4 ])
      .then(( [ promise1, promise2, promise3, promise4 ] ) => {
        setIsReady(true)
        changeLoad(true)
      })
      .catch(error => {
        console.error(error)
        // AppToaster.show({
        //   intent: Intent.DANGER,
        //   message: error.message(),
        //   className: "p-4"
        // })
      })
  }, [] ) 
  return isLoad
    ?
      config
      ?
        layoutConfig && moduleExists("pe-basic-module") && isReady
          ? 
          <ApolloProvider client={ client( config ) }>
            <LayoutApp />
          </ApolloProvider> 
          :
          <Callout intent={Intent.DANGER} className="p-5">
          { 
            sprintf( __( "PE application not complete. Layout config not exists. see more: %s" ),  "___" ) 
          }
          </Callout>
      :
      null
    :
    <Loading />
}

export default ProtopiaEcosystem;


export interface IProtopiaEcosystemProps {
  config: any
  layoutConfig: any
  dictionary: any
}