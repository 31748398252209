import { component_url } from "@/libs/utilities/links";
import { IState, useMainStore } from "@/settings/zustand";
import { useEffect, useState } from "react";

export default function useLandLink (): string {
    const [link, setLink] = useState<string>("")
    const landId = useMainStore((state:IState) => state.landId)
    useEffect(() => {
        const _link = component_url("FestivalView")
        console.log(_link)
        setLink(`${_link}fest/${landId}`)
    }, [ landId ])
    return link
}