import React from 'react' 
import { useMainStore, IState } from "@/settings/zustand"
import { LayoutIcon } from '@/libs/useful'
import { IUser } from '@/settings/zustand'

const UserAvatar = (props: any): JSX.Element => {
    
    const currentUser: IUser = useMainStore(( state: IState ) => state.user)
    const user:IUser = props.user ? props.user : currentUser

    return <div className="position-relative">
        <LayoutIcon 
            onClick={props.onClick}
            width={props.width}
            height={props.height}
            src=""
            //src={ !user.avatar ? "/assets/img/user_ava.png" : "" }
            style={ user.avatar ? { backgroundImage: `url(${user.avatar})`, backgroundSize: "cover" } : { backgroundImage: `url(/assets/img/user_ava.png)`, backgroundSize: "cover", width: props.width, height: props.height } }
            className={ `user-ava ${ props.noPadding ? "m-0" : "" }` }
        />
        <div 
            className="position-absolute bottom right ava-external-logo "
            style={{ fontSize: (props.width || 40) / 2 }}
        >
            <i className={`fab fa-${user.external}`}></i>
        </div>
    </div> 
} 

export default UserAvatar