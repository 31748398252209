import { ClipboardCopyBtn, CommentInputForm, Likes } from "@/libs/useful"
import { IDiary } from "@/modules/pe-fest-module/data/interfaces"
import Moment from "react-moment"
import ThumbnailTile from "./ThumbnailTile"
import MessageURL from "./MessageURL"
import { Button, ButtonGroup, Callout, Intent, Popover, Position } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"
import { getModeratorRoles } from "@/modules/pe-fest-module/data/utils/getModeratorRoles"
import { isRole } from "@/settings/zustand/user"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import actions from "../../data/actions"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "../../data/actionTypes"
import { DIALOG_SIZE, POST_STATUS } from "@/libs/interfaces/layouts"
import ResourceLabel from "./ResourceLabel"
import { getSingleDataTypeRoute, } from "@/libs/layouts"
import CommentFeed from "../comment/CommentFeed"
import Shortcode from "@/libs/useful/shortcode"
import Bookmark from "@/libs/useful/Bookmark"
import Comments from "@/libs/useful/Comments"

/* Protopia Ecosystem component */
export interface IDiaryCardProps {
   item: IDiary
   minimal?: boolean
   hideComments?:boolean
}
const DiaryCard = ({item, ...props}: IDiaryCardProps): JSX.Element => {
    const user = useMainStore((state: IState) => state.user)
    const landId = useMainStore((state:IState) => state.landId)
    const onRemove = () => {
        let _item: any = { 
            post_status: POST_STATUS.DRAFT
        }
        delete ( _item["id"] )
        delete ( _item["__typename"] )
        actions(
            UPDATE_SINGLE_TYPE,
            {
                landId,
                data_type: "PEDiary",
                id: item.id,
                item : _item
            }
        ).then(() => {
            actions(
                GET_FEED_ACTION, 
                { 
                    data_type: "PEDiary", 
                    land_id: landId 
                }
            )
        })
    }
    const onBlockUser = ( ) => {
        actions(UPDATE_SINGLE_TYPE, {
            data_type:"User",
            land_id: landId ,
            item: {
                is_blocked: true
            },
            id: item.post_author?.id
        })
        actions(
            GET_FEED_ACTION, 
            { 
                data_type: "PEDiary", 
                land_id: landId 
            }
        )
        //onRemove()
    }
    const post_date: Date = new Date(Number(item.post_date) * 1000)
    const isDay: boolean  = post_date.getDay() !== (new Date()).getDay()
    return item.post_author?.is_blocked
        ?
        <div className="position-relative w-100"> 
            <a href={item.link} target="_blank"  rel="noreferrer" className="vk-avatar hover">
                <div className="avatar rounded-circle mr-2" style={{ backgroundImage: `url(${item.post_author?.avatar})`}}></div>
            </a> 
            <svg className="avatar-tail " x="0px" y="0px" viewBox="0 0 20 20" >
                <path className="card_path" d="M20.1,19.7c-1.8-8-5.7-14.5-14.1-18C5.1,1.3,5.4,0,6.4,0c5.8,0,13.7,0,13.7,0L20.1,19.7z"/>
                <path className="contour"   d="M19.8,19.7C17.7,10.1,12.4,2.6,0.2,0"/> 
                <path className="contour2"  d="M20.1,19.7c-1.8-8-5.7-14.5-14.1-18C5.1,1.3,5.4,0,6.4,0c5.8,0,13.7,0,13.7,0"/> 
            </svg>
            <div className="vk-post card mt-0">
                <Callout intent={Intent.DANGER} className="p-5 lead-md text-center ">
                    {__("Message by banned User")}
                </Callout>
            </div>
        </div>
        :

        <div className={props.minimal ? "position-relative w-100 mb-3" : "position-relative w-100"}> 
        {
            !props.minimal &&
            <>
                <a href={item.link} target="_blank"  rel="noreferrer" className="vk-avatar hover">
                    <div className="avatar rounded-circle mr-2" style={{ backgroundImage: `url(${item.post_author?.avatar})`}}></div>
                </a> 
                <svg className="avatar-tail " x="0px" y="0px" viewBox="0 0 20 20" >
                    <path className="card_path" d="M20.1,19.7c-1.8-8-5.7-14.5-14.1-18C5.1,1.3,5.4,0,6.4,0c5.8,0,13.7,0,13.7,0L20.1,19.7z"/>
                    <path className="contour"   d="M19.8,19.7C17.7,10.1,12.4,2.6,0.2,0"/> 
                    <path className="contour2"  d="M20.1,19.7c-1.8-8-5.7-14.5-14.1-18C5.1,1.3,5.4,0,6.4,0c5.8,0,13.7,0,13.7,0"/> 
                </svg>
            </>
        }
            
            <div className={props.minimal ? 'card m-0 d-flex flex-column p-3' : `vk-post card mt-0`}>
                <div className={ !props.minimal ? 'head' : 'd-flex flex-row justify-content-center align-items-center flex-wrap' }> 
                    <a href={item.link} target="_blank"  rel="noreferrer" className="flex-centered mr-3 text-force-contrast hover">
                        {
                            props.minimal && <div className="avatar rounded-circle mr-2" style={{ backgroundImage: `url(${item.post_author?.avatar})`}}></div>
                        }
                        <span>{ item.post_author?.display_name }</span>
                    </a> 
                    <h5 className="title h mb-0 mr-auto">
                        { item.title }
                    </h5>   
                    <div className="ml-auto">
                        <ResourceLabel item={item} />
                    </div> 
                    <div className="d-flex align-items-center">
                        <div className="post-date mx-1 p-2 ">
                            <Moment locale="ru" format={!isDay ? "HH:mm" : "D MMM"} className="small text-nowrap">
                                {post_date}
                            </Moment>
                        </div> 
                        <Popover
                            interactionKind="hover"
                            position={Position.LEFT_TOP}
                            content={<div className="p-0">
                                <ButtonGroup fill vertical>
                                    {/* <Button icon="new-person" minimal alignText="left">
                                        <span className="small">{__("subscribe to the author")}</span>
                                    </Button>
                                    <Button icon="add-to-artifact" minimal alignText="left">
                                        <span className="small">{__("subscribe to feed")}</span>
                                    </Button> */}
                                    <ClipboardCopyBtn
                                        type="button"
                                        label={__("Copy link")}
                                        className="small"
                                        data={ window.location.origin + getSingleDataTypeRoute("PEDiary", item.id)}
                                    />
                                    {
                                        ( isRole(getModeratorRoles()) || user.id === item.post_author?.id) && 
                                        <>
                                            <ConfirmButton
                                                buttonLabel={<span className="small">{__("Remove")}</span>}
                                                buttonIcon="cross" 
                                                buttonIntent={Intent.DANGER} 
                                                buttonAlignText="left" 
                                                dialogClasssName={DIALOG_SIZE.MINIMAL}
                                                onConfirm={onRemove}
                                            >
                                                <div className="p-4">
                                                    {__("Really remove this Diary?")}
                                                </div>
                                            </ConfirmButton> 
                                        </>
                                    } 
                                    {
                                        isRole(getModeratorRoles()) && 
                                        <> 
                                            <ConfirmButton
                                                buttonLabel={<span className="small">{__("Ban author")}</span>}
                                                buttonIcon="blocked-person" 
                                                buttonIntent={Intent.DANGER} 
                                                buttonAlignText="left" 
                                                dialogClasssName={DIALOG_SIZE.MINIMAL}
                                                onConfirm={onBlockUser}
                                            >
                                                <div className="p-4">
                                                    {__("Ban author?")}
                                                </div>
                                            </ConfirmButton>
                                        </>
                                    }
                                </ButtonGroup>
                            </div>}
                        >
                            <Button minimal icon="more" />
                        </Popover>   
                    </div> 
                </div>
                <div className="content">
                    <div className="mb-3">
                        <ThumbnailTile item={item} /> 
                    </div> 
                    <div className="d-flex w-100">
                        <Shortcode content={item.post_content} className="position-relative flex-grow-100" />
                        <div className="flex-centered flex-wrap "> 
                            <Bookmark item={item} />  
                            <Comments item={item } dataType="PEDiary" /> 
                            <Likes item={item} className="" dataType="PEDiary" />
                            {/* </div> 
                            <div className="d-flex flex-nowrap mx-1 px-2">
                                <span className="far fa-comment" />
                                <span className="mx-1">{item.comments?.length || 0}</span>
                            </div>  */}
                        </div>               
                    </div> 
                    <MessageURL item={item} />
                </div>
                {
                    !props.hideComments 
                    && <>
                        <CommentInputForm 
                            item={item} 
                            className=" px-4 pb-2" 
                            noTitle
                        />
                        <CommentFeed item={item} />
                    </>
                }                
                <div className="footer ">
                    
                </div>
            </div>
        </div> 
}
export default DiaryCard