 
import { default as actionsMain } from '@/modules/pe-basic-module/data/actions'
import { useMainStore } from '@/settings/zustand'
import { getChangeName, getQueryName, querySingleName } from 'src/libs/layouts'
import { ADD_ATTACHMENT_ACTION, CHANGE_CARD_SORT_ACTION, CHANGE_CARD_TYPE_ACTION, CLEAR_CURRENT_PROJECT_ACTION, CLEAR_FESTIVAL_PRESCEPTIONS_ACTION, COMPARE_CURRENT_PROJECT_ACTION, COPY_LAND_ACTION, CORRECT_PROJECT_RATING_ACTION, DELETE_ATTACHMENT_ACTION, DELETE_EXHORTATION_ACTION, SET_FESTIVAL_EXTENDS_ACTION, GET_FEED_ACTION, GET_FEED_COUNT_ACTION, GET_FESTIVAL_COMMUNICATION_ACTION, GET_FESTIVAL_DATA_ACTION, GET_FESTIVAL_SETTINGS_ACTION, GET_FEST_DIARIES_ACTIONS, GET_FULL_STATISTICS_ACTION, GET_HONNOR_HALL_ACTIONS, GET_OWN_ACTION, GET_PROJECT_DIARY_ACTION, GET_PROJECT_MILESTONE_ACTION, GET_SINGLE_ACTIONS, GET_UNVERIFIED_ACTION, REGISTER_NEW_FESTIVAL, REGISTER_PROJECT_ACTION, SEND_EXHORTATION_ACTION, SET_FILTERS_ACTIONS, SNAPSHOT_LAND_ACTION, UDATE_PROJECT_RATING_ACTION, UPDATE_PROJECT_HONEYCOMBS, UPDATE_SINGLE_ACTION, EXTEND_LAND_ACTION, GET_PROJECTS_MILESTONES, GET_TRACK_ATTACHMENTS, UPDATE_TRACK_ATTACHMENT, INERT_PACK_PROJETCS_ACTION, BAN_EXPERT_ACTION } from './actionTypes'
import asyncControllers from './controllers'
import { CARD_TYPE, DEFAULT_FILTERS, IFestFilters, IProject, IProjectMilestone, IRating } from './interfaces'
import { useFestDataStore, useFestMyProjects, useFestProjects, useFestStore } from './store'
import { useDataStore } from '@/modules/pe-basic-module/data/store'



export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
        case REGISTER_PROJECT_ACTION: 
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise  
        case UPDATE_SINGLE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data[ getChangeName( data.data_type ) ] )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise    
        case CLEAR_FESTIVAL_PRESCEPTIONS_ACTION: 
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : any ) => { 
                        resolve( response.data.clearPEFestivalData )
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
        return promise
        case GET_PROJECT_DIARY_ACTION: 
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : any ) => { 
                        resolve( response.getPEDiarys )
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
        return promise
        case GET_FULL_STATISTICS_ACTION: 
        case GET_PROJECTS_MILESTONES: 
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : any ) => { 
                        resolve( response )
                        // console.log( response )
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case SEND_EXHORTATION_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : IRating ) => { 
                        resolve( response )
                        useFestProjects.getState().setCurrentProject({
                            ...useFestProjects.getState().currentProject,
                            exhortations: [
                                ...useFestProjects.getState().currentProject.exhortations,
                                {
                                   ...data,
                                   author: useMainStore.getState().user,
                                   unixtime: Date.now() / 1000
                                }
                                
                            ]
                        })
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case UDATE_PROJECT_RATING_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : IRating ) => { 
                        resolve( response )
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case CORRECT_PROJECT_RATING_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : IRating ) => { 
                        resolve( response )
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case DELETE_EXHORTATION_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    ( response : IRating ) => { 
                        resolve( response )
                    },
                    ( error: any ) => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case COMPARE_CURRENT_PROJECT_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        const currentProject: IProject = useFestProjects.getState().currentProject
                        
                        // сравниваем текущую версию клиента с версией сервера, если не свпадают - обновляем.
                        if( response.data.getPEFestProject.version !== currentProject.version ) {
                            actionsMain(GET_SINGLE_ACTIONS, {data_type: "PEFestProject", id: data.id, land_id: data.land_id } )
                                .then( (res: IProject) => { 
                                    //console.log(res)
                                    useFestProjects.getState().setCurrentProject( res )  
                                })  
                            actions(GET_PROJECT_MILESTONE_ACTION, {id: data.id, land_id: data.land_id}) 
                                .then( (res: IProjectMilestone[]) => { 
                                    //console.log( res )
                                    useFestProjects.getState().setCurrentProjectMilestones( res )  
                                })     
                        }
                        resolve( response.data.getPEFestProject.version !== currentProject.version )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case GET_HONNOR_HALL_ACTIONS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {                      
                        resolve( response.data.getPEFestProjects )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_FEST_DIARIES_ACTIONS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {                   
                        resolve( [
                            ...response.data.getPEDiarys
                        ] )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_OWN_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {
                        resolve( response.data.getPEFestProjects )
                        useFestMyProjects.getState().setMyProjects( response.data.getPEFestProjects )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_UNVERIFIED_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {
                        useFestMyProjects.setState({ unverificiedProjects: response.data.getPEFestProjects } )
                        resolve( response.data.getPEFestProjects )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_FESTIVAL_DATA_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        
                        resolve( response.data )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case SET_FESTIVAL_EXTENDS_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {  
                        resolve( response.data )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_FESTIVAL_SETTINGS_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        if(!data.ignoreSet) {
                            useFestDataStore.getState().setFestival( response.data.getPEFestival )
                        }
                        resolve( response.data.getPEFestival )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case COPY_LAND_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        resolve( response?.data?.registerLand )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case SNAPSHOT_LAND_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        resolve( response?.data?.get_full_festival_body )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case CHANGE_CARD_TYPE_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response:CARD_TYPE) => { 
                        useFestStore.getState().setCardType(response)
                        resolve( response )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case CHANGE_CARD_SORT_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response:CARD_TYPE) => { 
                        console.log( response )
                        useFestStore.getState().setCardSort(response)
                        resolve( response )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_FESTIVAL_COMMUNICATION_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        resolve( response.data.getPEFestival )                      
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_FEED_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        const name: string = getQueryName(data.data_type) 
                        const nameCount: string = querySingleName(data.data_type)
                        asyncControllers( GET_FEED_COUNT_ACTION, data ).then( r => {
                            resolve( {feed: response.data[name], count: r.data[`${nameCount}Count`] })
                        })                        
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_SINGLE_ACTIONS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        const query_name: string = querySingleName( data.data_type )  
                        resolve( response.data[ query_name ] )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case DELETE_ATTACHMENT_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        resolve( response.data  )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case ADD_ATTACHMENT_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        resolve( response.data  )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case GET_PROJECT_MILESTONE_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {   
                        const ProjectMilestones: IProjectMilestone[] = response.data.getPEFestProjectMilestones?.map((pm: IProjectMilestone) => ({
                            ...pm,
                            id: pm.id.toString().split("_")[0]
                        }))
                        useFestProjects.getState().setCurrentProjectMilestones( 
                            ProjectMilestones 
                        )
                        resolve( ProjectMilestones )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case CLEAR_CURRENT_PROJECT_ACTION:
            promise = new Promise(( resolve ) => {
                useFestProjects.getState().setCurrentProject( {} )
                useFestProjects.getState().setCurrentProjectMilestones( [] )
                resolve( true )
            })
            return promise
        case REGISTER_NEW_FESTIVAL:
            promise = new Promise(( resolve, reject ) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        resolve( response.data.registerNewPEFestival )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case UPDATE_PROJECT_HONEYCOMBS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {   
                        if( response.data.changePEFestProject?.honeycombs?.id === data.honeycombsId ) {
                            const currentProject : IProject = { ...useFestProjects.getState().currentProject }
                            currentProject.honeycombs = {
                                ...currentProject.honeycombs,
                                ...response.data.changePEFestProject.honeycombs
                            }
                            useFestProjects.getState().setCurrentProject( currentProject )
                        }
                        resolve( response.data.changePEFestProject?.honeycombs?.id === data.honeycombsId )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case EXTEND_LAND_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {   
                        resolve( response?.data?.extendLand )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case GET_TRACK_ATTACHMENTS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {  
                        // console.log( response?.data?.getPETrackAttachments )
                        useDataStore.setState({
                            PETrackAttachment : response?.data?.getPETrackAttachments
                        }) 
                        resolve( response?.data?.getPETrackAttachments )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case UPDATE_TRACK_ATTACHMENT:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {   
                        
                        resolve( response?.data?.changePETrackAttachment )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case INERT_PACK_PROJETCS_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {   
                        
                        resolve( response?.data?.registerProjectsPack )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        case BAN_EXPERT_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => {   
                        
                        resolve( response?.data?.banExpert )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        default:
            return promise
    }
} 

export const syncAction = (action: string, data: any): any => {
    switch(action) {
        case SET_FILTERS_ACTIONS: 
            const filters : IFestFilters = { ...DEFAULT_FILTERS, ...data.filters }
            useFestStore.getState().setFilter( filters )
            //   
            // asyncControllers( action, { ...data } ).then(                    
            //     response => { 
            //         console.log( response )
            //         // resolve( response.data[ query_name ] )
            //     },
            //     error => { 
            //         reject( error )
            //     }
            // )
            return filters
        default:
            return action
    }
    
}