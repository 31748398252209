import { ID } from "@/libs/interfaces/layouts"
import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import { Loading } from "@/libs/useful"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useUser } from "@/settings/zustand/user"
import { Button, Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { actions } from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { useFestival } from "../../hooks/festival"
import MessageInput from "./MessageInput"
import MessageSingle from "./MessageSingle"
import getMenuMessageCount from "../utils/getMenuMessageCount"

/* Protopia Ecosystem component */
/*
* Page of one author's messages to current user feed
*/
export interface IMessagesUserProps {
    parentMode?: boolean
}
const MessagesUser = (props: IMessagesUserProps): JSX.Element => {
    const params = useParams() 
    // console.log( params.userId )
    const currentUser = useUser()
    const landId: ID = useMainStore((state: IState) => state.landId) || "-1"  
    const isLoading = useFestival(!props.parentMode, landId)  
    const [user, setUser] = useState<IUser>()
    const [paging, setPaging] = useState<string>(` post_author:"${params.userId}" metas:[{key: "adressee", value:"${currentUser.id}"}] ` )
    const [isUserLoad, setIsUserLoad] = useState<boolean>(true)
    const [isLoadFeed, setIsLoadFeed] = useState<boolean>(true)
    const [seed, setSeed] = useState( Math.random().toString() )
    useEffect(() => {
        actions(GET_SINGLE_ACTIONS, {
            data_type: "User",
            land_id: landId,
            id: params.userId
        }).then((res:IUser) => {
            setUser(res)
            setIsUserLoad(false)  
        })
    }, [])
    if( isLoading || isUserLoad ) {
        return <div className="min-height-120 flex-centered">
            <Loading />
        </div>
    }
    const onSend = () => {
        setSeed( Math.random().toString() ) 
    }
    return <div className='short-container'>
        <div className="d-flex w-100 align-items-center mb-auto">
            <Link to={ `/${ getRoute( getFeedRoute( "PEMessage" ) ) }` } >
                <Button minimal>
                    <Icon icon="arrow-left" size={22} />
                </Button>
            </Link>
            <UserAvatar user={user} width={35} height={35} />
            <div className="title small ml-2" >
                {user?.display_name}
            </div>
        </div>
        <div className="pr-5">
            <MessageInput adressee={user!} onSend={onSend} />
        </div>
        <Feed 
            topPagiHide
            bottomPagiHide
            data_type={ "PEMessage" }  
            offset={0}
            land_id={landId}
            component={MessageSingle}
            class_name="container pl-4" 
            paging={ paging }
            onLoading={ (items: any[]) => {
                //console.log( items )
                setIsLoadFeed(false) 
                getMenuMessageCount(landId)
            }} 
            isDateRaze
            dateRazeClassName="small px-3 py-1 border border-secondary mt-4 mb-1"
            params= {{
                col: 1,
                pageCount: 10000,
                tick: seed
            } }
        />
    </div>
}
export default MessagesUser