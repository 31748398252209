import { useEffect, useState } from "react"
import { IFestival } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import { IAds } from "@/modules/pe-topos-module/data/interfaces"
import { ID } from "@/libs/interfaces/layouts"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import { Link } from "react-router-dom"
import AdCard from "@/modules/pe-topos-module/views/ads/AdCard"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
const LastAds = ( ): JSX.Element => {
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
        return !!festival.isAd
            ?
            <LastAdsForm / >
            :
            <></>
}
export default LastAds


const LastAdsForm = ( ): JSX.Element => {
    const festId : ID           = useFestDataStore( (state: IFestDataStor) => state.festId )
    const [posts, setPosts]     = useState<IAds[]>( [] as IAds[] )
    useEffect( () => {
        actions(
            GET_FEED_ACTION, 
            {
                count:4, 
                land_id: festId,
                data_type: "Ad"
            }
        )
            .then((resp : any) => { 
                // console.log( resp.feed )
                setPosts( resp.feed ) 
            })
    }, [ festId ] )
    
    return !!posts.length 
        ?
        <section className="">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 d-flex justify-content-center pt-5 pb-3">
                        <div className="role_descr">
                            {__("Last Ads")}
                        </div>
                    </div>
                    {
                        posts.map((post: IAds, i:number) => {
                            return <AdCard
                                key={ post.id }
                                item={ post } 
                                data_type="Ad"
                                i={i}
                                params={{
                                    col: 2
                                }}
                            />
                        })
                    }   
                    <div className='col-12 d-flex justify-content-center my-2'>
                        <Link 
                            to={ `/${getRoute(getFeedRoute( ("Ad")))}`}
                            className="btn btn-secondary"
                        >
                            {__("All ads")}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        :
        <></>
}