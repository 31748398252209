 import { __ , initDictionary, getAllDictionaries } from "./i18n" 
 import { 
    sprintf, 
    getYoutubeData, 
    extractVimeoVideoID, 
    getVimeoThumbnail,
    timeToReadableString } from "./sprintf" 
 import { isCapability } from "./user" 
 import isImageExt from './isImageExt' 
 import {withRouter} from './withRouter' 
import { initArea } from './getWidget'
import Widgets from "./Widgets"
import getWidgets from './getWidgets'
import importAll, {getAllViews, getAllWidgets, getModules } from './importAll'
import { data_type_link_url, data_type_feed_url, data_type_admin_url } from "./links" 
import { getInnerHeight } from './document'
import __cases from './__cases'
import isEmail from './isEmail'
import compareArrays from './compareArrays'
import { getContrastColor } from"./colors"
import { Json } from "../interfaces/layouts"
import { comparePeriods } from "./comparePeriods"
import { wordTrim } from "./wordTrim"
import { shuffle } from "./shuffle"
import clearHTML from "./clearHTML"
import translitterate from "./translitterate"
import { intToTime } from "./date" 
import imgToDataURL from "./imgToDataURL"  
import isArraysJoint from "./isArraysJoint"  
import arrayGroupBy from "./arrayGroupBy"  
export {  
    __, 
    initDictionary,
    sprintf,
    importAll,
    getAllViews, 
    getAllWidgets,
    getModules,
    isCapability,
    isImageExt, 
    initArea,
    Widgets, 
    withRouter,
    getWidgets,
    data_type_link_url, 
    data_type_feed_url ,
    data_type_admin_url,
    __cases,
    isEmail,
    getInnerHeight,
    compareArrays,
    getContrastColor,
    comparePeriods,
    wordTrim,
    shuffle,
    getYoutubeData,
    extractVimeoVideoID,
    getVimeoThumbnail,
    timeToReadableString,
    clearHTML,
    translitterate,
    getAllDictionaries,
    intToTime,
    imgToDataURL,
    isArraysJoint,
    arrayGroupBy
}
export const jsonToData = (json: Json ) : any => {
    try {
        const data: any = JSON.parse(json) 
        return data
    }
    catch(e: any) {
        console.log( json )
        console.log( e )
        return ""
    }
}

export const setMaxHeightByClass = ( className: string ) => {
    const max: number  = Math.max(...[...Array.from( document.getElementsByClassName( className ) ) ]
        .map( (div: any) => div.clientHeight as number ) ) 
    Array.from( document.getElementsByClassName( className ) )
        .forEach((div: any) => {
            div.style.height = `${max}px`
        })
}

export const getLayoutApp = (): HTMLElement => {
    const div: HTMLElement = document.getElementById("pe-layout-main")!
    return div
}