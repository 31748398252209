import { Widgets } from "@/libs/utilities"
import LayoutHeaderHeader from "../LayoutHeaderHeader"
import LayoutHeaderMenu from "../LayoutHeaderMenu"
import LayoutUser from "./LayoutUser"
import { useEffect, useState } from "react"
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface ILayoutHeaderScreenProps {
   
}
const LayoutHeaderScreen = (props: ILayoutHeaderScreenProps): JSX.Element => {
    const [isFixed, ] = useState(false)
    const [isHumburger, ] = useState(false)
    const [, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
    const fixedClass = isFixed 
        ? 
        "layout-header fixed animation-opened animated2" 
        : 
        "layout-header"
    useEffect(() => {
        const onResizeHandler = () => {
            setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
        }
        window.addEventListener("resize", onResizeHandler)
        return () => window.removeEventListener("resize", onResizeHandler)
    }, [])
    const onHumburger = () => {

    }
    return <div className={fixedClass} id="layout-header">
        <div className="layout-header-left">
            <LayoutHeaderHeader />
        </div>
        <Widgets areaName="layout-header-center" data={{...props}} /> 
        <div className={`d-flex-menu ${ isHumburger ? "open" : ""}`}>
            <LayoutHeaderMenu onHumburger={ onHumburger}  />
        </div>
        <div
            className={`layout-menu-right ${ isHumburger ? "open" : ""}`}
            id="layout-menu-right"
        >
            <LayoutUser isOpen={false} />
        </div>
    </div>
}
export default LayoutHeaderScreen