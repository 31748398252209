import { POST_STATUS } from "@/libs/interfaces/layouts"
import { __, __cases } from "@/libs/utilities"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { Button, Collapse, Icon, Intent, Tag } from "@blueprintjs/core"
import { MutableRefObject, useRef } from "react"
import { ICritery, IMilestone } from "../../data/interfaces"
import getCriteryMaxRating from "../../data/utils/getCriteryMaxRating"
import CriteryAttachments from "./CriteryAttachments" 
import Shortcode from "@/libs/useful/shortcode"

/* Protopia Ecosystem component */
export interface IMilestoneProps {
   isOpen:boolean
   item: IMilestone
   setIsOpen: ( item: IMilestone, isOpen: boolean, i: number) => void
   i:number
}
const Milestone = (props: IMilestoneProps): JSX.Element => {
    const ref: MutableRefObject<null> = useRef(null)
    const criteries: ICritery[] = useDataStore((state: any) => state.PEFestCritery || [])
    
    const onOpen = () => { 
        props.setIsOpen( props.item, !props.isOpen, props.i )
    }

    const getDate = (): string => {
        let startDate: string = "", 
            finishDate: string = "",
            yearS: string = "", 
            yearF: string = ""
        const options: Intl.DateTimeFormatOptions = { 
            // year: 'numeric',
            month: 'long',
            day: 'numeric',
        } 
        const years: Intl.DateTimeFormatOptions = { 
            year: 'numeric',
        } 

        if(props.item.start_date) {
            const start     = Number(props.item.start_date!) * 1000
            const s: Date   = new Date( start )
            startDate       = s.toLocaleDateString( "ru-RU", options )
            yearS           = s.toLocaleDateString( "ru-RU", years )
        }     
        if(props.item.finish_date) {
            const finish    = Number(props.item.finish_date!) * 1000
            const f: Date   = new Date( finish )
            finishDate      = f.toLocaleDateString( "ru-RU", options )
            yearF            = f.toLocaleDateString( "ru-RU", years )
        } 
        if( yearS === yearF) {
            return startDate + " - " + finishDate + " " + yearF
        }
        else {
            return startDate + " " + yearS + " - " + finishDate + " " + yearF
        }
    } 
    
    return <>
        <Button 
            minimal={ !props.isOpen } 
            fill 
            disabled={ props.isOpen && false } 
            alignText="left" 
            onClick={ onOpen }
            className={` ${props.i > 0 ? "short-delimiter-" : ""} ${ props.item.post_status === POST_STATUS.PUBLISH ? "" : " text-secondary" }`} 
            ref={ref}
        >
            <div className={`short-container `}> 
                    <div className="row d-flex flex-wrap justify-content-between aligh-items-center pr-2">
                        <div className={`col-md-8  col-12 transition-duration-300 d-flex  justify-content-start align-items-center ${ props.isOpen ? "h-70" : "h-25" }`}>
                            <div> { props.item.order }. </div>
                            <Icon icon={props.item.post_status === POST_STATUS.PUBLISH ? "blank" : "eye-off" } className="px-3" />
                            <div>
                                <div className=" lead-md text-md-left text-center w-100"> 
                                    { props.item.title } 
                                </div>                             
                            </div> 
                        </div>
                        <div className="col-md-3 col-11 d-flex align-items-center justify-content-md-start justify-content-center py-1">
                        {
                            (props.item.start_date || props.item.finish_date) 
                                &&
                                <Tag round className="w-100" intent={ props.isOpen ? Intent.DANGER : Intent.NONE }>
                                    { getDate() }
                                </Tag> 
                        } 
                        </div> 
                        <div className="col-md-1 col-1  d-flex justify-content-end align-items-center pr-0 ">
                            <Icon 
                                icon="plus" 
                                size={20} 
                                style={{ 
                                    width:40, 
                                    height: 40, 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    alignItems: "center",
                                    transition: "all 300ms linear",
                                    transform: props.isOpen ? "rotate(-45deg)" : "rotate(0)",
                                    opacity:.5
                                }}                                 
                            />
                        </div>
                    </div> 
            </div> 
        </Button>
        <Collapse className='w-100 milestone-collapse' isOpen={props.isOpen}>
            <div className="p-0 light-colored "> 
                <div className=" short-container">
                    <Shortcode content={ props.item.post_content} /> 
                </div>
                <div className=" short-container">
                    <CriteryAttachments item={props.item}/>   
                </div>
                <div>
                    {
                        criteries
                            .filter( cr => cr.milestone?.id === props.item.id )
                            .sort((a:ICritery, b:ICritery) => {
                                if(a.order > b.order) {
                                    return 1
                                }
                                else if(b.order > a.order) {
                                    return -1
                                }
                                else {
                                    return 0
                                }
                            })
                            .map((cr, i: number) => {
                                const max = getCriteryMaxRating(cr) 
                                return <div className={`py-5 container-fluid strob01 ${ i !== 0 ? "border-top border-secondary " : "" }`} key={cr.id} >
                                    <div className={`short-container px-md-0 px-3`}>
                                        <h4>
                                            {cr.order}. { cr.title }
                                        </h4>
                                        <Shortcode content={ cr.post_content } /> 
                                        <CriteryAttachments item={cr} isMayAdd />
                                        <div className="mt-3 ">
                                            <Tag round>
                                                { `${__("Up to ")}  ${max} ${__cases( max, "points" )}` }
                                            </Tag>
                                        </div>
                                    </div>
                                </div>
                            })
                    }
                </div>
            </div>
        </Collapse>
    </> 
}
export default Milestone