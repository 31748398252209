import { ID, IPEMessage } from "@/libs/interfaces/layouts"
import { data_type_single_route } from "@/libs/utilities/links"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import Moment from "react-moment"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import { Link } from "react-router-dom"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface IMessageProps {
   item: IPEMessage
}
const Message = (props: IMessageProps): JSX.Element => { 
    const [is_viewed, setIs_viewed] = useState<boolean>(props.item.is_viewed)
    useEffect(() => {
        setIs_viewed(props.item.is_viewed)
    }, [props.item.is_viewed])
    const link = () => {
        return `${ data_type_single_route( props.item.resource_type || "Post" )?.realRoute }/${props.item.resource_id}`
    } 
    const _date = parseInt(props.item.post_date || "0") * 1000
    let today = new Date()
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - .5)

    return <div className="row border-bottom-- border-secondary-- strob01 py-3 w-100">
        <Link to={`user/${props.item.post_author?.id}`} className="col-md-3 pl-md-0 pl-3 d-flex align-items-center text-force-contrast">
            <Icon 
                icon={ is_viewed ? "star-empty" : "star"}
                className={`pr-1 ${ !is_viewed ? "text-danger" : "opacity_5" } ml-3`}
                title={__(!!is_viewed ? "viewed": "not viewed")}  
            />
            <UserAvatar user={props.item.post_author} width={35} height={35} />
            <div className="title small ml-2" >
                {props.item.post_author?.display_name}
            </div>
        </Link>
        <div className={`col-md-7 d-flex flex-column hover small ${is_viewed ? "title": "opacity_75--"} `}>  
            <div className={`text-overflow-ellipse`}>
                {props.item.title}
            </div>
            <a href={link()} target="_blank" rel="noreferrer" className="small mt-1">
                {props.item.resource_title}
            </a>
        </div> 
        <div className="col-md-1 pl-md-0 pl-5 d-flex align-items-center">
            <Moment  
                locale="ru" 
                format={ _date > yesterday.getTime() ? "HH:mm" : "D.MM.YY" } 
                className={`small ${ _date > yesterday.getTime() ? "title" : " text-secondary " }`}  
            >
                { _date } 
            </Moment> 
        </div>
    </div>
}
export default Message