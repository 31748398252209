import { IDiary } from "@/modules/pe-fest-module/data/interfaces"

interface IProps {
    item : IDiary
}
const MessageURL = (props: IProps) : JSX.Element => {
  return props.item.url 
    ?
    <div className="py-2 my-2 border-top border-bottom border-secondary">
        <a  href={ props.item.url.url } rel="noreferrer" className="d-flex">
            <div className="thumbnail mr-3 my-0" style={{backgroundImage: `url(${props.item.url.image})`}}>
                <img src="/assets/img/empty.png"alt="" style={{height: 160}} />
            </div>
            <div>
               <div className="title ">
                    { props.item.url.site_name }
                </div>
                <div className="title ">
                    { props.item.url.title }
                </div>
                <div>
                    { props.item.url.description }
                </div> 
            </div>
        </a>
    </div>
    :
    <></>
} 

export default MessageURL