import { ID } from "@/libs/interfaces/layouts"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useEffect, useState } from "react"
import getMenuMessageCount from "../views/utils/getMenuMessageCount"
import MessagesScreen from "./messages/MessagesScreen"

export const MENU_MESSAGE_SEED = " for notify "
/* Protopia Ecosystem component */
export interface IFestivalMessagesWidgetProps {
   
}
const MessagesWidget = (props: IFestivalMessagesWidgetProps): JSX.Element => {
    const landId: ID = useMainStore((state:IState) => state.landId)
    const user: IUser = useMainStore((state: IState) => state.user)
    const {width} = useScreenSize()
    const [isLoad, setIsLoad] = useState<boolean>(true) 
    useEffect(() => {
        setIsLoad(true)
        if( !user?.id || !landId ) return
        getMenuMessageCount(landId).then(() => setIsLoad(false))
    }, [ landId, user?.roles ])

    if(isLoad)   return <></>
    return <MessagesScreen />
    // return user?.id
    //     ?
    //     width > WINDOW_WIDTH.TABLET
    //         ?
    //         <NotificationsScreen messageRoute={messageRoute} count={count} />  
    //         :
    //         <NotificationsMobile messageRoute={messageRoute} count={count} />
    //     :
    //     <></>
}
export default MessagesWidget