import { IUser } from "@/settings/zustand"
import Feed from "../core/Feed"
import Notification from "./Notification"
import { useUser } from "@/settings/zustand/user"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IPENotificationFeedFormProps {
    offset: number
}
const PENotificationFeedForm = (props: IPENotificationFeedFormProps): JSX.Element => {
    const user:IUser = useUser()
    const [paging, setPaging] = useState<string>("")
    useEffect(() => {
        setPaging(
            user.roles.includes("SuperAdmin")
                ?
                ` order: "DESC" order_by_meta:"id"  metas:[{key:"adressee", value: "${user.id}"}] ` //` order: "DESC" order_by_meta:"id"  `
                :
                ` order: "DESC" order_by_meta:"id"  metas:[{key:"adressee", value: "${user.id}"}] `
        )
    }, [user])
    return <div className={`p-0 d-flex flex-column w-100 container `}>  
            <Feed
                component={ Notification }
                data_type={ "PENotification" }
                paging={paging}
                is_hide_pagi={false}
                offset={ props.offset || 0 } 
                class_name={` row`}
                params={{ 
                    col: 1
                }} 
                containerClassName={ "small-container" }
            /> 
        </div>
}
export default PENotificationFeedForm