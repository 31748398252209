import { DIALOG_SIZE, HTML, IPostInput } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { AppToaster } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { Intent } from "@blueprintjs/core"
import { useState } from "react"


const MessagetToAdmin = ({onClick}: any): JSX.Element => {
    const landId = useMainStore((state:IState) => state.landId)
    const [content, setContent] = useState<HTML>("")
    const [thumbnail, setThumbnail] = useState<string>()
    const [thumbnailName, setThumbnailName] = useState<string>()
    const onConfirm = () => {
        const item: IPostInput ={
            title: content,
            post_content:"",
            thumbnail,
            thumbnail_name: thumbnailName
        }
        console.log(item, landId)
        actions(UPDATE_SINGLE_TYPE,
            {
                data_type: "PEMessage",
                landId,
                item
            }
        ).then(() => {
            AppToaster.show({
                message: __("Message sending successfully. Wait reaction")
            })
        })
        setContent("")
        setThumbnail("")
        setThumbnailName("")
    }
    return <ConfirmButton
        buttonLabel={
            <span className="text-uppercase descr text-light micro " onClick={onClick}>
            {__("Message to admins")}
        </span>
        }
        buttonClassName="py-2"
        buttonIntent={Intent.SUCCESS}
        buttonFill
        buttonMinimal
        dialogClasssName={DIALOG_SIZE.NORMAL}
        dialogTitle={__("Send message to admin")}
        onConfirm={onConfirm}
        yesLabel={__("Send message")}
        noLabel={__("Cancel")}
    >
        <div className=" row pl-4">
            <FieldInput
                editable
                type={SCALAR_TYPES.HTML}
                title={__("insert text")}
                value={content}
                onChange={ setContent } 
            />
            <FieldInput
                editable
                type={SCALAR_TYPES.MEDIA}
                title={__("insert image")}
                value={thumbnail}
                hideLib
                isFileNameHide
                isURLHide
                onChange={ (value: string, field: any, file: any) => {
                    setThumbnail(value)
                    setThumbnailName(file.name)
                } } 
            />
        </div>
    </ConfirmButton> 
}

export default MessagetToAdmin