import { __ } from '@/libs/utilities'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { Button, Icon } from '@blueprintjs/core'
import { NavLink } from 'react-router-dom'
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import { isStatusWorked } from '../../data/utils/status'

interface IAddProjectCardProps {
    route: string
}
const AddProjectCard = (props: IAddProjectCardProps) :JSX.Element | null => {
    const user: IUser = useMainStore((state: IState) => state.user) 
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    
    return festival.isRegisterProject && isStatusWorked()
        ?
        user.id
            ?
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <NavLink
                    className="w-100 h-100 bg-crystal border border-secondary flex-centered hover" 
                    style={{ minHeight: 260 }}
                    to={props.route}
                >
                    <AddCardContent />          
                </NavLink>
            </div>
            :
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <div
                    className="w-100 h-100 bg-crystal border border-secondary flex-centered hover" 
                    style={{ minHeight: 260 }}
                    onClick={ 
                        () => useMainStore.setState({ isOpenLogin: true })
                    }
                >
                    <AddCardContent />          
                </div>
            </div>
        :
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <div
                className="w-100 h-100 bg-crystal border border-secondary flex-centered hover" 
                style={{ minHeight: 260 }} 
            >
                <div>{ __("Festival finished") }</div>         
            </div>
        </div>
}

AddProjectCard.propTypes = {}

export default AddProjectCard

const AddCardContent = () => {
    return <div className='d-flex flex-column align-items-center' >
        <Button minimal className='rounded-circle ' style={{width: 70, height: 70}}>
            <Icon icon="plus" size={20}/>
        </Button> 
        <small className='mt-2'>
            {__("Insert new Project")} 
        </small>
    </div>
}