import { __, getLayoutApp } from "@/libs/utilities"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { useEffect, useState } from "react"
import { IFestival, IMilestone } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import Milestone from "./Milestone"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"

const BUTTON_HEIGHT: number = 38
const BUTTON_HEIGHT_MOBILE: number = 91

/* Protopia Ecosystem component */
export interface IMilestonesProps {
   
}
const Milestones = (props: IMilestonesProps): JSX.Element => {
    const {width} = useScreenSize()
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    const milestones: IMilestone[] = useDataStore((state: any) => state.PEFestMilestone || []) 
    const [current, setCurrent] = useState<IMilestone|null>( 
        milestones.filter(m => festival?.currentMilestoneId === m.id)[0] ||  milestones[0] 
    )

    useEffect(() => {
        setCurrent( milestones.filter(m => festival?.currentMilestoneId === m.id)[0] ||  milestones[0] )
    }, [festival, milestones])

    const setIsOpen = ( item:IMilestone, isOpen:boolean, i: number ) => {
        setCurrent( isOpen ? item : null )
        const scrollDiv:HTMLElement = getLayoutApp()
        const idabout = document.getElementById("aboutcont")
        const idmilestones = document.getElementById("milestones")
        if ( !!scrollDiv && !!idabout && !!idmilestones ) {
            scrollDiv.scrollTo({
                top: idmilestones!.getBoundingClientRect().top
                    - idabout!.getBoundingClientRect().top 
                    + (width > WINDOW_WIDTH.MOBILE ? BUTTON_HEIGHT : BUTTON_HEIGHT_MOBILE) * i,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    return festival.isMilestones 
        ?
        <section className='  light-colored2 ' id="milestones">
            <div className="short-container pb-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 d-flex justify-content-center pt-3 pb-3">
                        <div className="role_descr">
                            {__("Milestones")}
                        </div>
                    </div>
                </div>
            </div>
            <div className=" container-fluid "> 
                {
                    milestones.map((m, i) => {
                        return <Milestone item={m} key={m.id} isOpen={m.id === current?.id} setIsOpen={setIsOpen} i={i} />
                    })
                }
            </div>
        </section>
        : 
        <></>
}
export default Milestones