import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IHoneycombs } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IHonecombsProps {
   
}
const Honecombs = (props: IHonecombsProps): JSX.Element | false => {
    const honeycombs: IHoneycombs[] = useDataStore((state: any) => state.PEFestHoneycombsl|| []) 
    return !!honeycombs.length && <section id="honeycombs">
        <div className='pe-honecombs-container'>
       
        </div>        
    </section>
}
export default Honecombs