import React from "react" 
import { Button } from "@blueprintjs/core"
import BasicState from "."
import { __ } from "@/libs/utilities"
import { initArea } from "@/libs/utilities" 
import {LayoutIcon} from '@/libs/useful'
import { WINDOW_WIDTH } from "../interfaces/layouts"

class BasicExtendView extends BasicState { 
	basic_state_data() { 
		return {			
			isLeftClosed: window.innerWidth < WINDOW_WIDTH.TABLET,
			hide_title: true
		}
	} 
	head = () : JSX.Element => {
		return <></>
	}
	renderHead = () => {
		const c = this.head()
		if (c) return c
		return null
	}
	center = ():JSX.Element => {
		return <></>
	}

	renderCenter = () => {
		const c = this.center()
		if (c) return c
		return null
	}

	left = ():JSX.Element => {
		return <></>
	}

	renderLeft = () => {

		const l = this.left()
		if (l) return l
		return null
	}

	
	addRender = () => {
		const {  isLeftClosed, isHeadClosed } = this.state
		const leftClass = isLeftClosed
			? "tutor-left-aside-2 menu-aside h-100 closed"
			: "tutor-left-aside-2 menu-aside h-100"
		const mainClass = isLeftClosed
			? "tutor-main-2 pr-0 opened"
			: "tutor-main-2 pr-0"
		
		const help = this.props.help_url
			?
			<span>
				<Button 
					icon="help" 
					minimal 
					onClick={ () => window.open( this.props.help_url, '_blank' ) } 
				/>
			</span> 
			:
			null
		
		const alt = this.alternateRender()
		if (alt) return alt
		this.beforeRender()

		return (
			<div className="layout-state p-0">
				<div className="tutor-row menu-row">
					<div className={leftClass}>
						<div className="layout-state-head menu-header-22">
							<LayoutIcon
								isSVG
								src={this.state.route.icon}
								className="layout-state-logo "
							/>
							<div className="layout-state-title">
								{help}
								{__( this.state.route.title )} 
							</div>
						</div>
						<div className="small mx-3 mb-3 " dangerouslySetInnerHTML={{ __html: (this.props.description ? this.props.description : "") }} />
						<div className="small h-100 overflow-y-auto-thin mx-0 mb-3 text-secondary">
							{ this.renderLeft() }
						</div>						
						{
							initArea(
								"admin-menu-left-aside",
								{ ...this.props }
							)
						}
					</div>
					<div className={mainClass}>
						<div
							className="clapan-left"
							onClick={() => this.setState({ isLeftClosed: !isLeftClosed })}
						>
							<div className={`fas fa-caret-${!isLeftClosed ? "left" : "right"}`} />
						</div>
						{
							isHeadClosed 
								? 
								<></>
								:
								<div className="menu-header-22 flex-centered py-2 pl-5 pr-2">
									{this.renderHead()}
								</div>
						} 
						<div className=" p-4 h-100 overflow-y-auto-thin"> 
							{ this.renderCenter() }	
						</div>
					</div>
					<div className="tutor-right-aside-2">
					{
						initArea(
							"admin-menu-right-aside",
							{ ...this.props },
						)
					}
					</div>
				</div>
			</div>
		)
	}
}

export default  BasicExtendView