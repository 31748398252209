import { externalSystems } from "@/libs/layouts"
import { IExternal, externals } from "./externals"
import { IUser } from "@/settings/zustand/index"

/* Protopia Ecosystem component */
export interface IExternalLoginsProps {
    onCallBack : ( user: IUser, external: string, label:string ) => void
}
const ExternalLogins = (props: IExternalLoginsProps): JSX.Element => {
    const onCallBack = ( user: IUser, external: string, label:string ) => {  
        // console.log( user, external, label )
        props.onCallBack( user, external, label )
    }
    return <div className='pe-index-container d-flex'>
    {
        Array.isArray(externalSystems()) 
            ? 
            externalSystems() 
                .map((label: string) => {
                    const external: IExternal = externals()[label]
                    const LoginComponent: any = external.componentLogin
                    return <LoginComponent
                        key={label}
                        callback={(user: IUser, external: string) => onCallBack(user, external, label)}
                    />
                })
            :
            null
    }   
    </div>
}
export default ExternalLogins