
interface IActiveProps {
    isActive: boolean
}
export default function setActive ({ isActive } : IActiveProps) {
    return isActive 
        ? 
        " fest-project-route-btn text-force-contrast active-link " 
        :
        " fest-project-route-btn text-force-contrast "
} 