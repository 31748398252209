import { ID } from "@/libs/interfaces/layouts"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { ITrack } from "../../data/interfaces"
import { useEffect, useState } from "react"
import { IFestStore, useFestStore } from "../../data/store"
import Moment from "react-moment"
import { Button } from "@blueprintjs/core"
import { Link } from "react-router-dom"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import Shortcode from "@/libs/useful/shortcode"

/* Protopia Ecosystem component */
export interface ILastAdProps { 

}
const LastAd = (props: ILastAdProps): JSX.Element => { 
    const filters: any = useFestStore((state: IFestStore) => state.filters)
    const getCurrentTrack = (): ITrack | null => {
        let cur: ITrack | null = null
        if( filters.tracks[0] === -1 ) {
            
        }
        else {
            const track: ITrack = useDataStore.getState().PEFestTrack?.filter((track: ITrack) => track.id === filters.tracks[0]?.toString() )[0]
            cur = track ? track : null
        }
        return cur
    }
    const [currentTrack, setCurrentTrack] = useState<ITrack | null>( getCurrentTrack() )

    useEffect(() => {
        setCurrentTrack( getCurrentTrack() )
    }, [ filters ])

    // console.log( currentTrack?.last_ad )
    return currentTrack?.last_ad?.post_content 
        ?
        <div className="d-flex max-width-650 w-100 ">
            <div className='d-flex flex-grow-100 align-items-center text-overflow descr text-force-contrast opacity_75 px-3 py-1'> 
                <span> 
                    {
                        !!currentTrack?.last_ad?.date &&
                            <Moment  locale="ru" format="D MMMM" className={` `}>
                                { parseInt(currentTrack?.last_ad?.date?.toString()) * 1000 }    
                            </Moment>
                    }
                </span>:
                <span className="ml-2 text-overflow lh-1">
                    <Shortcode content={ (currentTrack?.last_ad?.post_content || "").replaceAll("</p><p>", " ") } />
                </span>
                <Link to={ `/${getRoute( getFeedRoute("Ad") )}` } className="hover">
                    <Button icon="arrow-right" minimal />
                </Link>
            </div>
        </div>
        :
        <></> 
}
export default LastAd