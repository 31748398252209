import { ID } from '@/libs/interfaces/layouts'
import addCSSClass from '@/libs/layout-app/utils/addCSSClass'
import removeCSSClass from '@/libs/layout-app/utils/removeCSSClass'
import { template } from '@/libs/layouts'
import { SCALAR_TYPES } from '@/libs/scalars'
import { __ } from '@/libs/utilities'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { IUser, SORT_ORDER_ASC, SORT_ORDER_DESC } from '@/settings/zustand'
import { Fragment, useEffect, useRef, useState } from 'react'
import RussionRegionList from '../../data/RussianRegionList'
import { FILTER_NAME, IFestival, IMilestone, IPEFestSchool, IProject, IRating, ITrack, SPECIAL_SORT_TYPE } from '../../data/interfaces'
import { IFestDataStor, IFestStatistics, specialsParamsProps, useFestDataStore, useFestStatistic } from '../../data/store'
import FormFieldRow from './FormFieldRow'
import ProjectFieldRow from './ProjectFieldRow'
import SpecialPanel from './SpecialPanel'
import SpecialTitleRow from './SpecialTitleRow'

interface IProps {
    members: IProject[]
    users: IUser[]
    ratings: IRating[]
    goto: (url:string) => void
}

const SpecialStatistics = (props: IProps) :JSX.Element => {
 
    const festival:IFestival = useFestDataStore((state:IFestDataStor) => state.festival ) 
    const tracks: ITrack[] = useDataStore((state:any) => state.PEFestTrack || [])
    const schools: IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool || [])
    const milestones: IMilestone[] = useDataStore((state:any) => state.PEFestMilestone || []) 

    const specialParams: specialsParamsProps = useFestStatistic((state: IFestStatistics ) => state.specialParams )
    const setSpecialsParams = useFestStatistic((state:IFestStatistics) => state.setSpecialsParams)
    const selected : string[] = useFestStatistic(( state:IFestStatistics ) => state.selected )
    const setSelected = useFestStatistic(( state:IFestStatistics ) => state.setSelected ) 
    const [ schoolFilter,   setSchoolFilter ] = useState(() => { 
        return !!specialParams.filters?.schools?.length 
            ?
            specialParams.filters?.schools
            :
            schools?.map(school => school.id) 
        || [] 
    })
    const [ trackFilter,   setTrackFilter ] = useState( () => {
        return !!specialParams.filters?.tracks?.length 
            ?
            specialParams.filters?.tracks
            :
            tracks?.map( track => track.id ) 
        || [] 
    })
    
    const [ regionFilter, setRegionFilter ] = useState<string[]>( RussionRegionList() )
    const [ regionId, ] = useState( () => {
        const rsID = festival.extendedFields?.findIndex(ff => ff.type === SCALAR_TYPES.RUSSIAN_REGIONS) 
        return typeof rsID === "number" ? rsID : -1
    } ) 
    const [ sortField, setSortField ]               = useState( SPECIAL_SORT_TYPE.RATING_SORT )
    const [ sortOrder, setSortOrder ]               = useState( SORT_ORDER_ASC )
    const [ ratingMaxFilter, setRatingMaxFilter ]   = useState( 0 )
    const [ fields, setFields ] = useState<any[]>( [] ) 
  
    useEffect(() => {
        const fieldsDefault: string[] = [ 
            "№", "#", "Project", "Track", "School", "Tutor", "Project_author", "Team",
            ...(festival.extendedFields || []).map((field: any) => field.name ),
            ...(milestones.map(m => m.title)),
            "List of ratings",
            "Exhortations",
            "Rating" 
        ]
        setFields( fieldsDefault )  
        setSelected( fieldsDefault ) 
        
        const mainCont = document.querySelector(".layout-app-state.overflow-y-auto")
        mainCont?.classList.remove("overflow-y-auto")
        addCSSClass(
            "statistics", 
            `.statistic-table-container {
                height: calc(100vh - ${ 44 }px);
                overflow-x: auto;
                overflow-x: auto;
            }`  
        ) 
        return () => {
            removeCSSClass("statistics")
            mainCont?.classList.add("overflow-y-auto")
        }
        /**/
    }, [])

    const tableRef = useRef(null);

    const onSomeFilter = (value: (ID | string )[] | number , field: FILTER_NAME ) => {
        switch(field) {
            case FILTER_NAME.REGION:
                onRegionFilter(value as string[])
                return
            case FILTER_NAME.RATING_MAX:
                onRatingMaxFilter(value as number )
                return 
            case FILTER_NAME.SCHOOL:
                onSchoolFilter(value as ID[])
                break
            case FILTER_NAME.TRACK:
                onTrackFilter(value as ID[])
                break

        }
        setSpecialsParams({
            ...specialParams, 
            filters: {
                ...specialParams.filters,
                [ field + "s" ]: value
            }
        })
    }

    const onTrackFilter = (value: ID[]) => { 
        setTrackFilter( value || [] )
        setSpecialsParams({
            ...specialParams, 
            filters: {
                ...specialParams.filters,
                tracks: value
            }
        })
    } 

    const onSchoolFilter = (value: ID[]) => { 
        // console.log(value)
        setSchoolFilter( value || [] )
        setSpecialsParams({
            ...specialParams, 
            filters: {
                ...specialParams.filters,
                schools: value
            }
        })
    } 
    const onRegionFilter = (value: string[]) => {  
        setRegionFilter( value || [] )
    }
    const onRatingMaxFilter = (value: number) => {  
        setRatingMaxFilter( Number(value) )
    } 
    const onSortOrder = () => {
        setSortOrder( () => sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC )
    }
    const onSelected = ( _selected: string[] ) => {
        setSelected( _selected ) 
    }

    const sortMembers = (a: IProject, b: IProject ) =>
    {
        switch(sortField)
        {
            case SPECIAL_SORT_TYPE.ALPHABET_SORT: 
                const nameA = a.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nameB = b.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nameA < nameB) {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nameA > nameB) {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1
                }
                return 0
            case SPECIAL_SORT_TYPE.NOMINATION_SORT: 
                const nomA = a.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nomB = b.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nomA > nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nomA < nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1 
                }
                return 0
            case SPECIAL_SORT_TYPE.ORDER_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( a.order ) - Number( b.order )
                    :
                    Number( b.order ) - Number( a.order )
            case SPECIAL_SORT_TYPE.RATING_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( b.actualRating )- Number( a.actualRating )
                    :
                    Number( a.actualRating ) - Number( b.actualRating )
            case SPECIAL_SORT_TYPE.SCHOOLS_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( b.fmru_school[0]?.id )- Number( a.fmru_school[0]?.id )
                    :
                    Number( a.fmru_school[0]?.id ) - Number( b.fmru_school[0]?.id )
            case SPECIAL_SORT_TYPE.TRACK_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( b.track?.id )- Number( a.track?.id )
                    :
                    Number( a.track?.id ) - Number( b.track?.id )
        }
        return 1
    }
    let memberData = props.members
        ?    
        props.members
            .filter( 
                member => {
                    if( schools.length === 0 ) return true
                    if( !member.fmru_school.length || !member.fmru_school[0]?.id) return true
                    return schoolFilter.filter( 
                        (filter:any) => {                            
                            return filter === member.fmru_school[0]?.id
                        }
                    ).length > 0
                })
            .filter( 
                member => {
                    if( tracks.length === 0 ) return true
                    if(!member.track || !member.track.id) return true
                    return trackFilter.filter( 
                        (filter:any) => {                            
                            return filter === member.track?.id
                        }
                    ).length > 0
                })
            .filter(
                member => regionFilter.filter(
                    filter => {
                        if(regionId < 0) return true
                        if( !Array.isArray( member.form_fields  ) || !member.form_fields[ regionId ] )
                        {
                            return regionFilter.length === RussionRegionList().length
                        }
                        if( typeof member.form_fields[ regionId ][ 0 ] === "undefined" )
                        {
                            return true
                        }
                        if(!member.form_fields[ regionId ][ 0 ]) return true
                        return filter === member.form_fields[ regionId ][ 0 ]
                    }
                ).length > 0
            )
            .filter( member => Number( member.actualRating ) >= ratingMaxFilter )
        :
        []
    memberData.sort( sortMembers )    
    let prev:any = { fmru_school: [] }     
    const memberTRs: JSX.Element[] | JSX.Element = memberData.length > 0 
        ?
        memberData.map(( member, index ) => 
        { 
            const row = <Fragment key={member.id}>
                <SpecialTitleRow sortField={sortField} prev={prev} current={ member } /> 
                <tr className='bg-light-strobe  border-top border-secondary border-2 '>
                    {
                        selected?.map((s: string) => {
                            return <td key={ s }>
                                <div className='py-2 d-flex align-items-start flex-column '> 
                                    { 
                                        !!festival.extendedFields?.filter( field => field.name === s ).length
                                            ?
                                            festival.extendedFields.map( (af, i) => 
                                            {
                                                return af.name === s && 
                                                    <FormFieldRow singleFormField={ member.form_fields[ i ] } i={ i } /> 
                                            })
                                            :                                            
                                            <ProjectFieldRow
                                                name={ s }
                                                index={ index }
                                                project={ member }  
                                                ratings={ props.ratings }  
                                            />
                                    }  
                                </div>
                            </td>
                        })
                    } 
                </tr>
            </Fragment>
            prev = member
            return row
        })
        :
        <div className='alert alert-danger lead text-center mt-5 p-5'>
            { __("No elements exists") }
        </div>

    return <div className='w-100 '>
        <SpecialPanel
            memberTRs={memberTRs} 
            fields={fields} 
            onSomeFilter={onSomeFilter} 
            onSelected={onSelected} 
            onSortOrder={onSortOrder}
            setSortField={setSortField}
            schoolFilter={schoolFilter} 
            trackFilter={trackFilter} 
            regionFilter={regionFilter} 
            ratingMaxFilter={ratingMaxFilter} 
            selected={selected} 
            tableRef={tableRef} 
            regionId={regionId} 
            sortField={sortField} 
            sortOrder={sortOrder} 
            goto={props.goto}
        /> 
        <div className='statistic-table-container'>
            <table 
                className='table table-striped table-hover1 pe-fest-table-statistics w-100 mt-0' 
                id="members-to-xls"            
                ref={ tableRef }
            > 
                <thead className="thead-dark-33 bg-secondary text-white">
                    <tr>
                        {
                            selected?.map(s => {
                                return <th scope={ s } >
                                    <span className='small'>{__( s )} </span>
                                </th>
                            })
                        } 
                    </tr>                
                </thead > 
                <tbody>
                    {memberTRs}
                </tbody> 
            </table>
        </div>
    </div> 
} 

export default SpecialStatistics