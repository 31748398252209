import { DIALOG_SIZE, ID, IPEMessage, POST_STATUS } from "@/libs/interfaces/layouts"
import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import { ClipboardCopyBtn, ScalableDialog } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { data_type_single_route } from "@/libs/utilities/links"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IIfaceProps, IState, IUser, useIfaceStore, useMainStore } from "@/settings/zustand"
import { isRole, useUser } from "@/settings/zustand/user"
import { Button, ButtonGroup, Icon, Intent, Popover, Position, Tag } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import Moment from "react-moment"
import { Link } from "react-router-dom"
import { getModeratorRoles } from "../../data/utils/getModeratorRoles"
import MessageParent from "./MessageParent"

/* Protopia Ecosystem component */
/*
 *  Single message form for individual feed between two users
 */
export interface IMessageSingleProps {
    item: IPEMessage
    i:number
    order?: number
    params?: any
    cardClass?: string
    className?: string
    footer?: JSX.Element | null
    onUpdate?: () => void
}
const MessageSingle = (props: IMessageSingleProps): JSX.Element => { 
    const landId: ID = useMainStore((state: IState) => state.landId) || "-1"
    const newMessageText = useIfaceStore((state: IIfaceProps) => state.newMessageText)
    const setNewMessageText = useIfaceStore((state: IIfaceProps) => state.setNewMessageText)
    const setNewMessageParent = useIfaceStore((state: IIfaceProps) => state.setNewMessageParent)
    const user: IUser = useUser() 
    const [is_viewed, setIs_viewed] = useState<boolean>(props.item.is_viewed) 
    const [isOpen, setIsOpen] = useState<boolean>(false) 
    const _date = parseInt(props.item.post_date || "0") * 1000
    let today = new Date()
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - .5)
    useEffect(() => {
        setIs_viewed( props.item.is_viewed )

    }, [props.item])
    const onIsViewed = (e: any) => {
        if( !isRole( getModeratorRoles() ) ){
            return
        }
        e.preventDefault()
        e.stopPropagation()
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                data_type: "PEMessage", 
                id:props.item.id, 
                item: { is_viewed: !is_viewed}, 
                landId
            }
        )
        .then((resp) => {
            setIs_viewed(resp.is_viewed)
            actions(GET_FEED_ACTION, {
                data_type: "PEMessage",
                paging: ` metas: [ { key: "menu", value_bool: false } ] `,
                land_id: landId,
                seed: " for notify "
            })
        })
    }
    const onRemove = () => {
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                data_type: "PEMessage", 
                id:props.item.id, 
                item: { post_status: POST_STATUS.DRAFT }, 
                landId
            }
        )
        .then((resp) => {
            setIs_viewed(resp.is_viewed)
            if(props.onUpdate) props.onUpdate()
        })
    }
    const onReply = () => { 
        setNewMessageParent(props.item.id)
        setNewMessageText( 
            `[[${props.item.post_author?.display_name}]]: ` + newMessageText.split("]]: ")[0]
        )
    }
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    return <div className="row border-bottom-2 border-secondary-medium strob01 py-3 w-100"> 
        <div className="col-md-10 pl-md-0 pl-3 d-flex align-items-center ">
            <UserAvatar user={props.item.post_author} width={35} height={35} />
            <div className={` d-flex flex-column ml-3 ${!is_viewed ? "title": ""} w-100`}> 
                <MessageParent item={props.item} /> 
                <div className="title mb-1 small" >
                    {props.item.post_author?.display_name}
                </div>
                <div className={``}>
                    {props.item.title}
                </div>
                <div className="small mt-1">
                    { 
                        props.item.resource_title && 
                        <Link to={`${ data_type_single_route( props.item.resource_type || "Post" )?.realRoute }/${props.item.resource_id}`}>
                            <Tag minimal >{props.item.resource_title}</Tag>
                        </Link> 
                    }
                </div> 
                <div >
                    {
                        props.item.thumbnail && <>
                            <img
                                src={props.item.thumbnail}
                                alt=""
                                style={{maxHeight: 150}}
                                className="pointer"
                                onClick={onOpen}
                            />
                            <ScalableDialog
                                dialogSize={DIALOG_SIZE.FULL}
                                isOpen={isOpen}
                                onClose={onOpen}
                                hideClose={false}
                                mayFullScreen={false}
                            >
                                <div className="w-100 h-100 flex-centered">
                                    <img
                                        src={props.item.thumbnail}
                                        style={{maxWidth: "90%", maxHeight: "90%"}}
                                        alt=""  
                                    />
                                </div>
                            </ScalableDialog>
                        </>
                    }
                </div> 
                {
                    !props.item.adressee?.id && 
                    <div>
                        <span>
                            <Popover
                                interactionKind="hover"
                                position={Position.TOP}
                                content={
                                    <div className="alert alert-danger descr small square3 p-4 m-0">
                                        <span className="small">
                                            {__("This message has been sent to all moderators. If you have responded to the message and consider it closed, remove the seen mark (green star)")}
                                        </span>
                                    </div>
                                }
                            >
                                <Tag intent={ is_viewed ? Intent.NONE : Intent.DANGER } minimal={is_viewed} className="pr-3 pl-1 py-1 ">
                                    <Icon icon="chevron-up" className="mr-2" />
                                    <span className="small">
                                        {__("Message for moderators")}
                                    </span>
                                </Tag>
                            </Popover>
                        </span> 
                    </div> 
                }
            </div> 
        </div>
        <div className="col-md-2 pl-md-0 pl-5 d-flex align-items-center">
            <Icon 
                icon={is_viewed ? "star-empty" : "star"} 
                className={`mr-3 ${isRole(getModeratorRoles()) ? "pointer " : ""}`} 
                intent={!is_viewed ? Intent.SUCCESS : Intent.NONE} 
                title={__(is_viewed ? "viewed": "not viewed")} 
                onClick={onIsViewed}
            />
            <Button minimal alignText="left" onClick={onReply}  title={__("Reply")} className=" mr-2">
                <i className="fas fa-reply text-secondary " />
            </Button>
            <Moment  
                locale="ru" 
                format={ _date > yesterday.getTime() ? "HH:mm" : "D.MM.YY" } 
                className={`small ${ _date > yesterday.getTime() ? "title" : " text-secondary " } mr-2`}  
            >
                { _date } 
            </Moment> 
            <Popover
                className="pe-surface2 "
                content={
                    <ButtonGroup minimal vertical> 
                        {/* <Button fill alignText="left">
                            <i className="fas fa-share mr-2" />
                            {__("Share")}
                        </Button> */}
                        <ClipboardCopyBtn
                            data={props.item.title}
                            type="button"
                            label="Copy text"
                        />
                        {
                            (isRole(getModeratorRoles()) || user.id === props.item.post_author?.id) &&
                            <ConfirmButton
                                buttonFill 
                                buttonLabel={<>
                                    <i className="fas fa-times mr-3" /> 
                                    {__("Remove")}
                                </>}
                                buttonAlignText="left" 
                                buttonIntent={Intent.DANGER} 
                                dialogAddedClassName={DIALOG_SIZE.MINIMAL}
                                onConfirm={onRemove}
                            > 
                                <div className="p-4 lead-md">
                                    {__("Really remove Message?")}
                                </div>
                            </ConfirmButton>
                        }
                    </ButtonGroup>
                }
            >
                <Button icon="more" minimal />
            </Popover>
        </div>
    </div>
}
export default MessageSingle