import { __ } from '@/libs/utilities'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { ICategory, ICritery, IProject, IProjectMilestone } from '../../data/interfaces'
import MilestoneTitle from './milestone/MilestoneTitle'
import Category from './rating/Category'
import Critery from './rating/Critery'
import { HasId } from '@/libs/interfaces/layouts'

interface IProjectRatingProps {
    item: IProject
    milestone?: IProjectMilestone | undefined
}

const ProjectRating = (props: IProjectRatingProps): JSX.Element => {
    const criteries: ICritery[]     = [
        ...useDataStore((state: any) => (state.PEFestCritery || [])
            .filter((critery:ICritery) => { 
                if( !!props.milestone )
                {
                    return critery.milestone?.id === props.milestone?.id
                }
                return  !critery.milestone
            }) )
            .filter((critery:ICritery) => {
                if(!!critery.fmru_group_player?.length) {  
                    return !!props.item.fmru_group_player.filter((ganre: HasId) => {
                        return !!critery.fmru_group_player.filter((g: HasId) => g.id === ganre.id).length
                    }).length
                } 
                return !critery.fmru_group_player.length
            }) 
    ];
    const categories: ICategory[]   = [];
    let items: any[] = !!props.item.is_verified && Array.isArray(criteries) && !!criteries.length
        ?
        criteries
            .sort((a: ICritery, b: ICritery) => { 
                return (a?.order === b?.order) 
                    ?
                    0 
                    :
                    a?.order > b?.order ? 1 : -1
            })
            .filter((critery:ICritery) => { 
                if(critery.category?.id && !categories.filter((category:ICategory) => category.id === critery.category.id).length) {
                    //console.log( critery.category )
                    categories.push( critery.category )
                    //console.log( categories )
                }
                return  !critery.category?.id
            })
            .map((critery:ICritery) => {   
                return <Critery 
                    item={critery}
                    key={critery.id} 
                    project={ props.item}
                    milestone={props.milestone!}
                />
            })
        :
        [ ]


    // items = [
    //     ...items,
    //     criteries
    //         .filter(critery => !critery.category?.id )
    //         .sort((a: ICritery, b: ICritery) => { 
    //             return (a?.order === b?.order) 
    //                 ?
    //                 0 
    //                 :
    //                 a?.order > b?.order ? 1 : -1
    //         }) 
    //         .map(critery => <Critery 
    //             item={critery}
    //             key={critery.id} 
    //             project={ props.item}
    //             milestone={props.milestone!}
    //         />)
    // ]
    const cats: JSX.Element[] = !!props.item.is_verified
        ?
        categories.map( ( category:ICategory ) => {
            //console.log( category )
            return <Category 
                item={ category } 
                key={ category.id }
                criteries={ criteries.filter( ( critery: ICritery ) => critery.category?.id === category.id ) }
                project={ props.item }
                milestone={ props.milestone }
            />
        } )
        :
        []
    return <div className="container">
        <MilestoneTitle milestone={props.milestone} /> 
        { cats }
        { items } 
        { 
            criteries.length === 0 
                ? 
                <div className="alert alert-danger py-5 lead-md d-none"> {
                    __( 
                        !!props.item.is_verified 
                            ?
                            "No criteries exists"
                            :
                            "Rating disabled. Reason: the project has not yet been approved"
                    ) 
                }
                </div>
                :
                null
        }
    </div>
}
export default ProjectRating
