

import { setContext } from 'apollo-link-context';
import gql from "graphql-tag";
import { ErrorResponse, onError } from "apollo-link-error"
import { createUploadLink } from "apollo-upload-client"  
import { useFestAdminStore } from '../store';
import { REFRESH_JWT_TOKEN } from "../../../../settings/qraphql"
import { useMainStore } from '@/settings/zustand';

const {
  ApolloClient, 
  ApolloLink, 
  InMemoryCache, 
  Observable
} = require('@apollo/client');
 

export function client( server_url: string ): any {
  //const token: string | null = useMainStore((state:IState) => state.accessToken) 
  const uploadLink = createUploadLink({
      uri: server_url,
  })
  const queryLink = uploadLink
  const cache = new InMemoryCache()
  const authLink = setContext((_, { headers }) => {
      let new_headers: any
      const lToken: string | null = useMainStore.getState().access_token
      const lclientToken : string | null = localStorage.getItem("client_token")
      if (lToken) 
      {
        const token = lToken || ""
        new_headers = {
          ...headers,
          authorization: `Bearer ${ token }`,
        }
      } 
      else if (lclientToken) 
      {
        const token = lclientToken || ""
        new_headers = {
          ...headers,
          authorization: `Bearer ${token}`,
        }
      } 
      else 
      {
        new_headers = {
          ...headers,
        }
      }
      return { headers: new_headers }
  }) 
  const errorlink: any = onError(( props: ErrorResponse ): any => { 
      // if(props.networkError && props.networkError.statusCode === 401)
      // { 
      //     console.log( props.networkError )  
      // }
      if (props.graphQLErrors) 
      {
        for (const err of props.graphQLErrors) 
        {
          console.log( err )
          if (err.extensions) {
            switch (err.extensions.code) 
            {            
              case "UNAUTHENTICATED": 
                if ( useMainStore.getState().access_token ) 
                { 
                  //localStorage.removeItem("token")
                  useMainStore.setState({access_token: ""})
                  
                  // TODO: move to serverside cookie httponly
                  const refresh_token = localStorage.getItem("refresh")
                  const mutation = gql`${ REFRESH_JWT_TOKEN( refresh_token ) }` 
                  
                  // eslint-disable-next-line no-loop-func
                  return new Observable((observer: any) => {
                    apolloClient.mutate({ 
                      mutation, 
                      variables: { } 
                    })
                      .then( (result: any) => {
                          // console.log( result )
                          //localStorage.setItem( "token", result.data.refresh.access_token, )
                          useMainStore.setState({ access_token: result.data.refresh.access_token })
  
                          // TODO: move to serverside cookie httponly
                          localStorage.setItem( "refresh", result.data.refresh.refresh_token, )
                          
                          const oldHeaders = props.operation.getContext().headers;
                          props.operation.setContext({
                            headers: {
                              ...oldHeaders,
                              authorization: `Bearer ${result.data.refresh.access_token}`,
                            },
                          });
                          // return forward( operation )                  
                          // return Observable.of(operation);
                          // apolloClient.query( { query: gql`${operation.query.loc.source.body}` } )
                        },
                        (err: Error) => {
                          console.error( err )
                        })
                          .then((res: any) => {
                            console.log(res) 
                            window.location.reload()
                          })
                  })
                }
                else
                {
                  //пользователь разлогинен
                  //localStorage.setItem( "token", '' )
                  useMainStore.setState({access_token: ""})
                  localStorage.setItem( "refresh", '' ) 
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000);
                } 
                break;
              case "INTERNAL_SERVER_ERROR":
                console.log(err)
                break
              case "FORBIDDEN":
                console.log(err)
                break
              default:
              //   AppToaster.show({
              //     intent: Intent.DANGER,
              //     icon: "error",
              //     message: __(err.message),
              //   })
                break
            }
          } 
          else 
          {
          //   AppToaster.show({
          //     intent: Intent.WARNING,
          //     icon: "warning",
          //     message: __(err.message),
          //   })
          }
        }
      }
      else {
        console.log(`Unknown error`, props)
      }
    }
  )
    
  const defaultOptions: any = {
    watchQuery: {
      fetchPolicy: "network-only",
      // errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: "network-only",
      // errorPolicy: 'all',
    },
  }
  const apolloClient: any = new ApolloClient({
    link: ApolloLink.from([
      errorlink,
      authLink,
      queryLink,

    ]),
    cache,
    // defaultHttpLink: false,
    defaultOptions,
    // fetchOptions: {
    //   credentials: "include",
    //   mode: "no-cors",
    // },

  })
  // console.log( apolloClient )
  useFestAdminStore.getState().setClient( apolloClient )
  return apolloClient
} 
