import { Tag } from "@blueprintjs/core"
import { IDomain } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IDomainLabelProps {
   item: IDomain
}
const DomainLabel = ({item}: IDomainLabelProps): JSX.Element => {
    return <Tag style={{ backgroundColor: item.color.toString() }} className="mr-1 mb-1" round>
        <div className="small">{item.title}</div>
    </Tag>
}
export default DomainLabel