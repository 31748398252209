import React, { useEffect, useReducer, useRef, useState } from 'react' 
import { Callout, Dialog, Icon, Intent } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'   
import { IFestDataStor, useFestDataStore, useFestStore } from '@/modules/pe-fest-module/data/store'
import { NEED_DESCRIPTION_COMMENT } from '@/modules/pe-fest-module/data/interfaces'

interface ICriteryCommentaryDialogProps {
    description : string
    isOpen: boolean
    onOpen: ( isOpen: boolean) => void
    lockWithoutComment: boolean
    onSend : ( description: string ) => void
    sendLabel: string
    isHideChangeDescr: boolean

}
const CriteryCommentaryDialog = (props: ICriteryCommentaryDialogProps) : JSX.Element => { 
    const [description , setDescription] = useState( props.description )
    const [placeholder, setPlaceholder] = useState("")
    const [isOpen , setOpen] = useState( false )
    const isComment: boolean = useFestDataStore( (state:IFestDataStor) => state.isComment )
    const setIsComment = useFestDataStore( (state:IFestDataStor) => state.setIsComment )

    const ref = useRef(null)

    useEffect(() =>
    { 
            
    }, [ ])
    useEffect(() =>
    { 
        setOpen( props.isOpen )
    }, [props.isOpen])

    const onOpen = () =>
    {
        props.onOpen(!isOpen) 
    } 
    const onDescription = (evt: any) =>
    {
        const value = evt.target.value
        setDescription(value)
        setPlaceholder("")
    }
    const onForceSend = () =>
    {
        if(props.lockWithoutComment && !description)
        {
            setPlaceholder(NEED_DESCRIPTION_COMMENT + "\n" + placeholder)
            return;
        }
        props.onSend( description )
        onOpen()
    }
    const comment = () =>
    {
        return <Callout intent={Intent.DANGER} className="w-100 ">
            { NEED_DESCRIPTION_COMMENT }
        </Callout> 
    }

    return <Dialog
        isOpen={ isOpen }
        className="rounded-0 little"
        onClose={ onOpen }
        title={__("Edit you description")}
    >
        <div className="d-flex flex-column justify-content-center flex-grow-100 mb-5">
            <div className="w-00">
                { props.lockWithoutComment ? comment() : null }
            </div>
            <textarea
                rows={7}
                placeholder={placeholder}
                className="form-control input dark p-4 border-bottom-0 flex-grow-100"
                onChange={ onDescription }
                value={ description } 
                autoFocus
                onFocus={e => e.currentTarget.select()}
            />
            <div className="d-flex w-100">
                <div className={`btn btn-${!!description ? "success" : "secondary"} rounded-0 btn-block`} onClick={ onForceSend } ref={ref} >
                    { __( props.sendLabel || SEND_DESCRIPTION_LABEL ) }
                </div>
                <div className="btn btn-danger rounded-0 " onClick={ onOpen }>
                    {__("Close")}
                </div>
            </div>
        </div>
    </Dialog>
}

CriteryCommentaryDialog.propTypes = {}

export default CriteryCommentaryDialog


export const SEND_DESCRIPTION_LABEL = "Send description"
export const SEND_DESCRIPTION_EXTEND_LABEL = "Change description and update rating"