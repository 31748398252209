import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { PEFestClearPrescription } from "@/modules/pe-fest-module/data/interfaces"
import { useState } from "react"
import { __ } from "src/libs/utilities" 

export enum STATUS {
    UPLOAD,
    CHOOSE
}

export enum EXPAND_FIELDS {
    EVENT_DATA  = "EVENT_DATA",
    RATING_DATA = "RATING_DATA",
    RATING_SYSTEM = "RATING_SYSTEM",
    ATTACHMENTS = "ATTACHMENTS",
    MESSAGES = "MESSAGES",
    COMMENTS = "COMMENTS",
    PROJECTS = "PROJECTS",
    DOMAINS = "DOMAINS",
    GANRES = "GANRES",
    SCHOOLS = "SCHOOLS",
    DESTRICTS = "DESTRICTS",
    TRACKS = "TRACKS",
    HONEYCOMBS = "HONEYCOMBS",
    USERS = "USERS",
}
export const ExpandsFields = {
    EVENT_DATA: {
        title: "All event data",
        commentary: "Include Land and Festival data"
    },
    RATING_DATA:{
        title: "Rating data",
        commentary: "Include all expert's rates, commentaries, all expert statistics"
    },
    RATING_SYSTEM:{
        title: "Rating system",
        commentary: "Include all categories, criteries, milestones and ganres"
    },
    ATTACHMENTS:{
        title: "Attachments",
        commentary: null
    },
    MESSAGES:{
        title: "Messages",
        commentary: null
    },
    COMMENTS:{
        title: "Comments",
        commentary: null
    },
    PROJECTS: {
        title: "Projects",
        commentary: null
    },
    SCHOOLS:{
        title: "Education organizations",
        commentary: null
    },
    DOMAINS:{
        title: "Domains",
        commentary: null
    },
    DESTRICTS:{
        title: "Destricts",
        commentary: null
    },
    TRACKS: {
        title: "Tracks",
        commentary: null
    },
    HONEYCOMBS: {
        title: "Honeycombs",
        commentary: null
    },
    GANRES: {
        title: "Ganres",
        commentary: null
    },
    USERS: {
        title: "Users",
        commentary: null
    }
} 
export type IExpands = {
    [key in EXPAND_FIELDS]: PEFestClearPrescription
}

/* Protopia Ecosystem component */
export interface IExpandMasterProps {
   onStatus: ( status:STATUS ) => void
   status: STATUS
   onExpand : ( _expands: IExpands, upload: string ) => void
   onUpload?: ( upload: string ) => void
}

const expandDefault = (): IExpands => {
    let expand: IExpands = {} as IExpands
    (Object.keys(EXPAND_FIELDS) as Array<keyof typeof EXPAND_FIELDS>).forEach(( key ) => {
        expand[key] = PEFestClearPrescription.IGNORE
    })
    return expand
}

const ExpandMaster = (props: IExpandMasterProps): JSX.Element => {
    const [expands, setExpands] = useState<IExpands>( expandDefault() )
    const [upload, setUpload] = useState<any>()
    const onUpload = ( value: any ) => {
        setUpload( value )
        props.onStatus( STATUS.CHOOSE ) 
    }
    const onExpandsChange = ( field: EXPAND_FIELDS, value: PEFestClearPrescription ) => {
        const _expands = {
            ...expands,
            [field] : value
        }
        setExpands(_expands)
        props.onExpand( _expands, upload )
    }
    let cont: JSX.Element=<></>
    switch(props.status) {
        case STATUS.UPLOAD:
            cont = <FieldInput
                type={ SCALAR_TYPES.UPLOAD }
                value={ upload }
                editable
                onChange={ onUpload }
                notClear
                limitSize={ 120000 }
                className={"col-height-full text-white"}
                placeholder={ __( "Upload *.peland file" ) }
                rowClassName="row-right-plus-15"
            />
            break
        case STATUS.CHOOSE:
        default:
            cont = <div className="p-4">
            <div className='display-6 mb-3'>
                {__("Expand Event data")}
            </div>
            <div className="overflow-y-auto">
            {
                (Object.keys(EXPAND_FIELDS) as Array<keyof typeof EXPAND_FIELDS>).map(( key ) => {
                    return <FieldInput 
                        title={__( ExpandsFields[key].title )} 
                        type={SCALAR_TYPES.TAGS}
                        data-ID={key}
                        id={key}
                        key={key}
                        value={ expands[key] }
                        values={[
                            { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                            { _id:PEFestClearPrescription.CLEAR, title:__("Add") }, 
                        ]}
                        default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
                        editable 
                        onClear={ () => onExpandsChange(key as EXPAND_FIELDS, PEFestClearPrescription.IGNORE ) }
                        commentary={ __( ExpandsFields[ key ].commentary || "" ) }
                        onChange={(value: PEFestClearPrescription) => onExpandsChange(key as EXPAND_FIELDS, value)}
                    />
                })
            }                
            </div>
        </div>
    }
    return <div className='pe-expand-master-container text-light'> 
        { cont } 
    </div>
}
export default ExpandMaster