import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { Button, Dialog, Icon, NumericInput } from "@blueprintjs/core" 
import { __ } from "../utilities" 
import FieldInput, { SCALAR_TYPES } from "../scalars"
import { IState, useMainStore } from "@/settings/zustand"
//import { initArea } from "@pe/utilities" 

export interface IPagiProps {
  all: number
  count?: number
  current: number
  containerClassName? : string
  marginPagesDisplayed?: any
  pageRangeDisplayed? : any
  isHideNumerics? : boolean
  onChoose: ( arg0: number ) => void
}
const Pagi = (props: IPagiProps):JSX.Element | null => {
  const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
  const setPageCount: any = useMainStore((state: IState ) => state.setPageCount)
  const[isOpen, setIsOpen] = useState(false)
  const [current, setCurrent] = useState<number>( props.current | 0 )
  const [all, setAll] = useState<number>( props.all | 0 )
  const {marginPagesDisplayed, pageRangeDisplayed} = props
  useEffect(() => setAll(props.all), [props.all])
  useEffect(() => setCurrent( props.current ), [ props.current ]) 

  const onChooseValue = (valueAsNumber : number) : void => {
    const i = valueAsNumber - 1
    setCurrent(i)
    props.onChoose(i)
  }
  const onOpen = () => setIsOpen( !isOpen )

  return typeof current !== "undefined"
    ?
    <div className="pagination-container d-flex w-100"> 
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        breakClassName="break-me"
        pageCount={all + 1}
        marginPagesDisplayed={marginPagesDisplayed ? marginPagesDisplayed : 2}
        pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 2}
        onPageChange={(data: any) => props.onChoose(data.selected)}
        containerClassName={`pagination pagination-sm ${props.containerClassName}`}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName=" page-item"
        nextLinkClassName="page-link" 
        breakLinkClassName="page-link"
        activeClassName="active"
        forcePage={current}
      /> 
      <Button minimal className="hover-pagination mb-2 "><Icon icon="cog" size={ 13 } onClick={onOpen}/></Button>
      {
        props.isHideNumerics
          ?
          null
          :
          <NumericInput 
            size={10} 
            small
            min={1}
            max={all + 1}
            value={current + 1}
            inputClassName="input dark  max-width-110"
            className="ml-auto align-items-center"
            onValueChange={onChooseValue }
          />
      }
      <Dialog
        isOpen={isOpen}
        onClose={onOpen}
        className="little"
      >
        <div className="p-5">
          <Button
              minimal
              onClick={() => setIsOpen(false)}
              className="position-absolute top dialog-close-btn" 
          >
              <Icon size={22} color="#ffffff" icon="cross"/>    
          </Button>
          <div className="lead mb-4">
            {__("Count elements by page:")}
          </div>
          <div>
            <FieldInput
              editable
              type={SCALAR_TYPES.NUMBER}
              value={pageCount}
              onChange={ ( value: number ) => setPageCount( value ) }
            />
          </div>
        </div>
      </Dialog>
      {/* {
        initArea(
          "pagination",
          { current, all, onchange },
        )
      } */}
    </div>
    :
    null
} 
export default Pagi 