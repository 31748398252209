import { ITrack } from "../../data/interfaces"
import DomainLabel from "./DomainLabel"

/* Protopia Ecosystem component */
export interface ITrackDomainsProps {
   item: ITrack 
}
const TrackDomains = (props: ITrackDomainsProps): JSX.Element => {
    const domains = !!props.item.pe_domain?.length
        ?
        props.item.pe_domain.map(d => <DomainLabel item={d} key={d.id}/>)
        :
        null
    return <div className='d-flex flex-wrap m-1'>
       {domains}
    </div>
}
export default TrackDomains