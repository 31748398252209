import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

/* Protopia Ecosystem component */
export interface IStorageManagerProps {
   
}
const StorageManager = (props: IStorageManagerProps): JSX.Element => {
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()
    useEffect(() => { 
        getLocal()
    }, [])
    const getLocal = () => {
        const strg = URLSearchParams.get( "strg" )
        if( strg === "clear" ) {
            localStorage.clear();
            AppToaster.show({
                message: __( "All local data cleared" )
            })
        }
    }
    return <></>
}
export default StorageManager