import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { useUser } from "@/settings/zustand/user"
import { Button, Dialog, Intent, Popover, Position, Tooltip } from "@blueprintjs/core"
import { Fragment, useState } from "react"
import { IPost } from "../interfaces/layouts"
import { __ } from "../utilities"
import { AppToaster } from "./AppToaster"
import AvatarLink from "./AvatarLink"
import { useDataStore } from "@/modules/pe-basic-module/data/store"

/* Protopia Ecosystem component */
export interface IBookmarkProps {
   item: IPost
   className?:string
   onBookmark?: () => void
}
const Bookmark = ({item, ...props}: IBookmarkProps): JSX.Element => {
    const user = useUser()
    const landId = useMainStore((state: IState) => state.landId)
    const [isOpen, setIsOpen] = useState<boolean> (false)
    const [isMarked,setIsMarked] = useState<boolean>(!!item.bookmarks?.filter(b => b.id === user.id).length) 
    const onBookmark = () => {
        actions(UPDATE_SINGLE_TYPE, {
            landId: landId, 
            id: item.id,
            data_type: item.__typename, 
            item: {
                bookmarks: [ { add: !isMarked } ]
            }
        })
        .then((res: IPost) => {
            const is = !!res.bookmarks?.filter(b => b.id === user.id).length
            setIsMarked( is )
            useDataStore.setState({
                [ item.__typename || "Post" ]: [
                    ...useDataStore.getState()[ item.__typename || "Post"].map((itm: any) => {
                        if(itm.id === res.id) {
                            return res
                        }
                        return itm
                    })
                ]
            })
            if(props.onBookmark) props.onBookmark()
            AppToaster.show({
                message: __( 
                    is 
                        ? 
                        "You have subscribed. Now you will receive notifications every time new comments appear or the object is changed." 
                        : 
                        "You have unsubscribed" 
                )
            })
        })
    }

    const tooltip = <Tooltip content={ __(isMarked ? "Remove bookmark":  "Add bookmark") } position={Position.TOP} >
        <Button minimal intent={Intent.NONE} large onClick={ onBookmark } className={`px-2 ${ props.className ? props.className : "" }`} >
            <span className="flex-centered">
                <i className={ `${isMarked ? "text-danger fas" : "far"} fa-bookmark mr-1 `}></i> 
                <span className={ `${isMarked ? "text-danger " : ""}`}>{ item.bookmarks?.length || 0 }</span> 
            </span>
        </Button> 
    </Tooltip>
    return  !!item.bookmarks?.length 
        ?
        <Popover
            interactionKind="hover"
            hoverOpenDelay={ 1000 }
            position={Position.TOP}
            content={
                <div>
                    <div className="px-3">
                        {
                            isMarked
                                ?
                                __("You are subscribe this. Click for remove bookmark")
                                :
                                __("Click for subscribe")
                        }
                    </div>
                     <div className="short-container d-flex flex-wrap">
                        {
                            item.bookmarks?.filter(bk => !!bk.id).map((bk, i) => {
                                return i  <  4 
                                    ?
                                    <AvatarLink user={bk} selected={user} key={bk?.id || i} />
                                    :
                                    <Fragment key={"0"} />
                            })
                        }
                        {
                            item.bookmarks?.length > 4 && <Fragment >
                                <div className="flex-centered p-1 pe-w-100 pe-h-100 pointer" onClick={ () => setIsOpen(true) }>
                                    +{ item.bookmarks?.filter(bk => !!bk.id).length - 4 }
                                </div>
                                <Dialog
                                    isOpen={isOpen}
                                    onClose={()=> setIsOpen(false)}
                                >
                                    <div className="pt-2 px-2 text-center lead-md">
                                        {__("All subscribers")}
                                    </div>
                                    <div className="p-4 flex-centered flex-wrap"> 
                                        {
                                            item.bookmarks?.filter(bk => !!bk.id).map((bk , i:number) => {
                                                return <AvatarLink user={bk} selected={user} key={bk?.id || i} />  
                                            })
                                        }
                                    </div>
                                </Dialog>
                            </Fragment> 
                        }
                    </div>   
                </div> 
            }
        >
            {tooltip}
        </Popover>
        :
        tooltip
}
export default Bookmark