import { Color, HasId } from '@/libs/interfaces/layouts'
import { LoaderMinimal } from '@/libs/useful/Loading'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { Tag as BTag } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { adminData, apolloFields, getVisibleValue } from 'src/libs/layouts'
import { __ } from "src/libs/utilities" 
import actions from 'src/modules/pe-basic-module/data/actions'
import IScalarForm, { SCALAR_TYPES } from '../interface/IScalarForm'
import MultiTag from './label/MultiTag'
import Scalar from './Scalar'
import { GET_FEED_ACTION } from '@/modules/pe-basic-module/data/actionTypes'

const ExternalTags = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<ExternalEnabled {...props} />}
        desabledForm={<ExternalSingleDisabled {...props} />}
    />
} 
export default ExternalTags 

const ExternalEnabled = ( props: IScalarForm ): JSX.Element => {
    return props.multiple
        ?
        <ExternalMultiEnabled  {...props} />
        :
        <ExternalSingleEnabled  {...props} />
}
const ExternalSingleEnabled = ( props: IScalarForm ): JSX.Element => {
    const [ seed, ] = useState<string>( props.seed || props.component + " " + props.paging + " " +  props.landId )
    const items: any[] = useDataStore((state:any) => {
        if( props.component ){
            return state[props.component + seed] || []
        }
        return []
    }) || []
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        actions( 
            GET_FEED_ACTION, 
            { 
                data_type: props.component,
                paging: props.paging,
                land_id: props.landId,
                seed 
            } 
        )
            .then( response => {
                setIsLoading( false ) 
            })
        return () => {
            const st = {...useDataStore.getState() }
            // console.log( props.component + seed )
            st[ props.component + seed ] = []
            // console.log(st)
            useDataStore.setState( st )
        }
    }, [ props.component, props.paging, props.landId, props.seed ])

    const [value, setValue] = useState( () => {
        return props.value
    })
    useEffect( () => {
        setValue( props.value )
    }, [props.value] ) 
    
    const visible_value = getVisibleValue( props.component! || "Post" ) || SCALAR_TYPES.TITLE 

    const onClick = (value: HasId) => {
        setValue(value)
        if( props.onChange )
            props.onChange(value,  props.field, "")
    }
    const values = Array.isArray([ ...(items ? items : [] ) ])
        ?
        items?.map( (v, i) =>
        {
            return <BTag
                key={v.id || i}
                round
                className='px-3 py-0 mr-2 mb-2 pointer hover'
                intent={ v.intent }
                style={{ }}
                minimal={ value?.id !== v.id }
                onClick={() => onClick( v ) }
            >
                {
                    v[ visible_value ]
                }
            </BTag>
        })
        :
        [ ]

    return (
        <div className='w-100 d-flex flex-wrap py-4 '>
            {
                isLoading && <LoaderMinimal />
            }
            { values }
        </div>
    )
}

const ExternalMultiEnabled = ( props: IScalarForm ): JSX.Element => {
    const [ seed, ] = useState<string>( props.seed || props.component + " " + props.paging + " " +  props.landId )
    const items: any[] = useDataStore((state:any) => {
        if( props.component ){
            return state[props.component + seed] || []
        }
        return []
    }) || []
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        actions( 
            GET_FEED_ACTION, 
            { 
                data_type: props.component,
                paging: props.paging,
                land_id: props.landId,
                seed 
            } 
        )
            .then( response => {
                setIsLoading( false ) 
            })
        return () => {
            const st = {...useDataStore.getState() }
            // console.log( props.component + seed )
            st[ props.component + seed ] = []
            // console.log(st)
            useDataStore.setState( st )
        }
    }, [])
     
    // console.log( props )
    const [value, setValue] = useState( () => {
        if(props.multiple)
        {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        }
        else
        {
            return props.value
        } 
    })
    useEffect( () => {
        setValue( () => {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        })
    }, [props.value] ) 

    const onMultipleClick = (id : any) =>
    {
        // console.log( "onMultipleClick", value)
        let vv
        if(value.filter((v: any) => v.id === id.id ).length > 0)
        {
            vv = value.filter((v: any) => v.id !== id.id )
        }
        else
        {
            vv = [...value]
            vv.push(id)
        }
        setValue( vv )        
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const onAll = () =>
    {
        let vv = value.length === items?.length 
            ? 
            [] 
            : 
            [ ...(items ? items?.map( v => v._id ? v._id : v ) : [] ) ]
        setValue( vv )
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const values = Array.isArray([ ...(items ? items : [] ) ])
        ?
        items?.map( (v, i) =>
        {
            return multiple({ 
                v, 
                value, 
                i, 
                onMultipleClick, 
                tags: props.tags, 
                component:          props.component || "Post" ,
                elementClassName:   props.elementClassName,
                elementClassName2:  props.elementClassName2,
            })
        })
        :
        [ ]
    return (
        <div className='w-100 d-flex flex-wrap py-4 '>
            {
                isLoading && <LoaderMinimal />
            }
            { values } 
            {
                !!values.length && <BTag 
                    intent={"primary"} 
                    className={`m-1 pointer ${ props.elementClassName ? props.elementClassName : "px-3 py-1" }`} 
                    minimal 
                    round
                    onClick={ onAll }
                > 
                    <div className={ props.elementClassName2 || "" } >{ __( "All" ) }</div>
                </BTag> 
            }
        </div>
    )
} 

const ExternalSingleDisabled = ( props: IScalarForm ): JSX.Element | JSX.Element[] => { 
    const visibled_value2 = getVisibleValue( props.component! || "Post" )
    const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
    return Array.isArray(props.value) 
        ?
        props.value.map(
            (v: any, i) => {
                const labels = !!props.tags && Array.isArray( props.tags )
                    ?
                    props.tags.map(tag => {
                        if( v[tag]?.id ) {
                            const vComponent = apolloFields( props.component! || "Post" )[tag]?.component                            
                            return v[tag][getVisibleValue( vComponent || "Post" )]
                        }
                        else {
                            return v[tag]
                        }
                             //apolloFields()
                    })
                    :
                    []
                console.log( adminData( props.component! || "Post" ).fill[0] )
                const fill: Color = adminData( props.component! || "Post" ).fill[0]
                return props.tags 
                    ?
                    <MultiTag
                        labels={ labels }
                        round
                        fill={ fill }
                        onClick={() => {} }
                    />
                    :
                    <BTag 
                        round 
                        className={`m-1 pointer ${ props.elementClassName ? props.elementClassName : "px-3 py-1" }`} 
                        key={i}
                    >
                        { !!v ? v[vv2] : "--" }
                    </BTag>
            }
        )
        :
        <></>
} 


/*
    @v      : current value
    @value  : props value
    @i      : 
    @tags   : 
*/

const multiple = (props: any) : JSX.Element | null =>
{
    const {v, value, i, tags} = props
    // console.log( props )
    const visibled_value2 = getVisibleValue( props.component! || "Post" )
    const vv2 = visibled_value2 || SCALAR_TYPES.TITLE 

    return  v?.id 
        ?
        tags
            ?
            <MultiTag
                labels={["title", "descr"]}
                onClick={() => props.onMultipleClick( v )}
            />
            :
            <BTag 
                key={ v.id + "_" + i } 
                intent={ v.intent }
                className={`m-1 pointer ${ props.elementClassName ? props.elementClassName : "px-3 py-1" }`} 
                round
                minimal={ value?.filter( (e: any) => e.id === v.id ).length === 0 }
                onClick={() => props.onMultipleClick( v )}
            >
                <div className={ props.elementClassName2 || 'px-2' } dangerouslySetInnerHTML={{__html: v[vv2]}} />
            </BTag>
        :
        typeof v === "string"
            ? 
            <BTag 
                key={v + "_" + i} 
                data-value={ v }  
                className={`m-1 pointer ${ props.elementClassName ? props.elementClassName : "px-3 py-1" }`}  
                round
                minimal={ value?.filter( (e: any) => e.id !== v ).length > 0 }
                onClick={() => props.onMultipleClick( v )}
            >
                <div className={ props.elementClassName2 || 'px-3' } dangerouslySetInnerHTML={{__html: v }} />
            </BTag> 
            :
            null
}
 