import { Likes } from '@/libs/useful'
import Bookmark from '@/libs/useful/Bookmark'
import { Link } from 'react-router-dom'
import { __ } from 'src/libs/utilities'
import { ICardProjectProps, IFestival, ITrack } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import getProjectLink from '../../data/utils/getProjectLink'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import { useMyTracks } from '../../hooks/track'
import ExtendedForms from '../project/projectData/ExtendedForms'
import ProjectComments from '../utils/ProjectComments'
import getDefaultProjectThumbnail from '../utils/getDefaultProjectThumbnail'
import MemberRatingDisplay from './MemberRatingDisplay'
import TrackLabel from './TrackLabel'
import GanreLabel from './search/GanreLabel'
import { HTML } from '@/libs/interfaces/layouts'
import { actions } from '../../data/actions'
import { GET_OWN_ACTION } from '../../data/actionTypes'
import QRCodeButton from '@/modules/pe-basic-module/views/core/QRCodeButton'
import { getRouteByComponent } from '@/libs/layouts'
import AddItemForm from '@/libs/scalars/scalars/gallery/AddItemForm'

const CardProject = (props: ICardProjectProps): JSX.Element => { 
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item)
    const myTracks : ITrack[] = useMyTracks()
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const gColor = "#14172b" 
    const url = getProjectLink(props.item.id)
     
    const onClick = (evt:any) => {
        evt.stopPropagation()
        evt.preventDefault()
    }
    const onCommentSend = ( comment: HTML ) => {
        if(isOwner) {
            actions(GET_OWN_ACTION, {
                land_id: festival.id
            })
        }
    }
    return <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">      
        <Link className="card h-100 m-0 p-0 position-relative " to={url}>
            <div
                className="card-img"
                style={{ 
                    backgroundImage: `url(${getDefaultProjectThumbnail( props.item, festival )})`,
                    borderColor: gColor,
                    height: festival.memberCardHeight,
                    opacity: !props.item.is_verified ? .5 : 1,
                }}
                data-mid={props.item.id}
                data-fmru_type="fmru_player"
                data-args={props.item.id}
            >
                <div className="card-id">
                    {props.item.order}
                </div>
                <TrackLabel track={props.item.track} />
                
            </div>
            <div className="card-icons">
                {/* {_ganres} */}
            </div>
            <div
                className="card-header d-flex justify-content-center align-items-center"
                title={props.item.title}
                style={{
                    height: 76,
                    overflow: "hidden",
                    padding: "0 1.25em",
                    position: "relative",
                    display: "table",
                }}
                >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                    {props.item.title}
                </h5>
            </div>
            {
                !props.item.is_verified && 
                <div className="mine-corner-no-verified corner-card-project">
                    {__("Project has not yet been approved")}
                </div>
            }
            <ul className="list-group list-group-flush flex-grow-100  mt-auto">
                {
                    props.item.prize
                        ?
                        <li className="list-group-item pb-2"> 
                            <div className=" pe-pennant pe-pumpkit" style={{height:80}}> 
                                {props.item.prize} 
                            </div>
                        </li>
                        :
                        null
                }
                {
                    props.item.nomination
                    ?
                    <li className="list-group-item pb-2">
                        <div className=" pe-pennant pe-pumpkit-dark" style={{height:80}}> 
                            {props.item.nomination} 
                        </div>
                    </li>
                    :
                    null
                }  
                {
                    Array.isArray(props.item.fmru_school) 
                        &&
                        props.item.fmru_school.map((school, i) => {
                            return <li className="list-group-item pb-2" key={school.id}> 
                                    <div className="p-3 small text-center text-dark" > 
                                        { school.title } 
                                    </div>
                                </li> 
                        })
                }
                {
                    Array.isArray(props.item.fmru_group_player) 
                        &&
                        <li className="list-group-item flex-centered py-3"> 
                        {
                            !!festival.isGanres &&
                                props.item.fmru_group_player.map((ganre, i) => i < (festival.maxGanres || 1) && <GanreLabel ganre={ganre} key={ganre.id} /> )
                        }
                        </li>
                }  
                <div className='mt-auto w-100' />
                <ExtendedForms 
                    vertical
                    item={ props.item } 
                    isCollapseOpen 
                    hidden="hide_in_card"
                    titleClassName="small d-flex justify-content-center align-items-center text-center"
                    className="text-center small title flex-centered"
                />
                
                <li className="list-group-item justify-content-between d-flex align-items-center">
                    <span className="discr text-dark">
                        {__("Rating")}:
                    </span>
                    <span className="data p-2"> 
                        <MemberRatingDisplay 
                            correct_member_rate={props.item.correctMemberRate}
                            countedRating={ props.item.actualRating }
                            className={"text-dark "}
                        />  
                    </span>
                </li>
                <li className="list-group-item justify-content-between d-flex align-items-center">
                    <span className="discr text-dark">
                        {__("Expert count:")}
                    </span>
                    <span className="data selected p-2">
                        {props.item.experts?.length || 0}
                    </span>
                </li>
                <li className="list-group-item justify-content-end d-flex align-items-center" onClick={onClick}>
                    <QRCodeButton 
                        item={props.item} 
                        options={{
                            text:`${window.location.origin}/${getRouteByComponent("FestivalView")?.realRoute}/project/${props.item.id}`, 
                            title: '',
                            width:  410,
                            height: 410, 
                        }} 
                    />
                    <ProjectComments item={props.item} onSend={onCommentSend} isForFeed />
                    <Bookmark item={props.item} />
                    <Likes item={props.item} dataType='PEFestProject' className='' /> 
                </li> 
            </ul>
            {
                isOwner
                    ?
                    <div className="mine-corner corner-3 ">
                        { __( "My project" ) }
                    </div>
                    :                    
                    isPartner 
                        ?
                        <div className="mine-honeycombs-corner mine-corner1">
                            {__("My honeycombs")}
                        </div>
                        :
                        props.item.track?.i && !!myTracks.filter(mt => mt?.id === props.item.track?.id).length 
                            ?
                            <div className="my-track-label" style={{ top: 5 }}>
                            { __( "My Track" ) }
                            </div>
                            :
                            null
            }  
        </Link>
    </div>
}
 

export default CardProject