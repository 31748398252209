import { DIALOG_SIZE, IPENotification } from "@/libs/interfaces/layouts"
import { getFeedRoute } from "@/libs/layouts"
import { ScalableDialog } from "@/libs/useful"
import { __, arrayGroupBy } from "@/libs/utilities"
import { useUser } from "@/settings/zustand/user"
import { Button, Callout } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import actions from "../../data/actions"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import { useDataStore } from "../../data/store"
import { UPDATE_NOTIFICATION_INTERVAL } from "../NotificationsWidget"
import MessagetToAdmin from "./MessagetToAdmin"
import { SingleNotification } from "./NotificationsScreen"

/* Protopia Ecosystem component */
export interface IMessagesMobileProps {
    messageRoute: string
    count: number
}
const NotificationsMobile = ({messageRoute, count, ...props}: IMessagesMobileProps): JSX.Element => {
    const user = useUser()
    const navigate = useNavigate()
    const notifications:IPENotification[] = useDataStore((state: any) => state["PENotification for notify "] || [])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    let timeInterval: any
    const checkNotifications = () => {
        actions( 
            GET_FEED_ACTION, 
            { 
                data_type: "PENotification",
                land_id: "1",
                paging: `relation:"AND" metas:[{key:"adressee", value:"${user.id}"}]`,
                seed: " for notify " 
            } 
        )
    } 

    useEffect(() => { 
        timeInterval = setInterval( checkNotifications, UPDATE_NOTIFICATION_INTERVAL)
        checkNotifications()
        return () => { 
            clearInterval( timeInterval )
        }
    }, [])
    
    const onClick = (item: IPENotification) => {
        let url ="";
        clearInterval( timeInterval )
        switch(item.resource_type) {
            case "PEMessage":
                url = `/fest/${item.land?.id || 1}/messages/user/${item.post_author?.id || 1}`
                break;
            case "Ad":
            default:
                url = `/fest/${item.land?.id || 1}/ads/mobile/${item.resource_id || 1}`
        } 
        navigate(url)
        window.location.reload() 
    }
    const gotoNoti = () => {
        setIsOpen(false)
        navigate( getFeedRoute("PENotification").realRoute )
    }
    const viewed: any = arrayGroupBy(notifications, "is_viewed")
    const length = notifications.filter(n => !n.is_viewed).length

    return <div >
        <div className={ `opened` }  onClick={() => setIsOpen(true)}>
            <div className={` `}>
                <div className=" header-menu-icon hover">
                    <i className=" far fa-bell mb-0 text-force-contrast lead-md" />
                    <div 
                        className={`messages-widget-label hover bg-danger ${ !length ? "d-none" : ""}`} 
                        id={ "PENotification for notify " }
                    >
                        { length }
                    </div>
                </div> 
            </div>
        </div>
        <ScalableDialog
            dialogSize={DIALOG_SIZE.FULL}
            isOpen={isOpen}
            onClose={ () => setIsOpen(false) }
            mayFullScreen={false}
            hideClose={false}
            isCloseButtonShown
        >
            <div className="p-4">
                <div className="lmenu podmenu text-light small p-2 position-fix w-100" >
                    <MessagetToAdmin />
                </div>
                <div className="w-100 h-100 overflow-y-auto mb-4">
                    <ul id="notifications_menu mb-4" style={{ paddingInlineStart: 0, marginBlockStart: 10, marginBlockEnd: 100}}>
                        {
                            !!notifications.length 
                                ?
                                <>
                                {
                                    viewed.is_viewed_false?.map((n:IPENotification) => {
                                        return <SingleNotification onClick={onClick} n={n} is_viewed key={n.id} />
                                    })
                                }
                                {
                                    !!viewed.is_viewed_true?.length && <li 
                                        className="lmenu podmenu text-light small d-flex align-items-center justify-content-start py-0 descr opacity_75 " 
                                        style={{ borderBottomColor: "transparent", paddingLeft: 40 }}    
                                    >
                                        <span 
                                            className="small text-secondary" 
                                            style={{ 
                                                transform: "translateY(6px)", 
                                                border: "1px solid #f8f9fa3d", 
                                                padding: "1px 6px",
                                                backgroundColor: "#171f29"
                                            }}
                                        >
                                            {__("Viewed notifications")}
                                        </span>
                                    </li>
                                }
                                {
                                    viewed.is_viewed_true?.map((n:IPENotification) => {
                                        return <SingleNotification onClick={onClick} n={n} key={n.id} />
                                    })
                                }
                                </>
                                :
                                <Callout minimal className="p-4 text-center descr text-light">
                                    <div className="small opacity_75">
                                        {__("No notificaions exists")}
                                    </div>
                                </Callout>
                        }  
                    </ul>
                </div>            
                <Button className="position-absolute bottom left w-100 text-light " style={{backgroundColor:"#293742"}} onClick={gotoNoti}>
                    {__("All notifications")}
                </Button>
            </div>
        </ScalableDialog>
    </div> 
}
export default NotificationsMobile