import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import useScreenSize from "../../hooks/useScreenSize"
import { IAttachmentCardProps } from "./AttachmentCard"
import AttachmentStrokeMobile from "./AttachmentStrokeMobile"
import AttachmentStrokeScreen from "./AttachmentStrokeScreen"

/* Protopia Ecosystem component */ 

const AttachmentStroke = (props: IAttachmentCardProps): JSX.Element => {
    const {width} = useScreenSize()
    return width > WINDOW_WIDTH.TABLET 
        ? 
        <AttachmentStrokeScreen {...props} />
        :
        <AttachmentStrokeMobile {...props} isScreen={false} />
}


export default AttachmentStroke



