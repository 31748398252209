import { IUser } from "@/settings/zustand"
import AvatarLarge from "./AvatarLarge"
import { Position, Tooltip } from "@blueprintjs/core"
import { __ } from "../utilities"
import { useNavigate } from "react-router"
import { getFeedRoute, getRoute } from "../layouts"

/* Protopia Ecosystem component */
export interface IAvatarLinkProps {
    user:IUser
    selected?:IUser 
    className?: string
    round?: boolean
    label?:string
}
const AvatarLink = ({user, selected, className, round, label, ...props}: IAvatarLinkProps): JSX.Element => {
    const navigate = useNavigate()
    const to = (evt: any) => {
        evt.preventDefault()
        evt.stopPropagation()
        navigate( `/${getRoute( getFeedRoute("PEMessage") )}/user/${user.id}` )
    }
    return <Tooltip
        position={Position.TOP_LEFT}
        content={<div className="p-2">{__(label || "Send message")}</div>}
    >
       <AvatarLarge user={ user } selected={ selected } key={ selected?.id } className={`${className} hover pointer`} round={round} onClick={to} />  
    </Tooltip>
}
export default AvatarLink