import { IProject } from '../../data/interfaces'
import { IFestProjectsProps, useFestProjects } from '../../data/store'
import ExpertDescriptions from './ExpertDescriptions'
import InsertCritery from './insert-critery'
import ProjectMilestones from './milestone/ProjectMilestones'
import ProjectRating from './ProjectRating'

const Project = (props: any) : JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)  
    return <div className="container pt-4 pb-5 single-project-container px-1">
            <ProjectMilestones {...props}  item={item} />
            <ProjectRating {...props}  item={item} />
            <InsertCritery />
            <ExpertDescriptions />
        </div> 
} 

export default Project