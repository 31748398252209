import { IDefaultFeedLayoutProps } from "../core/FeedLayout"
import CategoryFilter from "./CategoryFilter"

/* Protopia Ecosystem component */
export interface IAttachmentsListContainerProps extends IDefaultFeedLayoutProps {
   
}
const AttachmentsListContainer = (props: IAttachmentsListContainerProps): JSX.Element => {
    return <div className={ ` ${props.containerClassName || " d-flex " } flex-column` }> 
        <div>
            <CategoryFilter />
        </div>
        <div className={ `row ${props.class_name}`  } style={ props.style }>
            { props.children }
        </div> 
        
    </div> 
}
export default AttachmentsListContainer