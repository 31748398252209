import { Link } from 'react-router-dom'
import { __ } from 'src/libs/utilities'
import { DEFAULT_PROJECT_BG_URL, EMTY_IMAGE_NAME, ICardProjectProps, IFestival, ITrack } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import getProjectLink from '../../data/utils/getProjectLink'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import HoneycombsLabel from './HoneycombsLabel'
import MemberRatingDisplay from './MemberRatingDisplay'
import TrackLabel from './TrackLabel'
import GanreLabel from './search/GanreLabel'
import { useMyTracks } from '../../hooks/track'
import getDefaultProjectThumbnail from '../utils/getDefaultProjectThumbnail'
import ProjectComments from '../utils/ProjectComments'
import Bookmark from '@/libs/useful/Bookmark'
import { Likes } from '@/libs/useful'
import { actions } from '../../data/actions'
import { GET_OWN_ACTION } from '../../data/actionTypes'
import { HTML } from '@/libs/interfaces/layouts'
import QRCodeButton from '@/modules/pe-basic-module/views/core/QRCodeButton'
import { getRouteByComponent } from '@/libs/layouts'

const StrokeProject = (props: ICardProjectProps): JSX.Element => {
  const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival) 
  const myTracks : ITrack[] = useMyTracks()
  const isOwner: boolean = useUserOwner( props.item )
  const isPartner:boolean = useOwnerPartner(props.item)
  const url = getProjectLink(props.item.id)
  const table = {
    minHeight: 105,
    overflow: "hidden",  
    display: "table", 
    padding: "5px 30px",
  }
  const cell = { display: "table-cell", verticalAlign: "middle" }
  const content: string = props.item.post_content.replace(/<[^>]+>/g, '').split(" ").slice(0, 12).join(" ") + "..."
  
  
      
  const onClick = (evt:any) => {
      evt.stopPropagation()
      evt.preventDefault()
  }
  const onCommentSend = ( comment: HTML ) => {
      if(isOwner) {
          actions(GET_OWN_ACTION, {
              land_id: festival.id
          })
      }
  }
  
  return <div className="ml-2 w-100 grey2 position-relative overflow-hidden"> 
    <Link to={url} className="row hover">
      <div className="col-lg-3 col-md-5 col-sm-6 col-12">
        <div
          className="card-img"
          style={{
            backgroundImage: `url( ${ getDefaultProjectThumbnail( props.item, festival ) })`,
            backgroundColor: "#00000005",//"transparent",
            border: "0",
            height: "100%",
            overflow:"hidden",
            minHeight: 120
          }}
          data-mid={props.item.id}
        >
          <div className="card-id">{ props.item.order }</div>
          
        </div>
      </div>
      <div
        className="col-lg-3 col-md-2 col-sm-4 col-8 d-flex flex-centered flex-column position-relative "
        style={table}
      >
        
          <h5
            className={`card-title ${!props.item.is_verified ? "opacity_5" : ""} `}
            style={cell}
          >
            {props.item.title}
          </h5>
          {
            props.item.prize
              ?
              <div className="data text-danger title"> 
                {props.item.prize} 
              </div>
              :
              null
          }     
          {
            props.item.nomination
              ?
              <div className="data text-danger text-uppercase small"> 
                {props.item.nomination} 
              </div>
              :
              null
          }  
          
          <div className="position-absolute bottom left flex-centered w-100" onClick={onClick}>
              <QRCodeButton 
                  item={props.item} 
                  options={{
                      text:`${window.location.origin}/${getRouteByComponent("FestivalView")?.realRoute}/project/${props.item.id}`, 
                      title: '',
                      width:  410,
                      height: 410, 
                  }} 
              /> 
              <ProjectComments item={props.item} onSend={onCommentSend} isForFeed className={ "" }/>
              <Bookmark item={props.item}  className={ "" }/>
              <Likes item={props.item} dataType='PEFestProject' className={ "" }/>  
          </div>         
      </div>
      <div
        className="col-lg-1 col-md-1 col-sm-2 col-4 d-flex flex-centered position-relative "
        style={table}
      >            
        <MemberRatingDisplay 
          correct_member_rate={props.item.correctMemberRate}
          countedRating={props.item.r2}
          className={"text-dark "}
        />           
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-secondary py-4 pl-md-0 pl-4 pr-5" style={{}} >
        <em><div style={cell} dangerouslySetInnerHTML={{ __html: content }} className='pb-3'/></em>
        {
          !!props.item.track?.id && 
            <div className="d-flex align-items-center"> 
              {__("Track")}: 
              <TrackLabel track={props.item.track} className="small d-inline-flex align-items-center text-nowrap my-2 ml-2 text-force-contrast "/>
            </div>
        } 
        {
          !!props.item.honeycombs?.id && 
            <div> {__("Honeycomb")}: <HoneycombsLabel honeycombs={props.item.honeycombs}  className="title d-inline my-2 ml-2"/> </div>
        }
        {
          !!festival.isGanres && 
            <div className='d-flex align-items-center '>
              <span className="mr-1">
                {__("Ganres")}:
              </span>
              {
                props.item.fmru_group_player.map((ganre, i) => i < (festival.maxGanres || 1) && <GanreLabel ganre={ganre} key={ganre.id} small /> )
              }
            </div>
        }
      </div>
      {
          !props.item.is_verified && 
              <div className="mine-corner-no-verified corner-card-project">
                  {__("Project has not yet been approved")}
              </div>
      }
    </Link>
        {
          props.item.track?.i && !!myTracks.filter(mt => mt?.id === props.item.track?.id).length  && !isOwner && !props.isMineHoneycoms
            ?
            <div className="my-track-label">
              { __( "My Track" ) }
            </div>
            :
            null
        }
        {
          isOwner
            ?
            <div className="mine-corner corner-3 ">
                { __( "My project" ) }
            </div>
            :                    
            isPartner 
              ?
              <div className="mine-honeycombs-corner mine-corner1">
                  {__("My honeycombs")}
              </div>
              :
              null
        }
        {
          props.isMineHoneycoms
            ?
            <div className="mine-honeycombs-corner">
              { __( "My honeycombs" ) }
            </div>
            :
            null
        }
  </div>
} 

export default StrokeProject