import { IDiary } from "@/modules/pe-fest-module/data/interfaces"
import { IState, useMainStore } from "@/settings/zustand"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { ScalableDialog } from "@/libs/useful"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import DiaryCard from "./DiaryCard"

/* Protopia Ecosystem component */
export interface IDiarySingleModalProps {
   
}
const DiarySingleModal = (props: IDiarySingleModalProps): JSX.Element => {
    const navigate = useNavigate()
    const {dairyId} = useParams()
    const landId = useMainStore((state:IState) => state.landId)
    const [item, setItem]  = useState<IDiary>({} as IDiary)

    useEffect(() => {
        actions(GET_SINGLE_ACTIONS, {
            data_type: "PEDiary",
            land_id: landId,
            id: dairyId
        }).then((res: IDiary) => setItem(res))
    }, [])

    const onClose = () => {
        navigate(`/${getRoute(getFeedRoute("PEDiary"))}`)
    }
    return item?.id
        ?
        <ScalableDialog
            dialogSize={DIALOG_SIZE.MINIMAL}
            isOpen={true}
            onClose={onClose}
            hideClose={false}
            mayFullScreen={false}
            isCloseButtonShown
        >
            <div className="py-0">
                <div className="max-height overflow-y-auto">
                    <DiaryCard item={item} minimal />
                </div>   
            </div> 
        </ScalableDialog>
        :
        <></>
}
export default DiarySingleModal

 