import { HasId, ID } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { Callout } from "@blueprintjs/core"
import { IFestival, IMilestone } from "../../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IFestDataStor, useFestDataStore, } from "../../data/store"
import { actions } from "../../data/actions"
import { useAppoloStore } from "@/settings/zustand"
import gql from "graphql-tag"
import { AppToaster } from "@/libs/useful"

/* Protopia Ecosystem component */
export interface ICurrentMilestoneSwitcherFormProps {
   
}
const CurrentMilestoneSwitcherForm = (props: ICurrentMilestoneSwitcherFormProps): JSX.Element => {
    const milestones: IMilestone[] = useDataStore((state: any) => state.PEFestMilestone || [])
    const festival: IFestival       = useFestDataStore((state: IFestDataStor) => state.festival)
    const festId: ID       = useFestDataStore((state: IFestDataStor) => state.festId)

    const onValueChange = (field: string, value: any) =>
        {
            console.log(field, value)
            let fest: any = {...festival}
            fest[field] = value
            // setFestival( fest )
            const mutation: string =  `
            mutation changeFestival {
                changePEFestival (
                    input: { 
                        currentMilestoneId: ${ value.toString() }
                    }
                    land_id:"${ festId }"
                ) { 
                    currentMilestoneId
                }
            }`
            const apolloClient: any = useAppoloStore.getState().client 
            apolloClient.mutate({
                mutation : gql`${mutation}`
            }).then(() => AppToaster.show({
                message: __("Successfully update")
            }))
        }

    return <div className='pe-current-milestone-switcher-form-container short-container'>
       <Callout className=" mb-4">
            <div className="descr text-force-contrast opacity_5">
                {__("This utility sign current Milestone on About Festival page")}
            </div>
        </Callout>
        <div className=" mt-4 mb-2 text-uppercase ">
            { __( "Current Milestone switcher" ) }
        </div>
        <div className="pl-4">
            <FieldInput
                type={SCALAR_TYPES.EXTERNAL_TAGS} 
                vertical={VerticalType.VERTICAL}
                value={ milestones.filter((m: HasId) => festival.currentMilestoneId === m.id)[0] }  
                component={"PEFestMilestone"} 
                landId={ festId }
                placeholder="Select Milestone"
                editable  
                onChange={ value => onValueChange("currentMilestoneId", value?.id ) }
            />
        </div>
    </div>
}
export default CurrentMilestoneSwitcherForm