import Layouts, { byId, concatRouting, menu } from "@/libs/layouts"
import { LayoutIcon } from '@/libs/useful'
import { __, Widgets } from "@/libs/utilities"
import React, { Component } from "react"
// import {Style} from '@pe/useful' 
import { useMainStore } from "@/settings/zustand"
import { isUser } from "@/settings/zustand/user"
import { ID, IMenuItem, IRoutingData, URL } from "../interfaces/layouts"
import LayoutMainFooter from "../layout-app/body/LayoutMainFooter"
import ContextMenuContainer from "./ContextMenuContainer"
import OnlyLoggedInForm from "./OnlyLoggedInForm"

export interface IBasicViewProps {
  style_id?: ID  
  islogged?: boolean
  is_left?: boolean
  onChangeStyle?: (dataStyle: any ) => void
  variables?: any
  icon?: string
  title?: string
  description? : string
  html?: string
  panel?: any
  html_sourse?: string
  data_type?: string
  extend_params?: any
  hide_title?: boolean
  route?: string | IMenuItem
  client?: any
  help_url?: URL
  onAddRoute? : (data:any) => void 
}
class BasicState extends Component<IBasicViewProps, any> {
  static pressKeyData: boolean = false
  rp: any
  intervalRef: any
  btns: any[] = []
  constructor(props: IBasicViewProps) {
    super(props)
    // console.log(this.basic_state_data());
    const newStyle = byId(this.props.style_id || "")
    const isLeft = typeof this.props.is_left !== "undefined" ? this.props.is_left : null
		this.rp = React.createRef() 
		this.intervalRef = React.createRef()
    // console.log( newStyle );
    if ((newStyle || isLeft) && this.props.onChangeStyle) 
      this.props.onChangeStyle({ fluid: 1, style: newStyle.url, isLeft })
    this.state = {
      ...this.basic_state_data(), 
      html: "",
      route: {
        variables: this.props.variables ? this.props.variables : {},
        icon: this.props.icon ? this.props.icon : "usercor-light",
        title: this.getTitle(),
        html: this.props.html ? this.props.html : "",
        panel: this.props.panel ? this.props.panel : "",
        html_sourse: this.props.html_sourse ? this.props.html_sourse : "",
        data_type: this.props.data_type ? this.props.data_type : "",
        width: window.innerWidth
      },
      description: "",
      panelHtml: "",
      hasError: false
    } 
  }
  static getDerivedStateFromError( error: any ) {
    return {hasError: true}
  }
  componentDidMount() { 
    this.setState({
      route: this.layout(),
      panelHtml: this.myPanelHtml(this.layout()),
      html: this.myState(this.layout()),
    })
    window.addEventListener("resize", this.onResize)
    this.onResize()
    this.stateDidMount()
  }
  onResize = () => {
    this.setState({ width: window.innerWidth })
  }

  stateDidMount() {

  }   
  componentWillReceiveProps( nextProps: any ) {
    if (nextProps.html || nextProps.icon || nextProps.title || nextProps.html_sourse || nextProps.data_type) {
      const { route } = this.state
      // console.log(nextProps);
      this.setState({
        html: this.myState(nextProps),
        panelHtml: this.myPanelHtml(nextProps),
        route: {
          ...route,
          icon: nextProps.icon,
          title: nextProps.title,
          html: nextProps.html,
          html_sourse: nextProps.html_sourse,
          panel: nextProps.panel,
          data_type: nextProps.data_type,
        },
      })
    }
  }
  componentWillUnmount(): void {
    window.removeEventListener("resize", this.onResize)    
    useMainStore.setState({isOpenLogin: false})
  }

  alternateRender() {
    return null
  }

  addRender():JSX.Element| null {
    return null
  }

  beforeRender() {
    return null
  }
  getExtendParams = () =>
  {
    return this.props.extend_params
      ?
      {...Layouts().template.extend_params, ...this.props.extend_params}
      :
      Layouts().template.extend_params
  }
  render() {
    if(this.state.hasError) {
      return <div 
        className="layout-app-state overflow-hidden"   
      >
        <div 
          className="layout-state-bg"   
        />
        <div className="w-100 h-100 flex-centered">
          {__("Something went wrong")}
        </div>
      </div>
    }
    if( !!this.props.islogged && !isUser() ) {
      return <OnlyLoggedInForm />
    }
    const extendParams = this.getExtendParams()
    // console.log(this.props)
    // console.log( extendParams )
    const alt = this.alternateRender()
    if (alt) return alt
    this.beforeRender()

    // return Style.it(
    //   `
    //   .layout-state,
    //   .pe
    //   {
    //     background-color : ${extendParams?.backgroundColor};
    //     color: ${extendParams?.color};
    //     padding:0;
    //   }  
    //   .pe-border
    //   {
        
    //   }
    //   .pe-bg
    //   {
    //     background-color : ${extendParams?.backgroundColor}!important;
    //   }
    //   .pe-bg-hover
    //   {
    //     transition: background-color easy-out 200ms;
    //   }
    //   .pe-bg-hover:hover
    //   {
        
    //   }
    //   .layout-state-logo,
    //   .layout-state-title
    //   {
    //     color: ${extendParams?.color}!important;
    //   }
    //   .layout-state-bg
    //   {
    //     background-image: ${
    //       extendParams?.bgThumbnail 
    //         ? 
    //         extendParams?.bgThumbnail.indexOf("http") === 0
    //           ?
    //           `url(${extendParams?.bgThumbnail})`
    //           :
    //           extendParams?.bgThumbnail 
    //         : 
    //         null
    //     };
    //     filter: ${
    //       extendParams?.bgBlur 
    //           ? 
    //           `blur(${extendParams?.bgBlur}px)` 
    //           : 
    //           null   
    //     };
    //     opacity:${extendParams?.bgOpacity || 1};
    //   }
    //   .menu-aside,
    //   .tutor-main-2 .clapan-left,
    //   .menu-elem,
    //   .card,
    //   .card-header,
    //   .bp3-card,
    //   .pe-card
    //   {
    //    color: ${ extendParams?.cardTextColor }!important;
    //   }
    //   .card:hover,
    //   .menu-elem:hover
    //   {
    //     background-color: ${extendParams?.cardColor}!important; 
    //   }
    //   .stroke-a-dark a, .a-dark,
    //   .stroke-a-dark:hover a, .a-dark:hover,
    //   .bp3-button.bp3-minimal,
    //   .bp3-button:not([class*="bp3-intent-"]),
    //   .bp3-tab,
    //   .dark .bp3-input,
    //   .page-item.active .page-link,
    //   .datetimer input,
    //   .text-dark,
    //   .pe-by-bg
    //   {
    //     color: ${extendParams?.color}!important;
    //   }
    //   .layout-state-description
    //   {
        
    //   }
    //   .bp3-tab[aria-selected="true"], 
    //   .bp3-tab:not([aria-disabled="true"]):hover 
    //   {
    //     color: #106ba3!important;
    //   }
    //   .bg-light
    //   {
    //     background-color: ${extendParams?.cardColor}!important;
    //   }
    //   .bp3-minimal .pe-by-bg svg,
    //   .pe-by-bg.bp3-minimal svg
    //   {
    //     fill: ${extendParams?.color};
    //   } 

    //   `,
    //   <div className="layout-state" >
    //     <div 
    //       className="layout-state-bg"   
    //     />
    //     {this.renderTitle()}
    //     {this.addRender()}
    //     {this.state.html}
    //   </div>
    // ) 
    
    return <ContextMenuContainer onHideLogin={this.onHideLogin}>      
      <div className="layout-state">
        <div 
          className="layout-state-bg"   
        />
        <div className={`layout-state-content `} >
          {this.renderTitle()}
          {this.addRender()}
          {
            this.state.html && 
            <div className="px-4">
              {this.state.html}
            </div>
          }
          
        </div>
        
        <LayoutMainFooter />
      </div>
    </ContextMenuContainer> 
  }
  onHideLogin = () => {
    useMainStore.setState({ isOpenLogin: false })
  }
  onOpenLogin = () => { 
    useMainStore.setState({ isOpenLogin: true })
  }
  renderTitle()
  {
    return (this.state.route?.icon || this.state.route?.title) && (!this.props.hide_title && !this.state.hide_title )
      ?
      <div className="layout-state-head"> 
        <LayoutIcon
          isSVG
          src={this.state.route ? this.state.route.icon : "" }
          className="layout-state-logo "
        />
        <div className="layout-state-title">
          { __(this.state.route ? this.state.route.title : "") }
        </div>
        <div className="layout-state-heaader">          
          <Widgets areaName={"layout_heaader_panel"} data={this} /> 
          {this.layouteHeader()}
        </div>
      </div>
      :
      <div className="layout-state-head p-0 m-0"> 
        <div className="layout-state-heaader">
          <Widgets areaName={"layout_heaader_panel"} data={this} />          
          {/* { initArea("layout_heaader_panel", this) } */}
        </div>
      </div>
  }
  rightPanel() {
    return this.state.panelHtml
  }

	getName = () => {
	  let name = menu().filter((e:IRoutingData) => e.route === this.state.route)
	  if (!name) name = "404"
	  return name
	}

	getIcon = () => {
	  let icon = menu().filter((e:IRoutingData) => e.icon === this.state.icon)
	  if (!icon) icon = "fas fa-user"
	  return icon
	}

	getRoute = () => this.props.route

	myPanelHtml = (route:IRoutingData) => {
	  if (route && route.panel) return <div dangerouslySetInnerHTML={{ __html: route.panel }} />
	  return this.state.panelHtml
	}

	myState = (route:IRoutingData) => {
	  if (route && route.html) return <div dangerouslySetInnerHTML={{ __html: route.html }} />
	  if (route && route.html_sourse) {
	    fetch(route.html_sourse)
	      .then((response: any) => {
	        this.setState({ html: <div dangerouslySetInnerHTML={{ __html: response }} /> })
	      })
	    return ""
	  }
	  if (route && route.data_type) {
	    return ""// this.get_data( route.data_type);
	  }
	  return ""
	}

	layout = () => {
	  const name = concatRouting()
	    .filter((e:IMenuItem) => e.route === this.getRoute())
	  return name.length > 0 ? name[0] : this.state.route
	}

	getTitle = () => {
    return this.props.title ? this.props.title : ""
	}
  layouteHeader = () => { 
    return <></>
  }

	basic_state_data() {
	  return {}
	}
}
export default BasicState 
//export { BasicExtendView }
 
