import { useEffect, useState } from 'react'
//import { isStatusFinished } from './utilities/isStatusOpened'
import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { useParams } from 'react-router'
import { actions } from '../../../data/actions'
import { GET_PROJECT_MILESTONE_ACTION } from '../../../data/actionTypes'
import { IFestival, IMilestone, IProject, IProjectMilestone } from '../../../data/interfaces'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects, useFestStore } from '../../../data/store'
import MilestoneButton from './MilestoneButton'
import MilestoneIncludeList from './MilestoneIncludeList'
import ProjectRating from '../ProjectRating'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'


interface IProjectMilestonesProps {
    item: IProject
}

const ProjectMilestones = (props: IProjectMilestonesProps):JSX.Element => 
{ 
    const params = useParams()  
    const festId: ID = params.landId || ""
    const festival:IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const milestones : IProjectMilestone[] = useFestProjects( ( state:IFestProjectsProps ) => state.currentProjectMilestones || [] ) 
    const [currentMilestoneID, setCurrentMilestoneID] = useState<ID>( -1 )
    const [update, setUpdate] = useState( true )
    const {width}   = useScreenSize()
    
    useEffect(() => {
        actions(GET_PROJECT_MILESTONE_ACTION, {id: props.item.id, land_id: festId})
            .then( response => {
                if( response ) {
                    currentMilestoneID === -1 && 
                    setCurrentMilestoneID( 
                        response.filter( (m:IMilestone) => festival.currentMilestoneId === m.id )[0]?.id || response[0]?.id || -1 
                    )
                } 
            })
    }, [ update ]) 
    
    if(!milestones) return <></>

    const milestoneList: JSX.Element[] =  (width > WINDOW_WIDTH.TABLET) || (milestones?.length < 4)
            ?
            milestones?.map( (milestone: IProjectMilestone, i:number) =>
            {  
                return <MilestoneButton
                    key={ milestone.milestone.id } 
                    item={ milestone }
                    currentMilestoneID = { currentMilestoneID }
                    setCurrentMilestoneID={ ( id : ID ) => {
                        setCurrentMilestoneID(id)
                    }}
                    isCompact={ milestones?.length > 4 }
                    i={i}
                />
            })
            :
            [
                <FieldInput
                    key="select"
                    type={SCALAR_TYPES.SELECT}
                    editable
                    onChange={ (id : ID) => {
                        setCurrentMilestoneID( id )
                    } }
                    value={currentMilestoneID}
                    values={(milestones || []).map(m => ({_id:m.id, title: m.milestone.title}))}
                    notClear
                />
            ];

    const milestone: IProjectMilestone = milestones?.filter( (milestone: IProjectMilestone) => milestone.id === currentMilestoneID )[ 0 ]

    return <div className="pb-4 forms-short">
        <div className='row milestones-cont align-items-center '>
            <div className=' pr-5 '>{__("Milestones")}: </div>
            { milestoneList }
        </div>
        <MilestoneIncludeList
            memberId={props.item.id}
            member={props.item}
            milestone={ milestone }
            isEditForms 
            onUpdate={ () => setUpdate( !update ) }
        />
        {
            // если нужны вложения, а их нет - то скрыть
            (
                !!props.item.is_verified &&
                ( 
                    !milestone?.milestone.is_attachments_required || 
                    !!milestone.attachments.length 
                )
            )
                &&
                <ProjectRating 
                    {...props} 
                    milestone={milestone} 
                />
        }
    </div>
} 

export default ProjectMilestones