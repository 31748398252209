
import { LoaderBallsLine } from '@/libs/useful'
import { Widgets } from '@/libs/utilities'
import { CARD_SORT_TYPE, CARD_TYPE } from '../../data/interfaces'
import AllProjectsCountLabel from './AllProjectsCountLabel'
import CardFilterSwitcher from './CardFilterSwitcher'
import CardTypeSwitcher from './CardTypeSwitcher'
import FiltersLabel from './FiltersLabel'
import PListUnderMenu from './PListUnderMenu'
import SearchEngine from './SearchEngine'
import VerifiedFilterEngine from './VerifiedFilterEngine'

const PListLayout = (props: any): JSX.Element => { 

    const onCardType = (cardType:CARD_TYPE) => {
        props.params.onCardType(cardType)
    }
    const onOrder = (order: CARD_SORT_TYPE) => {
        props.params.onCardSort(order) 
    }
    return <div className={ ` ${props.containerClassName} pt-3 p-0` }>
        <PListUnderMenu />
        <div className={`${ props.class_name  } `} style={ props.style }> 
            <div className="d-flex w-100">
                <CardTypeSwitcher cardType={props.params.cardType} onCardType={onCardType} />
                <div className='flex-grow-100 max-w-100 pl-0'>
                    <div className='row'>
                        <div className='col-12 d-flex align-items-center flex-wrap pl-5 pl-md-0'>
                            <CardFilterSwitcher 
                                cardSort={ props.params.cardSort } 
                                onClick={onOrder}
                            />  
                            <div className="d-flex align-items-center ">
                                <VerifiedFilterEngine />
                                <AllProjectsCountLabel fullCount={props.params.fullCount}/>
                            </div>                            
                            <div className='ml-3 mr-auto'>
                                { props.isLoading  && <LoaderBallsLine /> }
                            </div>  
                            <Widgets 
                                areaName='fest-project-list-filter-hz' 
                                data={{}} 
                                defArea={<div />} 
                            />                          
                            <SearchEngine />
                            <FiltersLabel />
                        </div>
                    </div>
                    <div className="row w-100 pl-1 pl-md-0 pr-0 pr-md-0">
                        { props.children }
                    </div>                    
                </div>
            </div>
            
        </div>  
    </div> 
}

export default PListLayout