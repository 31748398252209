import { ID } from "@/libs/interfaces/layouts"
import AvatarLarge from "@/libs/useful/AvatarLarge"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { useSyncState } from "@/modules/pe-basic-module/hooks/useSyncState"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useUser } from "@/settings/zustand/user"
import { Position, Tooltip } from "@blueprintjs/core"
import { useEffect } from "react"
import { IProject, ITrack } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IMessageFriendsProps {
    onSelect: (user:IUser) => void
}
interface IFriend extends IUser{
    label:string
}
const MessageFriends = (props: IMessageFriendsProps): JSX.Element => {
    const user = useUser()
    const landId = useMainStore((state: IState) => state.landId)
    const admins = useSyncState<IFriend[]>([])
    const moderators = useSyncState<IFriend[]>([])
    const friends = useSyncState<IFriend[]>([])
    useEffect(() => {
        actions(GET_FEED_ACTION, {
            data_type: "User",
            landId,
            paging: ` role__in:[ "administrator", "SuperAdmin" ] `
        }).then((res: any) => {
            admins.set(res.feed.map((a: IUser) => ({...a, label: `${__("Administrator")}` })))
            actions(GET_FEED_ACTION, {
                data_type: "PEFestProject",
                land_id: landId,
                paging: `relation:"OR" metas:[{key: "tutor_id", value: "${user.id}"}, {key: "leader_id", value: "${user.id}"}, {key: "member0_id", value: "${user.id}"}, {key: "member1_id", value: "${user.id}"}, {key: "member2_id", value: "${user.id}"}, {key: "member3_id", value: "${user.id}"}, {key: "member4_id", value: "${user.id}"}, {key: "member5_id", value: "${user.id}"}, {key: "member6_id", value: "${user.id}"}, {key: "member7_id", value: "${user.id}"}, {key: "member8_id", value: "${user.id}"}, {key: "member9_id", value: "${user.id}"}] `
            }).then((res:any) => {
                    let tracks: ID[]            = [] as ID[]
                    let schools: ID[]           = [] as ID[]
                    let _friends: IFriend[]   = [] as IFriend[]
                    ( res.feed ).forEach((p: IProject) => {
                        ["tutor", "leader", "member0",  "member2",  "member2",  "member3",  "member4",  "member5",  "member6",  "member7",  "member8",  "member9", ].forEach(m => {
                            if(
                                p[m].id
                                && admins.get().filter(a => a.id === p[m].id).length === 0
                                && friends.get().filter(a => a.id === p[m].id).length === 0
                            ){
                                const member = m === "tutor"
                                    ?
                                    "Tutor"
                                    :
                                    m === "leader"
                                        ?
                                        "Leader"
                                        :
                                        "Member"
                                _friends.push( {
                                     ...p[m], 
                                     label: `${__(member)} «${p.title}»` 
                                } )
                            }
                        }) 
                        if(p.track) {
                            tracks.push( p.track.id )
                        }
                    })
                    friends.set(_friends)
                    
                    if(!!tracks.length) {
                        actions(GET_FEED_ACTION, {
                            data_type: "PEFestTrack",
                            land_id: landId,
                            paging: ` in: [${tracks.join(',')}] `
                        }).then((res2:any) => {
                            console.log(res2.feed)
                            const _moderators: IFriend[] = [] as IFriend[]
                            res2.feed.forEach((track: ITrack) => {
                                if(
                                    !!track.moderator?.id
                                    && moderators.get().filter( a => a.id === track.moderator?.id ).length === 0
                                ) {
                                    _moderators.push( { ...track.moderator, label: `${__("Moderator of track")} «${ track.title }»` } )
                                
                                }
                                if(
                                    !!track.moderator2?.id
                                    && moderators.get().filter( a => a.id === track.moderator2?.id ).length === 0
                                ) {
                                    _moderators.push( { ...track.moderator2, label: `${__("Moderator of track")} «${ track.title }»` } )
                                }
                            })
                            moderators.set( _moderators )
                        })
                    }
                })
        })
        
    }, [])
    console.log( friends.get() )
    return <div className='d-flex flex-wrap w-100 t'>
        {
            admins.get().map(friend => {
                return <Tooltip 
                    key={friend.id} 
                    content={friend.label}
                    position={Position.TOP}
                >
                    <div key={friend.id} className="bg-danger hover pointer " onClick={() => props.onSelect(friend)}>
                        <AvatarLarge user={friend} className="text-light" round />
                    </div>
                </Tooltip>
            })
        }
        {
            moderators.get().map(friend => {
                return <Tooltip 
                    key={friend.id} 
                    content={friend.label}
                    position={Position.TOP}
                >
                    <div className="bg-secondary hover pointer " onClick={() => props.onSelect(friend)}>
                        <AvatarLarge user={friend} className="text-light" round />
                    </div>
                </Tooltip>
            })
        }
        {
            friends.get().map(friend => {
                return <Tooltip 
                    key={friend.id} 
                    content={friend.label}
                    position={Position.TOP}
                >
                    <div key={friend.id} className=" hover pointer " onClick={() => props.onSelect(friend)}>
                        <AvatarLarge user={friend} className="text-force-contrast" round />
                    </div>
                </Tooltip>
            })
        }
    </div>
}
export default MessageFriends