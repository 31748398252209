import { IState, IUser, useMainStore } from "@/settings/zustand";
import { isRole } from "@/settings/zustand/user";
import { useEffect, useState } from "react";
import { IProject, ITrack } from "../data/interfaces";
import { useFestMyProjects } from "../data/store";

export function useIsTrackModerator( track: ITrack | null): boolean {
    const user:IUser = useMainStore((state:IState) => state.user) 
    const [value, setValue] = useState<boolean>( false )
    
    const getTrackModers = () => {
        if( !track ) {
            setValue(false)
            return
        }
        console.log( track.moderator?.id, track.moderator2?.id, user.id, track.moderator?.id === user.id || track.moderator2?.id === user.id )
        setValue( ( isRole(["Track_moderator"]) ) && ( track?.moderator?.id === user.id || track?.moderator2?.id === user.id)  )
    }
    useEffect(() => getTrackModers(), [ track ]) 
    return value
}

export function useMyTracks(): ITrack[] {
    const [value, setValue] = useState<ITrack[]>( [] )
    function getMyneTracks() {
        const myProjects: IProject[] = useFestMyProjects.getState().myProjects  
        let tracks: ITrack[] = []
        myProjects.forEach(p => {
            if(!tracks.filter(t => t?.id === p.track?.id).length ) {
                tracks.push( p.track )
            }
        }) 
        setValue(tracks)
    }
    useEffect(() => getMyneTracks(), [])
    return value 
}