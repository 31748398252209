import { __ } from "@/libs/utilities"
import { ITrack } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface ITrackBusinessLabelProps {
   item: any
}
const TrackBusinessLabel = (props: ITrackBusinessLabelProps): JSX.Element => {
    return !!props.item.is_buisness
        ?
        <div className="business-case-label">
            <span>{ __( "Task from entrepreneur include" ) }</span>
        </div>
        :
        <></>
}
export default TrackBusinessLabel