import React, { Fragment, useState } from 'react' 
import { IExhortation, IProject } from '../../data/interfaces'
import { IFestProjectsProps, useFestProjects } from '../../data/store' 
import { __, clearHTML } from '@/libs/utilities' 
import { isRole } from '@/settings/zustand/user'
import { actions } from '../../data/actions' 
import { SEND_EXHORTATION_ACTION } from '../../data/actionTypes'
import Moment from 'react-moment'
import { useParams } from 'react-router'
import ProjectExhortionBanForm from './ProjectExhortionBanForm'
import { AppToaster } from '@/libs/useful'
import { getModeratorRoles } from '../../data/utils/getModeratorRoles'
import { ID } from '@/libs/interfaces/layouts'

const ExpertDescriptions = () :JSX.Element => { 
    return <div className='container mt-5'>
        <div className='row'> 
            <div className="col-12">
                <div className="lead-md mb-3">
                    {__("Expert's parting words for the authors of Project")}
                </div>
            </div>  
            <div className="col-12 px-0">
                <div className="w-100   ">
                    <Quotes />
                </div>
            </div> 
        </div> 
        {
            isRole( [...getModeratorRoles(), "Expert"] )
                &&
                <>
                    <div className="row pt-4 ">
                        <div className="col-md-12">
                            <div className="lead-md">
                                { __( "Your parting words for the authors of Project" ) }
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <TextArea />
                    </div> 
                </>
        }
        
    </div>
} 

export default ExpertDescriptions

export const Quotes = () : JSX.Element[] | JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    const exhortations: IExhortation[] = item.exhortations
    let noDoubleds: ID[] = []
    return !!exhortations?.length 
        ?
        exhortations 
        .map((exhortation: IExhortation, index: number ) => {
            if( noDoubleds.includes(exhortation.id) ) {
                return <Fragment key={index} />
            }
            noDoubleds.push(exhortation.id)
            const classes = index === 0 
                ? 
                "col-md-12 p-md-3 p-1 light-colored p-2" 
                :
                "col-md-12 p-md-3 p-1 grey2 p-2"
            return <div className={ classes } key={ `exp_descr_${ item.id }_${ index }` }>
                <blockquote>
                    <div className="fs-4 d-flex justify-content-center">
                        <i className="fas fa-quote-left fa-2x mr-4 mb-md-5 text-secondary opacity_5 d-md-block" />
                        <div className="flex-grow-100 lh-base d-flex">
                            { exhortation.descr }
                        </div>
                        <i className="fas fa-quote-right fa-2x ml-4 mt-md-5 text-secondary opacity_5 d-md-block d-none" />
                    </div>
                    <div className="mt-0 d-flex flex-md-row flex-column justify-content-between align-items-md-end align-items-center ">
                        <div className="pe-surface2">
                            <ProjectExhortionBanForm 
                                item={exhortation} 
                            />
                        </div>
                        <div className="d-flex align-items-center iconic">
                            <div 
                                className="user-ava mr-3" 
                                style={{ backgroundImage: `url(${exhortation.author.avatar})`, backgroundColor: "grey"}}
                            />
                            <div>
                                <div>{ exhortation.author.display_name }</div>
                                <Moment locale="ru" format="D MMMM YYYY, HH:mm" className='small mt-2'>
                                    {new Date( Number( exhortation.unixtime ) * 1000  )}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </blockquote>
            </div>
        })
        :
        <div className="col-md-12 critery_cell3 grey2 first_row pt-3">
            {__("No descriptions")}
        </div>
}


export interface ITextAreaProps {
    onSend?: () => void
}
export const TextArea = ( props:ITextAreaProps ) : JSX.Element => {
    const {landId} = useParams()  
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 
    const [text, setText] = useState<string>("")
    const send = () => {
        if(!text) {
            AppToaster.show({
                message: "Need write text",
                intent: "danger"
            })
            return
        }
        const exhortation: any = {
            memberId: item.id, 
            descr: clearHTML( text ),
            landId
        } 
        actions(SEND_EXHORTATION_ACTION, exhortation )
            .then(() => {
                setText("") 
                if(props.onSend) {
                    props.onSend()
                }
            })
    }
    return <>
        <textarea
            className="col-md-6 col-sm-12 input dark form-control"
            rows={ 6 }
            placeholder={ __("Start write") }
            style={{ marginBottom: 10, padding: 20, height:140 }}
            value={ text }
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                setText( evt.currentTarget.value )
            }}
        />
        <div className="col-md-6 col-sm-12">
            <div className="btn btn-lg btn-primary" onClick={send}>
                {__("Send descriptions")}
            </div>
            <div className="mt-4">
                <small>
                    <p>Вы можете оставить текстовые комментарии и рекомендации для проектной команды.</p>
                    <p>Участникам Фестиваля важно получить обратную связь по вопросам:</p>
                    <ul>
                        <li>Какие задачи для развития замысла могут быть рекомендованы?</li>
                        <li>Какие задачи развития Вы рекомендуете для самих авторов — чему важно дальше учиться?</li>
                    </ul>
                </small>
            </div>
        </div>
    </>
}  