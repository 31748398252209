import { ClipboardCopyBtn } from "@/libs/useful"
import { IMedia } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { DIALOG_SIZE, IPost } from "@/libs/interfaces/layouts"
import { Button, Intent, Tag } from "@blueprintjs/core"
import Moment from "react-moment"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IMediaElementProps {
    item: IMedia
    next: () => void
    prev: () => void
    remove: (item: IMedia, isForce:boolean) => void
}
const MediaElement = ({item, ...props}: IMediaElementProps): JSX.Element => {
    const [force, setForce] = useState<boolean>(false)
    return item?.id
    ?
    <div className='pe-media-element-container'>
        <div className="pe-media-element-cont">
            <img src={ item.large.toString() } alt="" className="media-element" />
        </div> 
        <div className="pe-media-element-descr d-flex flex-column">
            <div className="row my-0 py-0 border-bottom border-secondary bg-secondary-light hidden">
                <Button icon="chevron-left"  className="py-3 px-4" minimal/>
                <Button icon="chevron-right" className="py-3 px-4" minimal />
            </div>
            <div className="row my-3 py-3 border-bottom border-secondary">
                <div className="col-md-5 col-12 font-weight-bold">
                    { __("Title") }
                </div>
                <div className="col-md-7 col-12">
                    { item.title }
                </div> 
            </div> 
            <div className="row my-3 py-3 border-bottom border-secondary">
                <div className="col-md-5 col-12 font-weight-bold">
                    { __("Date creation") }
                </div>
                <div className="col-md-7 col-12">
                    <Tag minimal round className="px-3">
                        <Moment locale="ru" format="D MMM YYYY, HH:mm" className='small'>
                        { item.post_date } 
                        </Moment> 
                    </Tag>
                </div> 
            </div> 
            <div className="row my-3 py-3 border-bottom border-secondary">
                <div className="col-md-5 col-12 font-weight-bold">
                    {
                        __("Link to file")
                    }
                </div>
                <div className="col-md-7 col-12">
                    <input 
                        type="text" 
                        className="form-control input dark mb-1" 
                        value={ item.large?.toString() }
                        readOnly
                    />
                    <ClipboardCopyBtn
                        data={ item.large.toString() }
                        label={__("Copy link to clipboard")}
                        type="button"
                        fill
                    />
                </div>
            </div>
            <div className="row my-3 py-3 border-bottom border-secondary">
                <div className="col-md-5 col-12 font-weight-bold">
                    { __("Mime type") }
                </div>
                <div className="col-md-7 col-12">
                    { item.mime }
                </div> 
            </div> 
            <div className="row my-3 py-3 border-bottom border-secondary">
                <div className="col-md-5 col-12 font-weight-bold">
                    { __( "Anchelors" ) }
                </div>
                <div className="col-md-7 col-12">
                    { item.ancestors?.map(( post:IPost ) => {
                        return <div className="mb-3" key={post.id}>
                            { post.title }
                        </div>
                    }) }
                </div> 
            </div> 
            <div className="row mt-auto py-3">
                <div className="col-12">
                    <ConfirmButton
                        onConfirm={ () => props.remove( item, force ) }
                        buttonLabel={__("Delete Media")}
                        buttonIntent={Intent.DANGER}
                        dialogClasssName={DIALOG_SIZE.MINIMAL}
                        buttonLarge
                    >
                        <div className="p-4">
                            <div className="lead-md">
                                {__("Realy remove this Media?")}
                            </div>
                            <div className="pt-4">
                                <label className="_check_">
                                    <input type="checkbox" onChange={(evt:any) => setForce(evt.target.checked )} checked={ force }/>
                                    {__("Force and finnaly remove")}
                                </label>
                            </div>
                        </div>
                    </ConfirmButton> 
                </div> 
            </div>
        </div>
    </div>
    :
    <></>
}
export default MediaElement