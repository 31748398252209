import React, { useEffect, useState } from 'react'
import { __ } from "../../../../libs/utilities"
import { AppToaster, LoaderLine } from "../../../../libs/useful"
import actions from '../../data/actions'
import { RESTORE_FINISH_PASSWORD_ACCESS_ACTION, RESTORE_FINISH_PASSWORD_ACTION, } from '../../data/actionTypes'
import { Params, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, Intent } from '@blueprintjs/core'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { login } from '@/libs/layouts'

export enum FINISH_RESTORE_STATUSES {
    START,
    NOT_FOUND,
    SUCCESS,
    NOT_SUCCESS
}

const FinishRestorePasswordForm = (props: any):React.ReactElement => {  
    const [isSuccess, setSuccess] = useState( FINISH_RESTORE_STATUSES.START )
    const [newPassword, setNewPassword] = useState( '' )
    const params: Readonly<Params<string>> = useParams<string>()
    const navigate = useNavigate()
    useEffect(() => { 
        actions( RESTORE_FINISH_PASSWORD_ACTION, params )
            .then(res => {
                console.log( res )
                setSuccess( res )
            })
    }, [ ]) 
    const onChangePassword = () => {
        actions(
            RESTORE_FINISH_PASSWORD_ACCESS_ACTION, 
            {
                id: params.id,
                code: params.code,
                password: newPassword
            }
        )
        .then((res) => {
            if(!!res) {
                navigate(login())
                AppToaster.show({
                    message: __("Password update successfully. Log in now."),
                    intent: Intent.SUCCESS
                })
            }
            else {
                setSuccess( FINISH_RESTORE_STATUSES.NOT_SUCCESS )
                AppToaster.show({
                    message: __("Unknown error."),
                    intent: Intent.DANGER
                })
            }
        })
    }

    const start = () : React.ReactElement => {
        return <div className="row text-center justify-content-center"> 
            <Card elevation={3} interactive className="p-5 lead">
                {__("Finish restore password")}
                <LoaderLine />
            </Card>
        </div> 
    }
    const notFound = () : React.ReactElement => {
        return <div className="row text-center justify-content-center"> 
            <Card elevation={3} interactive className="p-5 lead">
                {__("Searched data not found")}
                <div className="col-12 mt-4">
                    <Link
                        className="btn btn-danger btn-sm"
                        to="/"
                    >
                        {__("Return to main page")}
                    </Link>
                </div>
            </Card> 
        </div> 
    }

    const success = () : React.ReactElement => {
        return <div className="row text-center justify-content-center">
            <Card elevation={3} interactive className="p-5 lead">
                <div className="col-12 lead">
                    {__("Insert new password")}
                </div>
                <div className="col-md-12 mt-4">
                    <input 
                        type="password" 
                        value={ newPassword } 
                        onChange={ evt => setNewPassword( evt.target.value ) } 
                        className="form-control input dark" 
                    />
                </div>
                <div className="col-12 my-4">
                    <div
                        className="btn btn-danger btn-sm mr-4"
                        onClick={ onChangePassword }
                    >
                        {__("Save new password")}
                    </div>
                    <Link
                        className="btn btn-danger btn-sm"
                        to="/"
                    >
                        {__("or return to main page")}
                    </Link>
                </div>
            </Card> 
        </div>
    }

    const notSuccess = () : React.ReactElement => {
        return <div className="row text-center"> 
            <Card elevation={3} interactive className="p-5 lead">
                <div className="col-12 lead">
                    {__("Email address not verified. Repeat the procedure.")}
                </div>
                <div className="col-12 my-4">
                    <Link
                        className="btn btn-danger btn-sm"
                        to="/"
                    >
                        {__("Return to main page")}
                    </Link>
                </div>
            </Card> 
        </div>
    }

    switch( isSuccess )
    {
        case FINISH_RESTORE_STATUSES.SUCCESS:
            return success()
        case FINISH_RESTORE_STATUSES.NOT_SUCCESS:
            return notSuccess()
        case FINISH_RESTORE_STATUSES.NOT_FOUND:
            return notFound()
        default:
        case FINISH_RESTORE_STATUSES.START:
            return start()
    }
    
}

export default FinishRestorePasswordForm