import config from "@/settings/config"
import TelegramLoginButton from "./TelegramLoginButton"

/* Protopia Ecosystem component */
export interface ITelegramLoginProps {
    callback: (response: any) => void
}
const TelegramLogin = (props: ITelegramLoginProps): JSX.Element => {
    const tgLogin = config()[ "tg-login" ] 
    return tgLogin
        ?
        <div className="soc telegram overflow-hidden pointer hover" >
            <TelegramLoginButton
                botName={tgLogin}
                dataOnauth={(user: any) => console.log( user)}
                dataAuthUrl="/tg-login" 
                className=" opacity_01 "
            >
                <i className="fab fa-telegram-plane-0" />
            </TelegramLoginButton>
            <i className="fab fa-telegram-plane untouchble d-none22" />
        </div> 
        :
        <></>
}
export default TelegramLogin