import IScalarForm, { SCALAR_TYPES } from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar"
import { Button, ButtonGroup, Intent } from "@blueprintjs/core" 
import { __ } from "@/libs/utilities"
import FieldInput from "@/libs/scalars"
import attachmentTypes from "../data/attachmentTypes"

/* Protopia Ecosystem component */
export interface IAttachmentDataInputWidgetProps {
   type: string
   data: any
   onChange: ( data: any, type: "data" | "type", ) => void
}
const AttachmentDataInputWidget = ({data, type, ...props}: IAttachmentDataInputWidgetProps): JSX.Element => {
    const  onChange=(value: any ) => { 
        props.onChange( value.data, value.type )
    } 
    return <Scalar 
        editable
        value={{ data, type }}
        title={ "Data" }
        required
        onClear={() => props.onChange({ data: "", type: SCALAR_TYPES.STRING }, "data" )}
        enabledForm={<Enabled value={{ data, type: type || SCALAR_TYPES.STRING }}  onChange={onChange}/>} 
    />
}
export default AttachmentDataInputWidget

const Enabled = (props: IScalarForm): JSX.Element => {
    return <div className="mt-3 mb-5 w-100 px-4">
        <div className="d-flex align-items-center mb-3">
            <ButtonGroup >
                {
                    Object.keys(attachmentTypes()).map(( type: string ) => <Button 
                        key={ type } 
                        minimal={ props.value.type !== type } 
                        intent={  props.value.type !== type ? Intent.NONE : Intent.SUCCESS}
                        className="min-height-20"
                        onClick={() => {
                            if( props.onChange ) { 
                                props.onChange({ data: "", type: type }, "", "")
                            }
                        }}
                    >
                        <span className="small">
                            { __( attachmentTypes()[ type ].title ) }
                        </span>
                    </Button>)
                }
            </ButtonGroup>
        </div> 
        <SwitchDataForm 
            value={props.value.data}
            type={props.value.type}
            onChange={(value: any) => props.onChange!({data: value, type: props.value.type }, "", "")}
        />
    </div>
}

const SwitchDataForm = (props:any): JSX.Element => {
    switch(props.type) {
        case SCALAR_TYPES.URL:
        case SCALAR_TYPES.STRING:
            return <FieldInput
                editable
                value={props.value}
                onChange={props.onChange}
                type={SCALAR_TYPES.STRING}
                notClear
            />
        case SCALAR_TYPES.VK_VIDEO_ID:
        case SCALAR_TYPES.RUTUBE_ID:
        default:
            return <FieldInput
                editable
                value={props.value}
                onChange={props.onChange}
                type={props.type}
                notClear
            />
    }
}