
/* Protopia Ecosystem component */
export interface IEventCarouselItemProps {
    item: any
    name: string
}
const EventCarouselItem = (props: IEventCarouselItemProps): JSX.Element => { 
    return <div className='pe-event-carousel-item-container p-4'>
       {props?.item?.component}
    </div>
}
export default EventCarouselItem