import BasicExtendView from "@/libs/basic-view/BasicExtendView"
import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies"
import { Navigate, Outlet, Route, Routes } from "react-router"
import NoMatchForm from "./no-mathc/NoMatchForm"
import PrivateForm from "./profile/PrivateForm"
import ProfileForm from "./profile/ProfileForm"
import ProfileMenu from "./profile/ProfileMenu"

class ProfileView extends BasicExtendView { 
  props: any
  basic_state_data() { 
    return {			
      isLeftClosed: false,
      isHeadClosed: true,
      hide_title: true
    }
  }  
  left = ():JSX.Element => {
    return <ProfileMenu />
  }
  center = ():JSX.Element => {
    return <Routes>
      <Route path={`/*`} element={<Outlet />} >
        <Route index element={ <Navigate to="public" replace /> } />
        <Route path={`public`} element={<ProfileForm />} /> 
        <Route path={`private`} element={<PrivateForm />} /> 
        <Route path='*' element={<NoMatchForm {...DEFAULT_404_PROPS} />}  /> 
      </Route>
    </Routes>
  }

   
}
export default ProfileView
