import { KeyboardEventHandler, useState } from "react" 
import { HTML, ID, IPost } from "../interfaces/layouts" 
import { __ } from "../utilities"
import { Button, Icon, Popover, Position, Tooltip } from "@blueprintjs/core"
import EmojiButton from "./comments/EmojiButton";
import { EmojiClickData } from "emoji-picker-react";
import actions from "@/modules/pe-basic-module/data/actions";
import { GET_SINGLE_ACTIONS, UPDATE_COMMENT_ACTION } from "@/modules/pe-basic-module/data/actionTypes";
import { IState, useMainStore } from "@/settings/zustand";
import { useDataStore } from "@/modules/pe-basic-module/data/store";
import { AppToaster } from "./AppToaster";
import TextareaAutosize from "react-textarea-autosize"; 
import FieldInput, { SCALAR_TYPES } from "../scalars";
import MediaChooser from "./MediaChooser";

interface ICommentInputProps {
    className: string
    item: IPost
    parentID?: ID | string
    discussion_type?: "post" | "taxonomy"
    title?: string
    buttonLabel? : string
    onSend?: (comment: HTML) => void
    onBeforeSend?: (comment: HTML) => void
    onAfterSend?:  (comment: HTML) => void
    footer? : JSX.Element
    noTitle?: boolean
    autoFocus?:boolean
}
const CommentInputForm = ({ ...props }: ICommentInputProps): JSX.Element => {
    const landId = useMainStore( (state: IState) => state.landId )
    const [comment, setComment] = useState<HTML>("")
    const [thumbnail, setThumbnail] = useState<string>("")
    const [thumbnailName, setThumbnailName] = useState<string>("")
    const onComment = (value: HTML) => {
        setComment(value)
    }
    const onEnterPress = (e: any ) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            onSend()
        }
    }
    const onSend = () => {
        if(!comment) {
            AppToaster.show({
                message: __("Write something")
            })
            return
        }
        setComment("")
        setThumbnail("")
        setThumbnailName("")
        actions(UPDATE_COMMENT_ACTION , {
            landId,
            item: {
                content: comment,
                discussion_id: props.item.id,
                discussion_type: props.discussion_type,
                parent: 0,
                thumbnail,
                thumbnail_name: thumbnailName
            }    
        })
        .then((res) => {
            actions(GET_SINGLE_ACTIONS, {
                land_id: landId,
                data_type: props.item.__typename,
                id: props.item.id
            })
            .then((res: IPost) => {
                const feed: IPost[] = useDataStore.getState()[props.item.__typename || "Post"]
                useDataStore.setState({
                    [props.item.__typename || "Post"] : feed.map(f => {
                        return f.id === props.item.id ? res : f
                    })
                })
                if(props.onBeforeSend) {
                    props.onBeforeSend(comment)
                }
                if( props.onSend) {
                    props.onSend(comment)
                }
                else {
                    console.log("send Comment") 
                }  
                if(props.onAfterSend) {
                    props.onAfterSend(comment)
                }   
            }) 
        }) 
    }
    const onEmoji = (emojiData: EmojiClickData) => {
        setComment((inputValue) => inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji))
    }
    const onThumbnail = (value: string, field: string, file: any) => {
        setThumbnail(value)
        setThumbnailName(file.name)
    }
    const onMediaChange = (fileData: string, file: any ) => {
        onThumbnail( fileData, "" , file )
    }
    return <div className={`pe-comment-input-form-container ${props.className} `}>
        { 
            !props.noTitle && 
            <div className="pe-comment-form-title lead my-2 d-flex align-items-center">
                <Icon icon="edit" size={22} className="mr-3"/>
                {
                    __(props.title ? props.title : "Do comment")
                }
            </div>
        }
        <div className="d-flex align-items-center position-relative">
            <TextareaAutosize 
                value={comment}
                rows={1}
                onChange={(evt:React.ChangeEvent<HTMLTextAreaElement> ) => {
                    onComment( evt.currentTarget.value )
                }}
                onKeyDown={onEnterPress}
                className="ml-4 form-control input dark flex-grow-100"
                placeholder={__("Start write")}
                autoFocus={props.autoFocus}
            />
            <div className="position-absolute top left">
                <Popover
                    interactionKind="click"
                    position={Position.TOP}
                    content={
                        <div className="px-0 d-flex">
                            <MediaChooser
                                prefix={`thumbnail_${ props.item.id}`}
                                url={thumbnail}
                                id={ props.item.id } 
                                padding={0}
                                height={ 120 }
                                width={ 120 }
                                tab={ "ld" }
                                hideLib 
                                accept={ "image" } 
                                isURLHide 
                                isFileNameHide 
                                onChange={ onMediaChange } 
                                limitSize={ 2000000} 
                                isHideClearBtn 
                            />
                        </div> 
                    }
                >
                    <Button minimal>
                        <i className="fas fa-image"></i> 
                    </Button>   
                </Popover>
            </div> 
            <EmojiButton onChoose={onEmoji}/>
            <Tooltip
                content={<div className="p-2">{__( props.buttonLabel ? props.buttonLabel : "Do comment")}</div>}
                position="top"

            >
                <Button minimal onClick={onSend} >
                    <i className="fas fa-play"></i> 
                </Button>   
            </Tooltip> 
        </div>
        <div className="d-flex justify-content-end w-100">
            
            {props.footer}
        </div>
    </div> 
}

CommentInputForm.propTypes = {}

export default CommentInputForm