import { ID } from "@/libs/interfaces/layouts"
import { getQueryArgs } from "src/libs/layouts"
 
export const GET_PROJECT_DIARY = ( id:ID ) => {
    return ` getPEDiarys (
        paging: {
            metas: {
                key: "project",
                value: "${id}"
            }
        }) 
        {
            ${ getQueryArgs("PEDiary") }
        }
    }`
        
} 