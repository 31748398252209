import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, wordTrim } from "@/libs/utilities"
import { actions } from "@/modules/pe-fest-module/data/actions"
import { UDATE_PROJECT_RATING_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import { IFestival, IMilestone, IRating } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { getModeratorRoles } from "@/modules/pe-fest-module/data/utils/getModeratorRoles"
import updateLocalRating from "@/modules/pe-fest-module/data/utils/updateLocalRating"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { Button, Collapse, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"

/* Protopia Ecosystem component */
export interface ICorrectSingleRatingFormProps {
    items: IRating[]
    milestone?: IMilestone
    label?: string
}
const CorrectSingleRatingForm = (props: ICorrectSingleRatingFormProps): JSX.Element => {
    return isRole(getModeratorRoles())
        ?
        <CorrectRatingEngine items={props.items} milestone={props.milestone} label={props.label} />
        :
        <></>
}
export default CorrectSingleRatingForm

export const CorrectRatingEngine = (props: ICorrectSingleRatingFormProps): JSX.Element => { 
    const user: IUser = useMainStore((state: IState) => state.user)
    const {landId} = useParams()  
    const [select, setSelect] = useState<IRating>()
    const [items, setItems] = useState<IRating[]>(props.items) 
    
    const onConfirm = () => { 
        updateLocalRating(select!, props.milestone )
        actions( UDATE_PROJECT_RATING_ACTION, { id: select!.id, item: select, landId } ) 
    }
    const onSelect = ( value: any ) => {
        setSelect( items.filter( r => r.id === value )[0] )
    }
    const onSelectChange = (value: any, field: keyof IRating ) => { 
        const sel = { ...select, [field]: value, correct_user_id: user.id, correct_display_name: user.display_name, }
        setItems([
            ...items.map(item => ({
                ...item,
                [field] : item.id === select!.id ? value : item[field],
                correct_user_id: user.id,
                correct_display_name: user.display_name,
            }))
        ]) 
        setSelect( sel as IRating) 
    }
    return !! items.length && isRole(["SuperAdmin"])
        ?
        <div className="pe-surface5">
            <ConfirmButton
                onConfirm={onConfirm}
                yesLabel={__("Correct single rating")}
                noLabel={__("Close")}
                buttonLabel={<div className="small">{__(props.label || "Correct this rating?")}</div>}
                buttonClassName="opacity_75 mx-1 "
                buttonMinimal
            >
                <div className="p-4">
                    <FieldInput
                        value={select}
                        editable
                        vertical={ VerticalType.VERTICAL }
                        title={ __( "Select rating for correct" ) } 
                        onChange={onSelect}
                        type={ SCALAR_TYPES.TAGS }
                        values = {
                            items
                            .filter( r => !!r.rating )
                            .map( r => {
                                return {
                                    _id: r.id,
                                    title: `${r.rating} ${r.display_name} "${wordTrim(r.description,5)}" `,
                                    intent: r.correct_user_id ? Intent.DANGER: Intent.NONE
                                }
                            } )
                        }
                    />
                    <SingleCorrect item={select} milestone={props.milestone} onChange={onSelectChange}/>
                </div>
            </ConfirmButton>
        </div>
        :
        <></>
}

export const SingleCorrect = (props: any) => {
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival)
    const [correct_rating, setCorrect_rating] = useState<number| undefined>( props.item?.correct_rating || 0 )
    const [correct_description, setCorrect_description] = useState<string|undefined>( props.item?.correct_description )

    useEffect(() => {
        setCorrect_rating( props.item?.correct_rating || 0 )
        setCorrect_description( props.item?.correct_description )
    }, [props.item])

    const onRating = (value: number) => {
        setCorrect_rating(value)
        props.onChange(value, "correct_rating")
    }
    
    const onDescription = (value: string) => {
        setCorrect_description(value)
        props.onChange(value, "correct_description")
    }
    const onRemoveCorrect = () => {
        setCorrect_rating(0)
        setCorrect_description(undefined)
        props.onChange(undefined, "correct_description")
        props.onChange(-1, "correct_rating")
    }

    return <Collapse isOpen={ !!props.item && props.item?.id !== "-1" } > 
        
        <FieldInput
            type={SCALAR_TYPES.SLIDER}
            editable
            value={correct_rating}
            min={0}
            stepSize={1}
            labelStepSize={1}
            max={festival.maxRating || 3}
            onChange={ onRating }
            title={__("Set correct rating")}
        />
        <FieldInput
            type={SCALAR_TYPES.HTML}
            editable
            value={correct_description} 
            onChange={onDescription}
            title={__("Set reason")}
        />
        <Button 
            minimal={ (typeof correct_rating === "undefined" || correct_rating === null ) }
            large
            onClick={onRemoveCorrect}
        >
            {__("Remove correct rating")}
        </Button>
    </Collapse>
}