import { title } from "@/libs/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { AppToaster, Select } from "@/libs/useful"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { __, compareArrays } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import { IFestival, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "@/modules/pe-fest-module/data/store"
import { team } from "@/modules/pe-fest-module/data/utils/team"
import { useProjectLeader } from "@/modules/pe-fest-module/hooks/project"
import { IUser } from "@/settings/zustand"
import { isRole, useUser } from "@/settings/zustand/user"
import { Button, Intent } from "@blueprintjs/core"
import { Fragment, useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IProjectTeamProps {
   
}
const ProjectTeam = (props: IProjectTeamProps): JSX.Element => {
    const user = useUser()
    const [project, setProject] = useState<IProject>( useFestProjects.getState().currentProject )
    const [cur, setCur] = useState<string>( JSON.stringify( useFestProjects.getState().currentProject ) )
    const [prj, setPrj] = useState<string>( JSON.stringify( useFestProjects.getState().currentProject ) )
    const [isLoading, setIsLoading] = useState(false)   
    const landId = useFestDataStore((state: IFestDataStor) => state.festId ) 
    const festival:IFestival = useFestDataStore((state: IFestDataStor) => state.festival ) 
    const isOwner: boolean = useProjectLeader(project, landId)
    useEffect(() => useFestProjects.subscribe(
        (state: IFestProjectsProps) => {
            setProject(state.currentProject)
            setCur( JSON.stringify( state.currentProject ) )
        }
    ), [])
    const onValue = ( value: any, field: keyof IProject ) => {
        if( ["tutor", "leader"].includes( field.toString() ) && project[field]?.id === user.id ) {
            AppToaster.show({
                message:__("You can't remove yourself")
            })
            return
        }
        const newPr = {
            ...project,
            [field]: value
        }
        setProject( newPr )
        setPrj( JSON.stringify(newPr) )
    }
    const isSync = () => {
        return cur === prj
    }
    const onUpdate = () => {
        setIsLoading(true)
        const data = {
            data_type : "PEFestProject",
            id: project.id,
            item: {
                tutor: project.tutor?.id     || "-1",
                leader: project.leader?.id   || "-1",
                member0: project.member0?.id || "-1",
                member1: project.member1?.id || "-1",
                member2: project.member2?.id || "-1",
                member3: project.member3?.id || "-1",
                member4: project.member4?.id || "-1",
                member5: project.member5?.id || "-1",
                member6: project.member6?.id || "-1",
                member7: project.member7?.id || "-1",
                member8: project.member8?.id || "-1",
                member9: project.member9?.id || "-1",
            },
            landId: landId
        }
        actions(UPDATE_SINGLE_ACTION, data)
            .then(
                (response) => {
                    console.log( response )
                    setIsLoading(false)     
                    AppToaster.show({
                        message: __("Success update")
                    })
                } 
            )
            .catch(() => {
                setIsLoading(false)   
                AppToaster.show({
                    message: __("Unknown error. Try later"),
                    intent: Intent.DANGER
                })
            })
    }
    return <div className="pe-project-team-container minimal-colored my-3 pb-5 pt-3">
        <div className={`short-container ${isLoading ? "untouchble opacity_5" : ""}`}>
            {
                team().map((t, i) => {
                    if(i > (typeof festival.projectMembersCount === "undefined" ? 10 : festival.projectMembersCount) + 1) {
                        return <Fragment key={t.id}></Fragment>
                    }
                    return isOwner
                        ?
                        <div className="row dat strob01 " key={t.id}>
                            <div className="col-md-3 layout-label">
                                { __( t.id === "member0" ? "Team" : t.name ) }
                            </div>
                            <div className="col-md-9 layout-data"> 
                                <UserSearchEngine 
                                    landId={ landId }
                                    role={ Array.isArray( t.role ) ? t.role : [ t.role ]  }
                                    user={ project[t.id] as IUser }
                                    onSelect={( value ) => onValue( value, t.id ) }
                                    isMulti={false}
                                    onlyThisLands
                                /> 
                            </div> 
                        </div>
                        :
                        <FieldInput
                            key={t.id}
                            type={SCALAR_TYPES.EXTERNAL}
                            component="User"
                            editable={false}
                            vertical={VerticalType.HORIZONTAL}
                            value={ project[t.id] } 
                            title={ __( t.name ) || " " } 
                            landId={ landId }
                        /> 
                })
            }
            {
                isOwner && <div className="w-100 flex-centered mb-5">
                    <Button 
                        intent={isSync() ? Intent.NONE : Intent.DANGER}
                        minimal={ isSync() } 
                        disabled={ isSync() } 
                        onClick={onUpdate}
                        loading={isLoading}
                    >
                        {__("Update")}
                    </Button>
                </div> 
            }
        </div>
    </div>
}
export default ProjectTeam

