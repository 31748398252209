import React, { useState } from 'react' 
import { __ } from '@/libs/utilities'
import { IDomain, IFestival, ITrack } from '../../data/interfaces' 
import FestTrackCard from './FestTrackCard' 
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'
import { useParams } from 'react-router'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'

const Tracks = (props : any) : JSX.Element | false => { 
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    const tracks: ITrack[]      = useDataStore((state: any) => state.PEFestTrack || [] )
    const [domains, setDomains]   = useState<IDomain[]>([])
    
    const onFilter = ( domains: IDomain[] ) => {
        setDomains( domains )
    }
    return !!festival.isShowTracks && !!tracks.length && <section id="tracks">
        <div className="container">
            <div className="row justify-content-center py-4 px-md-5 px-2">
                <div className="col-md-12 d-flex justify-content-center">
                    <div className="role_descr">
                        {__( "Tracks on Festival" )}
                    </div>
                </div>
            </div>
            <TrackDomainFilter domains={ domains} onChange={ onFilter } />
            <TrackList domains={ domains} />
        </div>
    </section>
} 

export default Tracks

interface ITrackListProps {
    domains: IDomain[]
}
const TrackList = (props:ITrackListProps) : JSX.Element => {
    const tracks: ITrack[]      = useDataStore((state: any) => state.PEFestTrack || [] )
    return <div className="row justify-content-center px-2">
    {
        tracks?.filter( track => {
            return !props.domains.length 
            ?
            true
            :
            !!( track.pe_domain?.length ? track.pe_domain : [] )
                .filter( td => !!props.domains.filter( d => d.id === td.id ).length ).length
        })
        .map( 
            ( track: ITrack ) => <FestTrackCard item={ track } key={ track.id } /> 
        )
    }
    </div>
}

interface ITrackDomainFilterProps {
    domains: IDomain[] 
    onChange: ( domains: IDomain[] ) => void
}
const TrackDomainFilter = (props: ITrackDomainFilterProps) : JSX.Element => { 
    const { width }   = useScreenSize()
    const { landId }  = useParams()
    return <div className="d-flex px-3">
        <FieldInput
            type={ width >= WINDOW_WIDTH.MOBILE ? SCALAR_TYPES.EXTERNAL_TAGS : SCALAR_TYPES.EXTERNAL_ARRAY }
            multiple
            landId={ landId}
            editable
            component="PEDomain"
            value={props.domains}
            onChange={props.onChange}
            seed={"-track-filter"}
            elementClassName="small py-0 px-2"
            elementClassName2="small"
            placeholder="Select Domains"
            notClear
        />
    </div>
}