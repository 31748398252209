import { IPost } from "@/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import { useDataStore } from "../../data/store"
import Comment from "./Comment"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"

/* Protopia Ecosystem component */
export interface ICommentFeedProps {
   item: IPost
   dataType?: string
}
const CommentFeed = (props: ICommentFeedProps): JSX.Element => {
    const feed: IPost[] = useDataStore((state:any) => state[ props.dataType || props.item.__typename || "Post"])
    const land_id = useMainStore((state:IState) => state.landId)
    const [item, setItem] = useState<IPost>()
    useEffect(() => {
        const item = feed.filter(f => f.id === props.item.id)[0]
        if(item) {
            setItem( item )
        }
        else {
            actions(GET_SINGLE_ACTIONS,{
                data_type: props.dataType ? props.dataType : props.item.__typename,
                land_id,
                id: props.item.id
            })
            .then((res: IPost) => {
                setItem(res)
            })
        }
        
    }, [feed])
    useEffect(() => {
        setItem( props.item )
    }, [props.item])

    return <div className='pe-comment-feed-container'>
        {
            item?.comments?.map(c => {
                return <Comment item={c} key={c.id} />
            })
        }
    </div>
}
export default CommentFeed