import { Button, Dialog, Icon } from "@blueprintjs/core" 
import DialogRight from "./DialogRight"
import { IState, useMainStore } from "src/settings/zustand"
import { IMedia } from "@/libs/interfaces/layouts"

export interface IGallertDialog {
    isOpen: boolean
    data: IMedia[]
    i: number
    setI: (i:number) => void
    setIsOpen: (isOpen: boolean) => void

}
export const GalleryDialog = ({ isOpen, data, i, setI, setIsOpen }: IGallertDialog) : JSX.Element => { 
    const isGlleryFullSize = useMainStore((state: IState) => state.isGlleryFullSize) 
    const item: IMedia = Array.isArray( data ) 
        ?
        data[i]
        :
        {} as IMedia
    return <>
        <Dialog
            lazy={ false } 
            isOpen={ isOpen } 
            portalClassName="overflow-hidden"
            className="full flex-centered transparent flex-row"
        >
            <div className="prev ">
                <Button  
                    onClick={() => setI( i > 0 ? i-1 : data.length - 1 )} 
                    className="bg-dark-secondary hover-straght" 
                >
                    <Icon icon="chevron-left" size={ 33 } color="#FFFFFF" />
                </Button>
            </div>    
            <div className=" position-relative gallery-dialog">
                <img 
                    src={ item?.thumbnail } 
                    alt=""  
                    className={ `gallery-dialog-thumbnail ${ isGlleryFullSize ? "full-size" : "" }` } 
                />
                {/* <div className="gallery-thumbnail-container">
                    <div className="title">
                        {item.title}
                    </div>
                    <div className="post-content">
                        {item.post_content}
                    </div>
                </div> */}
            </div>
            <DialogRight 
                data={ data } 
                i={ i } 
                setI={ setI } 
                setIsOpen={ setIsOpen }
            />
        </Dialog>
    </>
} 

export default GalleryDialog