import Shortcode from '@/libs/useful/shortcode'
import { __ } from '@/libs/utilities'
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'

const Reglament = () : JSX.Element => {
    const festival: IFestival = useFestDataStore( (state: IFestDataStor) => state.festival )
    const file = festival.reglament || ""
    const firstWord = file.split(" ")[0]

    return <div className='short-container'>
        <div className='display-6 mb-5 mt-4 p-md-0 p-3 text-md-lef text-center'>
            { festival.reglamentTitle || __( "Rules and Reglament" ) }
        </div>
        <div className='row mb-5'> 
            <div className='col-md-12'> 
            {
                firstWord.indexOf("http") >= 0
                    ?
                    <>
                        <a href={ file } target="_blank" rel="noreferrer" download >{ __("Source") }</a>
                        <iframe src={ file } title="reglament" className='w-100'  style={{ minHeight: 1200, overflowX: "hidden" }}>

                        </iframe>
                    </>
                    :
                    <div className='p-md-0 p-3'>
                        <Shortcode content={ file } />
                    </div> 
            }
                
            </div>
        </div>
    
    </div>
} 
export default Reglament