import useClickOutside from "@/modules/pe-fest-module/widgets/hooks/useClickOutside"
import { Tag } from "@blueprintjs/core"
import { CSSProperties, useEffect, useRef, useState } from "react"

/* Protopia Ecosystem component */
export interface INumberSetterProps {
   onChange: (count:number) => void
   count: number
   className?: string
   style?: CSSProperties
   onClick?: () => void
}
const NumberSetter = (props: INumberSetterProps): JSX.Element => {
    const [isEnabled, setIsEnabled] = useState<boolean>(true)
    
    return <div>
        {
            !isEnabled
                ?
                <NumberSettingEnabled 
                    {...props} 
                    onClick={() => setIsEnabled(true)} 
                    onChange={ props.onChange }
                />
                :
                <NumberSettingDisabled {...props} onClick={() => setIsEnabled(false)} />
        }
    </div>
}
export default NumberSetter


const NumberSettingDisabled = (props: INumberSetterProps): JSX.Element => {
    return <Tag 
        minimal 
        className={`pointer`} 
        style={{ ...props.style, textAlign: "center" }} 
        onClick={props.onClick}
    >
       <div className="lead p-1">{ props.count || 0 }</div> 
    </Tag>
}

const NumberSettingEnabled = (props: INumberSetterProps): JSX.Element => {
    const myRef: React.MutableRefObject<null> = useRef( null )
    //useClickOutside( myRef, props.onClick! )

    const [count, setCount] = useState<number>(props.count)
    useEffect(() => {
        setCount( props.count )
    }, [props.count])
    const onCount = ( e: any ) => {
        const cn = parseInt(e.currentTarget.value)
        setCount(cn)
        props.onChange( cn ) 
    }
    return <div className="d-flex flex-column align-items-center"  ref={ myRef }>
        <input 
            type="number" 
            className="lead w-100" 
            value={ count } 
            onChange={ onCount }
            autoFocus 
            onFocus={ e => e.currentTarget.select() }
        />
    </div>
}

