import { useEffect, useState } from "react";
import useScreenSize from "./useScreenSize";

export default function useElementBound ( element: HTMLElement | null ): DOMRect  {
    const [bound, setBound] = useState<DOMRect | undefined>() 
    const {width}   = useScreenSize()
    useEffect(() => {
        setBound(element?.getBoundingClientRect())
    }, [element, width])
    return bound || {} as DOMRect
}