import { useState } from 'react';

/*
    get asyncronically change state result
*/
//https://dev.to/bytebodger/synchronous-state-with-react-hooks-1k4f
/*
    const SomeComponent = () => {
        const counter = useSyncState(0);
        const increment = () => {
            console.log('counter =', counter.get()); // 0
            const newValue = counter.set(counter.get() + 1);
            console.log('newValue =', newValue); // 1
            console.log('counter =', counter.get()); // 1
        }
        return (
            <>
            Counter: {counter.get()}
            <br/>
            <button onClick={increment}>Increment</button>
            </>
        );
        return (<>UX Here...</>);
    }

  
*/
interface IsyncState<T> {
    get:() => T, 
    set:(newState: T) => void
}
export function useSyncState<T>(initialState: T): IsyncState<T>{
    const [state, updateState] = useState(initialState)
    let current = state
    const get = (): T => current
    const set = (newState: T) => {
        current = newState
        updateState(newState)
        return current
    }
    return {get, set}
} 