import { IUser } from "@/settings/zustand"

/* Protopia Ecosystem component */
export interface IAvatarLargeProps {
   user: IUser
   selected?:IUser 
   className?: string
   round?: boolean
   onClick?: (evt: any) => void
}
const AvatarLarge = ({user, selected, className, round, onClick}: IAvatarLargeProps): JSX.Element => {
    const onC = (evt: any) => {
        if(onClick) onClick( evt )
    }
    return <div className={`d-flex flex-column p-1 pe-w-100 ${ className ? className : ""}`} onClick={ onC }>
        <div 
            className={`avatar-large border1 ${selected?.id === user.id ? "border-danger" : "border-transparent"} ${ round ? "rounded-circle" : "" }`} 
            style={{backgroundImage: `url(${user?.avatar})`}} 
        />
        <small className="small text-overflow">{user?.display_name}</small>
    </div>
}
export default AvatarLarge