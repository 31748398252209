import { Intent } from '@blueprintjs/core';
import React from 'react';
//import styles from './vk.scss';
import { AppToaster } from "@/libs/useful";
import { __, sprintf } from '@/libs/utilities';
import {  vk_api_key, vk_id_version } from '@/settings/config';
import { IUser } from '@/settings/zustand';
import actions from '@/modules/pe-basic-module/data/actions';
import { EXTERNAL_SIGN_IN_ESCAPE_ACTION, SEND_LOG_ACTION } from '@/modules/pe-basic-module/data/actionTypes'; 

class VKLogin extends React.Component {
  props:any
  state = {
    isSdkLoaded: false,
    isProcessing: false,
    isDisbled: false,
    src: "",
    trying:0,
  };

  componentDidMount() {
    // console.log( vk_id_version() )
    if (document.getElementById('vk-jssdk')) {
      this.sdkLoaded();
      return;
    }
    this.setAsyncInit();
    this.loadSdkAsynchronously();
  }

  setAsyncInit() { 
    window.vkAsyncInit = () => 
	  {
      try{
        if( vk_api_key() === "NONE") {
          throw new Error('Vk API not connected by owner. Contact administrator');
        }
        window.VK.init({ apiId: vk_api_key() })
        this.setState({ isSdkLoaded: true, isDisbled: false });
      }
      catch(e:any) {
        // console.error(e.message)
        AppToaster.show({
          intent: Intent.DANGER,
          message: "VK API not loaded: " + e.message
        })
      }
    };
  }

  sdkLoaded() {
    this.setState({ isSdkLoaded: true });
  }

  loadSdkAsynchronously() 
  {
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.src = 'https://vk.com/js/api/openapi.js?139';
    el.async = true;
    el.id = 'vk-jssdk';
    document.getElementsByTagName('head')[0].appendChild(el);
  }

	checkLoginState = (response: any) => 
	{
    actions(SEND_LOG_ACTION, {
      input: {response, userAgent: navigator.userAgent },
      log_type: "try_vk_sign_in",
      land_id: null,
    })
		// console.log( response );
		this.setState({ isProcessing: false });
		// console.log( response.session === null);
    if( !!response &&  (response.session === null || ["unknown", "not_authorized"].includes( response.status )) )
    { 
      actions(EXTERNAL_SIGN_IN_ESCAPE_ACTION, {external: "vk", response: JSON.stringify(response), label: "auth"})
      if(this.state.trying < 1) {
        window.VK.Auth.login( this.checkLoginState )
        this.setState({trying: this.state.trying + 1})
      }
      else {
        actions(SEND_LOG_ACTION, { 
          input: {response, userAgent: navigator.userAgent },
          log_type: "error_vk_sign_in",
          land_id: null,
        } )
        AppToaster.show({
          intent: Intent.DANGER,
          icon: "error", 
          message: sprintf(__("Sign in escape. Status — %s"), response?.status || __( "No response" )),
        })
      }
      return
    }
    else { 
      actions(SEND_LOG_ACTION, { 
        input: {response, userAgent: navigator.userAgent },
        log_type: "vk_sign_in",
        land_id: null,
      } )
      AppToaster.show({
        intent: Intent.NONE,
        icon: "endorsed", 
        message: sprintf( __("Sign in success. Status — %s"), __(response.status) ),
      })
    }
    // 
		window.VK.Api.call(
			"users.get",
			{
        uid: response.session.user.id, 
        fields:"photo_100,contacts,email,screen_name", 
        v: vk_id_version() !== "NONE" ? vk_id_version() : "5.199"
      },
			(r: any) => {
        if(r.error) {
          AppToaster.show({
            message: "VK API error:"+  r.error?.error_msg,
            intent: Intent.DANGER,
            timeout: 10000
          })
          actions(EXTERNAL_SIGN_IN_ESCAPE_ACTION, {external: "vk", response: JSON.stringify(response), label: "users.get"})
          return
        }
				if (this.props.callback) 
				{
          // console.log( r )
					// r.response[0].href = response.session.user.href; 
          fetch( r.response[0].photo_100 )
            .then(response => response.blob())
            .then(blob => {
              const file = new Blob([blob], { type: 'image/jpg' });
              const fileUrl = URL.createObjectURL(file);
              const user: IUser = {
                id: r.response[0].id,
                display_name: r.response[0].screen_name === `id${r.response[0].id}`
                  ?
                  `${r.response[0].first_name} ${r.response[0].last_name}`
                  : 
                  `${r.response[0].screen_name}`,
                roles: [],
                email: r.response[0].email
                  ?
                  r.response[0].email
                  :
                  `${ r.response[0].id }@vk.com`,
                phone: r.response[0].home_phone,
                avatar: r.response[0].photo_100,
                externalId: r.response[0].id,
              }
              this.props.callback( user, "vk" );		
            });
          return
				}
			});
	};

	click = () => 
	{
		if (!this.state.isSdkLoaded || this.state.isProcessing || this.props.disabled) 
		{
			return;
		}
		this.setState({ isProcessing: true });
		window.VK.Auth.login( this.checkLoginState, ( 4194304 ) )//4194304 - email 
	};

  style() 
  {
    return //<style dangerouslySetInnerHTML={{ __html: styles }}/>;
  }

  containerStyle() 
  {
    const style: any = { transition: 'opacity 0.5s' };
    if (this.state.isProcessing || !this.state.isSdkLoaded || this.props.disabled) {
      style.opacity = 0.6;
    }
    return Object.assign(style, this.props.containerStyle);
  }

  render() {
    const { disabled, callback, apiId, ...buttonProps } = this.props;
    const className = this.state.isDisbled &&  !vk_api_key() ? ' bg-secondary untouchble opacity_25' : ''
    return <div
          {...buttonProps}
          className={`soc vk ${this.props.className} ${className}`}
          onClick={this.click}
          value={this.props.value || "VK" }
        >
      <i className="fab fa-vk" /> 
    </div> 
  }
}

export default VKLogin;

declare global {
  interface Window {
      VK?: any
      vkAsyncInit: () => void
  }
}