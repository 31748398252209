import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { useParams } from "react-router"
import DiaryCard from "./DiaryCard"
import { useEffect, useState } from "react"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { IDiary } from "@/modules/pe-fest-module/data/interfaces"
import { Callout, Intent } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface IDiaryCardFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean   
}
const DiaryCardForm = (props: IDiaryCardFormProps): JSX.Element => {
    const params = useParams()
    const id: ID = params.id || "-1" 
    const landId: ID = params.landId || "-1" 
    const [item, setItem] = useState<IDiary>({} as IDiary)
    const [isLoad, setIsLoad] = useState<boolean>(false)
    useEffect(() => {
        actions(GET_SINGLE_ACTIONS ,{ 
            data_type:"PEDiary",
            land_id: landId, 
            id
        }).then((res: IDiary) => {
            console.log( res )
            setItem(res)
            setIsLoad(true)
        })
    })
    if( !isLoad) {
        return <></>
    }
    if(!item) {
        return <Callout className="p-5" intent={Intent.DANGER}>
            {__("Element not exists")}
        </Callout>
    }
    return <div className='small-container pt-4'>
        <DiaryCard item={item} />
    </div>
}
export default DiaryCardForm