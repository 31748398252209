import React, { useState } from 'react' 
import FieldInput, {SCALAR_TYPES} from '@/libs/scalars'
import Moment from 'react-moment'
import { __ } from '@/libs/utilities'
import { Button, ButtonGroup, EditableText, Intent, Tag } from '@blueprintjs/core'
import JSONRowElement from './JSONRowElement'
import { getVisibleValue, schema } from 'src/libs/layouts'
import DataTableCellFooter from './DataTableCellFooter'
import chroma from "chroma-js"
import ThreadWidget from '../../widgets/ThreadWidget'
import { useParams } from 'react-router'
import { DIALOG_SIZE, ID } from '@/libs/interfaces/layouts'
import { UPDATE_SINGLE_TYPE } from '@/modules/pe-basic-module/data/actionTypes'
import actions from '@/modules/pe-basic-module/data/actions'
import NumberSetter from '@/libs/useful/NumberSetter' 
import { ScalableDialog } from '@/libs/useful'

export interface IDataTableCellProps {
    thread: string
    item: any
    type: string
    apollo_fields: any
    data_type:string 
    className?: string
    default?: any
}
const DataTableCell = (props: IDataTableCellProps) : JSX.Element => { 
    // console.log( props.thread, props.apollo_fields )
    return <td key={props.thread} data-thread={props.thread} className={`p-2 ${ props.className }`}>
        <DataTypeCellType 
            item={props.item}
            itemCell={props.item[ props.thread ]} 
            thread={props.thread} 
            tread_widget={props.item.tread_widget }
            type={ props.type }
            data_type={ props.data_type }
            apollo_fields={ props.apollo_fields }
        /> 
        <DataTableCellFooter 
            itemCell={ props.item[ props.thread ] } 
            item={ props.item }
            thread={ props.thread } 
            type={ props.type }
            data_type={props.data_type}
        />
    </td>
} 
export default DataTableCell

export const DataTypeCellType = (props: any) : JSX.Element => {
    const params = useParams() 
    const landId : ID = params.landId || ""
    const [isOpen, setOpen] = useState(false)
    const [title, setTitle] = useState<string>(props.itemCell)
    const [isLoaded, setIsLoaded] = useState(false)
    let onChange
    if(!!props.apollo_fields.tread_widget) {
        return <ThreadWidget widget={ props.apollo_fields.tread_widget } {...props}  value={props.itemCell}/>
    }
    switch( props.type ) {
        case SCALAR_TYPES.RADIO:
            return props.apollo_fields.values
                ? 
                props.apollo_fields.values.filter((e: any) => ( 
                    e._id
                        ? 
                        e._id === props.itemCell
                        : 
                        e === props.itemCell
                ))
                    .map(
                        ( ee: any ) => (
                            ee._id
                                ? 
                                __( ee.title )
                                : 
                                __( ee )
                        )
                    )[0]
                : 
                props.itemCell 
        case SCALAR_TYPES.BOOLEAN:
            return <div className={`text-center data-${ props.thread }`} style={{ ...props.style }}>
                <i className={props.itemCell ? "fas fa-chevron-down text-success" : "fas fa-times text-danger"} />
            </div>
        case SCALAR_TYPES.ID:
            return <div
                className={`px-1 data-${ props.thread }`}
                title={ props.itemCell }
                style={{
                    maxWidth: 100,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}
            >
                { props.itemCell }
            </div>
        case SCALAR_TYPES.DATE:
            const txt = props.itemCell
                ?
                <Moment locale="ru" format="D MMMM YYYY" className="small text-nowrap px-3">
                    {new Date(props.itemCell * 1000)}
                </Moment>                    
                : 
                <small className="descr">
                    <div className="small text-nowrap px-3">
                        {__( "Date not defined" )}
                    </div>
                </small>
            return <div className={` data-${ props.thread } `}>{txt}</div>
        case SCALAR_TYPES.RGB:
        case SCALAR_TYPES.COLOR:
            return <div 
                className={` data-${ props.thread } `}
                style={{
                    width: 17,
                    height: 17,
                    backgroundColor: props.itemCell,
                    border: "1px solid #00000020",
                    outline: "1px solid #00000020",
                    outlineOffset: 2,
                    ...props.style,
                }}
            />
        case SCALAR_TYPES.GEO:
            const geo = Array.isArray(props.itemCell) ? props.itemCell : []
            return <div className={`small opacity_5 data-${props.thread}`} style={{ ...props.style }}>
                <div>{ geo[0] }</div>
                <div>{ geo[1] }</div>
            </div>
        case SCALAR_TYPES.MEDIA:
            onChange = ( value: string, file: any, name: string = "" ) => {
                setIsLoaded(true)
                actions(UPDATE_SINGLE_TYPE, { 
                    data_type: props.data_type, 
                    id: props.item.id, 
                    item: {
                        [props.thread] : value,
                        [props.thread + "_name"]: file.name
                    }, 
                    landId
                })
                    .then(
                        response => {
                            setIsLoaded(false)
                        }
                    )
            }
            return <FieldInput 
                type={ SCALAR_TYPES.MEDIA } 
                value={props.itemCell}
                onChange={ onChange }
                className={isLoaded ? "opacity_5" : ""}
            />
        case SCALAR_TYPES.JSON:
            const json = !!props.itemCell ? JSON.stringify(JSON.parse( props.itemCell ), null, 2) : ""
            return <JSONRowElement
                style={props.style}
                json={json}
                col={props.itemCel}
            />
        case SCALAR_TYPES.EXTERNAL:
            const componentSchemaName = schema()[ props.data_type ].apollo_fields[ props.thread ].component  
            const visibled_value = getVisibleValue( componentSchemaName )
            const vv = visibled_value || SCALAR_TYPES.TITLE
            // console.log( props.apollo_fields.default, props.apollo_fields, )
            return props.itemCell 
                ? 
                <Tag className="small" minimal>{props.itemCell[vv]}</Tag>
                : 
                !!props.apollo_fields.default
                    ?
                    <small>{props.apollo_fields.default.content}</small>
                    :
                    <small>Unknown type { props.data_type }</small>
        case SCALAR_TYPES.ARRAY:
            // console.log( props.itemCell )
            if(!Array.isArray(props.itemCell)) return <>No array data</>            
            if(props.apollo_fields.component === SCALAR_TYPES.STRING)
            {
                return props.itemCell.map((c: string) => <Tag className="mr-1 mb-1" minimal key={c}>
                    { c }
                </Tag>)
            } 
            if(props.apollo_fields.component === SCALAR_TYPES.ARRAY)
            {
                const color_stretch = props.apollo_fields.color_stretch
                    ?
                    props.apollo_fields.color_stretch
                    :
                    ["#498957","#2A4858", 15]
                const stretch: string[] = chroma.scale( [color_stretch[0], color_stretch[1]] )
                    .mode('lch')
                    .colors(color_stretch[2]) 
                return <div className=' my-1'>
                {
                    props.itemCell.map((c: any[], i: number) => {
                        
                        const backgroundColor = stretch[ i % color_stretch[ 2 ] ]
                        const color = chroma(backgroundColor).luminance() < 0.4
                            ?
                            "text-light"
                            :
                            "text-force-black"
                        return <div className='d-flex flex-wrap mb-1' key={i}>
                        {
                            c?.map( (cc: string, kk:number) => {
                                return <Tag 
                                    round 
                                    className={`px-3 mr-1 mb-1 pointer ${color}`} 
                                    style={{ backgroundColor, maxWidth: 170 }}
                                    title={ (cc || "").toString() }
                                    minimal 
                                    key={kk}
                                >
                                    <span className='small'>{ cc }</span>
                                </Tag>
                            })
                        }
                        </div>
                    }) 
                }
                </div>
            } 
            const visibled_value2 = getVisibleValue( props.apollo_fields.component )
            const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
            return props.itemCell
                .filter((e: any, i: number) => !!e )
                    .map((e: any, i: number) => {
                        return <Tag className="mr-1 mb-1" minimal key={i}>
                            {e[vv2]}
                        </Tag>
                    })
        // case SCALAR_TYPES.YOUTUBE_ID: 
        //     return <div 
        //         className={` data-${ props.thread } `}
        //         style={{ ...props.style }}
                
        //     >
        //         <div 
        //             style={{
        //                 width: 170,
        //                 height: 80,
        //                 backgroundSize: "cover",
        //                 backgroundPosition: "center",
        //                 backgroundImage: `url(https://img.youtube.com/vi/${ props.itemCell }/0.jpg)`,
        //                 cursor:"pointer",

        //             }}
        //             className="d-flex align-items-end"
        //             onClick={() => props.itemCell ? setOpen(!isOpen) : null}
        //         >
        //             <div className="small bg-light p-1 bg-dark text-light" dangerouslySetInnerHTML={{ __html: props.itemCell }} ></div>
        //         </div>
                
        //         <Dialog
        //             isOpen={ isOpen }
        //             onClose={ () => setOpen(!isOpen) }
        //         >
        //             <iframe 
        //                 width="800" 
        //                 height="360" 
        //                 src={`https://www.youtube.com/embed/${ props.itemCell }`} 
        //                 title="YouTube video player"
        //                 allowFullScreen
        //             />
        //         </Dialog>
        //     </div>
        case SCALAR_TYPES.NUMBER:
            onChange = ( value: number ) => {
                setIsLoaded(true)
                actions(UPDATE_SINGLE_TYPE, { 
                    data_type: props.data_type, 
                    id: props.item.id, 
                    item: {
                        [props.thread] : value 
                    }, 
                    landId
                })
                    .then(
                        response => {
                            setIsLoaded(false)
                        }
                    )
            }
            return <NumberSetter 
                count={ props.itemCell || 0 }
                onChange={ onChange }
                className={` data-${ props.thread } `}
            />
        case SCALAR_TYPES.LINK:
        case SCALAR_TYPES.PHONE:
        case SCALAR_TYPES.EMAIL:
        case SCALAR_TYPES.ADDRESS:
        case SCALAR_TYPES.SLIDER:
        case SCALAR_TYPES.INT:
        case SCALAR_TYPES.HTML:
        case SCALAR_TYPES.TEXT:
        case SCALAR_TYPES.STRING:
            
            const onTitleChange = (value: string) => {
                setTitle(value)
            }

            const onTitleUpdate = () => {
               // console.log( props.item[ props.thread ], props.item, props.thread, title ) 
               actions(
                    UPDATE_SINGLE_TYPE, 
                    {
                        item: {
                            [ props.thread ]: title
                        },
                        data_type: props.item.__typename,
                        id: props.item.id,
                        landId
                    }
                )
               setOpen( false )
            }
            return props.thread === "title"
                ? 
                <>
                    <h4 
                        className={` data-${ props.thread } `}
                        style={{ ...props.style }} 
                        onBlur={() => {
                            if(title !== props.itemCell) {
                                setOpen(true)
                            }
                        }}
                    >
                        <EditableText 
                            minLines={1}
                            multiline
                            className='overflow-hidden'
                            value={title}
                            onChange={onTitleChange}
                        />
                    </h4>
                    <ScalableDialog
                        dialogSize={DIALOG_SIZE.MINIMAL}
                        isOpen={isOpen}
                        onClose={() => setOpen(false)}
                    >
                        <div>
                            <div className="p-5">
                                { __( "Really change?" ) }
                            </div> 
                            <ButtonGroup fill large className='d-flex'>
                                <Button intent={Intent.DANGER} icon="cross" onClick={() => setOpen(false)}>

                                </Button>
                                <Button fill intent={Intent.SUCCESS} onClick={ onTitleUpdate }>
                                    {__("Change")}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </ScalableDialog>
                </>
                :
                <div 
                    className={` data-${ props.thread } `}
                    style={{ ...props.style, overflow: "hidden" }}
                    dangerouslySetInnerHTML={{ __html: props.itemCell }} 
                /> 
        default:
            return <FieldInput 
                type={props.type} 
                value={props.itemCell}
            />
    }
}