
import matrix from "./matrix.json"
export const getQRDefaultData = () => {
    let def: any = {};
    const m: any = {...matrix}
    Object.keys(m).forEach( (field:string) =>
        {
            def[field] = ( m[field] as any).default  
        }) 
    return def
}