import { LAYOUT_INIT_METHODS } from "@/libs/layout-app/LayoutBody"
import { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"

export const options = () : any => {
    return {
        name: {
            title: __("Domain title"),
            type: SCALAR_TYPES.STRING,
            commentary: __("")
        },
        description: {
            title: __("Domain description"),
            type: SCALAR_TYPES.HTML,
            commentary: __("")
        },
        web_client_url: {
            title: __("Web client URL"),
            type: SCALAR_TYPES.STRING,
            commentary: __("")
        },
        adress: {
            title: __("Pudlic geo adress"),
            type: SCALAR_TYPES.STRING,
            commentary: __("")
        },
        user_verify_account: {
            title: __("New user must verify owl account hby email"),
            type: SCALAR_TYPES.BOOLEAN,
            commentary: __("")
        },
        thumbnail: {
            title: __("Domain thumbnail"),
            type: SCALAR_TYPES.MEDIA,
            commentary: __("")
        },
        default_img: {
            title: __("Domain default posts's thumbnail"),
            type: SCALAR_TYPES.MEDIA,
            commentary: __("")
        },
        init_method: {
            title: __("application initiation method"),
            type: SCALAR_TYPES.RADIO,
            values: [
                {
                    _id: LAYOUT_INIT_METHODS.LOCAL,
                    title: "Local init",
                    commentary: "All client settings are included in the configuration. They can only be edited after a complete rebuild at the Factory.",

                },
                {
                    _id: LAYOUT_INIT_METHODS.STATIC,
                    title: "Static init",
                    commentary: "Only the remote user authorization method is connected.",

                },
                {
                    _id: LAYOUT_INIT_METHODS.EXTENDED,
                    title: "Extended init",
                    commentary: "The browser client receives configuration from the remote server. The administrator has the ability to change settings from the administration panel",

                },
            ],
            default: LAYOUT_INIT_METHODS.LOCAL,
            commentary: __("The local method is the most optimal. In the next versions of PE only this will remain")
        },
        vk: {
            title: __("Public VK url"),
            type: SCALAR_TYPES.URL,
            commentary: __("")
        },
        youtube: {
            title: __("Public Youtube url"),
            type: SCALAR_TYPES.URL,
            commentary: __("")
        },
        android: {
            title: __("Public Google Store url"),
            type: SCALAR_TYPES.URL,
            commentary: __("")
        },
        apple: {
            title: __("Public AppStore url"),
            type: SCALAR_TYPES.URL,
            commentary: __("")
        }, 
        help_url: {
            title: __("Help source URL. By default: https://..."),
            type: SCALAR_TYPES.URL,
            commentary: __("")
        },
        email: {
            title: __("Public administrator's Email"),
            type: SCALAR_TYPES.EMAIL,
            commentary: __("")
        },
        vk_client_id: {
            title: __("VK client ID"),
            type: SCALAR_TYPES.STRING,
            commentary: __("Register VK application (https://vk.com/apps?act=manage) and put this key 'client ID'.")
        },
        vk_client_secret: {
            title: __("VK client secret"),
            type: SCALAR_TYPES.STRING,
            commentary: __("Register VK application (https://vk.com/apps?act=manage) and put this key 'Service key'.")
        },
        yandex_client_id: {
            title: __("Yandex client ID"),
            type: SCALAR_TYPES.STRING,
            commentary: __("Instruction: https://yandex.ru/dev/id/doc/ru/register-client. Create application: https://oauth.yandex.ru/client/new/id/.")
        },
        yandex_client_token: {
            title: __("Yandex client token"),
            type: SCALAR_TYPES.STRING,
            commentary: __("This is extremely critical data. Copy the key from the Yandex application registration service. But don't give it to anyone. Changing this key is unacceptable for the integrity of user data on your server.")
        },
        yandex_client_secret: {
            title: __("Yandex client secret"),
            type: SCALAR_TYPES.STRING,
            commentary: __("This is extremely critical data. Copy the key from the Yandex application registration service. But don't give it to anyone. Changing this key is unacceptable for the integrity of user data on your server.")
        },
        telegramm_client_id: {
            title: __("Telegram client ID"),
            type: SCALAR_TYPES.STRING,
            commentary: __("")
        },
        icon_media_term: {
            title: __("Icon media term ID"),
            type: SCALAR_TYPES.EXTERNAL,
            commentary: __(""),
            component: "PEFestTrack"
        },
        user_media_term: {
            title: __("User's media term ID"),
            type: SCALAR_TYPES.EXTERNAL,
            commentary: __(""),
            component: "PEFestTrack"
        },
        test_media_term : {
            title: __("Test's media term ID"),
            type: SCALAR_TYPES.EXTERNAL,
            commentary: __(""),
            component: "PEFestTrack"
        },
    }
}