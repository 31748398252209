import { HasId, IPost } from "@/libs/interfaces/layouts"
import { schema } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { IDiary } from "@/modules/pe-fest-module/data/interfaces"
import { CompoundTag } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useDataStore } from "../../data/store"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"

/* Protopia Ecosystem component */
export interface IResourceLabelProps {
   item: IDiary
}
const ResourceLabel = ({item}: IResourceLabelProps): JSX.Element => {
    const land_id = useMainStore( (state:IState) => state.landId )
    const [resource, setResource] = useState<IPost>({} as IPost)
    // useEffect(() => {
    //     setResource(
    //         useDataStore.getState()[item.resource_type].filter( (r: HasId) => {
    //             return r.id.toString() === item.resource_id.toString()
    //         })[0]
    //     )
    // }, [])
    useEffect(() => useDataStore.subscribe( 
        (state: any) => {

            // если таксономия -- игнорируем. Их всё равно придётся отрезать, потому что
            // они всё равно прописаны в зависимостях
            if( ["PEFestTrack", "PEFestSchool"].includes(item.resource_type) ) return

            let _resource = state[item.resource_type].filter( (r: HasId) => {
                return r.id.toString() === item.resource_id.toString()
            })[0]
            if( _resource ) {
                setResource( _resource )
            }
            else {
                actions(GET_SINGLE_ACTIONS, {
                    land_id,
                    data_type: item.resource_type,
                    id: item.resource_id
                })
                .then((res: any) => setResource(res))
            }
        }    
    ), [])
 
    return <div className="d-flex">
        {
            item.fmru_honeycombs?.map((h: any) => <CompoundTag 
                key={h.id}
                leftContent={
                    <span className="small">
                        {__(schema().PEFestHoneycombs.name)}
                    </span>
                }
                style={{
                    backgroundColor: schema().PEFestHoneycombs.admin_data?.fill[0],
                }}
                className="pointer rounded-0"
            >
                <span className="small">
                    {h.title}
                </span>
            </CompoundTag>)
        }
        {
            item.fmru_school?.map((h: any) => <CompoundTag 
                key={h.id}
                leftContent={
                    <span className="small">
                        {__(schema().PEFestSchool.name)}
                    </span>
                }
                style={{
                    backgroundColor: schema().PEFestSchool.admin_data?.fill[0],
                }}
                className="pointer rounded-0"
            >
                <span className="small">
                    {h.title}
                </span>
            </CompoundTag>)
        }
        {
            item.fmru_track?.map((h: any) => <CompoundTag 
                key={h.id}
                leftContent={
                    <span className="small">
                        {__(schema().PEFestTrack.name)}
                    </span>
                }
                style={{
                    backgroundColor: schema().PEFestTrack.admin_data?.fill[0],
                }}
                className="pointer rounded-0"
            >
                <span className="small">
                    {h.title}
                </span>
            </CompoundTag>)
        }
        { 
            !!resource && !["PEFestTrack", "PEFestSchool"].includes(item.resource_type) && <CompoundTag 
                leftContent={
                    <span className="small">
                        {__(schema()[item.resource_type].name)}
                    </span>
                }
                style={{
                    backgroundColor: schema()[item.resource_type].admin_data?.fill[0],
                }}
                className="pointer rounded-0"
            >
                <span className="small">
                    {resource.title}
                </span>
            </CompoundTag>
        }
    </div> 
}
export default ResourceLabel