import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { AppToaster, Loading } from "@/libs/useful"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import AdCard from "@/modules/pe-topos-module/views/ads/AdCard"
import { useEffect, useState } from "react"
import { Route, Routes, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { IFestFilters, ITrack } from "../data/interfaces"
import { IFestStore, useFestStore } from "../data/store"
import { useFestival } from "../hooks/festival"
import PEFestProjectTerm from "./projectList/PEFestProjectTerm"
import PListUnderMenu from "./projectList/PListUnderMenu"
import { IAds } from "@/modules/pe-topos-module/data/interfaces"
import { __ } from "@/libs/utilities"
import { actions } from "../data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { GET_FEED_ACTION } from "../data/actionTypes"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { useIsTrackModerator } from "../hooks/track"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { isRole } from "@/settings/zustand/user"
import AttachmentResourceInputWidget from "@/modules/pe-basic-module/widgets/AttachmentResourceInputWidget"
import { AdContent } from "@/modules/pe-topos-module/views/ads/AdCardScreen"
import { getFeedRoute, getRoute } from "@/libs/layouts"

/* Protopia Ecosystem component */
export interface IAdListFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
}
interface IParamsPaging {
    params: any
    paging: string
}
const AdListForm = (props: IAdListFormProps): JSX.Element => {
    
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    const [tick, setTick] = useState<string>( Math.random().toString() )

    const onPaging = (): IParamsPaging => {
        let params: any = {}, paging: string = ""
        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
            paging = ( ` metas:[ {key: "parent_type", value: "PEFestTrack"} , {key: "parent_id", value: "${ filters.tracks[0] }" }  ]` )
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
            paging = ( ` metas:[ {key: "parent_type", value: "PEFestSchool"} , {key: "parent_id", value: "${ filters.schools[1] }" }  ]` )
        }
        return {params, paging}
    }

    // пагинация c учетом фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters ) 
    const tracks : ITrack[] = useDataStore((state:any) => state.PEFestTrack || [])
    const [paging, setPaging] = useState<string>( onPaging().paging )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const updateURLSearchParams = () => {
        const p = onPaging()
        setPaging( p.paging )
        SetURLSearchParams( p.params )
    }
    useEffect(()=> {
        const tracks = URLSearchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
    }, [])
    useEffect(() => { 
        updateURLSearchParams()
        
    }, [ filters ])

    const isLoading = useFestival(!props.parentMode, festId) 

    if(isLoading ) return <Loading /> 

    return <div className="w-100">
        <PEFestProjectTerm />
        <div className=' container pt-3 p-0'>
            <PListUnderMenu />
            <Feed 
                offset={0}
                data_type={"Ad"} 
                land_id={ festId }
                paging={ paging } 
                class_name=' '
                containerClassName="w-100" 
                component={ AdCard }
                topPagingWidget={
                    <AddAdForm 
                        track={ tracks.filter(tr => tr.id === filters.tracks[0])[0] } 
                        onTick={ () => setTick( Math.random().toString() ) } 
                    />
                }
                params={{ 
                    col: 2,
                    tick
                }}
            />
        </div>
        <Routes>
            <Route 
                path="/modal/:adId" 
                element={
                    <AdContent 
                        item={{} as IAds}
                        data_type="Ad" 
                        i={0} 
                        parentRoute={ `/${getRoute(getFeedRoute("Ad"))}` } 
                    />
                } 
            />
        </Routes> 
    </div>
}
export default AdListForm



interface IAddAdForm {
    track:ITrack | null
    onTick:() => void
}
const AddAdForm = (props:IAddAdForm) => {
    const params = useParams()
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters ) 
    const landId: ID = params.landId || "-1" 
    const [item, setItem] = useState<IAds>({} as IAds) 
    const isModer = useIsTrackModerator( props.track )
    useEffect(() => {
        if(filters.schools.length > 1) {
            setItem({
                parent_id: Number( filters.schools[1] ), 
                parent_type: "PEFestSchool", 
            } as IAds)
        }
        if(filters.tracks[0] !== -1) {
            setItem({
                parent_id: Number( filters.tracks[0] ), 
                parent_type: "PEFestTrack", 
            } as IAds)
        }
        
    }, [filters])
    const onChange = (value: any, field: keyof IAds, file: any) => {
        if(field === "thumbnail") {
            setItem({
                ...item,
                "thumbnail": value,
                "thumbnail_name": file.name
            })
        } else {
            setItem({
                ...item,
                [field]: value
            })
        }
    }
    const onResourceChange = (parent_type: string,  parent_id: number ) => { 
        setItem({
            ...item,
            parent_id,
            parent_type
        })
    }
    const onCreate = () => {
        if(!item.post_content) {
            AppToaster.show({
                message: __("Content need")
            })
            return
        } 
        actions(
            UPDATE_SINGLE_TYPE,
            {
                landId,
                data_type: "Ad",
                item
            }
        )
        .then(
            () => {
                AppToaster.show({
                    message: __("New diary created")
                })
                actions(
                    GET_FEED_ACTION, 
                    { 
                        data_type: "Ad", 
                        land_id: landId 
                    }
                ).then( () => props.onTick() )
            }
        )
    }
    const getResourceType = () : string => {
        if(filters.tracks[0] !== -1) {
            return "PEFestTrack" 
        }
        if(filters.schools.length > 1) {
            return "PEFestSchool" 
        }
        return item.parent_type || ""
    }
    
    console.log( item )
    return ( isRole([ "administrator", "SuperAdmin" ]) || isModer )
        ?
        <ConfirmButton 
            buttonIcon="plus"
            buttonMinimal
            buttonLabel={<div className="small text-nowrap">{__("Add ad")}</div>}
            dialogTitle={__("Create new Ad")}
            yesLabel={__("Start create")}
            noLabel={__("Cancel")}
            yesLock={ !item.post_content }
            onConfirm={onCreate}
        >
            <div className="p-4 max-height overflow-y-auto">
                <FieldInput
                    editable
                    title={__("Thumbnail")}
                    type={SCALAR_TYPES.MEDIA}
                    onChange={onChange}
                    value={item.thumbnail}
                    field="thumbnail"
                    isFileNameHide
                    hideLib
                    isURLHide
                    width={200}
                    height={200}
                />
                <FieldInput
                    editable
                    title={__("Title")}
                    type={SCALAR_TYPES.TITLE}
                    onChange={onChange}
                    value={item.title}
                    field="title"
                />
                <FieldInput
                    editable
                    title={__("Content")}
                    type={SCALAR_TYPES.HTML}
                    onChange={onChange}
                    value={item.post_content}
                    field="post_content"
                />
                <div>{ Number( item.parent_id ) }</div>
                <AttachmentResourceInputWidget
                    data_type="Ad"
                    isChanged={filters.tracks[0] !== -1 }
                    onChange={onResourceChange}
                    resource_type={ getResourceType() }
                    resource_id={ Number( item.parent_id ) }
                />
            </div>
        </ConfirmButton>
        :
        <></>
}