import React, { useState } from 'react' 
import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'
import FieldInput from '@/libs/scalars' 
import { AppToaster } from '@/libs/useful' 
import { ID } from '@/libs/interfaces/layouts'
import { isRole } from '@/settings/zustand/user' 
import { VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { IHoneycombs, } from '../../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../../data/store' 
import { actions } from '../../../data/actions'
import {default as mainActions} from "@/modules/pe-basic-module/data/actions"
import { UPDATE_PROJECT_HONEYCOMBS } from '../../../data/actionTypes'
import { useParams } from 'react-router'
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from '@/modules/pe-basic-module/data/actionTypes'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { getModeratorRoles } from '@/modules/pe-fest-module/data/utils/getModeratorRoles'

interface IHoneycombsProps {
    honeycombs : IHoneycombs 
    itemId: ID
}
const CorrectHoneycombs = (props: IHoneycombsProps) :JSX.Element => {
    const [ isEdit, changeEdit ] = useState(false)  
    if( !isRole( getModeratorRoles() ) )
    {
        return <div className="lead text-md-left text-center w-100">
            <i className='fas fa-users mr-2 opacity_5' /> { props.honeycombs?.title }
        </div>
    }
       
    return <div className='d-flex flex-wrap justify-content-between w-100'>
        {
            isEdit
                ?
                <EditHoneycombsDisplay {...props} changeEdit={() => changeEdit(false)}/>
                :
                <>
                    <div className="title text-md-left text-center d-flex ">
                        <i className='fas fa-users mr-2 opacity_5' /> { props.honeycombs?.title }
                    </div>
                    <Button 
                        minimal  
                        className="text-nowrap px-4"
                        onClick={() => changeEdit( !isEdit )}
                    >
                        <span className="descr">
                            {__("Change honeycombs")}
                        </span>
                    </Button>
                </>
        }
    </div> 
}
export default CorrectHoneycombs

const EditHoneycombsDisplay = (props:any):JSX.Element =>
{
    const {landId} = useParams()
    const [id, setId] = useState(props.honeycombs.id)
    const [change, setChange] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [newTitle, setNewTitle] = useState("") 
    const allHoneyCombs: IHoneycombs[] = useDataStore((state: any) => state.PEFestHoneycombs || [] )
    const changeValue = (id: any) =>
    { 
        if(id === "new")
        {
            setOpen(true)
        }
        else
        {
            setId(id) 
            setChange(true)
        }
        
    } 
    const doInsert = () =>
    {
        if(!newTitle)
        {
            AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: __("Insert title"),
			})
            return;
        }
        mainActions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "PEFestHoneycombs",
                id: "-1", 
                item: {
                    title: newTitle,
                    content : "",
                },
                landId: landId
            }
        )
            .then(() => {
                mainActions(GET_FEED_ACTION, {data_type: "PEFestHoneycombs", land_id: landId})
                    .then(() => setOpen(false)) 
            })
    }
    const doSetHoneycombs = () =>
    { 
        mainActions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "PEFestProject",
                id: props.itemId, 
                item: {
                    fmru_honeycombs: [ parseInt( id ) ]
                },
                landId: landId
            }
        )
            .then((response) => {
                if(response) {
                    props.changeEdit(false)
                }     
            })
    }
    const all =  [
        ...allHoneyCombs.map((h: any) => ({ _id: h.id, title:h.title })),
        { _id: "new", title: __("insert new Honeycomds"), intent: Intent.PRIMARY }
    ]
    return <>
        <div>
            <FieldInput         
                vertical={VerticalType.VERTICAL}
                editable={ true }
                type={ "tags" }
                value={ id }
                values={ all }
                onChange={ changeValue }
              />
        </div>
        <ButtonGroup className='d-flex align-items-center'>
            <Button 
                icon="tick" 
                intent={Intent.SUCCESS} 
                data-hint={__("Save")} 
                className="hint hint--top"
                onClick={ doSetHoneycombs }
                minimal={!change}
                disabled={!change}
            />
            <Button 
                icon="cross" 
                intent={Intent.DANGER} 
                data-hint={__("Cancel")} 
                className="hint hint--top"
                onClick={props.changeEdit}
            />
        </ButtonGroup>
        <Dialog
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            title={__("insert new Honeycomds")}
        >
            <div className='p-0'>
                <div className='p-5'>
                    <FieldInput
                        title={__("Honeycombs title")}
                        type="string"
                        value={newTitle}
                        onChange={value => setNewTitle(value)}
                        editable
                    /> 
                </div>
                <ButtonGroup fill large>
                    <Button 
                        className='' 
                        fill 
                        intent={Intent.SUCCESS}
                        onClick={doInsert}
                    >
                        {__("insert new Honeycomds")}
                    </Button>
                    <Button 
                        className=' ' 
                        fill={false} 
                        intent={Intent.DANGER} 
                        icon="cross" 
                        onClick={() => setOpen(!isOpen)}
                    />
                </ButtonGroup>
            </div>

        </Dialog>
    </>
}