import { HasId, IAttachment, IPost } from "@/libs/interfaces/layouts"
import { schema } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { IState, useMainStore } from "@/settings/zustand"
import { CompoundTag, Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { useDataStore } from "../../data/store"

/* Protopia Ecosystem component */
export interface IResourceLabelProps {
   item: IAttachment
   onClick?: (resource_type: string, resource_id: number) => void
}
const ResourceLabel = ({item, ...props}: IResourceLabelProps): JSX.Element => {
    const attachmentFilter  = useMainStore((state: IState) => state.attachmentFilter)
    const land_id           = useMainStore( (state:IState) => state.landId )
    const [resource, setResource] = useState<IPost>({} as IPost) 
    useEffect(() => useDataStore.subscribe( 
        (state: any) => {            
            let _resource = state[item.resource_type].filter( (r: HasId) => {
                return r.id.toString() === item.resource_id.toString()
            })[0]
            // console.log( _resource )
            if( _resource?.id ) {
                setResource( _resource )
            }
            else {
                actions(GET_SINGLE_ACTIONS, {
                    land_id,
                    data_type: item.resource_type,
                    id: item.resource_id
                })
                .then((res: any) => setResource(res))
            }
        }    
    ), [ ])

    const onClick = () => {
        if(props.onClick) props.onClick( item.resource_type, item.resource_id )
    }
    const onClearFilter = () => {
        useMainStore.setState({ attachmentFilter: { } })
    }
 
    return item.resource_id 
        ?
        <div className="d-flex small">
            <CompoundTag 
                leftContent={
                    <span className="small pl-1">
                        {__(schema()[item.resource_type].name)}
                    </span>
                }
                style={{
                    backgroundColor: schema()[item.resource_type].admin_data?.fill[0],
                    minHeight: 16
                }}
                className="pointer rounded-0 p-0" 
                onClick={ onClick }
            >
                <span className="small pr-1">
                {resource.id}. {resource.title}
                </span>
            </CompoundTag>
            {
                attachmentFilter.resource_id === item.resource_id && attachmentFilter.resource_type === item.resource_type &&
                <div className="ml-2 p-0 pointer hover" onClick={onClearFilter}>
                    <Icon icon="cross" />
                </div>
            }
            
        </div> 
        : 
        <></>
}
export default ResourceLabel