import { ID, IPEMessage, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { getRoute } from "@/libs/layouts"
import { data_type_feed_route } from "@/libs/utilities/links"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useEffect, useState } from "react"
import NotificationsMobile from "./notifications/NotificationsMobile"
import NotificationsScreen from "./notifications/NotificationsScreen"

export const UPDATE_NOTIFICATION_INTERVAL = 30000
/* Protopia Ecosystem component */
export interface INotificationsWidgetProps {
   
}
const NotificationsWidget = (props: INotificationsWidgetProps): JSX.Element => {
    const landId: ID = useMainStore((state:IState) => state.landId)
    const user: IUser = useMainStore((state: IState) => state.user) 
    const [ count, setCount ] = useState<number>(0)
    const [ messageRoute, setMessageRoute ] = useState<string>( getRoute(data_type_feed_route( "PEMessage" ))) //.realRoute )
    const feed : IPEMessage[] = useDataStore((state: any) => state["PEMessage for admin label"])
    const {width} = useScreenSize()
    // console.log(landId)
    useEffect(() => {
        if( !user?.id || !landId ) return
        setMessageRoute( getRoute(data_type_feed_route( "PEMessage" )) )
        actions(GET_FEED_ACTION, {
            data_type: "PEMessage",
            paging: ` metas: [{ key: "is_viewed", value_bool: false }, {key: "adressee", value: "${user.id}"}] order:"DESC" `,
            land_id: landId,
            seed: " for admin label"
        })
    }, [ landId, user?.roles ])
    useEffect(() => { 
        setCount( feed?.length ) 
    }, [ feed ])
   
    return user?.id
        ?
        width > WINDOW_WIDTH.TABLET
            ?
            <NotificationsScreen messageRoute={messageRoute} count={count} />  
            :
            <NotificationsMobile messageRoute={messageRoute} count={count} />
        :
        <></>
}
export default NotificationsWidget