import { DIALOG_SIZE } from '@/libs/interfaces/layouts'
import FieldInput from '@/libs/scalars'
import ConfirmButton from '@/libs/useful/ConfirmButton'
import { __ } from '@/libs/utilities'
import React, { FormEvent, useState } from 'react'
import { Location, Navigate, useLocation, } from 'react-router'
import { IState, useMainStore } from 'src/settings/zustand'
import { SCALAR_TYPES } from '../../../../libs/scalars'
import { LoaderLine } from '../../../../libs/useful'
import actions from '../../data/actions'
import { UPDATE_CURRENT_USER } from "../../data/actionTypes"

const PrivateForm = (props: any):React.ReactElement => {
    const user = useMainStore( (state: IState) => state.user )
    const location: Location = useLocation()
    return user.id
        ?
        <Profile />
        :
        <Navigate to="/login" replace state={{ pathname: location.pathname }} />
} 

export default PrivateForm

const Profile = ():React.ReactElement => {
    const user = useMainStore( (state: IState) => state.user) 
    const [phone, setPhone] = useState( user.phone )
    const [password, setPassword] = useState( '' )
    const [userEmail, setUserEmail] = useState( user.email ) 
    const [isLoading, setIsLoading] = useState( false )

    const update = (evt:FormEvent) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsLoading(true)
        const userData: any = {}
        if(userEmail)   userData.user_email = userEmail 
        if(phone)       userData.phone      = phone
        if(password)    userData.password   = password 
        console.log( userData )
        actions(UPDATE_CURRENT_USER, userData)
            .then(res => 
            {
                console.log( res )
                // AppToaster.show({
                //     message: __("Profile successfully update")
                // })
            })
            .finally(() =>  setIsLoading(false))
    }
    const onClearLS = () => {
        localStorage.clear()
        window.location.reload()
    }
    return <div className={`row justify-content-center ${isLoading ? "opacity_75 untouchble" : ""} pt-5`}>
        <div className="col-md-7 text-center pl-4 pb-5 mb-5">
            <form
                onSubmit={ update }
            > 
                <FieldInput
                    title="E-mail"
                    type={SCALAR_TYPES.EMAIL}
                    field="user_email"
                    editable
                    value={userEmail}
                    onChange={ (evt: any) => setUserEmail(evt)}
                />
                <FieldInput
                    title={__('Phone')}
                    type={SCALAR_TYPES.PHONE}
                    field="phone"
                    editable
                    value={ phone }
                    onChange={ (evt: any) => setPhone(evt)}
                />
                <FieldInput
                    title={__('Change password')}
                    type={SCALAR_TYPES.PASSWORD}
                    field="password"
                    editable
                    value={ password }
                    onChange={ (evt: any) => setPassword(evt)}
                />
                <div className='row'>
                    <div className='col-md-9 offset-md-3 d-flex justify-content-between'>
                        {
                            isLoading
                                ?
                                <LoaderLine />
                                :
                                <input
                                    type="submit"
                                    className="btn btn-secondary my-3"
                                    value={__("Update")}
                                />
                        }    
                        <div className="d-flex pe-surface align-items-center">
                            <ConfirmButton
                                buttonMinimal
                                buttonLabel={
                                    <span className="small opacity_5">
                                        {__("Clear Local storage")}
                                    </span>
                                }
                                dialogClasssName={DIALOG_SIZE.MINIMAL}
                                onConfirm={onClearLS}
                            >
                                <div className="p-5 text-center lead">
                                    {__("Really you want clear Local Storage?")}
                                </div>
                            </ConfirmButton>
                        </div>
                    </div>                
                </div>
                 
            </form>
        </div>
    </div>
}