import React, { useState } from "react"
import { Button, Card, Intent } from "@blueprintjs/core" 
import { __ } from "@/libs/utilities" 
import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm" 
 
const LoginRegisterScreen = (props: any): React.ReactElement => {
    const [isFlip, changeFlip] = useState(false)
    const flip = () => {
        changeFlip(!isFlip)
    }
    const orders: number[] = props.extend_params?.flap?.position === "right"
        ?
        [ 2, 1, 0 ]
        :
        [ 0, 1, 2 ]
    return <div className="col-12">
        <div className="p-0 w-100">
            <Card 
                elevation={4}
                className="m-auto d-flex p-0" 
                style={{
                    maxWidth: 750,
                    minHeight: 200,
                    backdropFilter: "blur(6px)",
                    backgroundColor:"#EEEEEE88"
                }}
            > 
                <div 
                    className={` flap pb-md-5 pb-4 default-gradient ${ isFlip ? "flip" : "" }`}
                    style={{
                        backgroundImage: props.extend_params?.flap?.thumbnail?.indexOf("http") === 0
                            ?
                            `url(${ props.extend_params?.flap?.thumbnail})`
                            :
                            props.extend_params?.flap?.thumbnail,
                        order: orders[0],
                        width: "50%"
                    }} 
                >
                    <div className="flap-overlay">
                        <div className="flap-overlay-left" style={{transform: "translateX(-70px)"}}>
                            <div className=" display-6 title text-light text-center text-black-contour">
                                {__("Hello, Friend!")}
                            </div>
                            <div className="title m-5 text-light text-center text-black-contour">
                                {__("Enter your first name, last name, email address and create a password")}
                            </div>
                            <Button
                                className="py-3 px-5 rounded-pill"
                                intent={Intent.DANGER}
                                onClick={ flip }
                            >
                                {__( "Sign up")}
                            </Button>
                        </div>
                        <div className="flap-overlay-right" style={{transform: "translateX(70px)"}}>
                            <div className=" display-6 title text-light text-center text-black-contour">
                                {__("Welcome Back!")}
                            </div>
                            <div className="title m-5 text-light text-center text-black-contour">
                                {__("To keep connected with us please login with your personal info")}
                            </div>
                            <Button
                                className="py-3 px-5 rounded-pill"
                                intent={Intent.DANGER}
                                onClick={ flip }
                            >
                                {__( "Sign in" )}
                            </Button>
                        </div>
                        
                    </div>  
                </div> 

                <div className={`w-100 order-${ orders[ 1 ] } overflow-hidden`} >
                    <div className={`under-flip-right h-100 ${ isFlip ? "flip" : "" }`}> 
                        <RegisterForm {...props}/> 
                    </div> 
                </div> 

                <div className={`w-100 order-${ orders[ 2 ] }  d-flex flex-column flex-fill overflow-hidden`} style={{justifyContent:"stretch"}}>
                    <div className={`under-flip-left  min-height-100  ${ !isFlip ? "flip" : "" }`} style={{justifyContent:"stretch"}}>
                        <LoginForm  {...props}/>
                    </div>               
                </div>               
            </Card>
        </div> 
    </div> 
} 
export default LoginRegisterScreen