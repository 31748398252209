import React, { useState } from 'react' 
import { getVisibleValue, schema } from 'src/libs/layouts'
import { SCALAR_TYPES } from '@/libs/scalars'
import { Tag } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'
import { ScalableDialog } from '@/libs/useful'
import { DIALOG_SIZE } from '@/libs/interfaces/layouts'

const DataTableCellFooter = (props: any) :JSX.Element => {
    const apollo_fields = schema()[props.data_type].apollo_fields
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isOpen1, setIsOpen1] = useState<boolean>(false)
    const [isOpen2, setIsOpen2] = useState<boolean>(false)
    let fieldNames: any = { }
    const tags = Object.keys( apollo_fields )
        .filter( (ee: any)  => {
            return apollo_fields[ee].field_footer === props.thread 
        }) 
        .map( (ee: any) => { 
            const fName: string = apollo_fields[ee].title
            if( !fieldNames[ fName ] ) fieldNames[ fName ] = []
            fieldNames[ fName ] = ( props.item[ ee ] )  
            if([ "string", "number" ].includes( apollo_fields[ee].type )) {
                return props.item[ ee ] && <div className='d-flex flex-wrap small align-items-center mr-3 mb-2' key={ee}>
                    <div className='pr-1 small opacity_75 '>
                        { __( fName ) }
                    </div>  
                    <Tag 
                        round 
                        minimal
                        className='m-1 px-3 pointer'  
                        title={ props.item[ ee ] }
                    >
                        <div className='small text-wrap title' style={{ color: apollo_fields[ee].tag_footer_color }}>
                        { 
                            props.item[ ee ]   
                        }
                        </div>
                    </Tag>
                </div>
            }
            else if( [ "html" ].includes( apollo_fields[ee].type ) ) {
                return props.item[ ee ] && <div className='d-flex flex-wrap small align-items-center mr-3 mb-2' key={ee}>
                    <div className='pr-1 small opacity_75 '>
                        { __( fName ) }
                    </div>  
                    <Tag  
                        minimal
                        className=''  
                        title={ props.item[ ee ] }
                    >
                        <div className='small text-wrap title' style={{ color: apollo_fields[ee].tag_footer_color }}>
                        { 
                            props.item[ ee ]   
                        }
                        </div>
                    </Tag>
                </div>
            }
            else if( ee === "comments" ) {
                return <>
                    <Tag className='mr-1 mb-1 pointer hover small' onClick={() => setIsOpen(!isOpen)}>{__("Comments")} | {props.item[ ee ].length || 0}</Tag>
                    <ScalableDialog
                        isOpen={isOpen}
                        onClose={() => setIsOpen(!isOpen)}
                        dialogSize={DIALOG_SIZE.MINIMAL}
                        title={__("Comments")}
                    >
                        <div className='max-height overflow-y-auto'>
                            {
                                props.item[ ee ].map((comment: any) => <div className="p-3 row strob01" key={comment.id}>
                                    <div className="small col-4">
                                        {comment.author?.display_name}
                                    </div>
                                    <div className="small title col-8">
                                        {comment.content}
                                    </div>
                                </div>)
                            }
                        </div>
                    </ScalableDialog>
                </>
            }
            else if( ee === "bookmarks" ) {
                return <>
                    <Tag className='mr-1 mb-1 pointer hover small'  onClick={() => setIsOpen1(!isOpen1)}>{__("Bookmarks")} | {props.item[ ee ].length || 0}</Tag>
                    <ScalableDialog
                        isOpen={isOpen1}
                        onClose={() => setIsOpen1(!isOpen1)}
                        dialogSize={DIALOG_SIZE.MINIMAL}
                        title={__("Bookmarks")}
                    >
                        <div className='max-height overflow-y-auto'>
                            <div className="p-4">
                            {
                                props.item[ ee ].map((comment: any) => <Tag className="mr-1 mb-1" key={comment.id}>
                                    {comment.display_name}
                                </Tag>)
                            }
                            </div> 
                        </div>
                    </ScalableDialog>
                </>
            }
            else if( ee === "likes" ) {
                return <>
                    <Tag className='mr-1 mb-1 pointer hover small' onClick={() => setIsOpen2(!isOpen2)}>{__("Likes")} | {props.item[ ee ].length || 0}</Tag>
                    <ScalableDialog
                        isOpen={isOpen2}
                        onClose={() => setIsOpen2(!isOpen2)}
                        dialogSize={DIALOG_SIZE.MINIMAL}
                        title={__("Likes")}
                    >
                        <div className='max-height overflow-y-auto'>
                            <div className="p-4">
                            {
                                props.item[ ee ].map((comment: any) => <Tag className="mr-1 mb-1" key={comment.id}>
                                    {comment.user?.display_name}
                                </Tag>)
                            }
                            </div> 
                        </div>
                    </ScalableDialog>
                </>
            }
            else if( [ "radio" ].includes( apollo_fields[ee].type ) ) {
                return props.item[ ee ] && <div className='d-flex flex-wrap small align-items-center mr-3 mb-2' key={ee}>
                    <div className='pr-1 small opacity_75 '>
                        { __( fName ) }
                    </div>  
                    <div title={ props.item[ ee ] }
                        className='small text-wrap title px-3 py-1 rounded-pill-2' 
                        style={{ 
                            backgroundColor: apollo_fields[ee].tag_footer_color,
                            color:"#FFF"
                        }}                            
                    >
                        { props.item[ ee ] }
                    </div> 
                </div>
            }
            else {
                return <CellFooterTags
                    key={ee}
                    fName={fName} 
                    list={ 
                        Array.isArray( props.item[ ee ] ) 
                            ? 
                            props.item[ ee ] 
                            : 
                            [ 
                                props.item[ ee ] && 
                                props.item[ ee ][ getVisibleValue( apollo_fields[ee].component ) ] 
                            ] 
                    } 
                    bg={ apollo_fields[ee].tag_footer_color }
                    component={ apollo_fields[ee].component } 
                />
            } 
        })
    
    return <div  className=' mt-2 '> 
        { tags }
    </div> 
} 

export default DataTableCellFooter

const CellFooterTags = (props:any): JSX.Element | null => { 
    // return <>..{JSON.stringify( props.list )}..</>

    return  !!props.list.filter((l: any) => l !== null ).length 
        ?
        <div className='d-flex flex-wrap small align-items-center mr-3'>
            <div className='pr-1  '>
                { __(props.fName) }
            </div>  
            {
                props.list
                    .filter(( tag: any ) => !!tag )
                    .map( (   tag: any ) => <CellFooterTagType 
                        key={tag} 
                        tag={tag} 
                        component={props.component} 
                        bg={props.bg} 
                    /> )
            }
        </div>
        :
        null 
}

const CellFooterTagType = (props:any): JSX.Element => {
    //return <>.. {JSON.stringify( props )} ..</>
    switch(props.component) {
        case SCALAR_TYPES.STRING:
            return <Tag 
                round 
                className='m-1 px-3 pointer' 
                style={{ backgroundColor: props.bg, maxWidth:290 }}
                title={ props.tag }
            >
                {props.tag}
            </Tag>
        default:
            return <Tag 
                round 
                className='m-1 px-3 pointer' 
                style={{ backgroundColor: props.bg, maxWidth:290 }}
                title={ props.tag }
            >
                {
                    !!props.tag?.__typename
                        ?
                        props.tag.title
                        :
                        props.tag?.toString() 
                }
            </Tag>

    }
}