import { IFestStore, useFestStore } from "@/modules/pe-fest-module/data/store"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"
import { useState } from "react" 
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import { ScalableDialog } from "@/libs/useful"

const SearchEngineMobile = () : JSX.Element => {
    const search: string                        = useFestStore((state: IFestStore) => state.search)
    const setSearch:(search: string) => void    = useFestStore((state: IFestStore) => state.setSearch)
    const [ isOpen, setIsOpen ]                 = useState( false ) 
    const [ curSeach, setCurSeach ]             = useState( search )

    const onSearch = ( value: string ) => {
        console.log( value )
        setCurSeach( value )
    }
    const startSearch = () => {
        setSearch( curSeach )
        setIsOpen(false)
    }
    const onClear = () => { 
        setCurSeach( "" )
        setSearch( "" )
    }
    const onOpen = () => setIsOpen(!isOpen)
    return <>
        <div className={!!search ? "search-result ": "search-result-closed"} title={search}>
            {search}
        </div> 
        <Button icon="search" minimal onClick={onOpen} />
        <ScalableDialog
            isOpen={isOpen}
            onClose={onOpen}
            dialogSize={DIALOG_SIZE.MINIMAL}
            isScale
            hideClose={false}
        >     
            <div className="small-container w-100 pl-3 h-100 flex-centered flex-column ">
                <FieldInput
                    type={SCALAR_TYPES.STRING}
                    editable
                    placeholder={__("Search Member")}
                    onChange={ onSearch }
                    value={ curSeach }
                />
                <div className="d-flex justify-content-between pr-3 pb-3">
                    <Button
                        minimal
                        onClick={ startSearch }
                    >
                        {__("Search")}
                    </Button>
                    <Button
                        minimal
                        intent={Intent.DANGER}
                        onClick={ onOpen }
                    >
                        {__("Close")}
                    </Button>
                </div>
                
            </div>
        </ScalableDialog>
    </>
}
export default SearchEngineMobile