import { Button, Intent, Position, Tooltip } from "@blueprintjs/core"
import { IPost } from "../interfaces/layouts"
import { __ } from "../utilities"

/* Protopia Ecosystem component */
export interface ICommentsProps {
   item: IPost
   dataType: string
   className?: string
   onClick?: () => void
}
const Comments = ({item, className, onClick}: ICommentsProps): JSX.Element => {
    const _onClick = () => {
        if(onClick) {
            onClick()
        }
    }
    return <Tooltip content={ __( "Comments") } position={Position.TOP} >
        <Button minimal intent={Intent.NONE} large className={`px-2 ${ className ? className:""}`} data-hint={__("Comments")} onClick={_onClick}>
            <i className="far fa-comment-alt mr-1"></i>
            { item.comments?.length || 0}
        </Button>
    </Tooltip>
}
export default Comments