import React, { useState } from 'react' 
import { Button, ButtonGroup, Icon, IconName, Intent, Popover, Position } from '@blueprintjs/core'  
import { __ } from '@/libs/utilities' 
import { cardSortType } from '../../data/cardSortType' 
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { CARD_SORT_TYPE } from '../../data/interfaces'

const CardFilterSwitcher = (props: any) :JSX.Element => {
    const {width} = useScreenSize() 
        return width > WINDOW_WIDTH.TABLET ? <CardFilterScreenSwitcher {...props}/> : <CardFilterMobileSwitcher {...props}/>
} 

export default CardFilterSwitcher

const CardFilterScreenSwitcher = (props: any) :JSX.Element => {
    return <ButtonGroup className='mb-1'>
    {
        cardSortType().map(cardSort =>
            {
                return <Button
                    key={cardSort.id}
                    intent={cardSort.id !== props.cardSort ? Intent.NONE : Intent.DANGER}
                    data-hint={ __(cardSort.type) }
                    minimal={cardSort.id !== props.cardSort}
                    disabled={cardSort.id === props.cardSort}
                    className={`hint hint--top flex-centered `}
                    onClick={ () => props.onClick(cardSort.id) }
                >
                    <Icon icon={ cardSort.icon as IconName } size={12} />
                </Button>
            })
    }
    </ButtonGroup>
} 

const CardFilterMobileSwitcher = (props: any) :JSX.Element => { 
    const [cardSort, setCardSort] =useState<any>( cardSortType().filter(cardSort => cardSort.id === props.cardSort )[0] )

    const onClick = (id: CARD_SORT_TYPE) => {
        props.onClick( id )
        setCardSort( cardSortType().filter(cardSort => cardSort.id === id)[0] )
    }
    return <div className=" position-relative">
        <Popover
            interactionKind="hover"
            position={Position.BOTTOM_LEFT}
            content={ <ButtonGroup vertical className='m-0'>
                {
                    cardSortType().map(cardSort =>
                        {
                            return <Button
                                key={cardSort.id}
                                intent={cardSort.id !== props.cardSort ? Intent.NONE : Intent.DANGER} 
                                minimal={cardSort.id !== props.cardSort}
                                disabled={cardSort.id === props.cardSort} 
                                onClick={ () => onClick(cardSort.id) }
                                alignText="left"
                            >
                                <div className="d-flex">
                                    <Icon icon={ cardSort.icon as IconName } size={12} />
                                    <div className="ml-1 small">
                                            { __(cardSort.type) }
                                    </div>
                                </div>
                            </Button>
                        })
                }
            </ButtonGroup> }
        >
            
            <Button 
                intent={cardSort.id !== props.cardSort ? Intent.NONE : Intent.DANGER} 
                minimal={cardSort.id !== props.cardSort}
                disabled={cardSort.id === props.cardSort}
                className={`ml-3`}
                onClick={ () => props.onClick(cardSort.id) }
            >
                <Icon icon={ cardSort.icon as IconName } size={12} />
            </Button>
        </Popover> 
    </div> 
} 