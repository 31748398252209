import { __ } from "@/libs/utilities"
import { IDestrict, IFestival, IPEFestSchool } from "../../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import FestSchoolCard from "./FestSchoolCard"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { useParams } from "react-router"
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IAboutSchoolListProps {
   
}
const AboutSchoolList = (props: IAboutSchoolListProps): JSX.Element | false  => {
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    const schools: IPEFestSchool[] = useDataStore((state: any) => state.PEFestSchool) || []
    const [destricts, setDestricts]   = useState<IDestrict[]>([])
    
    const onFilter = ( destricts: IDestrict[] ) => {
        setDestricts( destricts )
    }
    return !!festival.isShowSchools && !!schools?.length && <section id="schools">
        <div className="container" id="schools">
            <div className="row justify-content-center pt-4 pb-4 px-md-5 px-2">
                <div className="col-md-12 d-flex justify-content-center">
                    <div className="role_descr">
                        {__("Education organizations on Festival")}
                    </div>
                </div>
            </div>
            <DestrictFilter destricts={ destricts } onChange={ onFilter } />
            <SchoolsList destricts={ destricts } /> 
        </div>
    </section> 
}
export default AboutSchoolList

interface ISchoolsListProps {
    destricts: IDestrict[]
}
const SchoolsList = (props: ISchoolsListProps) : JSX.Element => {
    const schools: IPEFestSchool[] = useDataStore((state: any) => state.PEFestSchool) || []
    return <div className="row justify-content-center px-2 pb-4">
    {
        schools?.filter( school => {
            return !props.destricts.length 
                ?
                true
                :
                !!( school.destrict )
                    .filter( td => !!props.destricts.filter( d => d.id === td.id ).length ).length
        })
        .map( 
            (school: IPEFestSchool) => <FestSchoolCard item={school} key={school.id} /> 
        )
    }
    </div>
}

interface IDestrictFilterFilterProps {
    destricts: IDestrict[] 
    onChange: ( destricts: IDestrict[] ) => void
}
const DestrictFilter = (props: IDestrictFilterFilterProps) : JSX.Element => { 
    const { width }   = useScreenSize()
    const { landId }  = useParams()
    return <div className="d-flex px-3">
        <FieldInput
            type={ width >= WINDOW_WIDTH.MOBILE ? SCALAR_TYPES.EXTERNAL_TAGS : SCALAR_TYPES.EXTERNAL_ARRAY }
            multiple
            landId={ landId}
            editable
            component="PEFestDestrict"
            value={props.destricts}
            onChange={props.onChange}
            seed={"-track-filter"}
            elementClassName="small py-0 px-2"
            elementClassName2="small"
            placeholder="Select Destricts"
            notClear
        />
    </div>
}