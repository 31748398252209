import { DIALOG_SIZE, HTML, SHORTCODE_TYPE } from "@/libs/interfaces/layouts";
import { Map as YandexMap } from "@/libs/yandexMap";
import { CSSProperties, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Shortcode from ".";
import { Gallery } from "../gallery/Gallery";
import ScalableDialog from "../ScalableDialog";
import VKVideoButton from "../vkVideoButton";

export default function parseShortcode(content: HTML ) : JSX.Element[] {
    //const rgsp = /\[PE_([^)]+)\/\]/gi
    //const rgsp = /\[PE_(.*?)(\/\]|\](.*?)\[PE_(.*?)\/\])/gi
    const rgsp = /\[PE_(.*?)(\/\]|\[\/PE_(.*?)\])/gi
    let mod: any = [...content.matchAll( rgsp ) ]
    
    if(!!mod) { 
        let contents: JSX.Element[] = []
        let lastContent: string     = content
        mod.forEach((m: any, i: number) => {
            const c = lastContent.split( m[0] )
            contents.push( <span dangerouslySetInnerHTML={{ __html : c[0] }} key={ i * 2 } /> )
            c.splice(0, 1)
            lastContent = c.join( m[0] )
            // console.log( m )
            contents.push( <ShortcodeData mod={m[1]} i={i}  key={ i * 2 + 1 } />)
        }) 
        contents.push( <span dangerouslySetInnerHTML={{ __html : lastContent }} key="last"/> )
        return contents
    } 
    return [
        <span dangerouslySetInnerHTML={{__html : content}} />
    ]
}

interface IShortcodeDataProps {
    mod: string
    i: number
    className?: string
    style?:CSSProperties
}

const ShortcodeData = ({ mod, i }:IShortcodeDataProps ): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const regex = /\s+(?=(?:(?:[^']*'){2})*[^']*$)/
    const data: string[] = mod.split( regex )
    //console.log( mod )
    const code = data[0]
    const params: any = {}
    let element: JSX.Element = <></>
    data.forEach( d => {
        let elem = d.split("=")
        if( elem.length > 1 ){
            let c: string[] = [...elem]
            c.splice(0, 1)
            params[elem[0]] = c.join("=").replaceAll("'", "")
        }
        return null
    })

    //console.log( mod )
    //console.log( params )
    //console.log( data )

    const btnClassName = `btn btn-sm btn-${params.outline ? 'outline-': ''}${params.intent} ${params.fill ? ' btn-block ': ''} ${!!params.round ? ` rounded-pill${params.round === "left" ? "-left" : params.round === "right" ? "-right" : ""}` : ''}  px-3 `
    switch(code) {
        case SHORTCODE_TYPE.BUTTON:                
            // [PE_BUTTON intent='danger' url='https://ya.ru' label='перейти' width='250' height='250' /]
            element = <Fragment >
                <a href={params.url} target="_blank" rel="noreferrer" >
                    <div 
                        className={ btnClassName } 
                    >
                        <div 
                            className="flex-centered flex-row  "
                            style={{
                                width: !isNumeric(params.width)     ? params.width : Number(params.width)  + "px",
                                height: !isNumeric(params.height)   ? params.width : Number(params.height) + "px"
                            }}                            
                        > 
                            <span>{ params.label }</span>
                            <span className="small">
                                <span className="fas fa-external-link-alt ml-1" />
                            </span> 
                        </div>

                    </div>
                </a>
            </Fragment> 
            break
        case SHORTCODE_TYPE.INSERT:
            // [PE_INSERT height="2200" url="https://api.metaversitet/01-01-2024.pdf" /]
            element = <Fragment >
                <iframe
                    title="PE_INSERT" 
                    style={{ border:"none" }}
                    src={params.url} 
                    width={"100%"}
                    height={!isNumeric(params.height)   ? params.height : Number(params.height) + "px"}
                    
                />
            </Fragment>
            break
        case SHORTCODE_TYPE.VK_VIDEO:
            // [PE_VK_VIDEO src='<iframe src=https://vk.com/video_ext.php?oid=-226933489&id=456239188&hash=f8ef3149a3c59772 width=640 height=360 frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media; fullscreen; picture-in-picture"></iframe>" width="640" height="360" label='Приступая к 1 такту' minimal=true /]
            element = <VKVideoButton 
                key={ i * 2 + 1 } 
                src={params.src} 
                btnWidth={params.btnWidth}
                btnHeight={params.btnHeight}
                padding={params.padding}
                intent={params.intent}
                minimal={params.minimal}
                fill={params.fill}
                image={params.image}
                label={params.label}
                isModal={params.isModal}
            />
            break   
        case SHORTCODE_TYPE.MAP:
            // [PE_MAP coords='56.31, 162.36, 5' width='100%' height='340' /]
            const onMapClick = ( coords : number[] ) => {
                console.log( coords )
            }
            //console.log( jsonToData( `{'coordinates':[53.02257766684992, 158.66364985205237, 9], 'type':'Event', 'title':'Марафон'}` ) )
            element = <YandexMap
                width={ !isNumeric(params.width)    ? params.width : Number(params.width)  + "px" || "100%" }
                height={!isNumeric(params.height)   ? params.width : Number(params.height) + "px" || "100%"}
                coordinates={ params.coords.split(",") }
                markers={ params.markers || [] }
                onClick={onMapClick}
                //onClusterClick={onClusterClick}
                //onWeel={onWeel}
                //onBeforeDrawMap={onBeforeDrawMap}
            >

            </YandexMap>
            break
        case SHORTCODE_TYPE.GALLERY: 
            //[PE_GALLERY count={count} itemWidth={params.itemWidth} itemHeight={itemHeight} loop={loop} padding={padding} navigation={navigation} /]
            element = <Gallery
                count={params.count}
                items={params.items.split(",")}
                itemWidth={params.itemWidth}
                itemHeight={params.itemHeight}
                loop={params.loop}
                navigation={params.navigation}
            />
            break
        case SHORTCODE_TYPE.DIALOG:
            element = <>
                <span 
                    className={ btnClassName } 
                    onClick={() => setIsOpen(true)}
                >
                    <div 
                        className="flex-centered"
                        style={{
                            width: !isNumeric(params.width)     ? params.width : Number(params.width)  + "px",
                            height: !isNumeric(params.height)   ? params.width : Number(params.height) + "px"
                        }}                            
                    >
                        <span>
                            { params.label }
                        </span>
                        <span className="small far fa-comment-alt ml-1" />
                    </div>
                </span>
                <ScalableDialog
                    isOpen={isOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    hideClose={false}
                    isCloseButtonShown
                    onClose={() => setIsOpen(false)}
                >
                    <div className="p-5">
                        <Shortcode content={ params.content} />
                    </div>
                </ScalableDialog>
            </>
            break
        case SHORTCODE_TYPE.LINK:
            element = <Link to={params.link || params.to || params.url}>
                <span 
                    className={ btnClassName } 
                    data-to={ params.link || params.to || params.url }
                >
                    <span 
                        className="flex-centered"
                        style={{
                            width: !isNumeric(params.width)     ? params.width : Number(params.width)  + "px",
                            height: !isNumeric(params.height)   ? params.width : Number(params.height) + "px"
                        }}                            
                    >
                        { params.label }
                    </span>
                </span>
            </Link>
            break
        default:
            // element = <div dangerouslySetInnerHTML={{ __html : params.toString() }} /> 
            break
    }
    return element
}

function isNumeric(params:string) : boolean { 
    return params === parseInt(params).toString()
}

function doAutoplay(iframe: string, replace: string) : string  { 
    const match = (/<iframe[^>]+src=([^\s]+)[\s]/).exec(iframe)
    return match ? 
        iframe.replace( match[1],  match[1] + replace)
        : 
        iframe
     
}

