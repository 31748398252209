import React, { useState } from 'react' 
import { __ } from "@/libs/utilities"
import { Link } from 'react-router-dom' 
import actions from '../../data/actions'
import { RESTORE_PASSWORD_ACTION } from '../../data/actionTypes'

const re: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        
const RestorePasswordForm = (): React.ReactElement => {
    const [email, setEmail] = useState("")
    const [alert, setAlert] = useState("")
    const [isAlert, setIsAlert] = useState(false)
    const onEmail = (evt:any) => {
        setEmail( evt.target.value )
        setIsAlert( !evt.target.value )
    }
    const onRemember = (evt: any ) => {
        evt.preventDefault()
        if(!re.test(email)) {
            setIsAlert(true)
            setAlert( __( "Email expected" ) )
            return
        }
        actions( RESTORE_PASSWORD_ACTION, {email})
            .then( ( response: Response ) => {
                console.log(response)
                setIsAlert( !!response )
                setAlert( __( response
                    ?
                    "Instructions for resetting your password have been sent to your email."
                    :
                    "The specified email was not found." 
                ))
            })
    }


    return <form > 
        <label>
            {__("Insert your e-mail")}
        </label>
        <input
            type="string"
            className="form-control"
            placeholder={__("e-mail")}
            value={ email }
            onChange={ onEmail }
        />
        <div className={`descr text-center mt-2 ${ isAlert ? "text-danger " : "unvisibled"}`}>
            { alert }
        </div> 
        <div className='btn-group w-100 mt-3' role="group" aria-label="Basic outlined example">
            <div 
                className="btn btn-secondary py-2 px-4 w-100 mr-3"
                onClick={onRemember}
            >
                {__("Send instructions to e-mail")}
            </div>
            <Link className="btn btn-secondary py-2 px-4 w-100" to="/">
                {__("or return to main page")}
            </Link> 
        </div>
       
    </form>
} 

export default RestorePasswordForm