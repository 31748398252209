import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { IFeedProps } from "src/modules/pe-basic-module/views/core/FeedComponent"
import { IAds } from "../../data/interfaces"
import AdCardMobile from "./AdCardMobile"
import AdCardScreen from "./AdCardScreen"

interface IProps extends IFeedProps { 
    item: IAds
    className?: string 
}
const AdCard = (props:IProps) :JSX.Element => {
    const {width} =useScreenSize()
    return width > WINDOW_WIDTH.TABLET 
        ? 
        <AdCardScreen {...props} /> 
        : 
        <AdCardMobile {...props} />
}


export default AdCard