import { DIALOG_SIZE, IPENotification } from "@/libs/interfaces/layouts"
import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import { getFeedRoute } from "@/libs/layouts"
import { ScalableDialog } from "@/libs/useful"
import { __, arrayGroupBy, wordTrim } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_BULK_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { useUser } from "@/settings/zustand/user"
import { Button, Callout } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"
import Moment from "react-moment"
import { useNavigate } from "react-router"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import MessagetToAdmin from "./MessagetToAdmin"
import { UPDATE_NOTIFICATION_INTERVAL } from "../NotificationsWidget"

/* Protopia Ecosystem component */
export interface IMessagesScreenProps {
    messageRoute: string
    count: number
}
const NotificationsScreen = ({messageRoute, count, ...props}: IMessagesScreenProps): JSX.Element => {
    const user = useUser()
    const myRef = useRef( null ) 
    const navigate = useNavigate()
    const [height, setHeight] = useState<number>(0)
    const [isAdminOpen, setIsAdminOpen] = useState<boolean>(false)
    const notifications:IPENotification[] = useDataStore((state: any) => state["PENotification for notify "] || [])
    let timeInterval: any
    const onMouseLeaveHandler = (e: Event) => {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) { 
            setHeight( 0 )
        } 
    }
    const checkNotifications = () => {
        actions( 
            GET_FEED_ACTION, 
            { 
                data_type: "PENotification",
                land_id: "1",
                count: 10,
                paging: ` relation:"AND" metas:[{key:"adressee", value:"${user.id}"}] order:"DESC" order_by_meta:"id" `,
                seed: " for notify " 
            } 
        )
    } 

    useEffect(() => {
        document.body.addEventListener("click", onMouseLeaveHandler) 
        timeInterval = setInterval( checkNotifications, UPDATE_NOTIFICATION_INTERVAL)
        checkNotifications()
        return () => {
            document.body.removeEventListener("click", onMouseLeaveHandler)
            clearInterval( timeInterval )
        }
    }, [])

    const onClick = (item: IPENotification) => {
        let url ="";
        clearInterval( timeInterval )
        switch(item.resource_type) {
            case "PEMessage":
                url = `/fest/${item.land?.id || 1}/messages/user/${item.post_author?.id || 1}`
                break
            case "PEFestProject":
                url=`fest/${item.land?.id || 1}/project/${item.resource_id || 1}/ratings?comments=1`
                break
            case "PEDiary":
                url=`fest/${item.land?.id || 1}/diaries/modal/${item.resource_id || 1}`
                break
            case "Comment": 
                url = `/fest/${item.land?.id || 1}`
                break
            case "PEAttachment": 
                url = `/fest/${item.land?.id || 1}/attachments/modal/${item.resource_id || 1}`
                break
            case "Ad":
            default:
                url = `/fest/${item.land?.id || 1}/ads/modal/${item.resource_id || 1}`
        } 
        navigate(url)
        window.location.reload() 
    }
    const onToggle = () => {
        setHeight(height === 0 ? 450 : 0)
        if(height === 0) {
            const id = notifications.filter(n => !n.is_viewed).map(n => n.id)
            actions(UPDATE_BULK_ACTION, {
                land_id:1,
                data_type:"PENotification",
                id: id,
                data: {
                    is_viewed: true
                }
            })
        }

    } 
    const gotoNoti = () => {
        navigate( getFeedRoute("PENotification").realRoute )
    }
    const viewed: any = arrayGroupBy(notifications, "is_viewed")
    // console.log( viewed )
    const length = notifications.filter(n => !n.is_viewed).length
    return <div ref={ myRef }>
        <div className={ `opened` } onClick={onToggle}>
            <div className={` `}>
                <div className=" header-menu-icon hover mr-md-4  d-flex justify-content-center">
                    <i className=" far fa-bell mb-0 text-force-contrast lead-md" />
                    <div 
                        className={`messages-widget-label hover bg-danger ${ !length ? "d-none" : ""}`} 
                        id={ "PENotification for notify " }
                    >
                        { length }
                    </div>
                </div> 
            </div>
        </div>
        <div className="logined-menu" style={{ height: height }} >
            <div className="lmenu podmenu text-light small p-2 position-fix w-100" >
                <MessagetToAdmin />
            </div>
            <div className="w-100 h-100 overflow-y-auto mb-4">
                <ul id="notifications_menu mb-4" style={{ paddingInlineStart: 0, marginBlockStart: 10, marginBlockEnd: 100}}>
                    {
                        !!notifications.length 
                            ?
                            <>
                            {
                                viewed.is_viewed_false?.map((n:IPENotification) => {
                                    return <SingleNotification onClick={onClick} n={n} is_viewed key={n.id} />
                                })
                            }
                            {
                                !!viewed.is_viewed_true?.length && <li 
                                    className="lmenu podmenu text-light small d-flex align-items-center justify-content-start py-0 descr opacity_75 " 
                                    style={{ borderBottomColor: "transparent", paddingLeft: 40 }}    
                                >
                                    <span 
                                        className="small text-secondary" 
                                        style={{ 
                                            transform: "translateY(6px)", 
                                            border: "1px solid #f8f9fa3d", 
                                            padding: "1px 6px",
                                            backgroundColor: "#171f29"
                                        }}
                                    >
                                        {__("Viewed notifications")}
                                    </span>
                                </li>
                            }
                            {
                                viewed.is_viewed_true?.map((n:IPENotification) => {
                                    return <SingleNotification onClick={onClick} n={n} key={n.id} />
                                })
                            }
                            </>
                            :
                            <Callout minimal className="p-4 text-center descr text-light">
                                <div className="small opacity_75">
                                    {__("No notificaions exists")}
                                </div>
                            </Callout>
                    }  
                </ul>
            </div>            
            <Button className="position-absolute bottom left w-100 text-light " style={{backgroundColor:"#293742"}} onClick={gotoNoti}>
                {__("All notifications")}
            </Button>
        </div>
    </div>
}
export default NotificationsScreen

export const SingleNotification = ({onClick, n, is_viewed}: any) : JSX.Element => {
        const [isOpen, setOpen] = useState<boolean>(false)
        const onOpen = (evt:any) => {
            evt.preventDefault()
            evt.stopPropagation()
            setOpen(!isOpen)
        }
        return <>
            <li className="lmenu podmenu text-light small d-flex align-items-center justify-content-start p-2" key={n.id} onClick={() => onClick( n )}>
                <div className="w-100 d-flex flex-grow-100">
                    <UserAvatar user={n.post_author} noPadding />
                    <div className="ml-2">
                        <div className="descr small text-light">
                            <span className="small opacity_75 text-warning">
                                {n.post_author?.display_name}
                            </span>
                        </div>
                        <div className={`text-light ${ is_viewed ? "title" : "" }`}>
                            {n.title}
                        </div>
                        {
                            n.resource_title && <div 
                                className="text-light small mb-1 text-secondary "
                                dangerouslySetInnerHTML={{__html: wordTrim(n.resource_title, 10) || ""}}
                            /> 
                        }
                        <div className="descr small text-light">
                            <span className={`small opacity_75`}>
                                {n.land?.name}
                            </span>
                        </div>
                        <div className="descr small text-light">
                            <span className="small text-secondary ">
                                <Moment locale="ru" format="D MMMM YYYY ">
                                    { (Number(n.post_date) * 1000) }
                                </Moment> 
                            </span>
                        </div>
                    </div>
                </div>
                <div className="float-right">
                    {
                        n.thumbnail && <><img src={n.thumbnail} style={{ height:"100%", maxHeight: 75 }} alt="" onClick={onOpen} /></>
                    }
                </div>
            </li>
            <ScalableDialog
                dialogSize={DIALOG_SIZE.LARGE}
                isOpen={!!isOpen}
                onClose={ () => setOpen(false) }
                hideClose={false}
                isCloseButtonShown
                mayFullScreen={false}
            >
                <div className="p-0 h-100 position-relative">
                    <img src={ n.thumbnail }  style={{  maxHeight: "100%" }} alt="" onClick={ () => setOpen(false) }/>
                </div>                
            </ScalableDialog>
        </>
}