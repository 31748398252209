import { IPost } from "@/libs/interfaces/layouts"
import { ScalableDialog } from "@/libs/useful"
import QRCodeSign from "@/libs/useful/qr-code/QRCodeSign"
import { __ } from "@/libs/utilities"
import { Button, Position, Tooltip } from "@blueprintjs/core"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IQRCodeButtonProps {
   item: IPost
   options: any
}
const QRCodeButton = (props: IQRCodeButtonProps): JSX.Element => {
    const [isOpen, setIsopen] = useState<boolean>(false)
    const onOpen = () => {
        setIsopen(!isOpen)
    }
    return <>
        <Tooltip
            position={Position.TOP}
            content={__("QR")}
        >
            <Button
                minimal
                onClick={onOpen}
            >
                <i className="fas fa-qrcode" style={{ fontSize: "1.3em" }} />
            </Button>
        </Tooltip>        
        <ScalableDialog
            onClose={ onOpen }
            isOpen={isOpen}
        >
            <div className="p-0 bg-light">
                <QRCodeSign 
                    options={props.options}
                />
            </div>
        </ScalableDialog>
            
    </>
}
export default QRCodeButton