import { getFeedRoute, getRoute } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { NavLink } from "react-router-dom"
import { IFestival, ITrack } from "../../data/interfaces"
import setActive from "../utils/setActive"
import { IFestDataStor, useFestDataStore } from "../../data/store"

/* Protopia Ecosystem component */
export interface IPListMenuProps {
   
}
const PListMenu = (props: IPListMenuProps): JSX.Element => { 
    const festival:IFestival = useFestDataStore((state: IFestDataStor) => state.festival)  
    return festival.isAd || festival.isAttachments || festival.isDiary
        ?
        <div className='ml-md-auto mr-md-0 mx-auto d-flex'>
            <NavLink className={ setActive } to={`/${getRoute( getFeedRoute("PEFestProject") )}`}>
                <span className="small">
                    {__("Projects")}
                </span>
            </NavLink>
            {
                festival.isAd && <NavLink className={ setActive } to={`/${getRoute( getFeedRoute("Ad") )}`}>
                    <span className="small">
                        {__("Ads")}
                    </span>
                </NavLink>
            }
            {
                festival.isAttachments && <NavLink className={ setActive } to={`/${getRoute( getFeedRoute("PEAttachment") )}`}>
                    <span className="small">
                        {__("Matherials")}
                    </span>
                </NavLink>
            }
            {
                festival.isDiary && <NavLink className={ setActive } to={`/${getRoute( getFeedRoute("PEDiary") )}`}>
                    <span className="small">
                        {__("Diaries")}
                    </span>
                </NavLink>
            } 
        </div>
        :
        <></>
}
export default PListMenu