import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SEARCH_USERS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import useElementBound from "@/modules/pe-basic-module/hooks/useElementBound"
import { useSyncState } from "@/modules/pe-basic-module/hooks/useSyncState"
import { IUser } from "@/settings/zustand"
import { Collapse, Popover, PopoverInteractionKind } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"
import { ID, Role } from "../interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "../scalars"
import { __ } from "../utilities"
import { AppToaster } from "./AppToaster"
import { LoaderMinimal } from "./Loading"

/* Protopia Ecosystem component */
export interface IUserSearchEngineProps {
    user?: IUser | null
    onSelect: (user:IUser) => void
    landId?: ID
    minWidth? : number
    onlyThisLands? : boolean
    isMulti? : boolean
    notClear? : boolean
    role?: Role[]
    autoFocus? : boolean
    placeholder?: string
}
const UserSearchEngine = (props: IUserSearchEngineProps): JSX.Element => { 
    const ref = useRef(null)
    const [name, setName] = useState<string>(props.user?.display_name || "")
    //const [user, setUser] = useState<IUser | null | undefined>( props.user )
    const userSet = useSyncState<IUser | null | undefined>( props.user )
    const [isSelect, setIsSelect] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState<boolean>( false )
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [isMulti, setIsMulti] = useState<boolean>(!!props.isMulti)
    const [users, setUsers]= useState<IUser[]>([]) 
    const {width} = useElementBound( ref.current )
     

    useEffect(() => {
        setName( props.user?.display_name || "" )
    },[props.user])
    const onChange = (_name:string, isMulti: boolean) => {
        setIsSelect( false )
        setName( _name )
        setIsLoad( true ) 
        setIsOpen( true )
        actions( 
            GET_SEARCH_USERS_ACTION, 
            { 
                search:_name, 
                role: props.role,
                landId: isMulti ? "-1" : props.landId 
            } )
            .then((resp: IUser[]) => {
                setUsers(resp) 
                //setIsOpen(!!resp.length) 
                setIsLoad(false) 
            })
    }
    const selectUser = (user:IUser) => {
        props.onSelect(user)
        setUsers( [] )
        setName( "" )
        setIsOpen( false )
        setIsSelect(true) 
        userSet.set(user)
    }
    const onBlur = () => {
        setTimeout(() => {
            const user: IUser | null | undefined = userSet.get()
            if(!isSelect) { 
                if(user?.id) {
                    setName( user.display_name || "" )
                    setIsSelect(true)
                }
                if(!name) {
                    setIsSelect(true)
                }
                else {
                    AppToaster.show({
                        message: __("The User was not found")
                    })
                }
                setIsOpen( false ) 
            }
        }, 400)
        
    }
    const onFromAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setIsMulti( evt.target.checked )
        onChange( name, evt.target.checked )
    }
    return <div className='w-100 position-relative' style={{minWidth: props.minWidth || 0}}>
        <div 
            className={`d-flex align-items-center container pl-3  ${isSelect ? " " : " opacity_5 text-secondary "}`}
            onBlur={ onBlur }
            ref={ref}
        >
            <FieldInput
                type={SCALAR_TYPES.STRING}
                className={` ${isSelect ? "text-force-contrast title" : ""} input dark form-control `}
                value={name}
                onChange={value => onChange(value, isMulti)}
                onClear={() => {
                    userSet.set( null )
                    props.onSelect({id: "-1"} as IUser)}
                }
                editable
                placeholder={__(props.placeholder || "Start write user name")}
                notClear={props.notClear}
                autoFocus={!!props.autoFocus}
            />
            {
                isLoad && <LoaderMinimal />
            }
        </div>
        {
            !props.onlyThisLands && 
            <div>
                <label className="_check_">
                    <input type="checkbox" onChange={onFromAll} />
                    {__("From all festivals")}
                </label>
            </div>
        }
        <div className="w-100 position-absolute bottom left ">
            <Popover
                isOpen={isOpen}
                interactionKind={PopoverInteractionKind.HOVER}
                onInteraction={state => setIsOpen(state)}
                hoverCloseDelay={1000}
                position="bottom-left"
                modifiers={{
                    arrow: { enabled: false },
                    flip: { enabled: true },
                    preventOverflow: { enabled: false },
                    offset: { enabled: true, options: {
                        offset: [ 0, 0],
                    } }, 
                }}
                className="w-100"
                popoverClassName="w-100"
                content={
                    <div style={{width: width - 60}}>
                        <Collapse isOpen={isOpen} className="w-100">
                            <ul className={`list-group list-group-flush container overflow-y-auto w-100 h_200 ${isLoad ? "opacity_5" : ""}`}>
                            {
                                !!users.length 
                                    ?
                                    users.map(user => {
                                        return <li 
                                            className='list-group-item pointer hover-straght p-1 d-flex align-items-center' 
                                            key={user.id}
                                            onClick={ () => selectUser( user ) }
                                        >
                                            <div className="user-ava" style={{backgroundImage:`url(${user.avatar})`, borderRadius:0}}></div>
                                            { user.display_name}     
                                        </li>
                                    })
                                    :
                                    <div className="p-4 w-100">
                                        {__("User was not found")}
                                    </div>
                            }
                            </ul>
                        </Collapse>
                    </div>
                }
            >
                <div className="w-100 position-absolute bottom left"></div>
            </Popover>
        </div>
    </div>
}
export default UserSearchEngine