import Shortcode from "@/libs/useful/shortcode"
import { __ } from "@/libs/utilities"
import { IProjectMilestone } from "@/modules/pe-fest-module/data/interfaces"
import { Tag } from "@blueprintjs/core"

interface IMTitlePros {
    milestone: IProjectMilestone | undefined
}

const MilestoneTitle = ({ milestone }: IMTitlePros) : JSX.Element => {
    return !milestone
        ?        
        <></>
        :
        <>
            <div className='lead-md '>
                <span className="opacity_75 mr-2">
                    {__("Milestone")}: 
                </span>
                <span>
                    { milestone.milestone.title }
                </span>
            </div>
            <div className="my-1">
                <Shortcode content={ milestone.milestone.post_content } />
            </div>  
            {
                !!milestone.experts.length && <div className="small my-1">
                    <span>
                        {__("Experts, who put raiting:")}
                    </span>
                    <span className=''>
                    { 
                        milestone.experts
                        .filter(user => !!user.display_name)
                        .map(user => {
                            return <Tag round minimal className='title ml-1 mb-1 px-2' key={user.id}>
                                { user.display_name }
                            </Tag>
                        }) 
                    }
                    </span>
                </div>
            }     
            <div className="mb-3 small">
                <span>
                    {__("Roles for which rate is available")}:
                </span>
                <span className=''>
                    { milestone.milestone.roles
                    .filter(r => r !== "SuperAdmin")
                    .map(r => {
                        return <Tag round className='title ml-1 mb-1 px-2' key={r}>
                            {__(r)}
                        </Tag>
                    }) }
                </span>
            </div>
        </>
}
export default MilestoneTitle