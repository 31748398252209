
import gql from "graphql-tag"
import { UPDATE_SINGLE_TYPE, GET_OPTIONS_ACTION, UPDATE_OPTIONS_ACTION, UPDATE_DICTIONARY_ACTION, GET_LOG_FILES, DELETE_LOG_FILE_ACTION, GET_TRASH_FILES_ACTION, RESTORE_LOG_FILE_ACTION, ERASE_TRASH_LOG_FILE_ACTION, DELETE_ALL_LOGS_ACTION, BULK_DOUBLE_ACTION } from "./actionTypes" 
import { DocumentNode } from "graphql"
import { useAppoloStore, useMainStore } from "@/settings/zustand"
import { GET_OPTIONS }  from "./graphql/GET_OPTIONS"
import { setRequestError } from "@/settings/errorControllers/setRequestError"
import { getQueryArgs, getQueryName } from "@/libs/layouts"
import { ID } from "@/libs/interfaces/layouts"

const asyncControllers = async (action: string, data: any) => {
    let response: any, mutation: DocumentNode, query: DocumentNode, query_name: string, mutationString:string, fields: any, land_id: ID
    const apolloClient: any = useAppoloStore.getState().client
    try {
        switch (action) {
            case UPDATE_SINGLE_TYPE: 
                return response
            case GET_OPTIONS_ACTION:
                query = gql`${ GET_OPTIONS() }`
                response = await apolloClient.query({
                    query,
                    variables: data,
                })
                return response
            case UPDATE_DICTIONARY_ACTION:
                const json = JSON.stringify(data.dictionary).replaceAll('"', '\\"')
                console.log(JSON.stringify(data.dictionary))
                const m = `mutation changeDictionary {
                    changeDictionary(input: {
                        language: "ru-RU"
                        dictionary: "${ json }"
                    }) {
                        language
                        dictionary
                    }
                }`
                mutation = gql`${m}`
                response = await apolloClient.mutate({
                    mutation 
                })
                return response
            case UPDATE_OPTIONS_ACTION:
                mutation = gql`mutation changeOptions($input: OptionsInput) {
                    changeOptions(input: $input) {
                        name
                        description
                        web_client_url
                        adress
                        init_method
                        user_verify_account
                        thumbnail
                        default_img
                        vk
                        youtube
                        android
                        apple
                        help_url
                        email
                        vk_client_id
                        vk_client_secret
                        yandex_client_id
                        yandex_client_token
                        yandex_client_secret
                        telegramm_client_id
                        icon_media_term
                        user_media_term
                        test_media_term 
                    }
                }`
                console.log( data )
                console.log( mutation )
                response = await apolloClient.mutate({
                    mutation,
                    variables: {
                        input: data,
                    },
                })
                return response
            case GET_LOG_FILES: 
                query = gql`query getLogFiles($land_id: ID, $chapters: [String] $ignore_land_id: Boolean, $period: TIME_PERIOD) {
                    getLogFiles(land_id: $land_id, chapters: $chapters, ignore_land_id: $ignore_land_id, period: $period) {
                        text
                        file_name
                    } 
                }` 
                response = await apolloClient.query({
                    query,
                    variables: {
                        land_id: data.landId,
                        chapters: data.chapters,
                        ignore_land_id: !!data.ignore_land_id,
                        period: data.period
                    },
                })
                return response
            case GET_TRASH_FILES_ACTION: 
                query = gql`query getTrashLogFiles($ignore_land_id: Boolean) {
                    getTrashLogFiles(ignore_land_id: $ignore_land_id) {
                        text
                        file_name
                    }
                }` 
                response = await apolloClient.query({
                    query,
                    variables: {
                        ignore_land_id: true,
                    },
                })
                return response
            case DELETE_LOG_FILE_ACTION: 
                mutation = gql`mutation deleteLogFile($file_name: String) {
                    deleteLogFile(file_name: $file_name)
                }` 
                response = await apolloClient.mutate({
                    mutation,
                    variables: {
                        file_name: data.file_name,
                    },
                })
                return response
            case RESTORE_LOG_FILE_ACTION: 
                mutation = gql`mutation restoreLogFile($file_name: String) {
                    restoreLogFile(file_name: $file_name)
                }` 
                response = await apolloClient.mutate({
                    mutation,
                    variables: {
                        file_name: data.file_name,
                    },
                })
                return response
            case ERASE_TRASH_LOG_FILE_ACTION: 
                mutation = gql`mutation deleteTrashLogFile($file_name: String) {
                    deleteTrashLogFile(file_name: $file_name)
                }` 
                response = await apolloClient.mutate({
                    mutation,
                    variables: {
                        file_name: data.file_name,
                    },
                })
                return response
            case DELETE_ALL_LOGS_ACTION: 
                mutation = gql`mutation deleteAllLogFiles  {
                    deleteAllLogFiles
                }` 
                response = await apolloClient.mutate({
                    mutation 
                })
                return response
            case BULK_DOUBLE_ACTION:
                land_id = data.land_id 
                    ?
                    `land_id: "${ data.land_id }"`
                    :
                    ``
                mutationString = `mutation duplicateBulk${ data.data_type }($data_type: String!, $id: [ID!]! ) {
                    duplicateBulk${ data.data_type }( 
                        data_type: $data_type
                        id: $id
                        ${land_id}
                    )
                }`
                console.log( mutationString )
                mutation = gql`${mutationString}` 
                response = await apolloClient.mutate({
                    mutation, 
                    variables : {
                        data_type: data.data_type,
                        id: data.id
                    }
                })
                return response
        default:
                return new Promise<any>((resolve, reject) => { resolve(`Unknown action type: ${action}`) })
        }
    }
    catch( error: any ) {
      console.log( action, data, error )
      setRequestError( action, data, error ) 
     
      return response
    }
    finally {
      return response
    }
}
export default asyncControllers