import { Collapse } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react' 
import { __ } from '@/libs/utilities'
import { CARD_TYPE, IFestival, IProject } from '../../data/interfaces'
import CardProject from '../projectList/CardProject' 
import AddProjectCard from './AddProjectCard'
import { actions } from '../../data/actions'
import { GET_OWN_ACTION } from '../../data/actionTypes'
import StrokeProject from '../projectList/StrokeProject'
import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import { useParams } from 'react-router'
import { IState, IUser, useMainStore } from '@/settings/zustand'

const MyProjects = () : JSX.Element => {
    const params = useParams()
    const user: IUser = useMainStore((state: IState) => state.user)
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const festId : ID = params.landId || ""

    const [projects, setProjects] = useState<IProject[]>( [] as IProject[] ) 
    const [isOpen, setOpen] = useState<boolean>( !!projects.length || !!festival.isRegisterProject )
    const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
    
    //console.log("MyProjects:", festId)
    useEffect(() => {
        const onResizeHandler = () => {
            setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
        }
        window.addEventListener("resize", onResizeHandler)
        return () => window.removeEventListener("resize", onResizeHandler)
    }, [])
    
    useEffect(() => {
        if(user.id) {
            actions(GET_OWN_ACTION, { land_id: festId }).then((resp : IProject[]) => {
                //console.log( resp, festId ) 
                setProjects( resp ) 
                setOpen( true )
            })
        }
        
    }, [ festId ])

    useEffect(() => {
        setOpen( !!projects.length || !!festival.isRegisterProject )
    }, [ projects ])

    const route = `insert-project`
    return  <section id="my_projects" className=" light-colored "> 
        <Collapse isOpen={isOpen}>
            <div>
                <div className="container ">
                    <div className="row justify-content-center mt-0 py-4 px-md-5 px-2">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="role_descr">
                                {__("My projects")}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-0 pb-3  px-2">
                        {
                            projects.map((project: IProject, i:number) => {
                                return  isMobyle
                                ?
                                <StrokeProject
                                    key={project.id}
                                    item={project}
                                    type={CARD_TYPE.CARD}
                                    i={i}
                                    isMine
                                />
                                :
                                <CardProject
                                    key={project.id}
                                    item={project}
                                    type={CARD_TYPE.CARD}
                                    i={i}
                                    isMine
                                />
                            })
                        }
                        <AddProjectCard route={ route } />
                    </div>
                </div>
            </div>
        </Collapse>
    </section>
} 

export default MyProjects