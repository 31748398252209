import { CompoundTag, Intent, Position, Tag } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { DIALOG_SIZE } from "src/libs/interfaces/layouts";
import { getFeedRoute, getRoute, schema } from "src/libs/layouts";
import { getURL } from "src/libs/scalars/scalars/URL";
import { ClipboardCopyBtn, Likes, ScalableDialog } from "src/libs/useful";
import Bookmark from "src/libs/useful/Bookmark";
import Comments from "src/libs/useful/Comments";
import Shortcode from "src/libs/useful/shortcode";
import { __ } from "src/libs/utilities";
import actions from "src/modules/pe-basic-module/data/actions";
import { GET_SINGLE_ACTIONS } from "src/modules/pe-basic-module/data/actionTypes";
import { IFeedProps } from "src/modules/pe-basic-module/views/core/FeedComponent";
import { ITrimContent, trimContent } from "src/modules/pe-fest-module/views/utils/trimContent";
import { IState, useMainStore } from "src/settings/zustand";
import { IAds } from "../../data/interfaces";
import AdCardMobile from "./AdCardMobile";

interface IProps extends IFeedProps { 
    item: IAds
    className?: string
    firstCommentAutofocus?: boolean
}

const AdCardScreen = ({item, className, ...props }:IProps) :JSX.Element => {
    const navigate = useNavigate() 
    let colClass = " col-12 " 
    if( props.params?.col )
    {
        switch(parseInt( props.params?.col ))
        {
            case 2:
                colClass = " col-md-6 "
                break;
            case 3:
                colClass = " col-md-4 "
                break;
            case 4:
                colClass = " col-md-3 "
                break;
            case 1:
            default:                
                colClass = " col-12 "
                break;

        }
    }
    const tr: ITrimContent = trimContent( item.post_content, 20 )
    const typeName = __( item.parent_type &&  item.parent_type !== "all" ? schema()[item.parent_type]?.name : "" )
    return <>
        <Link 
            className={ `position-relative  flex-centered flex-column ${ className } ${colClass} my-3 pointer hover ` } 
            to={ `/${getRoute(getFeedRoute("Ad"))}/modal/${ item.id}` }
        >
            <div className="d-flex border border-secondary w-100" style={{maxWidth:700, minHeight: 270}}>
                <div className="thumbnail h-100 position-relative w-50" style={{ backgroundImage: `url(${ item.thumbnail })`, }}>
                    <img src="http://fakeimg.pl/150x200" alt="" className="unvisibled h-100" style={{width: "auto"}}/> 
                    {
                        !!item.date &&
                            <Moment  locale="ru" format="D.MM.YYYY" className={`px-3 py-1 bg-secondary text-light position-absolute top left m-1`}>
                                { parseInt(item.date.toString()) * 1000 }    
                            </Moment> 
                    }
                </div>
                <div className="position-relative w-50"> 
                    <div className="bg-secondary-light p-3 h lead text-force-contrast" style={{fontWeight: 700, lineHeight:0.9}}>
                        {item.title}
                    </div>
                    {
                        !!item.parent?.id && 
                            <CompoundTag  
                                // round
                                interactive
                                active={false}
                                intent={Intent.PRIMARY}
                                className="w-100 m-0 rounded-0" 
                                leftContent={ <div className="small text-overflow">{typeName}</div> }
                                
                            > 
                                <div className="small text-overflow">
                                    { item.parent?.title}
                                </div>
                            </CompoundTag>
                    }
                    {
                        tr.isTrim 
                            ?
                            <Shortcode content={tr.content} className={`pointer m-3 text-force-contrast `} /> 
                            :
                            <Shortcode content={tr.content} className={` m-3 text-force-contrast `} />   
                    }
                    <div className="d-flex align-items-center position-absolute bottom left" onClick={(evt) => { evt.stopPropagation(); evt.preventDefault(); }} >
                        <ClipboardCopyBtn
                            data={`${window.location.origin}/${getRoute(getFeedRoute("Ad"))}/${item.id}`}
                            type="icon"
                            label={__("Copy link")}
                            position={Position.TOP_RIGHT}
                        /> 
                        <Bookmark item={item} />  
                        <Comments item={item } dataType="Ad" />
                        <Likes item={item} dataType="Ad" className="" /> 
                    </div>
                    {
                        item.url &&
                        <a href={ getURL(item.url)[0]} target="_blank" rel="noreferrer" className="position-absolute m-1 bottom right">
                            <Tag intent={Intent.SUCCESS}>
                                <small>{ getURL(item.url)[1] || __("Ins")}</small>
                            </Tag>
                        </a> 
                    } 
                </div>
            </div>
        </Link> 
    </>
}

export default AdCardScreen

interface IAdContent extends IProps{
    parentRoute: string
}
export const AdContent = (props: IAdContent): JSX.Element => {
    const navigate = useNavigate()
    const {adId} = useParams()
    const landId = useMainStore((state:IState) => state.landId)
    const [item, setItem]  = useState<IAds>({} as IAds)

    useEffect(() => {
        actions(GET_SINGLE_ACTIONS, {
            data_type: "Ad",
            land_id: landId,
            id: adId
        }).then((res: IAds) => setItem(res))
    }, [])

    const onClose = () => {
        navigate(props.parentRoute)
    }
    return item?.id
        ?
        <ScalableDialog
            dialogSize={DIALOG_SIZE.MINIMAL}
            isOpen={true}
            onClose={onClose}
            hideClose={false}
            mayFullScreen={false}
            isCloseButtonShown
        >
            <div className="py-4">
                <div className="max-height overflow-y-auto">
                    <AdCardMobile item={item} firstCommentAutofocus data_type="Ad" i={0} />
                </div>   
            </div> 
        </ScalableDialog>
        :
        <></>
}