import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import attachmentTypes from "../../data/attachmentTypes"
import { SCALAR_TYPES } from "@/libs/scalars"
import { ScalableDialog } from "@/libs/useful"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts" 
import { useState } from "react"
import { Link } from "react-router-dom"
import { IAttachmentStrokeMobile } from "./AttachmentStrokeMobile"


const ActionButton = (props: IAttachmentStrokeMobile): JSX.Element  => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>( false )
    const onUrl = () => {
        //console.log( props.item.type )
        window.open( props.item?.data, '_blank', 'noopener,noreferrer' )
    }
    const onOpen = () => { 
        setIsDialogOpen( !isDialogOpen )
    } 
    const onString = () => {
        setIsDialogOpen( !isDialogOpen )
    }
    switch(props.item?.type) {
        case SCALAR_TYPES.MEDIA:
            return <>
                <Button minimal  onClick={ onOpen } >
                    <div className="text-uppercase small ">
                        {__( attachmentTypes()[props.item?.type || ""].actionName )}
                    </div>
                </Button>
                <ScalableDialog
                    isOpen={isDialogOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    mayFullScreen
                    hideClose={ false }
                    // title={props.item.title}
                    onClose={onOpen}
                > 
                     <div className=" h-100 ml-0 mr-0 min-height-350 ">
                        <img src={props.item?.thumbnail} alt="" />
                    </div>
                </ScalableDialog>                
            </>
        case SCALAR_TYPES.VK_VIDEO_ID:
        case SCALAR_TYPES.RUTUBE_ID: 
            return <Link to={ props.isScreen ? `content` : `${props.item?.id}/content` } className="">
                <Button minimal>
                    <div className="text-uppercase small ">
                        {__( attachmentTypes()[props.item?.type || ""].actionName )}
                    </div>
                </Button>
            </Link>
            // return <>
            //     <Button minimal  onClick={ onOpen } >
            //         <div className="text-uppercase small ">
            //             {__( attachmentTypes()[props.item.type].actionName )}
            //         </div>
            //     </Button>
            //     <ScalableDialog
            //         isOpen={isDialogOpen}
            //         dialogSize={DIALOG_SIZE.NORMAL}
            //         mayFullScreen
            //         hideClose={ false }
            //         // title={props.item.title}
            //         onClose={onOpen}
            //     > 
            //          <div className=" h-100 ml-3 mr-15 min-height-350">
            //             <FieldInput
            //                 type={props.item.type}
            //                 value={props.item.data}
            //                 width="100%"
            //                 height={"100%"}
            //                 rowClassName="h-100 min-height-350"
            //             />
            //         </div>
            //     </ScalableDialog>
            // </>
        case SCALAR_TYPES.STRING:
            return <>
                <Button minimal onClick={ onString } >
                    <div className="text-uppercase small ">
                        {__( attachmentTypes()[props.item?.type || ""].actionName )}
                    </div>
                </Button>
                <ScalableDialog
                    isOpen={isDialogOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    mayFullScreen
                    hideClose={ false }
                    // title={props.item.title}
                    onClose={onOpen}
                > 
                     
                </ScalableDialog>
            </>
        case SCALAR_TYPES.URL:
        default:
            return <>
                <Button minimal onClick={ onUrl } >
                    <div className="text-uppercase small ">
                        {__( attachmentTypes()[props.item?.type || ""].actionName )}
                    </div>
                </Button>
            </>
    }
}

export default ActionButton