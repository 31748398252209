import { IPEMessage } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface IMessageParentProps {
   item: IPEMessage
}
const MessageParent = (props: IMessageParentProps): JSX.Element => {
    return props.item.parent?.id 
        ?
        <div className='card p-2 mb-2 w-100 descr'>
            <div className="small mb-1 title" >
                {props.item.parent.post_author?.display_name}
            </div>
            <div className={`small`}>
                {props.item.parent.title}
            </div>
        </div>
        :
        <></>
}
export default MessageParent