import { getFeedRoute, getRoute } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { Collapse, Position, Tooltip } from "@blueprintjs/core"
import { Link, useNavigate } from "react-router-dom"
import { ITrack } from "../../data/interfaces"

export interface IModeratorAvatar {
  item: ITrack 
  isOpen: boolean// = true, 
  random: number//  = Math.random(), 
  moderator: "moderator" | "moderator2"// =
}
const ModeratorAvatar = ({
    item,
    isOpen , 
    random, 
    moderator
  }:IModeratorAvatar
) => { 
    moderator = moderator ? moderator :  "moderator"  
    const navigate = useNavigate()
    const to = (evt: any) => {
      evt.preventDefault()
      evt.stopPropagation()
      navigate( `/${getRoute( getFeedRoute("PEMessage") )}/user/${item[moderator]?.id}` )
    }
    return item[moderator]?.id
      ?        
      <div className='d-flex align-items-center m-3 mt-auto pb-2' onClick={to}>
        <div className={ ` mr-3 my-avatar-container` } >
          <Tooltip
            position={Position.TOP_LEFT}
            content={<div className="p-2">{__("Contact moderator")}</div>}
          >
            <div 
              className='my-avatar-cont'
              style={{
                backgroundImage: `url(${ item[moderator]?.avatar }?${ random || Math.random() })`,
                backgroundColor: '#555555'
              }}
            />
          </Tooltip>
        </div> 
        <div>
          <div className=' lead-md text-secondary'>
            { __( moderator === "moderator" ? "Track expert" : "Track moderator assistant" ) }
          </div>
          <div className='title'>
            { item[moderator].display_name }
          </div>
          <Collapse isOpen={ isOpen } className={`w-100`}>
            <div className='mt-1' dangerouslySetInnerHTML={{ __html: item[moderator]?.user_descr }} />
          </Collapse>            
        </div>
      </div> 
      :
      null
}

export default ModeratorAvatar