import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"

/* Protopia Ecosystem component */
export interface IProfileMenuProps {
   
}
const ProfileMenu = (props: IProfileMenuProps): JSX.Element => {
    return <div className='pe-profile-menu-container'>
        <NavLink
            to="public"
            className={"pe-admin-widget-btn"}
        >
            <Button minimal fill alignText='left' className='position-relative'>
                {__("Public data")}
            </Button>            
        </NavLink>
        <NavLink
            to="private"
            className={"pe-admin-widget-btn"}
        >
            <Button minimal fill alignText='left' className='position-relative'>
                {__("Private data")}
            </Button>            
        </NavLink>
        
    </div>
}
export default ProfileMenu