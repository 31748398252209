import React from 'react' 

const TrackLabel = (props: any): JSX.Element | null => { 
    return props.track
        ?
        <div className={props.className ? props.className : `fest-card-track-label`} >
            {
                props.track?.thumbnail 
                    ? 
                    <img src={props.track?.thumbnail} alt="" className='track-miniature'/> 
                    :
                    null
            }
            <div className='track-label-label'>{ props.track?.title }</div>
        </div>
        :
        null
} 

export default TrackLabel