
import { ID, ILayoutData, IMetaFilter, ITaxonomyFilter, POST_STATUS, Role } from "@/libs/interfaces/layouts";
import Layouts, { modules } from "@/libs/layouts"; 
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { SIGN_TYPE } from "./interfaces";
import { getStorage, SORT_ORDER } from "@/settings/zustand";

export const useAdminStore: any = create( devtools<IAdminStore>((set: any, get: any ) => ({
    sortOrder: SORT_ORDER.ASC,
    setSortOrder: (sortOrder: SORT_ORDER) => set( ( ) => ({ sortOrder }) ),
    sortByField: "",
    setSortByField: (sortByField: string) => set( ( ) => ({ sortByField }) ),
    signType: SIGN_TYPE,
    setSignType: (signType:SIGN_TYPE) => set( ( ) => ({ signType }) ),
    bulks: [],
    setBulks: (bulks: IBulk[]) => set( ( ) => ({ bulks }) ),
    items: [],
    setItems: ( items: any[] ) => set(() => ({ items })),
    data_type: "",
    setData_type: ( data_type: string ) => set(() => ({ data_type })),
    offset: 0,
    setOffset :(offset: number) => set( () => { 
        return {offset : offset}
    }),
    paging: "",
    setPaging: (paging: string) => set( ( ) =>  ({paging})),
    metaFilter: [],
    setMetaFilter: (metaFilter: IMetaFilter[]) => set( () => { 
        return { metaFilter }
    }),
    in__roles: [],
    setIn__roles: (in__roles: string[]) => set( () => { 
        return { in__roles }
    }),
    post_status: POST_STATUS.PUBLISH,
    setPost_status: (post_status: POST_STATUS) => set( () => { 
        return { post_status }
    }),
    search: "",
    setSearch: (search:string) => set( () => { 
        return { search }
    }),
    taxonomies: [],
    setTaxonomies: (taxonomies:ITaxonomyFilter[]) => set( () => { 
        return { taxonomies }
    }),
    currentDictModule: "",
    currentLandGroupID : -1
})))

export interface IAdminStore {
    sortOrder: SORT_ORDER
    setSortOrder: (sortOrder : SORT_ORDER) => void
    sortByField: string
    setSortByField: (sortByField : string) => void
    signType: typeof SIGN_TYPE
    setSignType: (signType:SIGN_TYPE) => void
    bulks: IBulk[]
    setBulks: (bulks: IBulk[]) => void
    items: any[]
    setItems: ( items: any[] ) => void
    data_type: string
    setData_type: ( data_type: string ) => void
    offset: number
    setOffset: (offset: number) => void
    paging: string
    setPaging: (paging: string) => void
    metaFilter: IMetaFilter[]
    setMetaFilter: (metaFilter: IMetaFilter[]) => void
    in__roles: Role[]
    setIn__roles: (in__roles: string[]) => void
    post_status: POST_STATUS
    setPost_status: (post_status: POST_STATUS) => void
    search: string 
    setSearch: (search:string) => void
    taxonomies: ITaxonomyFilter[]
    setTaxonomies: (taxonomies:ITaxonomyFilter[]) => void
    currentDictModule : string,
    currentLandGroupID: ID
}
export interface IBulk {
    checked: boolean,
    id: ID
    data_type: string
}

export interface IAdminMenuStore {
    currentRouteID: ID
    currentMenuGroup: string
    currentViewsSelected: string[]
    layoutsClone: ILayoutData
}

export const useAdminMenuStore: any = create( devtools( persist<IAdminMenuStore>( (set: any, get: any ) => ({
    currentRouteID: "",
    currentMenuGroup: "main_menu",
    currentViewsSelected: Object.keys( modules() ) || [],
    layoutsClone: {...Layouts()}
}),
{
    name: 'pe-admin-menu-storage', 
    storage: createJSONStorage( getStorage ), 
} 
)))