import { DIALOG_SIZE } from '@/libs/interfaces/layouts'
import FieldInput from '@/libs/scalars'
import ConfirmButton from '@/libs/useful/ConfirmButton'
import { __ } from '@/libs/utilities'
import React, { FormEvent, useState } from 'react'
import { Location, Navigate, useLocation, } from 'react-router'
import { IState, useMainStore } from 'src/settings/zustand'
import { SCALAR_TYPES } from '../../../../libs/scalars'
import { LoaderLine } from '../../../../libs/useful'
import actions from '../../data/actions'
import { UPDATE_CURRENT_USER } from "../../data/actionTypes"

const ProfileForm = (props: any):React.ReactElement => {
    const user = useMainStore( (state: IState) => state.user )
    const location: Location = useLocation()
    return user.id
        ?
        <Profile />
        :
        <Navigate to="/login" replace state={{ pathname: location.pathname }} />
} 

export default ProfileForm

const Profile = ():React.ReactElement => {
    const user = useMainStore( (state: IState) => state.user)
    const [avatar, setAvatar] = useState( user.avatar )
    const [avatarName, setAvatarName] = useState( user.avatar )
    const [firstName, setFirstName] = useState( user.first_name )
    const [user_descr, setUser_descr] = useState( user.user_descr )
    const [lastName, setLastName] = useState( user.last_name )
    
    const [isLoading, setIsLoading] = useState( false )

    const update = (evt:FormEvent) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsLoading(true)
        const userData: any = {}
        if(user_descr)  userData.user_descr = user_descr
        if(firstName)   userData.first_name = firstName
        if(lastName)    userData.last_name  = lastName 
        if(avatar)      userData.avatar     = avatar
        if(avatarName)  userData.avatar_name = avatarName.name
        console.log( userData )
        actions(UPDATE_CURRENT_USER, userData)
            .then(res => 
            {
                console.log( res )
                // AppToaster.show({
                //     message: __("Profile successfully update")
                // })
            })
            .finally(() =>  setIsLoading(false))
    }
    const onClearLS = () => {
        localStorage.clear()
        window.location.reload()
    }
    return <div className={`row justify-content-center ${isLoading ? "opacity_75 untouchble" : ""} pt-5`}>
        <div className="col-md-7 text-center pl-4 pb-5 mb-5">
            <form
                onSubmit={ update }
            >
                <FieldInput
                    title="Avatar"
                    type={SCALAR_TYPES.MEDIA}
                    field="first_name"
                    style={{ borderRadius:130 }}
                    height={80}
                    width={80}
                    editable 
                    isURLHide 
                    isFileNameHide
                    limitSize={ 300000 }
                    accept="image/*"
                    value={ avatar }
                    onChange={ (value: any, file: any={}, name: string = "" ) => {
                        setAvatar( value ) 
                        setAvatarName( name )                       
                    }}
                />
                <FieldInput
                    title={__('Description')}
                    type={SCALAR_TYPES.HTML}
                    field="user_descr"
                    editable
                    value={ user_descr }
                    onChange={ (evt: any) => setUser_descr( evt ) }
                />
                <FieldInput
                    title={__('Firs name')}
                    type={SCALAR_TYPES.STRING}
                    field="first_name"
                    editable
                    value={ firstName }
                    onChange={ (evt: any) => setFirstName( evt ) }
                />
                <FieldInput
                    title={__('Last name')}
                    type={SCALAR_TYPES.STRING}
                    field="last_name"
                    editable
                    value={lastName}
                    onChange={ (evt: any) => setLastName(evt)}
                /> 
                <div className='row'>
                    <div className='col-md-9 offset-md-3 d-flex justify-content-between'>
                        {
                            isLoading
                                ?
                                <LoaderLine />
                                :
                                <input
                                    type="submit"
                                    className="btn btn-secondary my-3"
                                    value={__("Update")}
                                />
                        }    
                        <div className="d-flex pe-surface align-items-center">
                            <ConfirmButton
                                buttonMinimal
                                buttonLabel={
                                    <span className="small opacity_5">
                                        {__("Clear Local storage")}
                                    </span>
                                }
                                dialogClasssName={DIALOG_SIZE.MINIMAL}
                                onConfirm={onClearLS}
                            >
                                <div className="p-5 text-center lead">
                                    {__("Really you want clear Local Storage?")}
                                </div>
                            </ConfirmButton>
                        </div>
                    </div>                
                </div>
                 
            </form>
        </div>
    </div>
}