import React from 'react' 
import { Icon, Popover, Position } from '@blueprintjs/core'
import { sprintf, __ } from "src/libs/utilities"

const MemberRatingDisplay = (props: any) : JSX.Element => {
    // console.log( props.correct_member_rate )
    return !!props.correct_member_rate?.rate && !["0.0", "0"].includes(props.correct_member_rate.rate)
        ?
        <div className='d-flex align-items-start '>
            <div className={` ${props.className} flex-grow-100 `}> 
                <span className=""> { props.correct_member_rate.rate }</span>
            </div> 
            <div className={` `}>
                <Popover
                    position={ Position.TOP }
                    interactionKind="hover"
                    content={<div 
                        className="p-4 large-square text-center" 
                        dangerouslySetInnerHTML={{ 
                            __html: sprintf(
                                __("This is the grade adjusted by the Track moderator, %s. <p>Reason: %s. <p>The average rating given by the experts is %s"),
                                `<b>${props.correct_member_rate?.display_name}</b>`,
                                props.correct_member_rate?.description
                                    ?
                                    `<b>${props.correct_member_rate?.description}</b>` 
                                    :
                                    __("no exist"),
                                    parseFloat(props.countedRating || 0 ).toFixed(2).replace(/\.?0*$/,'')
                            ) 
                        }} 
                    />}
                >
                    <div className={`btn btn-link opacity_5 d-flex d-flex align-items-start  ml-1 ${ props.className } `}>
                        <Icon icon="help" size={15} />
                    </div>
                </Popover>
            </div>
        </div>
        :
        <div className='d-flex align-items-start '>
            <div className={` ${props.className} flex-grow-100 `}> 
                <span className=""> { parseFloat( props.countedRating || 0 ).toFixed(2).replace(/\.?0*$/,'') }</span>
            </div> 
        </div>
} 

export default MemberRatingDisplay