
import { ATTACHMENT_CARD_TYPE, IAttachment, ID, IMenuItem } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { AppToaster, Loading } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { IFestFilters, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IFestStore, useFestStore } from "@/modules/pe-fest-module/data/store"
import { useFestival } from "@/modules/pe-fest-module/hooks/festival"
import { useIsTrackModerator } from "@/modules/pe-fest-module/hooks/track"
import PEFestProjectTerm from "@/modules/pe-fest-module/views/projectList/PEFestProjectTerm"
import PListUnderMenu from "@/modules/pe-fest-module/views/projectList/PListUnderMenu"
import { IState, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { useEffect, useState } from "react"
import { Route, Routes, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import actions from "../../data/actions"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "../../data/actionTypes"
import { useDataStore } from "../../data/store"
import Feed from "../core/Feed"
import AttachmentCard from "./AttachmentCard"
import AttachmentsListContainer from "./AttachmentsListContainer"
import AttachmentStroke from "./AttachmentStroke"
import { AttachmentMobile, AttachmentMobileContent } from "./AttachmentStrokeScreen"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import AttachmentDataInputWidget from "../../widgets/AttachmentDataInputWidget"
import AttachmentResourceInputWidget from "../../widgets/AttachmentResourceInputWidget"

/* Protopia Ecosystem component */
export interface ITrackAttachmentListFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
    cardType?: ATTACHMENT_CARD_TYPE
}
interface IParamsPaging {
    params: any
    paging: string
}
const AttachmentListForm = (props: ITrackAttachmentListFormProps): JSX.Element => {    
    const params = useParams()    
    const attachmentFilter = useMainStore((state: IState) => state.attachmentFilter)
    const festId: ID = params.landId || "-1" 
    const [tick, setTick] = useState<string>(Math.random().toString())
    
    useEffect(() => {
        return () => {
            useMainStore.setState({ attachmentFilter:{ } })
        }
    }, [])
    const onPaging = (): IParamsPaging => {
        let params: any = {}, paging: string = ""
        if( attachmentFilter.category_id && Number(attachmentFilter.category_id) !== -1) {
            paging = ` taxonomies: [ { tax_name: "category", term_ids: [${attachmentFilter.category_id}] } ] `
            return {params, paging}
        }
        if( attachmentFilter.resource_type ) {
            paging = ( ` metas:[ {key: "resource_type", value: "${attachmentFilter.resource_type}"} , {key: "resource_id", value: "${ attachmentFilter.resource_id }" }  ]` )
            return {params, paging}
        }

        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
            paging = ( ` metas:[ {key: "resource_type", value: "PEFestTrack"} , {key: "resource_id", value: "${ filters.tracks[0] }" }  ]` )
            useMainStore.setState({
                attachmentFilter:{
                    resource_type: "PEFestTrack",
                    resource_id:  filters.tracks[0]
                }
            })
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
            paging = ( ` metas:[ {key: "resource_type", value: "PEFestSchool"} , {key: "resource_id", value: "${ filters.schools[0] }" }  ]` )
            useMainStore.setState({
                attachmentFilter:{
                resource_type: "PEFestSchool",
                resource_id:  filters.schools[0]
                }
            })
        }
        return {params, paging}
    }

    // пагинация для фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters = useFestStore( (state:IFestStore) => state.filters )
    const tracks : ITrack[]     = useDataStore((state:any) => state.PEFestTrack || [])
    const [paging, setPaging]   = useState<string>( onPaging().paging )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams() 

    const updateURLSearchParams = () => {
        const p = onPaging()
        setPaging( p.paging )
        SetURLSearchParams( p.params )
    }
    useEffect(() => {
        const tracks = URLSearchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
    }, [ URLSearchParams ])
    useEffect(() => {
        //setOffset(0) 
        updateURLSearchParams()
        
    }, [ filters, attachmentFilter ])

    const isLoading = useFestival(!props.parentMode, festId) 

    if(isLoading ) return <Loading /> 

    const card = () => {
        switch(props.cardType) {
            case ATTACHMENT_CARD_TYPE.CARD:
                return AttachmentCard
            case ATTACHMENT_CARD_TYPE.STROKE:
            default: 
                return AttachmentStroke
        }

    }
    const onResourceFilter = ( resource_type: string, resource_id: number ) => {
        useMainStore.setState({
            attachmentFilter: { resource_type, resource_id }
        })
    }
    return <div className="mx-auto w-100">
        <PEFestProjectTerm />
        <div className='mx-auto container pt-3 p-0'>
            <PListUnderMenu />        
            <Routes>
                <Route 
                    path="/modal/:attachmentId"
                    element={
                        <> 
                            <AttachmentMobile 
                                {...props}
                                parentRoute={ `/${getRoute(getFeedRoute("PEAttachment"))}` } 
                            />
                        </>
                    }
                />
                <Route 
                    path="/modal/:attachmentId/content"
                    element={
                        <> 
                            <AttachmentMobileContent 
                                {...props}
                                parentRoute={ `/${getRoute(getFeedRoute("PEAttachment"))}` } 
                            />
                        </>
                    }
                />
            </Routes>
            <div className="row px-md-0 px-4">  
                <Feed 
                    offset={0}
                    layoutComponent={AttachmentsListContainer}
                    data_type={"PEAttachment"} 
                    land_id={ festId }
                    paging={ paging } 
                    class_name=' '
                    containerClassName="w-100" 
                    topPagingWidget={
                        <div className="d-flex"> 
                            <AddAttachmentForm   
                                track={ tracks.filter(tr => tr.id === filters.tracks[0])[0] }  
                                tick={() => setTick( Math.random().toString() )}
                            />
                        </div>
                    }
                    component={ card() }
                    params={{ 
                        col: 2,
                        onResourceFilter, 
                        tick
                    }}
                />
            </div>
        </div>
    </div>
}
export default AttachmentListForm

 
interface IAddDiaryForm {
    track:ITrack | null 
    tick: () => void
}
const AddAttachmentForm = (props:IAddDiaryForm) => {
    const params = useParams()
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters ) 
    const isModer = useIsTrackModerator( props.track )
    const landId: ID = params.landId || "-1" 
    const [item, setItem] = useState<IAttachment>({} as IAttachment)


    useEffect(() => {
        setItem({
            resource_id: Number( filters.tracks[0] ), 
            resource_type: "PEFestTrack",  
        } as IAttachment)
    }, [ filters ])
    const onChange = (value: any, field: keyof IAttachment, file: any) => {
        if(field === "thumbnail") {
            setItem({
                ...item,
                "thumbnail": value,
                "thumbnail_name": file.name
            })
        } else {
            setItem({
                ...item,
                [field]: value
            })
        }
    }
    const onDataChange = ( data: any, type: "data" | "type" ) => { 
        setItem({
            ...item,
            type,
            data
        })
    } 
    const onResourceChange = (resource_type: string,  resource_id: number ) => { 
        setItem({
            ...item,
            resource_id,
            resource_type
        })
    }
    const onCreate = () => {
        if(!item.title) {
            AppToaster.show({
                message: __("Title need")
            })
            return
        } 
        actions(
            UPDATE_SINGLE_TYPE,
            {
                landId,
                data_type: "PEAttachment",
                item
            }
        )
        .then(
            () => {
                setItem({} as IAttachment)
                AppToaster.show({
                    message: __("New attacment added")
                })
                actions(
                    GET_FEED_ACTION, 
                    { 
                        data_type: "PEAttachment", 
                        land_id: landId 
                    }
                ).then(() =>  props.tick() )
            }
        )
    } 
    return  ( isRole([ "administrator", "SuperAdmin" ]) || isModer ) 
        ?
        <ConfirmButton 
            buttonIcon="plus"
            buttonMinimal
            buttonLabel={<div className="small text-nowrap">{__("Add attachment")}</div>}
            dialogTitle={__("Create new Attachment")}
            yesLabel={__("Start create")}
            noLabel={__("Cancel")}
            yesLock={ !item.title }
            onConfirm={onCreate}
        >
            <div className="p-4 max-height overflow-y-auto">
                <FieldInput
                    editable
                    title={__("Thumbnail")}
                    type={SCALAR_TYPES.MEDIA}
                    onChange={onChange}
                    value={item.thumbnail}
                    field="thumbnail"
                    isFileNameHide
                    hideLib
                    isURLHide 
                />
                <FieldInput
                    editable
                    title={__("Title")}
                    type={SCALAR_TYPES.TITLE}
                    onChange={onChange}
                    value={item.title}
                    field="title"
                    required
                />
                <FieldInput
                    editable
                    title={__("Content")}
                    type={SCALAR_TYPES.HTML}
                    onChange={onChange}
                    value={item.post_content}
                    field="post_content"
                /> 
                <AttachmentDataInputWidget
                    type={item.type}
                    data={item.data}
                    onChange={onDataChange}
                />
                <AttachmentResourceInputWidget
                    data_type="PEAttachment"
                    isChanged={filters.tracks[0] !== -1 }
                    onChange={onResourceChange}
                    resource_type={ filters.tracks[0] !== -1 ? "PEFestTrack" : item.resource_type}
                    resource_id={ filters.tracks[0] !== -1 ? Number(filters.tracks[0]) : item.resource_id}
                />
                <FieldInput
                    editable
                    title={__("Category")}
                    type={SCALAR_TYPES.EXTERNAL_TAGS}
                    component={"Category"}
                    onChange={(value) => onChange(value.id, "category", "")}
                    value={item.category}
                    field="category"
                    landId={landId}
                /> 
            </div>
        </ConfirmButton>
        :
        <></>
}