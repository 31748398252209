import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies"
import { __ } from "@/libs/utilities"
import NoMatchView from "@/modules/pe-basic-module/views/NoMatchView"
import { Outlet, Route, Routes } from "react-router"
import MessagesList from "./MessagesList"
import MessagesUser from "./MessagesUser"

/* Protopia Ecosystem component */
export interface IMessagesFormProps {
    parentMode?: boolean
}
const MessagesForm = (props: IMessagesFormProps): JSX.Element => {  
    return <div className="container mb-5">
        <div className="container display-6 my-3 d-flex">
            {__("Messenger")} 
        </div>
        <Routes>  
            <Route 
                path="" 
                element={ <> 
                    <Outlet />
                </> } 
            > 
                <Route index element={ <MessagesList {...props} /> } />
                <Route 
                    path="user/:userId"
                    element={ <MessagesUser {...props} /> }
                />
                <Route path='*' element={<NoMatchView {...DEFAULT_404_PROPS} />} />
            </Route>
        </Routes> 
    </div>
}
export default MessagesForm