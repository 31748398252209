import { __ } from "@/libs/utilities"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import DiaryCard from "@/modules/pe-basic-module/views/diary/DiaryCard"
import { AddDiaryForm } from "@/modules/pe-basic-module/views/diary/DiaryListForm"
import { IState, useMainStore } from "@/settings/zustand"
import { useParams } from "react-router"

const Diary = (): JSX.Element => {
    const landId = useMainStore((state:IState) => state.landId)
    const { id } = useParams() 
    console.log( useParams() )
    return (
        <div className="small-container">
            <div className="display-6 text-center my-2">
                { __("Project Diary")}
            </div>
            <Feed 
                component={DiaryCard}
                data_type={ "PEDiary" }
                is_hide_pagi={false}
                offset={ 0 }
                count={ 20 } 
                paging={ `metas: [{ key: "resource_id", value: "${id}" }, {key: "resource_type", value: "PEFestProject"}]` } 
                params={{ 
                    col: 1
                }}
                topPagiHide
                topPagingWidget={<AddDiaryForm resource_id={Number( id )} resource_type={"PEFestProject"} />}
                style={{}}
                containerClassName="w-100" 
                land_id={landId} 
                isEmptyComment="Дневник не начат" 
            />
        </div>
    )
} 

export default Diary