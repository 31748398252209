import BasicState from "@/libs/basic-view"
import AttachmentListForm from "./attachments/AttachmentListForm"

class AttachmentListView extends BasicState {
    props: any
    addRender = () => {  
        return <> 
            <AttachmentListForm {...this.props} />
        </>   
    }
} 

export default AttachmentListView