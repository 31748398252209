import { HTML } from "@/libs/interfaces/layouts"
import parseShortcode from "./parseShortcode"
import { CSSProperties } from "react"

/* Protopia Ecosystem component */
export interface IShortcodeProps {
   content: HTML |string
   className?: string
   style?:CSSProperties
}
const Shortcode = (props: IShortcodeProps): JSX.Element => {
    return <div className={props.className} style={props.style}>
        {parseShortcode( props.content )}
    </div>
}
export default Shortcode

export {
    parseShortcode
}