import { schema } from "@/libs/layouts"
import FieldInput from "@/libs/scalars"
import IScalarForm, { SCALAR_TYPES } from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar"
import { __, sprintf } from "@/libs/utilities"
import { IState, useMainStore } from "@/settings/zustand"
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { useMemo } from "react"

/* Protopia Ecosystem component */
export interface IAttachmentResourceInputWidgetProps {
    data_type: string
    isChanged:boolean
    resource_type: string
    resource_id: number
    onChange:(resource_type: string, resource_id: number) => void
}
const AttachmentResourceInputWidget = ({resource_type, resource_id, ...props}: IAttachmentResourceInputWidgetProps): JSX.Element => {
    const  onChange=(value: any ) => { 
        props.onChange( value.resource_type, value.resource_id )
    } 
    return <Scalar 
        editable
        value={{resource_type, resource_id}}
        title={__("Attach to")}
        required
        onClear={() => props.onChange( resource_type, resource_id )}
        enabledForm={<Enabled value={{ resource_type, resource_id }}  onChange={ onChange } component={ props.data_type } />} 
    />
}
const Enabled = (props: IScalarForm): JSX.Element => {
    const landId = useMainStore((state:IState) => state.landId)
    const resource_type = useMemo( () => [...schema()[props.component!].apollo_fields[props.component === "Ad" ? "parent_type" : "resource_type"].values, "all"], [])
    return <div className="mt-3 mb-5 w-100 px-4">
        <div className="d-flex align-items-center mb-3"> 
            <ButtonGroup >
                {
                    resource_type.map(( type: any ) => <Button 
                        key={ type } 
                        minimal={ props.value.resource_type !== type } 
                        intent={  props.value.resource_type !== type ? Intent.NONE : Intent.SUCCESS}
                        onClick={() => {
                            if( props.onChange ) { 
                                props.onChange( {resource_type: type, resource_id: props.value.resource_id }, "", "" )
                            }
                        }}
                    >
                        { __( schema()[ type ]?.name || type)  }
                    </Button>)
                }
            </ButtonGroup>
        </div>   
        {
            //props.value.props.value.resource_type !== "all" &&  
            <div className="py-3"> 
                <FieldInput
                    editable
                    type={SCALAR_TYPES.EXTERNAL_TAGS}
                    component={props.value.resource_type}
                    value={props.value.resource_id}
                    onChange={(value) => {
                        if( props.onChange ) { 
                            props.onChange( {resource_type: props.value.resource_type, resource_id: value.id }, "", "" )
                        }
                    }}
                    placeholder={sprintf("Choose id of %s", props.value.resource_type)}
                    landId={landId}
                    seed="new_attachment_resource"
                    notClear
                />
            </div>
        }
    </div>
}
export default AttachmentResourceInputWidget