import PhaseLabel from "../PhaseLabel"
import LastAd from "./LastAd"
import PListMenu from "./PListMenu"

/* Protopia Ecosystem component */
export interface IPListUnderMenuProps {
   
}
const PListUnderMenu = (props: IPListUnderMenuProps): JSX.Element => {
    return <div className={` mb-4 pl-2 w-100 d-flex justify-content-between align-items-center flex-wrap`} style={{}}> 
        <PhaseLabel className='mx-0 text-nowrap'/> 
        <LastAd />
        <PListMenu />
    </div>
}
export default PListUnderMenu