import { MediaChooser } from "@/libs/useful"
import { Button, Icon, Intent } from "@blueprintjs/core"
import IScalarForm from "../../interface/IScalarForm"

export interface IGalleryProps extends IScalarForm {
    onChange: (value:any, field: string, data: any) => void
    onRemove: () => void
    onPrev? : ( i: number) => void
    i: number
}
const GalleryItem = ( props: IGalleryProps): JSX.Element => {
    const { field, value, tab, style, id, accept, height, width } = props 
    const onMediaChange = ( fileData: string, file: any ) => {
        props.onChange(fileData, "", file)
    }
    return <div className='pe-gallery-item-container position-relative '>
        <MediaChooser
            prefix={`_${ field ? field: "" }${ id ? id : "" }`}
            url={value || ""}
            id={ id ? "" + id : "" } 
            padding={2}
            height={ height || 120 }
            width={ width }
            tab={ tab }
            hideLib 
            accept={ accept }
            isUploadHide={ false }
            isURLHide 
            isFileNameHide 
            isHideClearBtn
            onChange={ onMediaChange }
            server_url={ "" }
            limitSize={ 0 }
            style={style} 
        />
        <Button 
            intent={Intent.DANGER} 
            className="position-absolute z-index-1 top right mt-2 mr-3"
            onClick={props.onRemove}
        >
            <Icon icon="cross" color="#FFFFFF" />
        </Button>
        {
            props.i > 0 && <Button
                intent={Intent.NONE} 
                className="pe-gallery-prev-btn"
                onClick={ () => { if(props.onPrev) props.onPrev(props.i) } }
            >
                <Icon icon="double-caret-horizontal" color="#777777" />
            </Button>
        }
    </div>
}
export default GalleryItem