import { IPost } from "@/libs/interfaces/layouts"
import { __, __cases, sprintf } from "@/libs/utilities"
import AttachmentCard from "@/modules/pe-basic-module/views/attachments/AttachmentCard"
import { Button, Icon } from "@blueprintjs/core"
import { useState } from "react"
import OffestAttachmentBtn from "./OffestAttachmentBtn"

const ROW_COUNT = 5
/* Protopia Ecosystem component */
export interface ICriteryAttachmentsProps {
   item: IPost
   isMayAdd?: boolean
}
const CriteryAttachments = (props: ICriteryAttachmentsProps): JSX.Element => {
    const [count, setCount] = useState<number>(ROW_COUNT)

    const setNext = () => {
        const newCount: number = count < (props.item.attachments?.length || 0) 
            ?  
            count < (props.item.attachments?.length || 0) + ROW_COUNT
                ?
                count + ROW_COUNT 
                :
                (props.item.attachments?.length || 0) - count 
            : 
            (props.item.attachments?.length || 0) + 1
        //console.log( newCount )
        setCount( newCount )
    }

    const nextCount: number = ( (props.item.attachments?.length || 0) - count > 6 
        ? 
        6 
        : 
        (props.item.attachments?.length || 0) - count )
    return <>
        <div className='pe-critery-attachments-container d-flex flex-wrap'>
            {
                props.item.attachments?.filter((__, i:number) => i < count - 1)
                .map(att => {
                    return <AttachmentCard item={att} key={att.id} />
                })
            }
            {
                !!props.item.attachments?.length &&  !!props.isMayAdd && <OffestAttachmentBtn item={props.item} />
            } 
        </div>
        {
            count < (props.item.attachments?.length || 0) && !!props.isMayAdd &&
            <Button minimal fill onClick={ setNext }>
                <Icon icon="arrow-down" size={20}/>
                <span className="px-1 small">
                    {
                        sprintf(
                            __cases(  nextCount, "Next %s elements", ), 
                            (  nextCount ).toString()
                        )
                    }
                </span>
            </Button>
        }
    </>
}
export default CriteryAttachments