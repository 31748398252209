import { useEffect, useState } from "react"
import { IAds } from "../../data/interfaces"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"
import { GET_ADS_FEED_ACTION } from "../../data/actionTypes"
import { Loading } from "src/libs/useful"
import { actions } from "../../data/actions"
import AdCard from "./AdCard"

const AdFeedForm = () :JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
    const ads : IAds[] = useToposPostsStore( (state:IToposPostsStore) => state.ads )
    useEffect(() => {
        actions(GET_ADS_FEED_ACTION, {}).then( () => setIsLoading( false ) )
    }, []) 
    if(isLoading) return <div className="layout-state"><Loading /></div> 
  return (
    <div className="container pb-5"> 
      <div className="row">
        {
          ads.map((ad, i) => <AdCard item={ad} key={ad.id} className="col-md-6 col-12 my-3" i={i} data_type="Ad" />)
        }
      </div>
    </div>
  )
} 

export default AdFeedForm