import { Fragment, useEffect, useState } from "react"
import IScalarForm, { SCALAR_TYPES } from "../interface/IScalarForm"
import Scalar from "./Scalar"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { LoaderMinimal } from "@/stories/Loading"
import { adminData, apolloFields, getVisibleValue } from "@/libs/layouts"
import { Color } from "@/libs/interfaces/layouts"
import { Select } from "@/libs/useful"
import {IPost} from "../../interfaces/layouts"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */ 
const ExternalArray = (props: IScalarForm): JSX.Element => {
    return <Scalar 
    { ...props } 
    enabledForm={<ExternalArrayEnabled {...props} />}
    desabledForm={<ExternalSingleDesabled {...props} />}
/>
}
export default ExternalArray

const ExternalArrayEnabled = ( props: IScalarForm ): JSX.Element => {
    const [ seed, ] = useState<string>( props.seed || props.component + " " + props.paging + " " +  props.landId )
    const items: any[] = useDataStore((state:any) => {
        if( props.component ){
            return state[props.component + seed] || []
        }
        return []
    }) || []
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        actions( 
            GET_FEED_ACTION, 
            { 
                data_type: props.component,
                paging: props.paging,
                land_id: props.landId,
                seed 
            } 
        )
            .then( response => {
                setIsLoading( false ) 
            })
        return () => {
            const st = {...useDataStore.getState() }
            // console.log( props.component + seed )
            st[ props.component + seed ] = []
            // console.log(st)
            useDataStore.setState( st )
        }
    }, [])
     
    // console.log( props )
    const [value, setValue] = useState( () => {
        if(props.multiple)
        {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        }
        else
        {
            return props.value
        } 
    })
    useEffect( () => {
        setValue( () => {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        })
    }, [props.value] ) 

    const onMultipleClick = (id : any) =>
    {
        // console.log( "onMultipleClick", value)
        let vv
        if(value.filter((v: any) => v.id === id.id ).length > 0)
        {
            vv = value.filter((v: any) => v.id !== id.id )
        }
        else
        {
            vv = [...value]
            vv.push(id)
        }
        setValue( vv )        
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    } 
    const onChange = (items: any[] = [] ) => { 
        setValue( items )      
        if( props.onChange )
            props.onChange(items,  props.field, "")
    }
     

    return (
        <div className={`w-100 d-flex flex-wrap py-4 ${ isLoading ? "opacity_5" : "" }`}> 
            <Select
                isMulti
                items={ items }
                value={ props.value || [] }
                onChange={ onChange }
                placeholder={__(props.placeholder || "Select")}
            />
        </div>
    )
} 
 
const ExternalSingleDesabled = ( props: IScalarForm ): JSX.Element | JSX.Element[] => { 
    const visibled_value2 = getVisibleValue( props.component! || "Post" )
    const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
    return Array.isArray(props.value) 
        ?
        props.value.map(
            (v: any, i) => {
                const labels = !!props.tags && Array.isArray( props.tags )
                    ?
                    props.tags.map(tag => {
                        if( v[tag]?.id ) {
                            const vComponent = apolloFields( props.component! || "Post" )[tag]?.component                            
                            return v[tag][getVisibleValue( vComponent || "Post" )]
                        }
                        else {
                            return v[tag]
                        }
                             //apolloFields()
                    })
                    :
                    []
                // console.log( adminData( props.component! || "Post" ).fill[0] )
                //const fill: Color = adminData( props.component! || "Post" ).fill[0]
                return <Fragment key={i}>
                
                </Fragment>
            }
        )
        :
        <></>
} 