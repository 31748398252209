import BasicState from "@/libs/basic-view"
import PENotificationFeedForm from "./notification/PENotificationFeedForm"

 
 class PENotificationFeedView extends BasicState { 
    props: any
    addRender = () => (
        <>
          <div className="container">
            <div className="row justify-content-center"> 
                <PENotificationFeedForm  offset={0} />
            </div>
          </div>
        </>
          ) 
  }
  export default PENotificationFeedView