import { LoaderBallsLine } from "@/libs/useful/Loading"
import { __ } from "@/libs/utilities"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"
import { useToposStore } from "@/modules/pe-topos-module/data/store"
import EventTypeLabel from "@/modules/pe-topos-module/views/labels/EventTypeLabel"
import { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router"
import RoleCarriersForm from "../../../pe-topos-module/views/admin/RoleCarriersForm"
import { actions } from "../../data/actions"
import { GET_FESTIVAL_SETTINGS_ACTION } from "../../data/actionTypes"
import { useFestDataStore } from "../../data/store"
import EventCarouselItem from "./EventCarouselItem"

// Import Swiper styles
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import NextButton from "@/libs/useful/gallery/NextButton"
import PrevButton from "@/libs/useful/gallery/PrevButton"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import ExtendedUtilsForm from "@/modules/pe-topos-module/views/admin/ExtendedUtilsForm"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const {  Navigation, Pagination, Scrollbar, A11y } =  require("swiper/modules")  
const {   Swiper, SwiperSlide } =  require("swiper/react") 

/* Protopia Ecosystem component */
export interface ICabinetPhaseFormProps {

}
const CabinetCarousel = (props: ICabinetPhaseFormProps): JSX.Element => {
    const {landId} = useParams()
    const {width} = useScreenSize()
    const eventCarousel: any = useToposStore.getState().eventCarousel || {}
    const [isLoad, setIsLoad] = useState<boolean>(true)
    const [ start, setStart ] = useState( 0 )
    useEffect(() => { 
        actions(GET_FESTIVAL_SETTINGS_ACTION, {id: landId})
            .then(
                response => {
                    // console.log(response) 
                    setIsLoad(false)
                }
            ) 
    }, [])
    

    const onPrev = () => {
        setStart( start - 1 )
    }
    const onNext = () => {
        setStart( start + 1 )
    }
    // console.log( eventCarousel )
    
    return <div className='card mb-4 cabinet-carousel' >
        <div className="card-body p-0">
            <div className="d-flex justify-content-between align-items-center border-bottom-1 border-secondary ">
                <div className="flex-centered">
                    <h5 className='card-title pt-3 px-4 pb-1 text-nowrap'>
                        <span className="mr-3">
                            <i className="fas fa-marker"></i>    
                        </span> 
                        {__( "Prompt actions" )}
                    </h5>
                    { isLoad && <LoaderBallsLine /> }  
                </div> 
                <EventTypeLabel 
                    className=""
                    item={{ 
                        ...useFestDataStore.getState().site, 
                        eventTypes: eventTypes().filter(et => {
                            return useFestDataStore.getState().site.event_types?.includes(et.title)
                        }) 
                    }} 
                /> 
            </div>
            <Swiper
                // install Swiper modules
                modules={[ Navigation, Pagination, Scrollbar, A11y ]}
                spaceBetween={ 20 }  
                loop={true} 
                loopAddBlankSlides 
                slidesPerView={ width > WINDOW_WIDTH.TABLET ? 3 : 1 } 
                pagination={ true } 
                mousewheel
            >
                { 
                        <Fragment>
                            <SwiperSlide> 
                                <div className="p-3" >
                                    <RoleCarriersForm />
                                </div>                       
                            </SwiperSlide> 
                            <SwiperSlide> 
                                <div className="p-3" >
                                    <ExtendedUtilsForm />
                                </div>                       
                            </SwiperSlide> 
                            {
                                Object.keys(eventCarousel).map(key => {
                                    return <SwiperSlide key={key}>
                                        <EventCarouselItem item={eventCarousel[key]} name={key} />
                                    </SwiperSlide>
                                })
                            } 
                        </Fragment> 
                }
                {
                    Object.keys(eventCarousel).length > 1 && <PrevButton onClick={onPrev} />
                }
                {
                    Object.keys(eventCarousel).length > 1 && <NextButton onClick={onNext} /> 
                }
            </Swiper>
            
        </div>
    </div>
}
export default CabinetCarousel