import { getRoute } from '@/libs/layouts'
import { data_type_feed_route } from '@/libs/utilities/links'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router' 

const useProjectListRoute = () => {
    const [isRoute, setRoute] = useState<boolean>(false)
    const location = useLocation() 
    useEffect(() => { 
        setRoute(
            location.pathname.indexOf( 
                getRoute( data_type_feed_route("PEFestProject") ) ||  
                "-@@@-FFF-@@@-" 
            ) > -1 ||
            location.pathname.indexOf(  
                getRoute( data_type_feed_route("Ad") ) || 
                "-@@@-FFF-@@@-" 
            ) > -1 ||
            location.pathname.indexOf(                 
                getRoute( data_type_feed_route("PEAttachment") ) ||
                "-@@@-FFF-@@@-" 
            ) > -1 ||
            location.pathname.indexOf(                 
                getRoute( data_type_feed_route("PEDiary") ) ||
                "-@@@-FFF-@@@-" 
            ) > -1
        )
    }, [location])
    return isRoute
}
export default useProjectListRoute
