import { DIALOG_SIZE, IAttachment } from "@/libs/interfaces/layouts"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { ClipboardCopyBtn, Likes, Loading, ScalableDialog } from "@/libs/useful"
import { __, wordTrim } from "@/libs/utilities"
import { Button, Position } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import Moment from "react-moment"
import { useNavigate, useParams } from "react-router"
import { IAttachmentCardProps } from "./AttachmentCard"
import AttachmentStrokeMobile from "./AttachmentStrokeMobile"
import CategoryLabels from "./CategoryLabels"
import ResourceLabel from "./ResourceLabel"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import Bookmark from "@/libs/useful/Bookmark"

const AttachmentStrokeScreen = (props: IAttachmentCardProps) : JSX.Element => {
    const {attachmentId, landId} = useParams()
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState<boolean>(true)
    const [item, setItem] = useState<IAttachment|undefined >(props.item)
    useEffect(() => {
        if(props.item?.id) {
            setItem( props.item )
            setIsLoad(false)
        }
        else {
            actions(GET_SINGLE_ACTIONS, {
                data_type: "PEAttachment",
                id: attachmentId,
                land_id: landId
            })
            .then((res:IAttachment) => {
                setItem(res)
                setIsLoad(false)
            })
        }
    }, [props.item])


    let colClass = " col-6 " 
    if( props.params?.col )
    {
        switch(parseInt( props.params?.col ))
        {
            case 2:
                colClass = " col-md-6 "
                break;
            case 3:
                colClass = " col-md-4 "
                break;
            case 4:
                colClass = " col-md-3 "
                break;
            case 1:
            default:                
                colClass = " col-12 "
                break;

        } 
    }

    if(isLoad) {
        return <div className={`col-md-${colClass} my-3`}>
            <div className="thum-cont"  style={{height: 150}}>
                <div className="thumbnail" />
                <div className="muar"></div>
            </div> 
            <div className=" card attachment-stroke-content position-relative text-dark h-100 flex-centered  position-relative  " style={{height: 150}}>
                
            </div>
        </div>
    }
    const onAction = () => {
        switch(props.item?.type) {
            case SCALAR_TYPES.MEDIA:
            case SCALAR_TYPES.VK_VIDEO_ID:
            case SCALAR_TYPES.RUTUBE_ID: 
                onOpen()
                break
            case SCALAR_TYPES.STRING:
                onString()
                break
            case SCALAR_TYPES.URL:
            default:
                onUrl()
        }
    }
    const onUrl = () => {
        // console.log( props.item.type )
        window.open( props.item?.data, '_blank', 'noopener,noreferrer' )
    }
    const onResource = ( resource_type: string, resource_id: number ) => {
        if(props.params?.onResourceFilter) {
            props.params?.onResourceFilter( resource_type, resource_id )
        }
    }
    const onOpen = () => {  
        navigate(`modal/${item?.id}/content`)
    } 
    const onString = () => {
        navigate(`modal/${item?.id}/content`)
    }
    const onContent = () => {
        navigate(`modal/${item?.id}`)
    } 
    return <div className={`col-md-${colClass} my-3`}>
        <div className="attachment-stroke flex-sm-row flex-column text-force-contrast ">
            <div className="back card" />
            <div className="thum-cont" onClick={ onAction }>
                <div className="thumbnail" style={{backgroundImage: `url(${ item?.thumbnail })`}} />
                <div className="muar"></div>
            </div> 
            <div className={`icon ${item?.type} `} style={{backgroundImage: `url(/assets/img/topos/${item?.type}-type.svg)` }}></div>
            <div className=" attachment-stroke-content position-relative text-dark h-100 d-flex flex-column  position-relative  ">
                <div className="w-100 d-flex flex-column flex-grow-100 pb-4  position-relative ">
                    <div className=" px-3 pt-3 ">
                        <div className=" small mb-2 ">
                            <ResourceLabel item={item!} onClick={onResource}/>
                        </div>
                        <div className="title text-uppercase mb-1 pointer"  onClick={onContent}>
                            { item?.title }
                        </div> 
                        <div className=" mb-1 small pointer"  onClick={onContent}>
                            { wordTrim( (item?.post_content || ""), 14 ) }
                        </div>
                        <div className="position-absolute bottom left d-flex px-3 w-100 align-items-center">
                            <CategoryLabels item={item!}/>
                            <div className="text-uppercase small pb-2 px-1 ml-auto"> 
                                <Moment locale="ru" format="D.MM.YYYY ">
                                    { (Number(item!.post_date) * 1000) }
                                </Moment> 
                            </div> 
                            <div className="pb-2"> 
                                <ClipboardCopyBtn
                                    data={`${window.location.origin}/${getRoute(getFeedRoute("PEAttachment"))}/${item!.id}`}
                                    type="icon"
                                    label={__("Copy link")}
                                    position={Position.TOP_RIGHT}
                                /> 
                            </div>
                            <div className="pb-2"> 
                                <Bookmark item={item!} /> 
                            </div>
                            <div className="pb-2">                                
                                <Button minimal className="d-flex flex-nowrap mx-1 px-1" onClick={onContent}>
                                    <span className="far fa-comment" />
                                    <span className="mx-1">{ item!.comments?.length || 0 }</span>
                                </Button> 
                            </div>
                            <div className="pb-2">  
                                <Likes item={item!} dataType="PEAttachment" className="" />  
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
}
export default AttachmentStrokeScreen 

interface IAttachmentMobileProps extends IAttachmentCardProps{
    parentRoute: string
}
export const AttachmentMobile = ( props: IAttachmentMobileProps ) : JSX.Element => {
    const {attachmentId} = useParams()
    const navigate = useNavigate()
    const onParentRoute = () => {
        navigate(props.parentRoute)
    }
    return <ScalableDialog
            dialogSize={DIALOG_SIZE.MINIMAL}
            isOpen={true}
            mayFullScreen
            hideClose={ false }
            onClose={onParentRoute}
        >
            <div className="py-0 short-container">
                <div className="max-height overflow-y-auto px-0">
                    <AttachmentStrokeMobile {...props} isScreen className="p-0" cardClassName="m-0"/> 
                </div>
            </div>
        </ScalableDialog> 
}
export const AttachmentMobileContent = ( props: IAttachmentMobileProps ) : JSX.Element => {
    const navigate = useNavigate()
    const onParentRoute = () => {
        navigate(props.parentRoute)
    } 
    return <ScalableDialog
            isOpen={true}
            dialogSize={DIALOG_SIZE.NORMAL}
            mayFullScreen
            hideClose={ false }
            // title={props.item.title}
            onClose={onParentRoute}
        > 
            <AttachmentContent item={props.item!} />
        </ScalableDialog> 
} 

interface IAttachmentContent {
    item: IAttachment
}
export const AttachmentContent = (props:IAttachmentContent) => { 
    const {attachmentId, landId} = useParams() 
    const [isLoad, setIsLoad] = useState<boolean>(true)
    const [item, setItem] = useState<IAttachment>({} as IAttachment)
    useEffect(() => {
        if(props.item?.id) {
            setItem( props.item )
            setIsLoad(false)
        }
        else {
            actions(GET_SINGLE_ACTIONS, {
                data_type: "PEAttachment",
                id: attachmentId,
                land_id: landId
            })
            .then((res:IAttachment) => {
                setItem(res)
                setIsLoad(false)
            })
        }
    }, [props.item])

    if(isLoad) {
        return <div className={` h-100 ml-0 mr-0 min-height-350 `}>
             <div className={`card flex-column text-force-contrast `} style={{height:200}}>
                
            </div>
        </div>
    }
    switch( item.type ) {
        case SCALAR_TYPES.MEDIA:
            return <div className=" h-100 ml-0 mr-0 min-height-350 ">
                <img src={item.thumbnail} alt="" />
            </div>
        case SCALAR_TYPES.VK_VIDEO_ID:
        case SCALAR_TYPES.RUTUBE_ID: 
            return  <div className=" h-100 ml-3 mr-15 min-height-350">
                <FieldInput
                    type={item.type}
                    value={item.data}
                    width="100%"
                    height={"100%"}
                    rowClassName="h-100 min-height-350"
                />
            </div>
        case SCALAR_TYPES.STRING:
        default:
            return  <div className=" h-100 ml-3 mr-15 min-height-350">
                <FieldInput
                    type={item.type}
                    value={item.data}
                    width="100%"
                    height={"100%"}
                    rowClassName="h-100 min-height-350"
                />
            </div>
    }
}