import React, { useCallback, useEffect, useRef, useState } from "react"
import {useMainStore, IState} from '@/settings/zustand'
import ReactDOM from "react-dom"
import { __ } from "@/libs/utilities"
import {LayoutIcon} from '@/libs/useful'
import { NavLink } from "react-router-dom"
import { profile } from "src/libs/layouts" 
import { isCapability } from "@/libs/utilities"
import { login } from "src/libs/layouts"
import { initArea } from  "@/libs/utilities"
import LoginPanelPosMenuBtn from "@/libs/layout-app/header/LoginPanelPosMenuBtn"
import DayNightSwitcher from "./DayNightSwitcher"
import { Role } from "@/libs/interfaces/layouts"
 

const CurrentUserPanelWidget = (props: any):JSX.Element => {
    const [press, setPress] = useState(false)
    const [hover, setHover] = useState(false)
    const [height, setHeight] = useState<number>(0)
    const inputRef = useRef(null)
    const user: any = useMainStore(( state: IState ) => state.user)
    const setUser = useMainStore(( state: IState ) => state.setUser)

    const onMouseLeaveHandler = useCallback((e: any) => {
        const domNode = ReactDOM.findDOMNode( inputRef.current )
        if (!domNode || !domNode.contains(e.target)) {
            setPress(false)
            setHeight(0) 
        }
    }, [])
    useEffect(() => {
        document.body.addEventListener( "click", onMouseLeaveHandler )
        return () => document.body.removeEventListener( "click", onMouseLeaveHandler )
    }, [])

    const onLogout = () => {
        useMainStore.setState({ access_token: "" })
        setUser({})
    }
    const togglePress = ( bool: boolean ) : void =>
    {
        setPress(bool)
        setHeight( bool ? document.getElementById("person_menu_widget")!.clientHeight : 0 )
    }

    const isLogin = ():JSX.Element => {
        const profile_routing = profile()
		let profile_menu: any[] | JSX.Element
		if (profile_routing.length > 0) 
		{
			profile_menu = profile_routing.map((e, i) => {
				const isRole = isCapability(e.capability, user)
				if (isRole) return "" 
				return <LoginPanelPosMenuBtn key={i} menuItem={e}> 
					<NavLink
						className="" 
						to={`/${e.route}`} 
					>
						<LayoutIcon
                            isSVG
                            src={e.icon}
                            className="personal-menu__icon mr-3"
						/>
						{ __(e.title) }
					</NavLink>
				</LoginPanelPosMenuBtn>
			})
		} 
		else 
		{
			profile_menu = <li className="lmenu podmenu">
				<NavLink
					className=""
					to="/profile"
				>
					<LayoutIcon
                        isSVG
                        src="/assets/img/user.svg"
                        className="personal-menu__icon mr-3"
					/>
					{__("edit profile")}
				</NavLink>
			</li>
		}

        const login_panel = <div className="position-relative"  ref={inputRef} >
                <div 
                    className={"layout-left-btn " + ( press ? " active " : "" )} 
                    onMouseDown={ () => togglePress(!press) } 
                >
                    <div className="layout-menu-icon thumbnail-radius">
                        <LayoutIcon
                            style={{ width: 70, height: 70, borderRadius: '100%' }}
                            src={ user.avatar ? user.avatar : "fas fa-user"}
                            className="left-menu-icon"
                            isSVG={false}
                        />
                    </div>
                    <div className={`layout-menu-left-label ${ hover ? "hover" : null}`}>
                        { user.display_name}
                    </div>
                </div> 
                <div className="logined-menu lm-widget" style={{ height }} >
                    <ul id="person_menu_widget">
                        {profile_menu}
                        <li className="lmenu podmenu">
                            <div className='pl-4 py-2'>
                            {
                                user.roles.map((role: Role) => <div className='p-1 small opacity_5 text-light ml-4' key={role}>{ __( role ) }</div>)
                            }
                            </div>
                        </li>
                        <li className="lmenu podmenu px-3">
                            <DayNightSwitcher type="buttons" className="text-light"/>
                        </li>
                        <li onClick={ onLogout } className="lmenu podmenu exit">
                            <LayoutIcon
                                isSVG
                                src="fas fa-sign-out-alt"
                                className="personal-menu__icon mr-3"
                            />
                            {__("logout")}
                        </li>
                    </ul>
                </div>            
            </div>

        return <>
            {
                initArea(
                    "login_panel",
                    { ...props },
                    login_panel,
                )
            }
        </> 
    }
    const isUnlogin = ():JSX.Element => {
        return <NavLink 
            className={"layout-left-btn " + ( press ? " active " : "" )}
                to={'/login'} 
        >
            <div className="layout-menu-icon">
                <LayoutIcon
                    isSVG={ false }
                    src={ "fas fa-sign-in-alt" }
                    className="left-menu-icon"
                />
            </div>
            <div
                className={`layout-menu-left-label `}
            >
                {__("log in")}
            </div>
        </NavLink>
    }
    return user.id 
        ?
        isLogin()
        :
        isUnlogin()
} 

export default CurrentUserPanelWidget
// class CurrentUserPanelWidget extends Component 
// {
//     constructor(props)
//     {
//         super(props)
//         this.state = {
//             hover:false,
//             press: false ,
//             height: 0, 
//             current: this.props.current 
//         }
//     }
    
// 	componentDidMount() {
//         document.body.addEventListener("click", this.onMouseLeaveHandler)
//     }
//     componentWillUnmount() {
//         document.body.removeEventListener("click", this.onMouseLeaveHandler)
//     }
//     onMouseLeaveHandler = (e) => {
//         const domNode = ReactDOM.findDOMNode(this)
//         if (!domNode || !domNode.contains(e.target)) {
//           this.setState({
//             press: false ,
//             height:0
//           })
//         }
//       }
    
//     unlogined = () =>
//     {
//         return initArea(
//             "unlogin_panel",
//             { ...this.props },
//             <NavLink 
//                 className={"layout-left-btn " + (this.state.press ? " active " : "" )}
//                     to={login} 
//             >
//                 <div className="layout-menu-icon">
//                     <LayoutIcon
//                     src={ "fas fa-sign-in-alt" }
//                     className="left-menu-icon"
//                     />
//                 </div>
//                 <div
//                     className={`layout-menu-left-label ${this.state.hover ? "hover" : null}`}
//                 >
//                     {__("log in")}
//                 </div>
//             </NavLink>
//         )
//     }
//     logined = () =>
//     {
//         const { user } = this.props
// 		const profile_routing = profile()
// 		let profile_menu
// 		if (profile_routing.length > 0) 
// 		{
// 			profile_menu = profile_routing.map((e, i) => {
// 				const isRole = isCapability(e.capability, this.props.user)
// 				if (isRole) return ""
// 				let children
// 				if(Array.isArray(e.children) && e.children.length > 0)
// 				{
// 					children = <>
// 						<div className="lmenu-children" >

// 						</div>
// 					</>
// 				}
// 				else
// 				{

// 				}
// 				return <li className="lmenu" key={i}>
// 					{children}
// 					<NavLink
// 						className=""
// 						activeClassName="active"
// 						to={`/${e.route}`} 
// 					>
// 						<LayoutIcon
//                             isSVG
//                             src={e.icon}
//                             className="personal-menu__icon mr-3"
// 						/>
// 						{ __(e.title) }
// 					</NavLink>
// 				</li>
// 			})
// 		} 
// 		else 
// 		{
// 			profile_menu = <li className="lmenu">
// 				<NavLink
// 					className=""
// 					activeClassName="active"
// 					to="/profile"
// 				>
// 					<LayoutIcon
//                         isSVG
//                         src="/assets/img/user.svg"
//                         className="personal-menu__icon mr-3"
// 					/>
// 					{__("edit profile")}
// 				</NavLink>
// 			</li>
// 		}

//         const login_panel = <div className="position-relative">
//                 <div 
//                     className={"layout-left-btn " + (this.state.press ? " active " : "" )} 
//                     onMouseDown={() =>this.togglePress(!this.state.press)} 
//                 >
//                     <div className="layout-menu-icon">
//                         <LayoutIcon
//                             src={"fas fa-user"}
//                             className="left-menu-icon"
//                         />
//                     </div>
//                     <div className={`layout-menu-left-label ${this.state.hover ? "hover" : null}`}>
//                         {this.props.user.display_name}
//                     </div>
//                 </div> 
//                 <div className="logined-menu lm-widget" style={{ height: this.state.height }} >
//                     <ul id="person_menu_widget">
//                         {profile_menu}
//                         <li onClick={this.logout} className="lmenu exit">
//                             <LayoutIcon
//                                 isSVG
//                                 src="/assets/img/logout.svg"
//                                 className="personal-menu__icon mr-3"
//                             />
//                             {__("logout")}
//                         </li>
//                     </ul>
//                 </div>            
//             </div>

//         return <>
//             {
//                 initArea(
//                     "login_panel",
//                     { ...this.props },
//                     login_panel,
//                 )
//             }
//         </> 
//     }
//     onToggle = (evt) => {
//         // console.log( document.getElementById("person_menu").clientHeight );
//         this.setState({
            
//         })
//     }

//     logout = () => { 
//         localStorage.removeItem("token")
//         this.props.refetchUser()
//     }



//     render() 
//     {
//         //console.log( this.props )
//         return this.props.user?.id > 0
//             ?
//             this.logined()
//             :
//             this.unlogined()
//     }
//     toggleHover() {
//         this.setState({ hover: !this.state.hover })
//     }
//     togglePress = bool =>
//     {
//         this.setState({ 
//             press: bool,
//             height: bool ? document.getElementById("person_menu_widget").clientHeight : 0, 
//         })
//     }
// }
// export default compose(
//     withApollo,
//     withRouter,
//   )( CurrentUserPanelWidget )