import { useEffect, useRef, useState } from "react"
//import * as QRCode from 'easyqrcodejs'
import { getQRDefaultData } from "./getQRDefaultData";

const QRCode = require('easyqrcodejs')

const QRCodeSign = (props: any) =>
{
    let QRcode = useRef(null);  
    let QRCodeObject: any
    const [logo, setLogo] = useState(props.options?.logo || "")
    
    useEffect(() =>
    { 
        //console.log(props.options )
        if(props.options?.logo)
        {
            toDataUrl( props.options.logo, ( result: any ) => {
                //console.log(result)
                setLogo( result )
                createQRCode()
            })
        }
        else
        {
             createQRCode()
        }           
    }, [ props ]  )

    const createQRCode = () => {
        // Options
        var options = {
            ...getQRDefaultData(),
            text: "https://github.com/ushelp/EasyQRCodeJS",
            ...(props.options || {}),
            logo: logo,
            thumbnail:logo
        }
        if( !QRcode.current ) return
        // console.log( options, QRcode.current )
        // clear
        // if( QRcode.current.firstElementChild)
        // {
        //     QRcode.current.firstElementChild.remove()
        // }
        (QRcode.current as HTMLDivElement).innerHTML = ""
        // Create new QRCode Object
        QRCodeObject = new QRCode( QRcode.current, options)
        console.log( QRCodeObject )
    }
    
    const toDataUrl = (url: string, callback: any) => {
        var xhr = new XMLHttpRequest()
        xhr.onload = function() {
            var reader = new FileReader()
            reader.onloadend = function() {
                callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
        };
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    }
 

    return <> 
        <div
            id={props.id} 
            ref={QRcode} 
            className="pe-qr-cont"
            data-name={
                props.item?.title 
                    ? 
                    props.item.title?.replace( /[//, :,?,=,.]/g, "_" )  
                    : 
                    props.options?.text?.replace( /[//, :,?,=,.]/g, "_" ) 
            } 
            style={{ width: (props.options?.width || 240 ) + 20 , height: (props.options?.height || 262) + 20 }}
        /> 
    </>

}
export default QRCodeSign;
