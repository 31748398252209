import { IAttachment, ID } from "@/libs/interfaces/layouts"
import { IState, useMainStore } from "@/settings/zustand"
import { Icon, Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ICategoryLabelsProps {
   item: IAttachment
}
const CategoryLabels = ({item, ...props}: ICategoryLabelsProps): JSX.Element => {
    const attachmentFilter  = useMainStore((state: IState) => state.attachmentFilter)
    // const land_id           = useMainStore( (state:IState) => state.landId )

    const onClick=(cat_id: ID) => {
        useMainStore.setState({
            attachmentFilter: { category_id: Number(cat_id) }
        })
    }
    const onClearFilter = () => {
        useMainStore.setState({
            attachmentFilter: { category_id: -1 }
        })
    }
    return <div className="smal d-flex mb-2"> 
    {
        item.category?.map(cat => <div className="d-flex small" key={cat.id || Math.random()}>
            <Tag
                style={{
                    //backgroundColor: schema().category.admin_data?.fill[0],
                    minHeight: 16,
                    margin: "0 2px 2px 0"
                }}
                minimal
                className="pointer rounded-0 p-0"  
                onClick={() => onClick(cat.id)}
            >
                <span className="small px-2 text-secondary">
                   {cat.title}
                </span>
            </Tag> 
            
        </div> ) 
    }
    {
        !!attachmentFilter.category_id && attachmentFilter.category_id?.toString() !== "-1" && 
        <div className="ml-2 p-0 pointer hover" onClick={onClearFilter}>
            <Icon icon="cross" />
        </div>
    }
    </div>
    
}
export default CategoryLabels