import { IAttachment } from "@/libs/interfaces/layouts"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import { ClipboardCopyBtn, CommentInputForm, Loading } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Position } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import Moment from "react-moment"
import { useNavigate, useParams } from "react-router"
import actions from "../../data/actions"
import { GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import CommentFeed from "../comment/CommentFeed"
import ActionButton from "./ActionButton"
import { IAttachmentCardProps } from "./AttachmentCard"
import CategoryLabels from "./CategoryLabels"
import ResourceLabel from "./ResourceLabel"

export interface IAttachmentStrokeMobile extends IAttachmentCardProps {
    isScreen:boolean
}

const AttachmentStrokeMobile = (props: IAttachmentStrokeMobile) : JSX.Element => {
    const {attachmentId, landId} = useParams()
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState<boolean>(true)
    const [item, setItem] = useState<IAttachment|undefined >(props.item)
    useEffect(() => {
        if(props.item?.id) {
            setItem( props.item )
            setIsLoad(false)
        }
        else {
            actions(GET_SINGLE_ACTIONS, {
                data_type: "PEAttachment",
                id: attachmentId,
                land_id: landId
            })
            .then((res:IAttachment) => {
                setItem(res)
                setIsLoad(false)
            })
        }
    }, [props.item])

    if(isLoad) {
        return <div className={` ${ props.className || "col-12 mb-2 pr-4" }`}>
             <div className={`card flex-column text-force-contrast ${props.cardClassName || "mr-2"}`} style={{height:200}}>
                
            </div>
        </div>
    }

    return <div className={` ${ props.className || "col-12 mb-2 pr-4" }`}>
        <div className={`card flex-column text-force-contrast ${props.cardClassName || "mr-2"}`}>
            <div className="d-flex p-2"> 
                <div 
                    className="thumbnail" 
                    style={{
                        backgroundImage: `url(${ item!.thumbnail })`,
                        minWidth: 120,
                        maxWidth: 120,
                        height: 120,
                        marginRight: 20,
                        marginBottom: 20,
                        position:"relative",
                    }} 
                /> 
                <div className="flex-grow-100">
                    <div className=" small mb-2 ">
                        <ResourceLabel item={ item! } />
                    </div>
                    <div className="title text-uppercase mb-2">
                        { item!.title }
                    </div>
                </div> 
            </div>
            <div className=" position-relative text-dark h-100 d-flex flex-column  position-relative  px-2 ">
                <div className="w-100 d-flex flex-column flex-grow-100 pb-4  position-relative ">
                    <div className=" px-3 pt-3 "> 
                        <div className=" mb-2 small" dangerouslySetInnerHTML={{__html: item!.post_content}} />
                        <CategoryLabels item={item!}/>
                        <div className="position-relative d-flex w-100 align-items-center">
                            <div className="pb-2 ml-15 pl-1">
                                <ActionButton {...props} item={item!}/>
                            </div>  
                        </div>
                        <div className="position-relative d-flex w-100 align-items-center"> 
                            <div className="text-uppercase small ">
                                <Moment locale="ru" format="D.MM.YYYY ">
                                    { item!.date }
                                </Moment>
                            </div>
                            <div className="pl-2">    
                                <ClipboardCopyBtn
                                    data={`${window.location.origin}/${getRoute(getFeedRoute("PEAttachment"))}/${item!.id}`}
                                    type="icon"
                                    label={__("Copy link")}
                                    position={Position.TOP_RIGHT}
                                /> 
                            </div>
                            <div className=" ">                                
                                <div className="d-flex flex-nowrap mx-1 px-2">
                                    <span className="far fa-comment" />
                                    <span className="mx-1">{ item!.comments?.length || 0 }</span>
                                </div> 
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <CommentInputForm 
                item={item!} 
                className="" 
                noTitle 
                discussion_type="post"
                autoFocus
            />
            <CommentFeed item={item!}  />
        </div> 
    </div> 
}

export default AttachmentStrokeMobile