import { __ } from "@/libs/utilities"
import { useDataStore } from "../../data/store"
import { useEffect } from "react"
import actions from "../../data/actions"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { HasId, ICategory } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"

/* Protopia Ecosystem component */
export interface ICategoryFilterProps {
   
}
const CategoryFilter = (props: ICategoryFilterProps): JSX.Element => {
    const landId = useMainStore((state: IState) => state.landId)
    const attachmentFilter  = useMainStore((state: IState) => state.attachmentFilter)
    const categories: ICategory[] = useDataStore((state: any) => state.Category || [])
    // useEffect(() => {
    //     actions(GET_FEED_ACTION, {
    //         land_id: landId,
    //         data_type: "Category"
    //     })
    // })
    const onChange = (value: any) => {
        console.log(value )
        useMainStore.setState({
            attachmentFilter: { category_id: Number( value.id ) }
        })
    }
    return !!categories.length
        ?
        <div className='pe-categoryfilter-container mx-3 small '>
            <FieldInput
                type={SCALAR_TYPES.EXTERNAL_TAGS}
                component="Category"
                value={ categories.filter( (c: HasId) => c.id.toString() === attachmentFilter.category_id?.toString() )[0] }
                editable
                onChange={ onChange }
                landId={landId}
            />
            {/* <select 
                placeholder={__("Select category")}
                onChange={onChange} 
                className="form-control input dark"
                value={ attachmentFilter.category_id }
            >
                <option value="-1"></option>
                {
                    categories.map(cat => <option key={cat.id} value={cat.id}>
                        <div className="small">{cat.title}</div>
                    </option>)
                }
            </select> */}
        </div>
        :
        <></>
}
export default CategoryFilter