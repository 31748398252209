import actions from "src/modules/pe-basic-module/data/actions"
import { GET_ALL_ROLES_ACTION } from "src/modules/pe-basic-module/data/actionTypes"
import { useEffect, useState } from "react"
import { Role } from "src/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { VerticalType } from "src/libs/scalars/interface/IScalarForm"
import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IEventForRoleFormProps {
    onViewRoles: (value: Role[]) => void
    viewRoles: Role[]
}
const EventForRoleForm = (props: IEventForRoleFormProps): JSX.Element => {
    const [roles, setRoles] = useState<Role[]>([])
    useEffect(() => {
        actions(GET_ALL_ROLES_ACTION, {}).then(res => { 
            setRoles([ "SuperAdmin", ...res, "User without role" ] )
        })
    }, [])
    return <div className='pe-eventforroleform-container'>
       <div className="display-6 my-3">{__("Show only for Roles")}</div>
        <div className="pb-5"> 
            <FieldInput
                type={SCALAR_TYPES.CHECKBOX}
                value={ props.viewRoles }
                values={[ ...roles ]}
                onChange={props.onViewRoles}
                vertical={VerticalType.VERTICAL}
                editable
                width="100%"
            />
        </div> 
    </div>
}
export default EventForRoleForm