import { IPEMessage } from "@/libs/interfaces/layouts"
import { getFeedRoute, getRoute } from "@/libs/layouts"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { Link } from "react-router-dom"
import { MENU_MESSAGE_SEED } from "../MessagesWidget"

/* Protopia Ecosystem component */
export interface IMessagesScreenProps {
   
}
const MessagesScreen = (props: IMessagesScreenProps): JSX.Element => { 
    const messages: IPEMessage[] = useDataStore((state: any) => state["PEMessage" + MENU_MESSAGE_SEED] || [] )
    const user: IUser = useMainStore((state: IState) => state.user)
    const landId = useMainStore((state:IState) => state.landId)
 

    const length = messages.filter(m => !m.is_viewed).length
    return landId > 0 && user?.id
        ?
        <div>
            <div className={ landId > 0 ? `opened`: `closed` }>
                <div className={` `}>
                    <Link to={ getRoute( getFeedRoute("PEMessage") ) } className=" header-menu-icon hover mr-md-4  d-flex justify-content-center">
                        <i className=" far fa-comments mb-0 text-force-contrast " style={{fontSize: "1.3rem"}}/>
                        <div 
                            className={`messages-widget-label hover bg-danger untouchble ${ !length ? "d-none" : ""}`} 
                            id={ "PEMessage" + MENU_MESSAGE_SEED }
                        >
                            { length > 90 ? "> 90" : length }
                        </div>
                    </Link> 
                </div>
            </div>
        </div>
        :
        <></>
}
export default MessagesScreen