import { ATTACHMENT_CARD_TYPE, DIALOG_SIZE, IAttachment } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { ScalableDialog } from "@/libs/useful"
import { wordTrim } from "@/libs/utilities"
import { useState } from "react"
import AttachmentStroke from "./AttachmentStroke"
import { Popover } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IAttachmentCardProps {
   item?: IAttachment
   cardType?: ATTACHMENT_CARD_TYPE
   className?: string
   cardClassName?: string
   params?: any
}
const AttachmentCard = ( props: IAttachmentCardProps ): JSX.Element => {
    switch(props.cardType) {
        case ATTACHMENT_CARD_TYPE.STROKE:
            return <AttachmentStroke  {...props} />
        case ATTACHMENT_CARD_TYPE.CARD:
        default:
            return <Card {...props} />    
    }
    
}
const Card = ( props: IAttachmentCardProps ): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>( false )
    const onUrl = () => {
        console.log( props.item?.type )
        window.open( props.item?.data, '_blank', 'noopener,noreferrer' )
    }
    const onOpen = () => { 
        setIsOpen( !isOpen )
    } 
    const onString = () => {
        setIsOpen( !isOpen )
    }

    switch(props.item?.type) { 
        case SCALAR_TYPES.MEDIA:
            return <>
                <AttacmentThumbnail item={props.item} onClick={ onOpen } />
                <ScalableDialog
                    isOpen={isOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    mayFullScreen
                    hideClose={ false }
                    // title={props.item.title}
                    onClose={onOpen}
                > 
                    <div className=" h-100 ml-0 mr-0 min-height-350 ">
                        <img src={props.item.thumbnail} alt="" />
                    </div>  
                </ScalableDialog>
            </>
        case SCALAR_TYPES.VK_VIDEO_ID:
        case SCALAR_TYPES.RUTUBE_ID: 
            return <>
                <AttacmentThumbnail item={props.item} onClick={ onOpen } />
                <ScalableDialog
                    isOpen={isOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    mayFullScreen
                    hideClose={ false }
                    // title={props.item.title}
                    onClose={onOpen}
                > 
                    <div className=" h-100 ml-3 mr-15 min-height-350">
                        <FieldInput
                            type={props.item.type}
                            value={props.item.data}
                            width="100%"
                            height={"100%"}
                            rowClassName="h-100 min-height-350"
                        />
                    </div>  
                </ScalableDialog>
            </>
        case SCALAR_TYPES.STRING:
            return <>
                <AttacmentThumbnail item={props.item} onClick={ onString } />
                <ScalableDialog
                    isOpen={isOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    mayFullScreen
                    hideClose={ false }
                    onClose={onOpen}
                > 
                    <div className=" h-100 ml-3 mr-0 py-3 min-height-350 max-height overflow-y-auto">
                        <div className="px-4 pt-4 pb-2 lead"> { props.item.title } </div>
                        <div className="px-4 pt-2 pb-4" dangerouslySetInnerHTML={{__html:  props.item.data}} />
                    </div>  
                </ScalableDialog>
            </>
        case SCALAR_TYPES.URL:
        default:
            return <AttacmentThumbnail item={props.item!} onClick={ onUrl } />
    }
    
}
export default AttachmentCard


interface IAttachmentThumbnailProps {
    item: IAttachment
    onClick: () => void
}
const AttacmentThumbnail = (props:IAttachmentThumbnailProps): JSX.Element => {
    return !!props.item.post_content
        ?
        <Popover
            position="top"
            interactionKind="hover"
            popoverClassName="untouchble"
            modifiers={{
                arrow: { enabled: false },
                flip: { enabled: true },
                preventOverflow: { enabled: false },
                offset: { enabled: true, options: {
                    offset: [ 0, -10],
                } }
            }}
            content={
                <div className="p-4 text-light flex-centered" style={{width: 160, height: 160, backgroundColor: "#292929"}}>
                    { wordTrim(props.item.post_content || props.item.content || "", 7) }
                </div>
            }
        >
            <ACard {...props} />
        </Popover>
        :
        <ACard {...props} />
}

const ACard = (props: IAttachmentThumbnailProps) : JSX.Element => {
    return <div className={`topos-card-news attachment-card ${props.item.type}`}>
        <div className="thum-cont">
            <div 
                className='thumbnail'
                data-attachment-id={ props.item.id } 
                style={{
                    backgroundImage:`url(${props.item.thumbnail})`
                }}
                title={ props.item.title }
                onClick={props.onClick}
            >
                { props.item.thumbnail !== "" ? "" : props.item.type }
            </div>
            <div className="muar"></div>
        </div> 
        <div className={`icon ${props.item.type} `} style={{backgroundImage: `url(/assets/img/topos/${props.item.type}-type.svg)` }}>

        </div>
        <div className="_title">
            <div className={`icon-title   `} style={{backgroundImage: `url(/assets/img/topos/${props.item.type}-type.svg)` }} />
            {wordTrim(props.item.title, 4)}
        </div>       
    </div>
}