import { ID, IMedia } from "@/libs/interfaces/layouts"
import { Button, Dialog } from "@blueprintjs/core"
import { useState } from "react"

interface IGalleryItemProps {
    i: number
    url: IMedia
    width?:number | string
    height?: number
    type?: "image" | "div" | "span"
    galleryId: ID
    gallery?: any[]
    to?: (i:number) => void
    padding?: number
}
export const GalleryItem = ( 
{
    i, 
    url={ thumbnail: "", title: "" } as IMedia, 
    galleryId,
    gallery, 
    type, 
    width,
    height,
    to,
    ...props
}: IGalleryItemProps ) :JSX.Element => {
    const padding = props.padding || 0;
    const [isOpen, setIsOpen] = useState(false)
    const onOpen = () : void => {
        if(url)  {
            !gallery ? setIsOpen(!isOpen) : to ? to(i) : <></>
        } 
    } 
    let thumbnail : JSX.Element
    const mediaSrc = url?.thumbnail || ""
    switch(type) {        
        case "image" :
            thumbnail = <img 
                src={ mediaSrc.toString() } 
                className="thumbnail gallery-item pointer" 
                style={{ minWidth: width, width, height, margin: `${ padding }px ${ padding / 2 }px ` }} 
                onClick={ onOpen }
                alt=""
            />
            break
        case "span": 
            thumbnail = <span 
                className={`thumbnail gallery-item pointer`} 
                style={{backgroundImage: `url(${ mediaSrc })`, width, height, margin: `${ padding }px ${ padding / 2 }px ` }} 
                onClick={onOpen}
            />
            break
        case "div":
        default:
            thumbnail = <div 
                className="thumbnail gallery-item pointer" 
                style={{ backgroundImage: `url(${ mediaSrc })`, width, height, margin: `${ padding }px ${ padding / 2 }px ` }} 
                onClick={onOpen}
            />
            break
    }
    return <>
        {thumbnail}
        <Dialog
            isOpen={isOpen}
            onClose={onOpen}
            className="large flex-centered transparent"
        >
            <div className=" position-relative gallery-dialog"> 
                <img 
                    src={ mediaSrc.toString() } 
                    alt="" 
                    style={{ maxHeight:"90vh", width: "100%", maxWidth:800 }} 
                />
                <div className="position-absolute top right m-2">
                    <Button onClick={onOpen} icon="cross"></Button>
                </div>            
            </div>
        </Dialog>
    </>
}

export default GalleryItem