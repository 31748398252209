import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'
import { __ } from '@/libs/utilities'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'
import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'
import { IFestival, IProject } from '../../data/interfaces'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from '../../data/store'
import { useUserOwner } from '../../hooks/project'
import PhaseLabel from '../PhaseLabel'
import ProjectData from './ProjectData'
import setActive from '../utils/setActive'

const ProjectContainer = (props:any):JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    const nav = useNavigate()
    
    return <div>
        <ProjectData {...props} item={item} />
        <div className="d-flex w-100 justify-content-center">
            <div className="mx-2"> 
                <PhaseLabel />
            </div>     
            <Links /> 
            {/* <Popover
                content={<div className='p-3'>
                    {__("Ratings report")}
                </div>}
                interactionKind='hover'
            >
                <Button minimal icon="archive" onClick={onReport} />
            </Popover>                   */}
        </div>
        <Outlet/>
    </div>
}
 

export default ProjectContainer

const Links = () : JSX.Element | null => {    
    const festival:IFestival = useFestDataStore( ( state:IFestDataStor )  => state.festival )
    const navigate = useNavigate()
    const item: IProject = useFestProjects( ( state: IFestProjectsProps ) => state.currentProject )  
    
    const isOwner: boolean = useUserOwner( item ) 
    const {width}   = useScreenSize()
    
    let links: any[] = []
    if(isOwner) {
        links = links.concat(
            links, 
            [
                {
                    title: __("Project team"),
                    route: "project-team",
                    _id: "project-team",
                },
                {
                    title: __("Project management"),
                    route: "management",
                    _id: "management",
                },
                {
                    title : __("Ratings report"),
                    route: "ratings-report",
                    _id: "ratings-report"
                },
                {
                    title : __("Diary"),
                    route: "diary",
                    _id: "diary"
                }
            ]
        )
    }
    const [select, setSelect] = useState<any>( links[0] )

    if( !festival.isDiary  && !isOwner ) return null

    if(festival.isDiary){
        links.splice(0, 0, {title: "Diary", route: "diary"})
    }
    if( !!links.length ) links.unshift({title: "Ratings", route: "ratings"})
    

    const onChoose = ( value: any ) => {
        console.log( value )
        setSelect(value)
        navigate(value)
    }

    return width > WINDOW_WIDTH.TABLET
        ?
        <>
            {
                links.map((l:any) => <NavLink
                    key={l.route}
                    to={l.route}
                    className={ setActive }
                >
                    {__(l.title)}
                </NavLink>  )
            } 
        </>
        :
        links
            ?
            <div className='w-100'>
                <div className="text-center">
                    { __( "Manage sections" ) }:
                </div>
                <FieldInput
                    type={SCALAR_TYPES.SELECT}
                    editable
                    onChange={ onChoose }
                    value={select}
                    values={links}
                    notClear
                />
            </div>
            
            :
            <></>
}