import React, { useEffect, useState } from 'react' 
import { __ } from '@/libs/utilities'
import { CARD_TYPE, IFestival, IProject } from '../../data/interfaces' 
import { actions } from '../../data/actions'
import { GET_HONNOR_HALL_ACTIONS } from '../../data/actionTypes'
import CardProject from '../projectList/CardProject'
import { Collapse } from '@blueprintjs/core'
import StrokeProject from '../projectList/StrokeProject'
import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { useParams } from 'react-router'
import { IFestDataStor, useFestDataStore } from '../../data/store'

const Laureates = (props: any) : JSX.Element | false => {
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    const {landId} = useParams()
    const [projects, setProjects] = useState<IProject[]>( [] as IProject[] ) 
    const [isOpen, setOpen] = useState<boolean>(false)
    const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
    useEffect(() => setOpen( !!projects.length ), [ projects ])
    useEffect(() => {
        actions(GET_HONNOR_HALL_ACTIONS, {count:3, land_id: landId}).then((resp : IProject[]) => {
            //console.log( resp ) 
            setProjects( resp ) 
        })
    }, [])
    useEffect(() => {
        const onResizeHandler = () => {
            setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
        }
        window.addEventListener("resize", onResizeHandler)
        return () => window.removeEventListener("resize", onResizeHandler)
    }, [])
    const laureates: IProject[] =  projects.filter( project => project.nomination !== "" && project.prize !== "" )
    return !!festival.isShowLaureates && 
        !!laureates.length
        &&
        <section id="laureats">
            <Collapse isOpen={isOpen} transitionDuration={30000} >
                <div className=" colored ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 d-flex justify-content-center pt-5 pb-3">
                                <div className="role_descr">
                                    {__("Honnor Hall. Our laureates")}
                                </div>
                            </div>
                            {
                            
                                laureates.map((project: IProject, i:number) => {
                                    return isMobyle
                                    ?
                                    <StrokeProject
                                        key={project.id}
                                        item={project}
                                        type={CARD_TYPE.CARD}
                                        i={i}
                                    />
                                    :
                                    <CardProject
                                        key={project.id}
                                        item={project}
                                        type={CARD_TYPE.CARD}
                                        i={i}
                                    />
                                })
                            }   
                        </div>
                    </div>
                </div>
            </Collapse> 
        </section>
} 

export default Laureates