import { SyntheticEvent, useEffect, useState } from "react";
// import Prism  from "prismjs" 
// import "prismjs/components/prism-clike";
// import "prismjs/components/prism-javascript";
// import "prismjs/themes/prism.css"; //Example style, you can use another
import { default as UUID } from "node-uuid";
import TextareaAutosize from "react-textarea-autosize";


const handlePreview = (__code:string = "") : string => {
    return __code
    .replaceAll('<p>', '')
    .replaceAll('</p>', '\n')
}

const CLOSED_HEIGHT: number = 120
const CodeEditor = (props: any) : JSX.Element =>
{

    // const [isOpen, setIsOpen] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>( handlePreview(props.value) || "" )
    const [code, setCode]       = useState<string>( props.value );
    const [uid]                 = useState<string>(UUID.v4())
    const [startSelect, setStartSelect] = useState<number>(-1)
    const [endSelect, setEndSelect] = useState<number>(-1)
    useEffect(() => {
        setCode(props.value)
        setPreview( handlePreview(props.value) || "" )
    }, [props.value])
    const onPreview = (_code: string) => {
        setPreview(handlePreview(_code)) 
    }
    // const open = ( bool:boolean = false ) => {
    //     const textContainer:Element | null = document.querySelector(`.editor_${uid}`);
    //     //console.log( textContainer )
    //     if(!textContainer) return
    //     const element: HTMLElement = textContainer.querySelector(".npm__react-simple-code-editor__textarea") as HTMLElement
    //     const parent = element.parentElement as HTMLElement
    //     const grandParent = parent.parentElement as HTMLElement
    //     const height: string = (bool ?  15 + element.scrollHeight :  CLOSED_HEIGHT) + "px";
    //     element.style.height = "1px"; 
    //     parent.style.height = "1px"; 
    //     grandParent.style.height = "1px"; 
    //     element.style.height = height; 
    //     parent.style.height = height; 
    //     grandParent.style.height = height; 
    // } 

    useEffect(() => {
        if(code !== props.value)
        {
            setCode(props.value)
        }
        setTimeout(() => {
            //open()
        }, 120)
    }, [])
    const onEdit = (string: string) =>
    {
        let _code: string =  string
        //.replace(/(<p>[^<>].*[^<>]<\/p>)/g, '\n')
        //.replace(/-(^| )/g, ' — $1') 
        .replace(/(?<=\=)^|a href\=/g, "a target='_blank' rel='noopener' href=") 
        .split(`\n`)
        .join("</p><p>")
        .replace(/["]/g, "'")
        // .replace(/(?<=\=)"(?=\>)/i, "'")
        // .replace(/(^| )"/g, '$1«')
        // .replace(/"($|)/g, '»$1')
        // .replace(/"($| )/g, '»$1')
        // .replace(/(?<=\=)«(?=\>)/i, "'")
        // .replace(/(?<=\=)»(?=\>)/i, "'")
        //console.log( _code )
        //console.log( handlePreview(_code) )
        setCode(_code)
        onPreview(_code)
        if(props.onChange)
        {
            props.onChange(_code)
        }
    }
    const onSelect = ( e: SyntheticEvent<HTMLTextAreaElement>) => {
        // console.log( e )
        const target: any   = e.target
        const start:number  = target.selectionStart
        const end:number    = target.selectionEnd
        setStartSelect(start)
        setEndSelect(end)
        showContextMenu(start,end)
        console.log( start, end, target.value.substring(start, end) )

    }
    const showContextMenu = (start:number, end: number) => {

    }
    // const loadFonts = () =>
    // {
    //     if($("head link#monoFont").length === 0)
    //     {
    //         $("head").append(`<link id="monoFont" href="https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap" rel="stylesheet">`)
    //     }
    // }
    // useEffect(() => {
    //     Prism.manual = true;
    //     Prism.highlightAll();
    //     loadFonts()
    // }, [code])
    // const changeOpen = () => {
    //     open( !isOpen )
    //     setIsOpen(!isOpen)
    // }
    return <div className="w-100 d-flex flex-column">
        <input type="text" value={code} hidden readOnly />
        <TextareaAutosize
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 14,
                // minHeight: CLOSED_HEIGHT,
                width: "100%", 
                background:"transparent"
                // color: "#111!important"
            }}
            className={`editor_${uid} text-force-contrast`}
            onChange={(evt) => onEdit( evt.target.value )}
            onSelect={onSelect}
            value={preview}
            autoFocus={!!props.autoFocus}
            placeholder={props.placeholder}
        > 
        </TextareaAutosize>

         {/*
         <div className="hidden">
           
            <Editor
                value={code}
                onValueChange={ onEdit }
                highlight={(code: any) =>  Prism.highlight(code, Prism.languages.html, 'html')}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 14,
                    // color: "#111!important"
                }}
                className={`editor_${uid}`}
            />
            <div>
                <Button minimal  onClick={ changeOpen }>
                    <Icon icon={ isOpen ? "chevron-up" : "chevron-down" } size={22} />
                </Button>
            </div>            
            
        </div>
        */}
        
    </div>
        
}

export default CodeEditor