import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { ICategory, ICritery, IMilestone, IProject, IRating } from '@/modules/pe-fest-module/data/interfaces'
import { IFestProjectsProps, useFestProjects } from '@/modules/pe-fest-module/data/store'
import { useIsExpert } from '@/modules/pe-fest-module/hooks/project'
import CriteryRatingEngine from './CriteryRatingEngine'
import CriteryRatingView from './CriteryRatingView'
import CorrectSingleRatingForm from './CorrectSingleRatingForm'

interface ICriteryRatingExpertDisplayProps {
    critery: ICritery 
    category: ICategory | null 
}
const CriteryRatingExpertDisplay = (props: ICriteryRatingExpertDisplayProps):JSX.Element => {
  const project: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 


  const milestone:IMilestone  = useDataStore( (state:any) => state.PEFestMilestone )
    .filter((milestone : IMilestone) => milestone.id === props.critery.milestone?.id )[0]
  const isExpert: boolean     = useIsExpert( project, milestone, "critery rating display" )
  const ratings:IRating[] = project?.ratings?.filter(( rating:IRating ) => rating.criteryId === props.critery.id )
  
  const onOpen = (isOpen:boolean, isMax: boolean, data: any) => {

  }
  const onCheck = (data: any) => {

  }
  

  return !isExpert
    ?
    <CriteryRatingView ratings={ratings} milestone={milestone}/>
    :
    <div>
      <CriteryRatingEngine 
        ratings={ratings}  
        critery={props.critery}
        category={props.category}
        onOpen={onOpen}
        onCheck={onCheck}
      />
      {/* <CorrectSingleRatingForm items={ratings} milestone={milestone} label="Adjust other ratings" /> */}
    </div>
    
}
 

export default CriteryRatingExpertDisplay


