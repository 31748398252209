import { ID, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { Gallery, Likes } from "@/libs/useful"
import Shortcode from "@/libs/useful/shortcode"
import { __ } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { isRole } from "@/settings/zustand/user"
import { Button, Collapse, Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import TrackClosedLabel from "../../utils/TrackClosedLabel"
import TrackDomains from "../../utils/TrackDomains"
import TrackVKLink from "../../utils/TrackVKLink"
import TrackVideo from "../../utils/TrackVideo"
import ModeratorAvatar from "../../utils/getModeratorAvatar"
import { trimContent } from "../../utils/trimContent"
import TrackEditor from "./TrackEditor"
import Bookmark from "@/libs/useful/Bookmark"
import QRCodeButton from "@/modules/pe-basic-module/views/core/QRCodeButton"
import { getRouteByComponent } from "@/libs/layouts"

/* Protopia Ecosystem component */
export interface ITrackTaxProps {
    filter: ID[]
}

const TrackTax = (props: ITrackTaxProps): JSX.Element => { 
    const PEFestTrack: ITrack[] = useDataStore((state: any) => state.PEFestTrack )
    const getCurrent = () => {
        let cur: ITrack | null = null
        if( props.filter[0] === -1 ) {
            
        }
        else {
            const track: ITrack = useDataStore.getState().PEFestTrack?.filter((track: ITrack) => track.id === props.filter[0]?.toString() )[0]
            cur = track ? track : null
        }
        return cur
    }
    const [current, setCurrent] = useState<ITrack | null>( getCurrent() )
    
    useEffect(() => {
        setCurrent(getCurrent())
    }, [ props.filter, PEFestTrack ])

    return <>
        <Collapse 
            className=' container-float' 
            isOpen={ !!current && props.filter[0] !== -1 } 
            transitionDuration={ 1000 } 
        >
            <CurrentTrackTax track={current} />
        </Collapse>    
    </>
}
export default TrackTax 
 
interface ICurrentTrackTaxProps {
   track: ITrack | null
}
const CurrentTrackTax = ({...props}: ICurrentTrackTaxProps): JSX.Element => { 
    const {width} = useScreenSize()
    return width > WINDOW_WIDTH.TABLET 
        ? 
        <CurrentTrackTaxScreen {...props} /> 
        : 
        <CurrentTrackTaxMobile {...props} />
} 

const CurrentTrackTaxMobile = ({track, ...props}: ICurrentTrackTaxProps): JSX.Element => { 
    const[isOpen, setOpen] = useState(false)
    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column" >
                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                    <div 
                        className="w-md-33 position-relative"
                        style={{ 
                            minHeight: 250, 
                            backgroundColor: "#00000012"
                        }} 
                    >
                        <div 
                            className="w-100 flex-grow-100 position-relative overflow-hidden pointer "
                            style={{
                                backgroundImage:`url(${ track?.illustration })`, 
                                minHeight: 250,
                                backgroundSize: 'cover',
                                backgroundPosition: "center",
                                height: "100%",
                                maxHeight: 650, 
                            }}
                        >
                            <TrackVKLink item={track || {} as ITrack} />
                            <TrackVideo  item={track || {} as ITrack} />
                            <TrackClosedLabel is_closed={!!track?.is_closed} />
                        </div>
                    </div> 
                </Collapse>          
                <div
                    className=" flex-grow-100 p-0 " 
                >
                    <div className="p-3 d-flex flex-column align-items-center ">
                        <img src={ track?.thumbnail } alt="" className='track-miniature'/> 
                        <div className="display-7 h d-flex text-center">
                            { track?.title }
                            <TrackEditor item={track} />
                        </div> 
                    </div>
                </div> 
                {
                    !!track?.images?.length &&  <Collapse isOpen={!!track?.images?.length && isOpen} className={` ${ isOpen ? "cclosed" : "" } `}>  
                        <div className="pt-3 d-flex flex-wrap px-2">
                            <Gallery 
                                items={ track?.images || []} 
                                itemWidth={ 130 }                 
                                itemHeight={ 100 } 
                                navigation
                                count={{
                                    [WINDOW_WIDTH.PANEL]    : 6,
                                    [WINDOW_WIDTH.LARGE]    : 5,
                                    [WINDOW_WIDTH.SCREEN]   : 4,
                                    [WINDOW_WIDTH.TABLET]   : 4,
                                    [WINDOW_WIDTH.MOBILE]   : 3,
                                    [WINDOW_WIDTH.SMALL]    : 2,
                                }}
                                loop
                                padding={ 0 }
                            />
                        </div>
                    </Collapse>                        
                }   
                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                    <>    
                        <Shortcode content={track?.content || ""} className="px-4 py-2"/>   
                        {
                            track && <ModeratorAvatar {...{item:track, isOpen:true, random:Math.random(), moderator:"moderator"}} />
                        }
                        {
                            track && <ModeratorAvatar {...{item:track, isOpen:true, random:Math.random(), moderator:"moderator2"}} />
                        }
                    </>
                </Collapse>
                <div className=" w-100 d-flex p-0 "> 
                    <div className="btn btn-link small m-auto" onClick={() => setOpen( !isOpen )}> 
                        <Icon icon={isOpen ? "caret-up" : "caret-down"} size={22} /> 
                    </div>   
                </div>
            </div> 
        </div>
    </div>
}
const CurrentTrackTaxScreen = ({track, ...props}: ICurrentTrackTaxProps): JSX.Element => {
    const { landId } = useParams() 
    const[isOpen, setOpen] = useState(false)
    const trimmedContent = trimContent( track?.content || "", 30 ) 

    const toAdmin = `${component_url("FestivalAdminListView")}/${landId}/tracks/${track?.id || ""}`

    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div 
                    className="w-md-33 position-relative"
                    style={{ 
                        minHeight: 250, 
                        backgroundColor: "#00000012"
                    }}
                    onClick={() => setOpen( !isOpen )}
                >
                    <div 
                        className="w-100 flex-grow-100 position-relative overflow-hidden pointer "
                        style={{
                            backgroundImage:`url(${ track?.illustration })`, 
                            minHeight: 250,
                            backgroundSize: 'cover',
                            backgroundPosition: "center",
                            height: "100%",
                            maxHeight: 650, 
                        }}
                    >
                        <TrackVKLink item={track || {} as ITrack} />
                        <TrackVideo  item={track || {} as ITrack} />
                        <TrackClosedLabel is_closed={!!track?.is_closed} />
                    </div>
                </div>                
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center ">
                        <img src={ track?.thumbnail } alt="" className='track-miniature'/> 
                        { track?.title }
                        <TrackEditor item={track} />
                    </div>
                    <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}>
                        <div className="px-3">
                            <TrackDomains item={track || {} as ITrack} />
                        </div>
                    </Collapse>
                    {
                        !!track?.is_buisness && <div className="py-1">
                            <a className=" mx-3 btn btn-link" href={ track?.is_buisness } target="_blank"  rel="noreferrer">
                                {__("Task from entrepreneur")}
                            </a>
                        </div>
                    }
                    <div onClick={() => setOpen( !isOpen )} className="pointer">
                    {
                        trimmedContent.isTrim || track?.moderator?.user_descr?.length > 0
                            ?
                            <>
                                <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                    <div className="px-4 pt-2 pb-2 flex-centered flex-column content-collapse"> 
                                        <Shortcode content={trimmedContent.content} className="h-100 text-clip--transparent" />
                                    </div>                                               
                                </Collapse>
                                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                    <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                                        <Shortcode content={track?.content || ""} className="h-100" /> 
                                    </div>
                                </Collapse>       
                            </>
                            : 
                            <>
                                <Shortcode className="px-4 py-2" content={trimmedContent.content} /> 
                            </>
                    }
                    </div>
                    {
                        !!track?.images?.length &&  <Collapse isOpen={!!track?.images?.length && isOpen} className={` ${ isOpen ? "cclosed" : "" } `}>  
                            <div className=" px-3 d-flex flex-wrap px-0">
                                <Gallery 
                                    items={ track?.images || []} 
                                    itemWidth={ 130 }                 
                                    itemHeight={ 100 } 
                                    navigation
                                    count={{
                                        [WINDOW_WIDTH.PANEL]    : 6,
                                        [WINDOW_WIDTH.LARGE]    : 5,
                                        [WINDOW_WIDTH.SCREEN]   : 4,
                                        [WINDOW_WIDTH.TABLET]   : 4,
                                        [WINDOW_WIDTH.MOBILE]   : 3,
                                        [WINDOW_WIDTH.SMALL]    : 2,
                                    }}
                                    loop
                                    padding={ 0 }
                                />
                            </div>
                        </Collapse>                        
                    } 
                    {
                        track && <ModeratorAvatar {...{item:track, isOpen:true, random:Math.random(), moderator:"moderator"}} />
                    }
                    {
                        track && <ModeratorAvatar {...{item:track, isOpen:true, random:Math.random(), moderator:"moderator2"}} />
                    }
                    <div className="d-flex align-items-center mx-3">
                        <QRCodeButton 
                            item={track || {} as ITrack} 
                            options={{
                                text:`${window.location.origin}/${getRouteByComponent("FestivalView")?.realRoute}/members?tack=${track?.id || "-1"}`, 
                                title: '',
                                width:  410,
                                height: 410, 
                            }} 
                        />
                        <Bookmark item={track || {} as ITrack} />
                        <Likes item={track || {} as ITrack} dataType='PEFestTrack' className='' /> 
                    </div> 
                </div>
                
            </div> 
        </div>
        {
            isRole(["administrator", "SuperAdmin"]) && <NavLink
                to={toAdmin}
                className={"position-absolute top left"}
            >
                <Button minimal icon="unlock" />
            </NavLink>
        }
    </div>
} 