import { FEST_ADMIN_STATUSES } from "../interfaces"
import { useFestDataStore } from "../store"

// статусы, которые позволяют ставить оценки экспертам
export const isStatusOpened = () => {
    const festival = useFestDataStore.getState().festival
    return !![ 
        FEST_ADMIN_STATUSES.PREPAIRE_PHASE, 
        FEST_ADMIN_STATUSES.PRESENTATION_PHASE 
    ].filter(status => status == festival.status).length
}
 
// статусы, которые позволяют ставить оценки экспертам
export const isStatusWorked = () => {
    const festival = useFestDataStore.getState().festival
    return !![ 
        FEST_ADMIN_STATUSES.BEFORE_START_PHASE, 
        FEST_ADMIN_STATUSES.PREPAIRE_PHASE, 
        FEST_ADMIN_STATUSES.PRESENTATION_PHASE 
    ].filter(status => status == festival.status).length
}