import { coords } from 'src/libs/layouts'
import { getStorage } from 'src/settings/zustand'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { IAds, IEvent, INews, IPlace, ITeam, IVideo, MAP_FILTER } from './interfaces'


export const useEventAdminStore: any = create( devtools<IToposAdminStore>( (set: any, get: any ) => ({ 
    client: { },
    setClient: ( client: any ) => set( ( ) => ({ client }) ),
    newEventTypeSetting: {}
    
})))
export const useToposStore : any = create( devtools( persist<IToposStore>(
    (set:any, get:any) => ({ 
        range: 1,
        setRange: (range: number) => set(() =>  {  
            return {range: range}
        } ),
        mapCoords: coords(),
        setMapCoords: (mapCoords: number[]) => set(() => ({ mapCoords }) ),
        mapMode: MAP_FILTER.PLACES,
        setMapMode: (mapMode:MAP_FILTER) => set(() => ({mapMode})), 
        isLandscapeClapanOpen: true,  
        eventTypes: [],
        eventTypeAdminData: {},
        eventRoutes: {},
        eventCarousel:{},
        eventExtendedUtils: {}
    }),
    {
        name: 'pe-topos-storage', 
        storage: createJSONStorage( getStorage ), 
    } 
)))
export const useToposPostsStore : any = create( devtools<IToposPostsStore>(
    (set:any, get:any) => ({  
        events: [],
        setEvents: (events: IEvent[]) => set(() => ({events})),
        places: [],
        setPlaces: (places: IPlace[]) => set(() => ({places})),
        teams: [],
        setTeams: (teams: ITeam[]) => set(() => ({teams})),
        news: [],
        setNews: (news: INews[]) => set(() => ({news})),
        ads: [],
        setAds: (ads: IAds[]) => set(() => ({ads})),
        videos: []
    }) 
))
export interface IToposAdminStore {
    newEventTypeSetting: any
}
export interface IToposStore {
    range: number,
    setRange: (range: number) => void,
    mapCoords: number[],
    setMapCoords: (mapCoords: number[]) => void
    mapMode: MAP_FILTER,
    setMapMode: (mapMode:MAP_FILTER) => void,
    isLandscapeClapanOpen: boolean, 
    eventTypes: string[],
    eventTypeAdminData: any
    eventRoutes: any
    eventCarousel: any 
    eventExtendedUtils: {}
}

export interface IToposPostsStore { 
    events: IEvent[],
    setEvents: (events: IEvent[]) => void
    places: IPlace[],
    setPlaces: (places: IPlace[]) => void
    teams: ITeam[]
    setTeams: (teams: ITeam[]) => void
    news: INews[],
    setNews: (news: INews[]) => void
    ads: IAds[],
    setAds: (ads: IAds[]) => void,
    videos: IVideo[]
}
