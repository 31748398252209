import React, { useState } from 'react'
import { useLocation } from 'react-router' 
import { description, image, init_method, title } from '@/libs/layouts'
import LayoutBodyLocal from './body/LayoutBodyLocal'
import LayoutBodyStatic from './body/LayoutBodyStatic'
import LayoutBodyExtended from './body/LayoutBodyExtended'
import SEO from './seo'
import StorageManager from './storageManager/StorageManager'


export enum LAYOUT_INIT_METHODS {
  STATIC='static',
  LOCAL='local',
  EXTENDED='extended'
}
interface ILayoutBodyProps {

}
const LayoutBody = (props: ILayoutBodyProps) => {
  const location = useLocation()
  const [cl, ] = useState( 
    location.pathname.split("/")
      .splice(1)
        .map((e: string) => `route-${e}`).join(" ") 
  )  
  let content = <LayoutBodyLocal />
  switch( init_method() )
  {
    case LAYOUT_INIT_METHODS.EXTENDED:
      content = <LayoutBodyExtended />
      break;
    case LAYOUT_INIT_METHODS.STATIC: 
      content = <LayoutBodyStatic />
      break;
    case LAYOUT_INIT_METHODS.LOCAL:
    default:
      content = <LayoutBodyLocal />
      break;
  }
  return <div className={`full ${cl}`}>
    <SEO
      title={title()}
      description={description()}
      image={image()}
    />
    <StorageManager />
    <header />
    <main>
      <div className="container-fluid  cont h-100 w-100"> 
        { content }	 
      </div>
    </main>
    <footer /> 
  </div>
} 

export default LayoutBody
