import actions from "@/modules/pe-basic-module/data/actions";
import { MENU_MESSAGE_SEED } from "../../widgets/MessagesWidget";
import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes";
import { ID, IPEMessage } from "@/libs/interfaces/layouts";

export  default function getMenuMessageCount (landId: ID): Promise<IPEMessage[]> {
    return actions(GET_FEED_ACTION, {
        data_type: "PEMessage",
        paging: ` metas: [{ key: "menu", value_bool: true }] `,
        land_id: landId,
        seed: MENU_MESSAGE_SEED
    })
}