import BasicState from "@/libs/basic-view"
import DiaryListForm from "./diary/DiaryListForm"

 
 class PEDiaryFeedView extends BasicState { 
    props: any
    addRender = () => (
        <>
          <div className="container">
            <div className="row justify-content-center"> 
                <DiaryListForm  />
            </div>
          </div>
        </>
          ) 
  }
  export default PEDiaryFeedView