import { HTML } from "@/libs/interfaces/layouts"
import { AppToaster, MediaChooser } from "@/libs/useful"
import EmojiButton from "@/libs/useful/comments/EmojiButton"
import { __ } from "@/libs/utilities"
import { IIfaceProps, IState, IUser, useIfaceStore, useMainStore } from "@/settings/zustand"
import { Button, Popover, Position, Tooltip } from "@blueprintjs/core"
import { EmojiClickData } from "emoji-picker-react"
import { useEffect, useRef, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"; 
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"

/* Protopia Ecosystem component */
export interface IMessageInputProps {
    adressee: IUser
    onSend: () => void
}
const MessageInput = (props: IMessageInputProps): JSX.Element => {
    const landId = useMainStore((state:IState) => state.landId)
    const newMessageText = useIfaceStore((state: IIfaceProps) => state.newMessageText)
    const setNewMessageText = useIfaceStore((state: IIfaceProps) => state.setNewMessageText)
    const newMessageParent = useIfaceStore((state: IIfaceProps) => state.newMessageParent)
    const setNewMessageParent = useIfaceStore((state: IIfaceProps) => state.setNewMessageParent)
    const textAreaRef           = useRef(null) 
    const [gallery, setGallery] = useState<string[]>([])
    const [thumbnail, setThumbnail] = useState<string>("")
    const [thumbnail_name, setThumbnailName] = useState<string>("")  

    useEffect(() => {
        setNewMessageText( "" )
        setNewMessageParent("0")
        return () => {
            setNewMessageText( "" )
            setNewMessageParent("0")
        }
    }, [])
    
    const onMediaChange = (fileData: string, file: any ) => {
        setThumbnail( fileData )
        setThumbnailName( file.name )
    }
    const onText = (evt: any) => {
        setNewMessageText(evt.target.value) 
    }
    const onEmoji = (emojiData: EmojiClickData) => {
        setNewMessageText((inputValue:any) => inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji))
    }
    const onSend = () => {
        if(!newMessageText) {
            AppToaster.show({
                message: __("Write something")
            })
            return
        }
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                data_type: "PEMessage",
                landId,
                item: {
                    title: newMessageText,
                    adressee: props.adressee.id,
                    thumbnail,
                    thumbnail_name,
                    parent: Number(newMessageParent) || 0
                } 
            }
        )
        .then(() => {
            setNewMessageText( "" )
            setThumbnail( "" )
            setThumbnailName( "" )
            props.onSend()
        })
    }
    return <div className='position-relative pl-4 pt-3'>
        <div className="card " >
            <TextareaAutosize 
                ref={ textAreaRef }
                className="w-100 pt-1 pl-5 mr-5 pb-2 input dark "
                style={{resize: "none", paddingLeft: 65, paddingRight: 65}}
                minRows={1}
                value={newMessageText}
                onChange={ onText }
                autoFocus
            />
        </div>
        <div className="position-absolute left bottom z-index-100 pl-4">
            <Popover
                popoverClassName="w-100"
                interactionKind="click"
                position={Position.TOP_LEFT}
                content={
                    <div className="col-126"> 
                        <MediaChooser 
                            url={thumbnail || ""}
                            id={ "message-thumbn" } 
                            padding={0}
                            height={ 120 }
                            width={ 120 } 
                            hideLib 
                            accept={ "image" }
                            isUploadHide={ false }
                            isURLHide 
                            isFileNameHide 
                            onChange={ onMediaChange } 
                            limitSize={ 2000000}
                            style={{}} 
                            isHideClearBtn 
                        />
                    </div>
                }
            >
                {
                    thumbnail
                        ?
                        <div className="thumbnail"
                            style={{
                                width: 34,
                                height:26,
                                backgroundImage: `url(${thumbnail})`    
                            }}
                        />
                        :
                        <Button minimal  >
                            <i className="far fa-image" /> 
                        </Button>
                }
            </Popover>
        </div>
        <div className="position-absolute right bottom z-index-100">
            <EmojiButton onChoose={onEmoji}/>
            <Tooltip
                content={<div className="p-2">{__( "Do comment" )}</div>}
                position="top"

            >
                <Button minimal onClick={onSend} >
                    <i className="fas fa-play" /> 
                </Button>   
            </Tooltip> 
        </div>
    </div>
}
export default MessageInput