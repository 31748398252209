import { ILike, LIKE_TYPE } from "@/libs/interfaces/layouts";

export const likes = (): ILike[] => [
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 1,
            "display_name": "Марфа Маревна",
            "avatar": "https://i.pinimg.com/236x/c2/e2/1b/c2e21bbc9f2754a7518a0a98a7a21f2b.jpg",
            "roles": [],
            "post_content": ""
        }
    }, 
    {
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 3,
            "display_name": "Выхухоль",
            "avatar": "https://i.pinimg.com/564x/4c/1d/97/4c1d973710488b8ad11a8519fc59ce2a.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Владимир Винокур",
            "avatar": "https://i.pinimg.com/564x/dd/26/7b/dd267b369ed75afab5671db8f688a33c.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Фраер Жених",
            "avatar": "https://i.pinimg.com/564x/b7/83/33/b78333180d279cfa2104d7d6d6208cd4.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Модельер Зайцев",
            "avatar": "https://i.pinimg.com/236x/30/78/cc/3078cc6ff047c9b933395e7cee2eee4d.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Иван Деньги",
            "avatar": "https://i.pinimg.com/236x/9d/61/71/9d61716801737b343b34fe03f3288b03.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Лада Калина",
            "avatar": "https://i.pinimg.com/236x/1b/a7/95/1ba7951c26943351623816bfbe608cdc.jpg",
            "roles": [],
            "post_content": ""
        }
    },

    
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Модельер Зайцев",
            "avatar": "https://i.pinimg.com/236x/30/78/cc/3078cc6ff047c9b933395e7cee2eee4d.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Иван Деньги",
            "avatar": "https://i.pinimg.com/236x/9d/61/71/9d61716801737b343b34fe03f3288b03.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Лада Калина",
            "avatar": "https://i.pinimg.com/236x/1b/a7/95/1ba7951c26943351623816bfbe608cdc.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 1,
            "display_name": "Марфа Маревна",
            "avatar": "https://i.pinimg.com/564x/f8/21/14/f8211410289ca21a2d29649cc173e351.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 3,
            "display_name": "Выхухоль",
            "avatar": "https://i.pinimg.com/564x/4c/1d/97/4c1d973710488b8ad11a8519fc59ce2a.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Владимир Винокур",
            "avatar": "https://i.pinimg.com/564x/dd/26/7b/dd267b369ed75afab5671db8f688a33c.jpg",
            "roles": [],
            "post_content": ""
        }
    },
    { 
        "type": LIKE_TYPE.LIKE,
        "user": {
            "id": 2,
            "display_name": "Фраер Жених",
            "avatar": "https://i.pinimg.com/564x/b7/83/33/b78333180d279cfa2104d7d6d6208cd4.jpg",
            "roles": [],
            "post_content": ""
        }
    }, 
    { 
        "type": LIKE_TYPE.ANGRY,
        "user": {
            "id": 1,
            "display_name": "Марфа Маревна",
            "avatar": "https://i.pinimg.com/236x/c2/e2/1b/c2e21bbc9f2754a7518a0a98a7a21f2b.jpg",
            "roles": [],
            "post_content": ""
        }
    }
]