import { Button, ButtonGroup, Intent, Popover, Position } from "@blueprintjs/core"
import { cardTypes } from "../../data/cardTypes"
import { CARD_TYPE, IFestival } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ICardTypeSwitcherProps {
    cardType: CARD_TYPE
    onCardType: (cardType:CARD_TYPE) => void 
}
const CardTypeSwitcher = (props: ICardTypeSwitcherProps): JSX.Element => {
    const {width} = useScreenSize() 
    return width > WINDOW_WIDTH.TABLET ? <CardTypeScreenSwitcher {...props}/> : <CardTypeMobyleSwitcher {...props}/>
}
export default CardTypeSwitcher

const CardTypeScreenSwitcher = (props: ICardTypeSwitcherProps): JSX.Element => {
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    return <div className="mt-5 position-relative">
        <ButtonGroup vertical className='mr-3 sticky-top'>
            {
                festival.isChangeCardType &&
                    cardTypes().map(cardType => {
                        // if( cardType.type !== props.params.cardType) return null
                        let is:boolean = cardType.type === props.cardType
                        if( props.cardType === CARD_TYPE.NONE ) {
                            is = cardType.type === festival.memberCardType
                        }
                        return <Button 
                            key={cardType.type}
                            disabled={ is }
                            minimal={ !is }
                            intent={ !is ? Intent.NONE : Intent.DANGER }
                            data-hint={ __(cardType.title) }
                            className={`hint hint--right flex-centered `}
                            onClick={ () => props.onCardType(cardType.type) }
                        >
                            <i className={cardType.icon + ( is ? "" : " opacity_5" ) } />
                        </Button>
                    })
            }
        </ButtonGroup>
    </div> 
}

const CardTypeMobyleSwitcher = (props: ICardTypeSwitcherProps): JSX.Element => {
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    const [cardType, setCardType] =useState<any>( 
        cardTypes().filter(t => t.type === (props.cardType === CARD_TYPE.NONE ? festival.memberCardType: props.cardType) )[0] 
    )
    const onCardType = (cardTypeType: CARD_TYPE) => {
        setCardType(
            cardTypes().filter(t => t.type === cardTypeType )[0]  
        )
        props.onCardType(cardTypeType)
    }
    return <div className="ml-2 z-index-100 position-absolute">
        <Popover
            interactionKind="hover"
            position={Position.BOTTOM_RIGHT}
            content={ <ButtonGroup vertical className='m-0'>
                {
                    festival.isChangeCardType &&
                        cardTypes().map(cardType => {
                            // if( cardType.type !== props.params.cardType) return null
                            let is:boolean = cardType.type === props.cardType
                            if( props.cardType === CARD_TYPE.NONE ) {
                                is = cardType.type === festival.memberCardType
                            }
                            return <Button 
                                key={cardType.type}
                                disabled={ is }
                                minimal={ !is }
                                intent={ !is ? Intent.NONE : Intent.DANGER }  
                                onClick={ () => onCardType(cardType.type) }
                                alignText="left"
                            >
                                <div className="d-flex">
                                    <i className={cardType.icon + ( is ? "" : " opacity_5" ) } />
                                    <div className="ml-1 small">
                                        { __(cardType.title) }
                                    </div>
                                </div>
                            </Button>
                        })
                }
            </ButtonGroup>}
        >
            <Button intent={ Intent.DANGER } >
                <i className={cardType.icon  } />
            </Button>
        </Popover> 
    </div> 
}