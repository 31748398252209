import { Gallery } from "@/libs/useful"
import { ILand } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface IFestivalGalleryProps {

}
const FestivalGallery = (props: IFestivalGalleryProps): JSX.Element => {
    const site: ILand = useFestDataStore((state: IFestDataStor) => state.site)
    return !!site.images?.length
        ?
        <section>
            <div className='pe-festival-gallery-container short-container-0 over-colored'>
                <Gallery 
                    items={ site.images } 
                    itemWidth="100%"                
                    itemHeight={ 320 } 
                    navigation
                    count={{
                        [WINDOW_WIDTH.PANEL]    : 5,
                        [WINDOW_WIDTH.LARGE]    : 4,
                        [WINDOW_WIDTH.SCREEN]   : 3,
                        [WINDOW_WIDTH.TABLET]   : 2,
                        [WINDOW_WIDTH.MOBILE]   : 1,
                        [WINDOW_WIDTH.SMALL]    : 1,
                    }}
                    loop
                    padding={ 4 }
                />
            </div>
        </section>        
        :
        <></>
}
export default FestivalGallery