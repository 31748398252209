import { ID, IMenuItem, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { AppToaster, Loading } from "@/libs/useful"
import EmojiButton from "@/libs/useful/comments/EmojiButton"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { IDiary, IFestFilters } from "@/modules/pe-fest-module/data/interfaces"
import { IFestStore, useFestStore } from "@/modules/pe-fest-module/data/store"
import { useFestival } from "@/modules/pe-fest-module/hooks/festival"
import PEFestProjectTerm from "@/modules/pe-fest-module/views/projectList/PEFestProjectTerm"
import PListUnderMenu from "@/modules/pe-fest-module/views/projectList/PListUnderMenu"
import { Button, Collapse, Icon, Popover, Tooltip } from "@blueprintjs/core"
import { EmojiClickData } from "emoji-picker-react"
import { useEffect, useState } from "react"
import { Route, Routes, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import actions from "../../data/actions"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "../../data/actionTypes"
import useScreenSize from "../../hooks/useScreenSize"
import Feed from "../core/Feed"
import DiaryCard from "./DiaryCard"
import DiarySingleModal from "./DiarySingleModal"

/* Protopia Ecosystem component */
export interface IDiaryListFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
}
interface IParamsPaging {
    params: any
    paging: string
}
const DiaryListForm = (props: IDiaryListFormProps): JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || "-1" 

    const onPaging = (): IParamsPaging => {
        let params: any = {}, paging: string = ""
        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
            paging = ( ` taxonomies:[ {tax_name: "fmru_track", term_ids: ${ filters.tracks[0] }} ] ` )
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
            paging = ( ` taxonomies:[ {tax_name: "fmru_school", term_ids: ${ filters.schools[1] }} ] ` )
        }
        return {params, paging}
    }

    // пагинация c учетом фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters ) 
    const [paging, setPaging] = useState<string>( "" )
    const [isPagingLoad, setPagingLoad] = useState<boolean>( false )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const updateURLSearchParams = () => {
        const p = onPaging()
        setPaging( p.paging )
        SetURLSearchParams( p.params )
        setPagingLoad(true)
    }
    useEffect(()=> {
        const tracks = URLSearchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
    }, [])
    useEffect(() => { 
        updateURLSearchParams()
        
    }, [ filters ])

    const isLoading = useFestival(!props.parentMode, festId) 
 

    return <div className="w-100">
        <PEFestProjectTerm />
        <div className=' container pt-3 p-0'>
            <PListUnderMenu />
            {
                isLoading  || !isPagingLoad
                    ?
                    <div className="py-5">
                        <Loading /> 
                    </div>
                    :
                    <div className="container d-flex">
                        <div className="clapan-container">
                            
                        </div>
                        <div className=' small-container '>
                            <Feed 
                                offset={0}
                                data_type={"PEDiary"} 
                                land_id={ festId }
                                paging={ paging } 
                                class_name=' '
                                containerClassName="w-100" 
                                component={ DiaryCard }
                                topPagiHide
                                topPagingWidget={<AddDiaryForm  resource_id={Number( filters.tracks[0] )} resource_type={"PEFestTrack"} />}
                                params={{ 
                                    col: 2
                                }}
                            />
                        </div>
                        <div className="clapan-container">
                            
                        </div>
                    </div>
            }
        </div>
        <Routes>
            <Route
                path="/modal/:dairyId"
                element={ <DiarySingleModal />}
            />
        </Routes>
    </div>
}
export default DiaryListForm

interface IAddDiaryForm {
    resource_id: number
    resource_type: string
}
export const AddDiaryForm = ({resource_id, resource_type, ...props}:IAddDiaryForm) => {
    const params = useParams()
    const {width} = useScreenSize()
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters ) 
    const landId: ID = params.landId || "-1" 
    const [item, setItem] = useState<IDiary>({} as IDiary)

    const getDefault = (): IDiary => {
        let newItem: any = {
            title:" ",
            post_content:" ",
            resource_id, 
            resource_type,
            images: new Array(),
            like_count:0,
        }
        if(resource_type === "PEFestTrack") {
            newItem.fmru_track = [resource_id]
        }
        if(resource_type === "PEFestSchool") {
            newItem.fmru_track = [resource_id]
        }
        return newItem as IDiary   
    }
    useEffect(() => {
         
        setItem(getDefault())
    }, [])
    const onChange = (value: any, field: keyof IDiary, file: any) => {
        if(field === "thumbnail") {
            setItem({
                ...item,
                "thumbnail": value,
                "thumbnail_name": file.name
            }) 
        } else {
            setItem({
                ...item,
                [field]: value
            })
        }
    }
    const onCreate = () => {
        if( !item.post_content.trim() && !item.title?.trim() && !item.images?.length ) {
            AppToaster.show({
                message: __("Something need")
            })
            return
        } 
        //console.log(item)
        //return

        actions(
            UPDATE_SINGLE_TYPE,
            {
                landId,
                data_type: "PEDiary",
                item
            }
        )
        .then(
            () => {
                AppToaster.show({
                    message: __("New diary created")
                })
                setItem( getDefault() )
                actions(
                    GET_FEED_ACTION, 
                    { 
                        data_type: "PEDiary", 
                        land_id: landId,
                        paging: ` metas: [{key:"resource_id", value:"${resource_id}"}, {key:"resource_type", value:"${resource_type}"}]`
                    }
                )
            }
        )
    }
    return width < WINDOW_WIDTH.TABLET
        ?
        <ConfirmButton 
            buttonIcon="plus"
            buttonMinimal
            buttonLabel={<div className="small text-nowrap">{__("Create post")}</div>}
            dialogTitle={__("Create new Post")}
            yesLabel={__("Start create")}
            noLabel={__("Cancel")}
            yesLock={ !item.title }
            onConfirm={onCreate}
        >
            <div className="p-4 max-height overflow-y-auto">            
                <InputForm item={item} onChange={onChange} />
                
            </div>
        </ConfirmButton>
        :
        <div className="w-100">
            <div className="row">
                <Collapse isOpen className="w-100 ">
                    <div className="vk-post card p-4">
                        <InputForm item={item} onChange={onChange} />
                        <Button className="mt-2" minimal onClick={onCreate}>
                            {__("Create new Post")}
                        </Button>
                    </div> 
                </Collapse>
            </div>
        </div>
        
}

interface IInputFormProps {
    item: IDiary
    onChange: (value: any, field: keyof IDiary, file: any) => void
}
const InputForm = ({item, onChange}: IInputFormProps): JSX.Element => { 
    const [isTitle, setIsTitle] = useState<boolean>(false)
    const [isThumbnail, setIsThumbnail] = useState<boolean>(false)
    const [isGallery, setIsGallery] = useState<boolean>(false)
    const onEmoji = (emojiData: EmojiClickData) => {
        onChange( item.post_content + (emojiData.isCustom ? emojiData.unified : emojiData.emoji), "post_content", null)
    }
    return <>
        <div className="d-flex w-100 pl-3 mr-15">
            <FieldInput
                editable
                //title={__("Content")}
                type={SCALAR_TYPES.HTML}
                onChange={onChange}
                value={item.post_content}
                placeholder={__("Start write")}
                autoFocus
                field="post_content"
                notClear
            />
            <div className="d-flex flex-column ml-3">
                <Tooltip
                    interactionKind="hover"
                    hoverOpenDelay={1000}
                    content={
                        <div className="">{__("Add emoji")}</div>
                    }
                >
                    <EmojiButton onChoose={onEmoji}/>
                </Tooltip>
                <Tooltip
                    interactionKind="hover"
                    hoverOpenDelay={1000}
                    content={
                        <div className="">{__("Set title")}</div>
                    }
                >
                    <Button minimal={!isTitle} className="px-3 ml-1" onClick={() => setIsTitle(!isTitle)}>
                        <Icon icon="header" size={22}/>
                    </Button>
                </Tooltip>
                <Tooltip
                    interactionKind="hover"
                    hoverOpenDelay={1000}
                    content={
                        <div className="">{__("add images")}</div>
                    }
                >
                    <Button minimal={!isGallery} className="px-3 ml-1" onClick={() => setIsGallery(!isGallery)}>
                        <Icon icon="grid-view" size={22}/>
                    </Button>
                </Tooltip>
            </div>
            
        </div>
       
        <div className={ isTitle ? "" :"d-none"}>                
            <FieldInput
                editable
                title={__("Title")}
                type={SCALAR_TYPES.TITLE}
                onChange={onChange}
                value={item.title}
                field="title"
                notClear
            />
        </div> 
        <div className={ isGallery ? "" :"d-none"}>
            <FieldInput
                editable
                title={__("Gallery")}
                type={SCALAR_TYPES.GALLERY}
                onChange={ onChange }
                value={item.images }
                field="images"
                notClear
            />
        </div>
    </>
}