
import { Json, IRoutingData, ID, IMenuItem, IPost } from "src/libs/interfaces/layouts" 

//
export interface IFolderProps {
  title: string
  hidden: boolean
  id: ID
  isSelect?: boolean
  level?: number
  folders?: IFolderProps[]
}
//
export interface ISingleFolderProps {
  folder?: IFolderProps
  onChangeRoute: (data: any, routeJson: Json ) => void
  moveToFolder: (routeData: any, folderId: ID) => void
  onRemoveRoute: (route: any) => void
}

//
export interface ICardRouteProps {
  module: any
  menu : string
  data: IRoutingData
  hidden: boolean
  moveToFolder: (routeData: any, folderId: ID) => void
  onChangeRoute: (data: any, routeJson: Json) => void
  onRemoveRoute: (route: any) => void
}

export interface IAdminStateProps {
  signType: SIGN_TYPE
  setSignType: () => {}
}

export enum SIGN_TYPE {
  CARD = 'card', 
  ROW  = 'row'
}

export interface IDataTableFormProps {
  data_type: string
  data: any[]
  isList: boolean
} 

export interface IDataFormProps {
  data_type: string 
  isNew?: boolean
  item?: any
  route: string
}

export interface IMedia {
  id: ID
  url?: URL
  large: URL
  title: string
  post_date?: Date
  mime?: string
  ancestors?: IPost[]
  post_parent?: IPost
}

export const CORRECT_DICTIONARIES_TAG = "correct-dictionary"
export const ALL_DICTIONARIES_TAG = "all-dictionary"

export enum LOG_DANGER_TYPE {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  FORCE_MAJEURE ="force-majeure"
}

export interface ILogDatas {
  [key:string] : ILogData
}

export interface ILogData {
  chapter : string
  title: string
  dangerType: LOG_DANGER_TYPE
}

export enum TIME_PERIOD {
  HALF_HOUR="HALF_HOUR",
  HOUR="HOUR",
  TWO_HOURS="TWO_HOURS",
  THREE_HOURS="THREE_HOURS",
  SIX_HOURS="SIX_HOURS",
  TWELVE_HOURS="TWELVE_HOURS",
  DAY="DAY",
  TWO_DAYS="TWO_DAYS",
  THREE_DAYS="THREE_DAYS",
  WEEK="WEEK",
  TWO_WEEKS="TWO_WEEKS",
  MONTH="MONTH",
  TWO_MONTHS="TWO_MONTHS",
  THREE_MONTHS="THREE_MONTHS",
  SIX_MONTHS="SIX_MONTHS",
  YEAR="YEAR",
}